import { Box } from "@mui/material";
import { ChangeEvent, ReactElement } from "react";
import BookmarkToggle from "./InputFields/BookmarkToggle";

interface BookmarkProps {
  bookmarked: boolean;
  handleBookmark: (event: ChangeEvent<HTMLInputElement>) => void;
}

export default function Bookmark(props: BookmarkProps): ReactElement {
  return (
    <Box
      className="bookmark"
      maxWidth={props.bookmarked ? "24px" : 0}
      sx={{
        opacity: props.bookmarked ? 1 : 0,
        transition: "opacity 0s, max-width 0.3s;",
      }}
      marginRight={0.5}
    >
      <BookmarkToggle
        checked={props.bookmarked}
        handleBookmark={props.handleBookmark}
        sx={{
          marginLeft: "-4px",
          "& .bookmark-btn": {
            padding: "4px",
          },
        }}
      />
    </Box>
  );
}
