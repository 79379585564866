import axios from "axios";
import {
  CreateClientContactDTO,
  ClientContactDTO,
  GetClientContactParams,
} from "../../Types/ClientContact";

export default class ClientContactHttpService {
  public static async createContact(
    contact: CreateClientContactDTO
  ): Promise<ClientContactDTO> {
    const response = await axios.post<ClientContactDTO>(
      `/api/client-contacts`,
      contact
    );
    return response.data;
  }

  public static async deleteContact(contactId: number): Promise<void> {
    await axios.delete<void>(`/api/client-contacts/${contactId}`);
  }

  public static async updateContact(
    contact: ClientContactDTO,
    id: number
  ): Promise<void> {
    await axios.put<void>(`/api/client-contacts/${id}`, contact);
  }

  public static async getContacts(name: string): Promise<ClientContactDTO[]> {
    const params: GetClientContactParams = {};

    if (name) {
      params.name = name;
    }

    const response = await axios.get<ClientContactDTO[]>(
      "/api/client-contacts",
      {
        params: params,
      }
    );

    return response.data;
  }

  public static async getContactById(id: number): Promise<ClientContactDTO> {
    const response = await axios.get(`/api/client-contacts/${id}`);
    return response.data;
  }

  public static async getContactsByName(
    ventureClientId: number,
    name: string
  ): Promise<ClientContactDTO[]> {
    return (
      await axios.get<ClientContactDTO[]>(`/api/client-contacts`, {
        params: { ventureClientId, name },
      })
    ).data;
  }
}
