import { Box, Chip, Typography, styled } from "@mui/material";
import theme from "../../../theme";
import { ReactElement } from "react";

const ContactCounterWrapper = styled(Box, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(
  ({
    $color = "white",
    $fullWidth = true,
  }: {
    $color: "white" | "gray";
    $fullWidth: boolean;
  }) => ({
    display: "flex",
    gap: theme.spacing(2),
    width: "100%",
    height: "fit-content",
    backgroundColor:
      $color === "white"
        ? theme.palette.surface.secondary.main
        : theme.palette.surface.primary.main,
    alignItems: "center",
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    borderRadius: theme.shape.radius.minimal,
    justifyContent: "space-between",
    ...(!$fullWidth && {
      flex: "1 1 0",
    }),
    ...theme.typography.body2,
  })
);

interface ContactCounterProps {
  label: string;
  backgroundColor: "white" | "gray";
  counterColor?: "primary" | "secondary";
  fullWidth: boolean;
  value: string;
}

const ContactCounter = ({
  label,
  backgroundColor: color,
  fullWidth,
  value,
  counterColor = "primary",
}: ContactCounterProps): ReactElement => {
  return (
    <ContactCounterWrapper $color={color} $fullWidth={fullWidth}>
      <Typography variant="body2">{label}</Typography>
      <Chip size="small" label={value} variant="counter" color={counterColor} />
    </ContactCounterWrapper>
  );
};

export default ContactCounter;
