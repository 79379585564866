import { ReactElement } from "react";
import { Typography, Box, styled } from "@mui/material";
import theme from "../../../../theme";
import { MissingProjectInformation } from "../../../../Types/ProjectRules";
import { ErrorOutline } from "@mui/icons-material";

const StyledContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  color: theme.palette.text.mediumEmphasis,
  ...theme.typography.body1,
}));

interface StageQualityModalProps {
  missingData?: MissingProjectInformation;
}

function StageQualityModal(props: StageQualityModalProps): ReactElement {
  return (
    <StyledContainer data-cy="missing-data-tooltip">
      <Box
        display="flex"
        gap={theme.spacing(1)}
        color="text.primary"
        alignItems="center"
      >
        <ErrorOutline sx={{ fontSize: "20px" }} />
        <Typography variant="subtitle1">Required Data</Typography>
      </Box>
      <Typography>
        The information collected in this stage does not meet minimum data
        quality requirements.
        <br />
        To improve data quality please add or edit the following.
      </Typography>
      {!!props.missingData?.fields.length && (
        <Box>
          <Typography>Required Information</Typography>
          {props.missingData.fields.map((field) => (
            <Typography key={field.name} color="text.action.main">
              {field.name}
            </Typography>
          ))}
        </Box>
      )}

      {!!props.missingData?.files.length && (
        <Box>
          <Typography>Required Files</Typography>
          {props.missingData?.files.map((field) => (
            <Typography key={field.name} color="text.action.main">
              {field.name}
            </Typography>
          ))}
        </Box>
      )}
    </StyledContainer>
  );
}

export default StageQualityModal;
