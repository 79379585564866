import { IconButton, styled, Typography } from "@mui/material";
import { Dispatch, ReactElement, SetStateAction, useState } from "react";
import { StartupContactDTO } from "../../../Types/StartupContact";
import CreateStartupContactModal from "./CreateStartupContactModal";
import DetailsStartupContactModal from "./DetailsStartupContactModal/DetailsStartupContactModal";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import {
  DataGridPremiumProps,
  GridActionsCellItem,
  GridColDef,
  GridValueGetterParams,
} from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import theme from "../../../theme";
import CustomDataGrid from "../../ProjectList/CustomDataGrid";
import { useSnackbar } from "notistack";

interface StartupsDataGridProps extends Omit<DataGridPremiumProps, "columns"> {
  refreshStartupContact: () => void;
  setCreateStartupContactModalOpen: Dispatch<SetStateAction<boolean>>;
  createStartupContactModalOpen: boolean;
  apiRef: React.MutableRefObject<GridApiPremium>;
}

type GridColumn = GridColDef & {
  hidden?: boolean;
};

const StyledButton = styled(IconButton)(() => ({
  display: "none",
  color: theme.palette.icon.action.main,
}));

export default function StartupsDataGrid({
  apiRef,
  refreshStartupContact,
  setCreateStartupContactModalOpen,
  createStartupContactModalOpen,
  ...defaultProps
}: StartupsDataGridProps): ReactElement {
  const { enqueueSnackbar } = useSnackbar();

  const [startupContactDetailsModalOpen, setStartupContactDetailsModalOpen] =
    useState<boolean>(false);
  const [selectedStartupContact, setSelectedStartupContact] =
    useState<StartupContactDTO>();

  const handleStartupDetailsModalOpen = (startupContact: StartupContactDTO) => {
    setStartupContactDetailsModalOpen(true);
    setSelectedStartupContact(startupContact);
  };

  const handleStartupContactRefresh = () => {
    refreshStartupContact();
  };

  const copyToClipboard = (
    e: React.MouseEvent<HTMLElement>,
    value: string,
    entity: string
  ) => {
    e.stopPropagation();
    navigator.clipboard.writeText(value);
    enqueueSnackbar(entity + " successfully copied to clipboard", {
      variant: "success",
    });
  };

  const baseColumns: GridColumn[] = [
    {
      flex: 1,
      field: "name",
      headerName: "Name",
    },
    {
      flex: 1,
      field: "title",
      headerName: "Title",
      valueGetter: (params: GridValueGetterParams) => {
        if (!params.row.title) {
          return "";
        }
        return `${params.row.founder ? "(Co-)Founder " : ""}${
          params.row.title
        }`;
      },
    },
    {
      flex: 1,
      field: "company",
      headerName: "Company",
      valueGetter: (params: GridValueGetterParams) => {
        if (!params.row.startup || !params.row.startup.name) {
          return "";
        }
        return `${params.row.startup.name}`;
      },
    },
    {
      flex: 1,
      field: "email",
      headerName: "Email",
      type: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            key={1}
            icon={
              <>
                <StyledButton className="copy-icon">
                  <ContentCopyOutlinedIcon sx={{ fontSize: "1rem" }} />
                </StyledButton>
                <Typography
                  overflow="hidden"
                  textOverflow="ellipsis"
                  color="text.action.main"
                >
                  {params.row.email}
                </Typography>
              </>
            }
            label={params.row.email}
            onClick={(e) =>
              copyToClipboard(e, params.row.email, "Email address")
            }
            sx={{
              maxWidth: "100%",
            }}
          />,
        ];
      },
    },
    {
      flex: 1,
      field: "phone",
      headerName: "Phone",
      type: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            key={1}
            icon={
              <>
                <StyledButton className="copy-icon">
                  <ContentCopyOutlinedIcon sx={{ fontSize: "1.2rem" }} />
                </StyledButton>
                <Typography
                  overflow="hidden"
                  textOverflow="ellipsis"
                  color="text.action.main"
                >
                  {params.row.phone}
                </Typography>
              </>
            }
            label={params.row.phone}
            onClick={(e) => copyToClipboard(e, params.row.phone, "Phone")}
            sx={{
              maxWidth: "100%",
            }}
          />,
        ];
      },
    },
  ];

  const columns = baseColumns.filter((column) => !column.hidden);

  return (
    <>
      <CustomDataGrid
        {...defaultProps}
        apiRef={apiRef}
        columns={columns}
        noRowsText="No Contact Found"
        onRowClick={(e) => {
          handleStartupDetailsModalOpen(e.row);
        }}
      />
      {selectedStartupContact && startupContactDetailsModalOpen && (
        <DetailsStartupContactModal
          setModalOpen={setStartupContactDetailsModalOpen}
          modalOpen={startupContactDetailsModalOpen}
          contact={selectedStartupContact}
          handleSave={handleStartupContactRefresh}
        />
      )}
      {createStartupContactModalOpen && (
        <CreateStartupContactModal
          setModalOpen={setCreateStartupContactModalOpen}
          modalOpen={createStartupContactModalOpen}
          handleSave={handleStartupContactRefresh}
        />
      )}
    </>
  );
}
