import { forwardRef, ReactElement } from "react";
import { Box, styled, Typography, BoxProps } from "@mui/material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import theme from "../../theme";

interface ArchiveBannerProps extends BoxProps {
  mainText: string;
  subText: string;
}

const ArchiveBannerContainer = styled(Box)(() => ({
  width: "100%",
  padding: theme.spacing(1),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(1),
  backgroundColor: theme.palette.surface.cool,
  position: "sticky",
  zIndex: 4,
}));

const ArchiveBanner = forwardRef(function ArchiveBanner(
  { mainText, subText, ...boxProps }: ArchiveBannerProps,
  ref
): ReactElement {
  return (
    <ArchiveBannerContainer
      data-testid="session-warning"
      ref={ref}
      {...boxProps}
    >
      <ReportProblemIcon color="warning" />
      <Typography variant="subtitle2">{mainText}</Typography>
      <Typography>{subText}</Typography>
    </ArchiveBannerContainer>
  );
});

export default ArchiveBanner;
