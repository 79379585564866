import { Grid } from "@mui/material";
import { ReactElement } from "react";
import { GenericProject } from "../../../Types/Project";
import TotalProjects from "./TotalProjects";
import BusinessImpact from "./BusinessImpact";
import Startups from "./Startups";
import {
  BusinessImpactValues,
  ProjectCountByStage,
  StartupCountByAssessment,
} from "../../../Types/Analytics";
import { getImpactValueByFunnelStage } from "../../../utils";

interface MetricsProps {
  projects: GenericProject[];
}

function Metrics(props: MetricsProps): ReactElement {
  const projectCountByStage: ProjectCountByStage = {
    discover: 0,
    assess: 0,
    buy: 0,
    pilot: 0,
    adopt: 0,
  };

  const impactValuesByImpactType: BusinessImpactValues = {
    Cost: 0,
    Revenue: 0,
    "Cost & Revenue": 0,
  };

  const startupCountByAssessment: StartupCountByAssessment = {
    curated: 0,
    qualified: 0,
  };

  props.projects.forEach((project) => {
    const { funnelStage, impactType } = project;

    // project count
    projectCountByStage[funnelStage] += 1;

    // business impact
    const impactValue = getImpactValueByFunnelStage(
      funnelStage,
      project.impactValues
    );
    if (impactValue && Number(impactValue.value)) {
      impactValuesByImpactType[impactType] += Number(impactValue.value);
    }

    // startup count
    project.opportunities.forEach((opportunity) => {
      startupCountByAssessment.curated += 1;
      if (opportunity.isQualified) {
        startupCountByAssessment.qualified += 1;
      }
    });
  });

  return (
    <Grid
      container
      spacing={3}
      justifyContent={{ md: "center", lg: "flex-start" }}
    >
      <Grid item lg={5} md={12} sm={12} xs={12} order={{ md: -1, lg: 0 }}>
        <TotalProjects projectCount={projectCountByStage} />
      </Grid>
      <Grid item lg={5} md={8} xs={8}>
        <BusinessImpact impactValuesByImpactType={impactValuesByImpactType} />
      </Grid>

      <Grid item lg={2} md={4} xs={4}>
        <Startups startupCount={startupCountByAssessment} />
      </Grid>
    </Grid>
  );
}

export default Metrics;
