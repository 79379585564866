import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
  Link,
} from "@mui/material";
import {
  Dispatch,
  Fragment,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { ClientContactDTO } from "../../../../../Types/ClientContact";
import { ProductDemo } from "../../../../../Types/ProductDemo";
import { Project } from "../../../../../Types/Project";
import { StartupContactDTO } from "../../../../../Types/StartupContact";
import ClientContactSelect from "../../../../UI/InputFields/ClientContactSelect";
import CustomTextField from "../../../../UI/InputFields/CustomTextField";
import StartupContactSelect from "../../../../UI/InputFields/StartupContactSelect";
import { SelectInput } from "../../../../UI/InputFields/SelectInput";
import theme from "../../../../../theme";
import { findLogo } from "../../../../../utils";
import { DeleteOutline } from "@mui/icons-material";
import CustomDatePicker from "../../../../UI/InputFields/CustomDatePicker";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.surface.tertiary.dark,
    color: theme.palette.text.primaryInvert.main,
  },
  [`&.${tableCellClasses.body}`]: {
    borderColor: theme.palette.borderOutline.soft,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:first-of-type": {
    border: 4,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface ProductDemosTableProps {
  project: Project;
  productDemos: ProductDemo[];
  setProductDemos: Dispatch<SetStateAction<ProductDemo[]>>;
  editMode: boolean;
}

const shouldAddContact = (
  contact?: ClientContactDTO,
  list?: ClientContactDTO[]
) => contact && !list?.some((member) => member.id === contact.id);

export default function ProductDemosTable(
  props: ProductDemosTableProps
): ReactElement {
  const noQualifiedStartups =
    props.project.opportunities.filter((opp) => opp.isQualified).length !== 0;

  const getInitialContacts = () => {
    const { projectLeader, programManager, projectTeam } = props.project;

    const contacts = [...projectTeam];

    if (shouldAddContact(projectLeader, contacts)) {
      contacts.unshift(projectLeader);
    }

    if (shouldAddContact(programManager, contacts)) {
      programManager && contacts.push(programManager);
    }

    return contacts;
  };

  const handleEdit = (productDemo: ProductDemo, index: number) => {
    const newProductDemos = [...props.productDemos];

    newProductDemos[index] = {
      ...productDemo,
    };
    props.setProductDemos(newProductDemos);
  };

  const handleAdd = () => {
    const startupContact = props.project.opportunities?.filter(
      (opp) => opp.isQualified
    )?.[0]?.startup.mainContact;

    const newProductDemo: ProductDemo = {
      id: 0,
      date: null,
      opportunityId: props.project.opportunities
        ? props.project.opportunities.filter((opp) => opp.isQualified)[0].id
        : null,
      location: "",
      startupParticipants: startupContact ? [startupContact] : [],
      ventureClientParticipants: getInitialContacts(),
    };

    props.setProductDemos([...props.productDemos, newProductDemo]);
  };

  const handleDelete = (index: number) => {
    const newDemos = [...props.productDemos];
    newDemos.splice(index, 1);
    props.setProductDemos(newDemos);
  };

  return (
    <Fragment>
      <TableContainer
        sx={{
          borderTopLeftRadius: theme.shape.radius.minimal,
          borderTopRightRadius: theme.shape.radius.minimal,
        }}
      >
        <Table aria-label="Product Demos">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell sx={{ width: theme.spacing(5) }} />
              <StyledTableCell width="50%">Startup</StyledTableCell>
              <StyledTableCell width="30%">Date</StyledTableCell>
              <StyledTableCell width="20%">Participants</StyledTableCell>
              {props.editMode && (
                <StyledTableCell sx={{ width: theme.spacing(10) }} />
              )}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {props.productDemos.map((productDemo, index: number) => (
              <Row
                index={index}
                key={index}
                project={props.project}
                productDemo={productDemo}
                editMode={props.editMode}
                onEdit={(productDemo) => handleEdit(productDemo, index)}
                onDelete={() => handleDelete(index)}
              />
            ))}
            {props.productDemos.length < 1 && (
              <StyledTableRow>
                <StyledTableCell colSpan={5}>
                  <Typography color="text.disabled">
                    No Product Demo Added
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {props.editMode && noQualifiedStartups && (
        <Box paddingLeft={theme.spacing(2)}>
          <Button
            onClick={handleAdd}
            startIcon={<AddIcon />}
            data-testid="add-demo-button"
          >
            Add Product Demo
          </Button>
        </Box>
      )}
    </Fragment>
  );
}

function addClientContactOrderIds(
  contacts: ClientContactDTO[]
): ClientContactDTO[] {
  contacts.forEach((contact, index) => {
    if (contact.ProductDemosClientContact) {
      contact.ProductDemosClientContact.orderId = index + 1;
    } else {
      contact.ProductDemosClientContact = { orderId: index + 1 };
    }
  });
  return contacts;
}

function addStartupContactOrderIds(
  contacts: StartupContactDTO[]
): StartupContactDTO[] {
  contacts.forEach((contact, index) => {
    if (contact.ProductDemosStartupContact) {
      contact.ProductDemosStartupContact.orderId = index + 1;
    } else {
      contact.ProductDemosStartupContact = { orderId: index + 1 };
    }
  });
  return contacts;
}

export const exportedForTesting = {
  addClientContactOrderIds,
  addStartupContactOrderIds,
};

interface RowProps {
  index: number;
  project: Project;
  productDemo: ProductDemo;
  editMode: boolean;
  onEdit: (productDemo: ProductDemo) => void;
  onDelete: () => void;
}

function Row(props: RowProps): ReactElement {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [props.editMode]);

  const handleVentureClientParticipantsChange = (
    contacts: ClientContactDTO[]
  ) => {
    props.onEdit({
      ...props.productDemo,
      ventureClientParticipants: addClientContactOrderIds(contacts),
    });
  };

  const handleStartupParticipantsChange = (contacts: StartupContactDTO[]) => {
    props.onEdit({
      ...props.productDemo,
      startupParticipants: addStartupContactOrderIds(contacts),
    });
  };

  const handleStartupChange = (opportunityId: number) => {
    const startupContact = props.project.opportunities.find(
      (opp) => opp.id === opportunityId
    )?.startup.mainContact;

    props.onEdit({
      ...props.productDemo,
      startupParticipants: startupContact ? [startupContact] : [],
      opportunityId: opportunityId,
    });
  };

  const selectedStartup = props.project.opportunities.find(
    (opportunity) => opportunity.id === props.productDemo.opportunityId
  )?.startup;

  const participantsCount =
    props.productDemo.ventureClientParticipants.length +
    props.productDemo.startupParticipants.length;

  const startupLogo = findLogo(selectedStartup?.files);

  return (
    <Fragment>
      <StyledTableRow
        data-testid={"demo-" + props.productDemo.id}
        sx={{ "& > td": { borderBottom: "none" } }}
        key={props.index}
      >
        <StyledTableCell sx={{ paddingRight: 0 }}>
          <IconButton
            data-testid={`expand-product-demo-button-${props.productDemo.id}`}
            aria-label="expand row"
            size="small"
            sx={{ color: theme.palette.icon.primary, padding: 0 }}
            onClick={() => setOpen((prevState) => !prevState)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell>
          <Link
            href={`/startups/${selectedStartup?.id}`}
            target="_blank"
            rel="noopener noreferrer"
            data-testid={`startup-link-${selectedStartup?.id}`}
            sx={{
              display: "flex",
              gap: theme.spacing(2),
              alignItems: "center",
              color: theme.palette.text.primary,
              maxWidth: props.editMode ? undefined : "fit-content",
            }}
          >
            {!props.editMode && startupLogo && (
              <Box
                component="img"
                src={startupLogo}
                sx={{
                  width: theme.spacing(10),
                  height: theme.spacing(8),
                  objectFit: "contain",
                }}
              />
            )}
            <SelectInput
              fullWidth
              id={"startup-id-" + props.productDemo.id}
              data-testid={"startup-id-" + props.productDemo.id}
              label={props.editMode ? "Startup" : undefined}
              selectValues={
                props.project.opportunities
                  ?.filter((opportunity) => opportunity.isQualified)
                  .map((opportunity) => {
                    return {
                      id: opportunity.id,
                      name: opportunity.startup.name,
                    };
                  }) || []
              }
              value={props.productDemo.opportunityId}
              editMode={props.editMode}
              onChange={(e) => handleStartupChange(parseInt(e.target.value))}
            />
          </Link>
        </StyledTableCell>
        <StyledTableCell>
          <CustomDatePicker
            id={"date-" + props.productDemo.id}
            data-testid={"date-" + props.productDemo.id}
            label={props.editMode ? "Date" : undefined}
            value={
              props.productDemo.date ? new Date(props.productDemo.date) : null
            }
            fullWidth
            editMode={props.editMode}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(e: any) => {
              if (!e?.$d) return;
              props.onEdit({
                ...props.productDemo,
                date: e.$d || null,
              });
            }}
            renderInputProps={{
              sx: {
                maxWidth: theme.spacing(20),
              },
              "data-testid": "date-" + props.productDemo.id,
            }}
          />
        </StyledTableCell>
        <StyledTableCell>
          <CustomTextField
            id={"participants-" + props.productDemo.id}
            value={participantsCount}
            editMode={false}
            fullWidth
          />
        </StyledTableCell>
        {props.editMode && (
          <StyledTableCell>
            <IconButton
              data-testid={"delete-demo-" + props.productDemo.id}
              onClick={props.onDelete}
              edge="end"
              aria-label="delete"
              size="large"
            >
              <DeleteOutline />
            </IconButton>
          </StyledTableCell>
        )}
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell colSpan={5} padding="none">
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              display="flex"
              gap={theme.spacing(4)}
              paddingTop={theme.spacing(2)}
              paddingBottom={theme.spacing(4)}
              paddingLeft={theme.spacing(7)}
              paddingRight={theme.spacing(11)}
            >
              <ClientContactSelect
                labelText="Venture Client Participants"
                ventureClientId={props.project.businessUnit?.ventureClient.id}
                onChange={handleVentureClientParticipantsChange}
                fieldId="ventureClientParticipants"
                editMode={props.editMode}
                multiSelect={true}
                contactData={props.productDemo.ventureClientParticipants}
              />
              <StartupContactSelect
                editMode={props.editMode}
                labelText="Startup Participants"
                startupId={
                  props.project.opportunities?.find(
                    (opportunity) =>
                      opportunity.id == props.productDemo.opportunityId
                  )?.startupId
                }
                onChange={handleStartupParticipantsChange}
                contactData={props.productDemo.startupParticipants}
                fieldId="startupParticipants"
                multiSelect={true}
              />
            </Box>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </Fragment>
  );
}
