import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  Typography,
  debounce,
  styled,
} from "@mui/material";
import {
  ReactElement,
  Reducer,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { Startup, StartupSolutionAndClients } from "../../../../Types/Startup";
import CustomTextField from "../../../UI/InputFields/CustomTextField";
import { MultiSelectInput } from "../../../UI/InputFields/MultiSelectInput";
import { SelectOption } from "../../../../Types/Common";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ReferenceClient from "../../../../Types/Company";
import { CompaniesHttpService } from "../../../../Http/Companies/Companies.http.service";
import { SelectInput } from "../../../UI/InputFields/SelectInput";
import { technologyReadinessOptions } from "../../../../Constants/Startup";
import UseCase from "../../../../Types/UseCase";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { UseCasesHttpService } from "../../../../Http/UseCases/UseCases.http.service";
import CreateEntityByName from "../../../UI/Modals/CreateEntityByName/CreateEntityByName";
import { Industry } from "../../../../Types/Industry";
import { IndustriesHttpService } from "../../../../Http/Industries/Industries.http.service";
import CustomExpendableText from "../../../UI/CustomExpendableText";
import { StartupHttpService } from "../../../../Http/Startup/Startup.http.service";
import StartupUspHttpService from "../../../../Http/StartupUsp/StartupUsp.Http.service";
import { useSnackbar } from "notistack";
import { GlobalStartupEditContext } from "../../../../Context/StartupDetailsContext";
import { getErrorMessage } from "../../../../utils";
import useRoles from "../../../../Hooks/useRoles";
import {
  technologyReadinessOptionsDescriptions,
  TechnologyReadinessTooltip,
} from "../../../../Constants/Startup";

interface SolutionsAndClientsProps {
  startup: Startup;
  handleSave: (withScroll?: boolean) => void;
}

const SolutionsAndClientsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
}));

const SectionHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: theme.spacing(4),
}));

const UspCard = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.palette.surface.secondary.main,
  width: "300px",
  padding: theme.spacing(2, 2, 2, 3),
  gap: 2,
}));

export default function SolutionAndClients(
  props: SolutionsAndClientsProps
): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const { globalEditMode, setGlobalEditMode } = useContext(
    GlobalStartupEditContext
  );
  const { isExternalUser } = useRoles();
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [useCases, setUseCases] = useState<UseCase[]>([]);
  const [industries, setIndustries] = useState<Industry[]>([]);
  const [referenceClients, setReferenceClients] = useState<ReferenceClient[]>(
    []
  );
  const [createUseCasesModalOptions, setCreateUseCasesModalOptions] = useState({
    open: false,
    value: "",
  });
  const [
    createReferenceClientsModalOptions,
    setCreateReferenceClientsModalOptions,
  ] = useState({
    open: false,
    value: "",
  });
  const [startup, setStartup] = useReducer<
    Reducer<StartupSolutionAndClients, Partial<StartupSolutionAndClients>>
  >((state, newState) => ({ ...state, ...newState }), {
    id: props.startup.id,
    usps: props.startup.usps,
    useCases: props.startup.useCases,
    technologyReadiness: props.startup.technologyReadiness,
    pricingModel: props.startup.pricingModel,
    referenceClients: props.startup.referenceClients,
    industries: props.startup.industries,
  });

  useEffect(() => {
    !editMode && handleCloseEditMode();
  }, [props.startup]);

  useEffect(() => {
    IndustriesHttpService.getIndustries().then((result) => {
      setIndustries(result);
    });
  }, []);

  const toggleEditMode = (value: boolean) => {
    setEditMode(value);
    setGlobalEditMode(value);
  };

  const searchForUseCases = async (searchValue: string): Promise<void> => {
    setIsLoading(true);
    if (searchValue.trim().length) {
      setCreateUseCasesModalOptions((prevState) => ({
        ...prevState,
        value: searchValue,
      }));
      await UseCasesHttpService.getUseCasesByName(searchValue).then(
        (result) => {
          setUseCases([...result, ...startup.useCases]);
        }
      );
    }
    setIsLoading(false);
  };

  const searchForReferenceClients = async (
    searchValue: string
  ): Promise<void> => {
    if (searchValue.trim().length) {
      setCreateReferenceClientsModalOptions((prevState) => ({
        ...prevState,
        value: searchValue,
      }));
      setIsLoading(true);
      await CompaniesHttpService.getCompaniesByName(searchValue)
        .then((result) =>
          setReferenceClients([...result, ...startup.referenceClients])
        )
        .finally(() => {
          setIsLoading(false);
        });
    }
    setIsLoading(false);
  };

  const handleAddUseCase = (useCase: UseCase) => {
    setStartup({ useCases: [...startup.useCases, useCase] });
  };

  const handleAddReferenceClient = (client: ReferenceClient) => {
    setStartup({
      referenceClients: [...startup.referenceClients, client],
    });
  };

  const handleUspChange =
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      const updatedUsps = [...startup.usps];

      updatedUsps[index] = { ...updatedUsps[index], description: value };
      setStartup({ usps: updatedUsps });
    };

  const deleteUsp = (index: number) => {
    setStartup({
      usps: startup.usps.filter((usp) => startup.usps.indexOf(usp) !== index),
    });
  };

  const addUsp = () => {
    if (startup) {
      const updatedUsps = [...startup.usps];

      updatedUsps.push({
        description: "",
        startupId: startup.id,
      });

      setStartup({ usps: updatedUsps });
    }
  };

  const saveStartupDetails = async () => {
    await StartupHttpService.updateStartup(startup);
    await StartupUspHttpService.updateAllForStartup(
      props.startup.id,
      startup.usps
    )
      .then(() => {
        toggleEditMode(false);
        props.handleSave(false);
        enqueueSnackbar("Startup saved successfully.", {
          variant: "success",
        });
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        enqueueSnackbar(`Could not update startup details: ${errorMessage}`, {
          variant: "error",
        });
      });
  };

  const handleCloseEditMode = () => {
    toggleEditMode(false);
    setStartup(props.startup);
  };

  return (
    <SolutionsAndClientsWrapper data-testid="solutionsAndClients">
      <Stack>
        <SectionHeader>
          <Typography variant="h6">Solution & Clients</Typography>
          {!editMode && !isExternalUser && (
            <Box>
              <Button
                variant="contained"
                onClick={() => toggleEditMode(true)}
                data-testid="edit-solution-and-clients"
                disabled={globalEditMode}
              >
                Edit
              </Button>
            </Box>
          )}
        </SectionHeader>

        <Grid container rowGap={editMode ? 5 : 4}>
          <Grid
            item
            xs={editMode ? 12 : 6}
            gap={editMode ? 5 : 3}
            display="flex"
            flexDirection="column"
          >
            {/* Use Cases */}
            {editMode ? (
              <MultiSelectInput
                labelText="Use Cases"
                fieldId="useCases"
                selectValues={useCases as SelectOption[]}
                value={(startup.useCases as SelectOption[]) || []}
                onChange={(selectedUseCases) => {
                  setStartup({ useCases: selectedUseCases as UseCase[] });
                }}
                addIcon={true}
                isLoading={isLoading}
                setModalOpen={() => {
                  setCreateUseCasesModalOptions((prevState) => ({
                    ...prevState,
                    open: true,
                  }));
                }}
                onInputChange={debounce(searchForUseCases, 500)}
              />
            ) : (
              <Stack gap={0.5}>
                <InputLabel>
                  <Typography variant="caption">Use Cases</Typography>
                </InputLabel>
                {startup?.useCases.length > 0 ? (
                  startup?.useCases.map((useCase, index) => (
                    <Box
                      key={index}
                      gap={0.5}
                      display="flex"
                      alignItems="center"
                      data-testid="useCase"
                    >
                      <ChevronRightIcon sx={{ fontSize: "16px" }} />
                      <Typography data-testid="useCaseText">
                        {useCase.name}
                      </Typography>
                    </Box>
                  ))
                ) : (
                  <Typography>--</Typography>
                )}
              </Stack>
            )}
            <Box
              display="flex"
              flexDirection={editMode ? "row" : "column"}
              gap={3}
            >
              <Stack gap={2} flex={1}>
                {/* Technology Readiness */}
                <SelectInput
                  label="Technology Readiness"
                  id="technologyReadiness"
                  data-testid="technology-readiness-input"
                  fullWidth
                  editMode={editMode}
                  selectValues={technologyReadinessOptions}
                  value={startup.technologyReadiness}
                  onChange={(e) =>
                    setStartup({ technologyReadiness: e.target.value })
                  }
                  toolTipText={TechnologyReadinessTooltip}
                  disableSorting
                />
                {startup.technologyReadiness && (
                  <Stack>
                    <Typography
                      variant="caption"
                      color="text.mediumEmphasis"
                      data-testid="technology-readiness-title"
                    >
                      {startup.technologyReadiness}
                    </Typography>
                    <Typography
                      variant="body2"
                      data-testid="technology-readiness-description"
                    >
                      {
                        technologyReadinessOptionsDescriptions[
                          startup.technologyReadiness
                        ]
                      }
                    </Typography>
                  </Stack>
                )}
              </Stack>

              {/* Pricing Model */}
              <Box flex={1}>
                <CustomTextField
                  fullWidth
                  id="pricingModel"
                  editMode={editMode}
                  data-testid="pricing-model-input"
                  label="Pricing Model"
                  value={startup.pricingModel || ""}
                  onChange={(e) => setStartup({ pricingModel: e.target.value })}
                />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={editMode ? 12 : 6}
            gap={editMode ? 5 : 3}
            display="flex"
            flexDirection="column"
          >
            {/* Client Industries AND Reference Clients */}
            {editMode ? (
              <>
                <MultiSelectInput
                  labelText="Client Industries"
                  fieldId="industries"
                  selectValues={industries as SelectOption[]}
                  value={(startup.industries as SelectOption[]) || []}
                  onChange={(e) => {
                    setStartup({ industries: e as Industry[] });
                  }}
                  addIcon={false}
                  isLoading={isLoading}
                />
                <MultiSelectInput
                  labelText="Reference Clients"
                  fieldId="referenceClients"
                  selectValues={referenceClients as SelectOption[]}
                  value={(startup.referenceClients as SelectOption[]) || []}
                  onChange={(clients) => {
                    setStartup({
                      referenceClients: clients as ReferenceClient[],
                    });
                  }}
                  addIcon={true}
                  isLoading={isLoading}
                  setModalOpen={() => {
                    setCreateReferenceClientsModalOptions((prevState) => ({
                      ...prevState,
                      open: true,
                    }));
                  }}
                  onInputChange={debounce(searchForReferenceClients, 500)}
                />
              </>
            ) : (
              <>
                <Stack gap={1}>
                  <InputLabel>
                    <Typography variant="caption">Client Industries</Typography>
                  </InputLabel>
                  {startup.industries.length > 0 ? (
                    startup.industries.map((industry, index) => (
                      <Chip
                        key={index}
                        size="medium"
                        label={industry.name}
                        data-testid="industry-chip"
                        variant="outlined"
                        color="secondary"
                      />
                    ))
                  ) : (
                    <Typography>--</Typography>
                  )}
                </Stack>
                <Stack gap={1} display="flex">
                  <InputLabel>
                    <Typography variant="caption">Reference Clients</Typography>
                  </InputLabel>
                  <CustomExpendableText
                    maxLines={1}
                    text={
                      <Box
                        display="flex"
                        flexWrap="wrap"
                        rowGap={1}
                        columnGap={2}
                      >
                        {startup.referenceClients.length > 0 ? (
                          startup.referenceClients?.map((client, index) => (
                            <Typography
                              variant="subtitle2"
                              data-testid="client-text"
                              key={index}
                            >
                              {client.name}
                            </Typography>
                          ))
                        ) : (
                          <Typography>--</Typography>
                        )}
                      </Box>
                    }
                  />
                </Stack>
              </>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            gap={3}
            display="flex"
            flexDirection={editMode ? "column" : "row"}
          >
            {editMode ? (
              <>
                {startup.usps &&
                  startup.usps.map((usp, index: number) => (
                    <CustomTextField
                      key={index}
                      id={index.toString()}
                      label={"USP " + (index + 1)}
                      fullWidth
                      editMode={editMode}
                      value={usp.description || ""}
                      data-testid="usp"
                      onChange={handleUspChange(index)}
                      sx={{
                        "& .MuiInputBase-input": {
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        },
                      }}
                      inputProps={{ maxLength: 145 }}
                      InputProps={{
                        endAdornment: (
                          <>
                            <Typography
                              variant="body2"
                              color="text.disabled"
                              ml={6}
                            >
                              {usp.description.length}/145
                            </Typography>
                            <IconButton
                              aria-label="delete"
                              size="large"
                              id={"delete-usp-" + index}
                              onClick={() => deleteUsp(index)}
                              sx={{ color: "icon.mediumEmphasis" }}
                            >
                              <DeleteOutlineIcon />
                            </IconButton>
                          </>
                        ),
                      }}
                    />
                  ))}
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    onClick={addUsp}
                    id="add-usp"
                    color="secondary"
                    variant="contained"
                  >
                    Add USP
                  </Button>
                </Box>
              </>
            ) : (
              <Stack gap={1}>
                <InputLabel>
                  <Typography variant="caption">
                    Unique Selling Propositions
                  </Typography>
                </InputLabel>
                <Box
                  display="flex"
                  flexWrap="wrap"
                  gap={2}
                  align-items="stretch"
                >
                  {startup.usps.length > 0 ? (
                    startup.usps.map((usp, index) => (
                      <UspCard key={index}>
                        <Box>
                          <Typography variant="subtitle2">
                            {`USP ${index + 1}`}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography data-testid="usp-text">
                            {usp.description}
                          </Typography>
                        </Box>
                      </UspCard>
                    ))
                  ) : (
                    <Typography color="text.disabled">
                      Unique selling propositions for this startup have not been
                      provided yet.
                    </Typography>
                  )}
                </Box>
              </Stack>
            )}
          </Grid>
          {editMode && (
            <Grid item xs={12} display="flex" justifyContent="flex-end" gap={2}>
              <Button
                onClick={handleCloseEditMode}
                color="primary"
                id="cancel-details-edit"
              >
                Cancel
              </Button>
              <Button
                onClick={saveStartupDetails}
                variant="contained"
                id="save-details-edit"
              >
                Save
              </Button>
            </Grid>
          )}
        </Grid>
      </Stack>
      {/* Modals Dialogs */}
      {createUseCasesModalOptions.open && (
        <CreateEntityByName
          name="useCase"
          label="Use Case"
          modalOpen={createUseCasesModalOptions.open}
          setModalOpen={() => {
            setCreateUseCasesModalOptions(() => ({
              open: false,
              value: "",
            }));
          }}
          initialValue={createUseCasesModalOptions.value}
          handleAdd={handleAddUseCase}
          maxCharacter={30}
        />
      )}
      {createReferenceClientsModalOptions.open && (
        <CreateEntityByName
          name="referenceClient"
          label="Reference Client"
          modalOpen={createReferenceClientsModalOptions.open}
          setModalOpen={() => {
            setCreateReferenceClientsModalOptions(() => ({
              open: false,
              value: "",
            }));
          }}
          initialValue={createReferenceClientsModalOptions.value}
          handleAdd={handleAddReferenceClient}
        />
      )}
    </SolutionsAndClientsWrapper>
  );
}
