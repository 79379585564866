import { createContext } from "react";
import { Role, User } from "../Types/User";

export const UserContext = createContext<User | undefined>(undefined);

export const isVentureManager = (
  user: User | undefined,
  ventureClientId?: number
): boolean => {
  if (user === undefined) {
    throw new Error("user is undefined");
  }
  if (!ventureClientId) {
    return !!user.roles.find(
      (role: Role) => role.name === "Venture Manager" || role.name === "Admin"
    );
  }
  return !!user.roles.find(
    (role: Role) =>
      (role.name === "Venture Manager" &&
        role.ventureClientId === ventureClientId) ||
      role.name === "Admin"
  );
};

export const hasAccessToVentureClient = (
  user: User | undefined,
  ventureClientId: number
): boolean => {
  if (user === undefined) {
    throw new Error("user is undefined");
  }
  return !!user.roles.find(
    (role: Role) =>
      role.ventureClientId === ventureClientId ||
      role.name === "Admin" ||
      role.name === "Startup Intelligence"
  );
};
