import { File as DocumentFile } from "../../../../../Types/File";
import { Fragment, ReactElement } from "react";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  styled,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileItem from "./FileItem";
import { otherFileTypes } from "../../../../../Constants/Files";
import theme from "../../../../../theme";
import useRoles from "../../../../../Hooks/useRoles";

interface FilesListSectionProps {
  files: DocumentFile[];
  mapId: number | string;
  deleteFile: (fileId: number) => void;
  editingFileId?: number;
  setEditingFileId: (id?: number) => void;
  handleFileRename: (
    index: number,
    name: string,
    mapId: number | string
  ) => void;
  projectId?: number;
  handleSave: () => void;
}
const StyledAccordion = styled(Accordion)(() => ({
  boxShadow: "none",
  ":before": {
    display: "none",
  },

  "& .MuiAccordionSummary-root": {
    padding: "0",
  },
  "& .MuiAccordionDetails-root": {
    padding: "0",
  },
}));

const FilesContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}));

export function FilesListSection(props: FilesListSectionProps): ReactElement {
  const { isExternalUser } = useRoles();

  const files = props.files.filter(
    (file) =>
      !otherFileTypes.includes(file.type) &&
      (!isExternalUser ||
        (file.type !== "Strategic Image" && file.type !== "Logo"))
  );

  const otherFiles = props.files.filter(
    (file) =>
      otherFileTypes.includes(file.type) &&
      (!isExternalUser ||
        (file.type !== "Strategic Image" && file.type !== "Logo"))
  );

  return (
    <Fragment>
      {files.length > 0 && (
        <FilesContainer data-testid="files-container">
          {files.map((file, index) => (
            <FileItem
              key={file.id}
              file={file}
              index={index}
              mapId={props.mapId}
              deleteFile={props.deleteFile}
              editingFileId={props.editingFileId}
              setEditingFileId={props.setEditingFileId}
              handleFileRename={props.handleFileRename}
              handleSave={props.handleSave}
              projectId={props.projectId}
            />
          ))}
        </FilesContainer>
      )}
      {otherFiles.length > 0 && (
        <StyledAccordion>
          <AccordionSummary
            sx={{ flexDirection: "row-reverse" }}
            expandIcon={<ExpandMoreIcon sx={{ color: "text.primary" }} />}
            aria-controls="other-files-panel"
            id="other-files-panel"
            data-testid="other-files-panel"
          >
            <Typography variant="h6">Other Files</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ border: 0 }}>
            <FilesContainer data-testid="other-files-container">
              {otherFiles.map((file, index) => (
                <FileItem
                  key={file.id}
                  file={file}
                  index={index}
                  mapId={props.mapId}
                  deleteFile={props.deleteFile}
                  editingFileId={props.editingFileId}
                  setEditingFileId={props.setEditingFileId}
                  handleFileRename={props.handleFileRename}
                  handleSave={props.handleSave}
                  projectId={props.projectId}
                />
              ))}
            </FilesContainer>
          </AccordionDetails>
        </StyledAccordion>
      )}
    </Fragment>
  );
}
