import React, { ReactElement } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { getSelectedStartupName } from "../../../../StageValidation/StageValidator";
import { Project } from "../../../../Types/Project";

interface Props {
  setModalOpen: (args: boolean) => void;
  modalOpen: boolean;
  moveProject: () => void;
  projectData: Project;
}

function StageConfirmationModal(props: Props): ReactElement {
  return (
    <Dialog
      open={props.modalOpen}
      onClose={() => props.setModalOpen(false)}
      id="proceed-to-buy-dialog"
    >
      <DialogTitle>Proceed to Buy Stage</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ textAlign: "center" }}>
          Are you sure you want to proceed to Buy with the following startup?
          <br />
          <Typography component={"span"} mt={1} variant="h6">
            {getSelectedStartupName(props.projectData)}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}
      >
        <Button
          color="primary"
          variant="outlined"
          onClick={() => props.setModalOpen(false)}
          data-testid="proceed-to-buy-no"
        >
          No
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => (props.moveProject(), props.setModalOpen(false))}
          data-testid="proceed-to-buy-yes"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default StageConfirmationModal;
