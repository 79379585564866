import { CheckCircle, HelpOutline } from "@mui/icons-material";
import { Box, BoxProps } from "@mui/material";
import { ReactElement } from "react";
import theme from "../../../../theme";
import { QualityCheck } from "../../../../Types/QualityCheck";

interface QualityVerificationItemProps extends BoxProps {
  qualityCheck: QualityCheck;
  checked: boolean;
  hasExpired: boolean;
}

export default function QualityVerificationItem({
  qualityCheck,
  checked,
  hasExpired,
  ...defaultProps
}: QualityVerificationItemProps): ReactElement {
  return (
    <Box
      bgcolor={
        checked && !hasExpired
          ? theme.palette.success.light
          : theme.palette.surface.secondary.light
      }
      p={1}
      display="flex"
      gap={1}
      borderRadius={theme.shape.radius.minimal}
      {...defaultProps}
    >
      {checked ? (
        <CheckCircle
          fontSize="small"
          color={hasExpired ? "disabled" : "success"}
        />
      ) : (
        <HelpOutline
          fontSize="small"
          htmlColor={theme.palette.icon.mediumEmphasis}
        />
      )}
      {qualityCheck.name}
    </Box>
  );
}
