import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  Box,
  Button,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { ReactElement, Reducer, useContext, useReducer, useState } from "react";
import {
  ASSUMPTIONS_TOOLTIP,
  COMMENT_TOOLTIP,
  INTANGIBLE_BENEFITS_TOOLTIP,
} from "../../../../Constants/TooltipText";
import { SelectOption } from "../../../../Types/Common";
import { Project, ProjectStrategicBenefits } from "../../../../Types/Project";
import CustomTextField from "../../../UI/InputFields/CustomTextField";
import { SelectInput } from "../../../UI/InputFields/SelectInput";
import useFetchImpactValue from "./useFetchImpactValue";
import theme from "../../../../theme";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import CalculationContainer from "./CalculationContainer";
import { calculate, getDescAndTitle, hasMonetaryValue } from "./helper/helper";
import {
  CalculationType,
  CreateCalculation,
  CreateImpactValue,
  ImpactValueStage,
} from "../../../../Types/ImpactValue";
import ProjectDetailsAccordionSummary from "../ProjectDetailsAccordionSummary";
import { ProjectHttpService } from "../../../../Http/Project/Project.http.service";
import NumberCard from "../../../UI/NumberCard";
import { getErrorMessage } from "../../../../utils";
import { useSnackbar } from "notistack";
import { CalculationHttpService } from "../../../../Http/Calculation/Calculation.http.service";
import { ImpactValueHttpService } from "../../../../Http/ImpactValue/ImpactValue.http.service";
import { funnelStages } from "../../../../Constants/FunnelStages";
import { GlobalProjectEditContext } from "../../../../Context/ProjectDetailsContext";
import useSaveProject from "../../../../Hooks/useSaveProject";

const impactTypeSelectValues: SelectOption[] = [
  { id: "Cost", name: "Cost" },
  { id: "Revenue", name: "Revenue" },
  { id: "Cost & Revenue", name: "Cost & Revenue" },
];

const Description = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}));

const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1.5),
}));

const ImpactValueContent = styled(Box)(({ theme }) => ({
  whiteSpace: "pre-line",
  paddingInline: theme.spacing(4, 5),
  display: "flex",
  flexDirection: "column",
  ...theme.typography.body1,
}));

interface StrategicBenefitsSectionProps
  extends Omit<AccordionProps, "children"> {
  project: Project;
  handleSave: (withScroll: boolean) => void;
}

export default function StrategicBenefitsSection(
  props: StrategicBenefitsSectionProps
): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const { globalEditMode, setGlobalEditMode, activeStep } = useContext(
    GlobalProjectEditContext
  );
  const [editMode, setEditMode] = useState(false);
  const [project, setProject] = useReducer<
    Reducer<ProjectStrategicBenefits, Partial<ProjectStrategicBenefits>>
  >((state, newState) => ({ ...state, ...newState }), {
    id: props.project.id,
    impactType: props.project.impactType,
    assumptionsDiscover: props.project.assumptionsDiscover,
    strategicBenefits: props.project.strategicBenefits,
    businessImpactPotentialComment:
      props.project.businessImpactPotentialComment,
  });
  const [expandedCalculationId, setExpandedCalculationId] = useState<
    number | null
  >(null);

  const { impactValues, setImpactValues, fetchImpactValues } =
    useFetchImpactValue(props.project);
  const { description, title } = getDescAndTitle(props.project.impactType);
  const calculations = impactValues.length ? impactValues[0].calculations : [];

  const costCalculations = calculations.filter(
    (calculation) => calculation.type === "Cost"
  );
  const revenueCalculations = calculations.filter(
    (calculation) => calculation.type === "Revenue"
  );
  const combinedCalculations = [...costCalculations, ...revenueCalculations];
  const funnelStage = funnelStages[activeStep || 0] as ImpactValueStage;

  const handleCancelEdit = () => {
    if (!props.expanded) return;

    setProject({
      impactType: props.project.impactType,
      assumptionsDiscover: props.project.assumptionsDiscover,
      strategicBenefits: props.project.strategicBenefits,
      businessImpactPotentialComment:
        props.project.businessImpactPotentialComment,
    });
    setEditMode(false);
    setGlobalEditMode(false);
  };

  const handleSaveSection = async () => {
    if (!props.expanded) return;

    await ProjectHttpService.updateProject(project as Project)
      .then(() => {
        props.handleSave(false);
        setEditMode(false);
        setGlobalEditMode(false);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        enqueueSnackbar(`could not save the project: ${errorMessage}`, {
          variant: "error",
        });
      });
  };

  const handleEditButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setEditMode(true);
    setGlobalEditMode(true);
  };

  const handleImpactValue = () => {
    fetchImpactValues();
  };

  const getCalculation = (impactType: string) => {
    if (impactType === "Cost") {
      return costCalculations;
    } else if (impactType === "Revenue") {
      return revenueCalculations;
    } else if (impactType === "Cost & Revenue") {
      return combinedCalculations;
    } else {
      return [];
    }
  };
  const displayedCalculation = getCalculation(project.impactType);

  let calculationResult = 0;
  if (+impactValues[0]?.value) {
    calculationResult = impactValues[0].value;
  } else {
    displayedCalculation.forEach((calculation: CalculationType) => {
      const variables = calculation.variables;
      const res = hasMonetaryValue(variables) ? calculate(variables) : 0;
      calculationResult += +res;
    });
  }

  const shouldShowCostCalculation =
    project.impactType === "Cost" || project.impactType === "Cost & Revenue";
  const shouldShowRevenueCalculation =
    project.impactType === "Revenue" || project.impactType === "Cost & Revenue";

  const createImpactValue = async () => {
    const impactValueToBeCreated = {
      value: 0,
      stage: funnelStage,
      projectId: props.project.id,
    } as CreateImpactValue;

    const newImpactValue = await ImpactValueHttpService.createImpactValue(
      impactValueToBeCreated
    );

    setImpactValues([{ ...newImpactValue, calculations: [] }]);
    return newImpactValue.id;
  };

  const createNewCalculation = async (type: "Cost" | "Revenue") => {
    let impactValueId = impactValues?.[0]?.id;

    if (!impactValueId) {
      impactValueId = await createImpactValue();
    }

    const calculationToBeCreated = {
      name: type === "Cost" ? "Cost Saving Calculation" : "Revenue Calculation",
      type: type,
      impactValueId: impactValueId,
      variables: [],
    } as CreateCalculation;

    const newCalculation = await CalculationHttpService.createCalculation(
      calculationToBeCreated
    );

    fetchImpactValues();
    setExpandedCalculationId(newCalculation.id);
  };

  const handleExpand = (calculationId: number | null) => {
    setExpandedCalculationId(
      expandedCalculationId === calculationId ? null : calculationId
    );
  };

  const EditButton = () => {
    return (
      <Button
        data-testid="edit-strategic-benefits"
        variant="contained"
        onClick={handleEditButtonClick}
        disabled={globalEditMode}
      >
        Edit
      </Button>
    );
  };

  useSaveProject(handleSaveSection);

  return (
    <Accordion
      expanded={props.expanded}
      onChange={props.onChange}
      data-testid="strategic-benefit-section"
      disabled={editMode}
    >
      <ProjectDetailsAccordionSummary
        actionButton={props.expanded && !editMode ? <EditButton /> : null}
      >
        Strategic Benefits
      </ProjectDetailsAccordionSummary>
      <AccordionDetails>
        {project && (
          <Box display="flex" flexDirection="column" gap={5}>
            <Box width="50%">
              <SelectInput
                id="impactType"
                label="Impact Type"
                selectValues={impactTypeSelectValues}
                required
                value={project.impactType}
                onChange={(e) => setProject({ impactType: e.target.value })}
                editMode={editMode}
                fullWidth
                data-testid="impact-type-select"
              />
            </Box>
            {project.impactType && (
              <Stack gap={2} width="100%">
                <Box display="flex" justifyContent="space-between" gap={5}>
                  <Description>
                    <Header>
                      <EuroSymbolIcon sx={{ fontSize: "1rem" }} />
                      <Typography variant="subtitle2">
                        Impact Value Calculation
                      </Typography>
                    </Header>
                    <ImpactValueContent>{description}</ImpactValueContent>
                  </Description>
                  <Box
                    width="100%"
                    maxWidth="278px"
                    data-testid="impact-value-result"
                  >
                    <NumberCard
                      label={title}
                      fullWidth
                      value={Math.round(calculationResult)}
                      hideUnit
                      data-testid="impact-value-result"
                    />
                  </Box>
                </Box>
                <Stack gap={3}>
                  {shouldShowCostCalculation && (
                    <CalculationContainer
                      calculations={costCalculations}
                      handleImpactvalue={handleImpactValue}
                      expandedCalculationId={expandedCalculationId}
                      setExpandedCalculationId={handleExpand}
                    />
                  )}
                  {shouldShowRevenueCalculation && (
                    <CalculationContainer
                      calculations={revenueCalculations}
                      handleImpactvalue={handleImpactValue}
                      expandedCalculationId={expandedCalculationId}
                      setExpandedCalculationId={handleExpand}
                    />
                  )}
                </Stack>
                <Box display="flex" justifyContent="end" gap={2}>
                  {project.impactType === "Cost" ||
                  project.impactType === "Cost & Revenue" ? (
                    <Button
                      data-testid="add-new-cost-calculation"
                      onClick={() => createNewCalculation("Cost")}
                      variant="contained"
                      color="secondary"
                    >
                      Add Cost
                    </Button>
                  ) : null}

                  {project.impactType === "Revenue" ||
                  project.impactType === "Cost & Revenue" ? (
                    <Button
                      data-testid="add-new-revenue-calculation"
                      onClick={() => createNewCalculation("Revenue")}
                      variant="contained"
                      color="secondary"
                    >
                      Add Revenue
                    </Button>
                  ) : null}
                </Box>
              </Stack>
            )}
            <CustomTextField
              id="assumptions"
              label="Assumptions"
              editMode={editMode}
              value={project.assumptionsDiscover || ""}
              toolTipText={ASSUMPTIONS_TOOLTIP}
              onChange={(e) => {
                setProject({ assumptionsDiscover: e.target.value });
              }}
              fullWidth
              multiline
              minRows={2}
            />
            <CustomTextField
              id="intangibleBenefits"
              label="Intangible Benefits"
              editMode={editMode}
              value={project.strategicBenefits || ""}
              toolTipText={INTANGIBLE_BENEFITS_TOOLTIP}
              maxCharacter={255}
              onChange={(e) =>
                setProject({ strategicBenefits: e.target.value })
              }
              fullWidth
              multiline
              minRows={2}
            />
            <CustomTextField
              id="businessImpactPotentialComment"
              label="Comment"
              editMode={editMode}
              value={project.businessImpactPotentialComment || ""}
              toolTipText={COMMENT_TOOLTIP}
              onChange={(e) =>
                setProject({
                  businessImpactPotentialComment: e.target.value,
                })
              }
              fullWidth
              multiline
              minRows={2}
            />
            {editMode && (
              <Box width="fit-content" marginLeft="auto" display="flex" gap={2}>
                <Button onClick={handleCancelEdit}>Cancel</Button>
                <Button variant="contained" onClick={handleSaveSection}>
                  Save
                </Button>
              </Box>
            )}
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
