import { DisabledFiles } from "../../../../../Types/File";
import { FunnelStage } from "../../../../../Types/Project";

export const projectFileTypes: {
  [stage in FunnelStage]: string[];
} = {
  discover: [
    "Other",
    "Problem Deep Dive",
    "Curated List",
    "Business Case - Discover",
    "Curated List Presentation",
  ],
  assess: [
    "Other",
    "Assessment Report",
    "Qualified List Presentation",
    "Business Case - Assess",
  ],
  pilot: [],
  buy: [],
  adopt: [],
};

export const opportunityFileTypes: {
  [stage in FunnelStage]: string[];
} = {
  discover: [
    "Partnership Confirmation",
    "Startup Rejection",
    "Product Deck",
    "Strategic Onepager",
  ],
  assess: [
    "Demo Protocol",
    "Demo Deck",
    "NDA",
    "Product Deck",
    "SF Confirmation",
    "Strategic Onepager",
    "Internal Product Demo Briefing",
    "External Product Demo Briefing",
    "Follow-up Protocol",
  ],
  pilot: [
    "Other",
    "Kickoff Deck",
    "Kickoff Protocol",
    "MS I Protocol",
    "MS I Deck",
    "MS II Protocol",
    "MS II Deck",
    "MS III Protocol",
    "MS III Deck",
    "Project Report",
    "Conclusion Protocol",
    "Project Success Report",
    "Conclusion Deck",
    "Pilot Synthesis",
    "Business Case - Pilot",
  ],
  buy: [
    "Request for Proposal",
    "Offer",
    "Startup Contract",
    "Business Case - Buy",
    "Pilot Approval",
    "Pilot Fact Sheet",
    "Pilot Request",
    "Other",
  ],
  adopt: ["Other", "Business Case - Adopt"],
};

export const disabledFileTypes: {
  [stage in FunnelStage]: DisabledFiles | undefined;
} = {
  discover: undefined,
  assess: {
    "Strategic Onepager": [1, 8, 13, 19],
  },
  pilot: undefined,
  buy: undefined,
  adopt: undefined,
};
