import { useContext } from "react";
import { UserContext } from "../Context/UserContext";

export interface useRolesReturns {
  isExternalUser: boolean;
  isAdmin: boolean;
  ventureClientId?: number;
}

function useRoles(): useRolesReturns {
  const user = useContext(UserContext);

  const externalRole = user?.roles.find(
    (role) => role.name === "Venture Client Unit Head"
  );

  const adminRole = user?.roles.find((role) => role.name === "Admin");

  return {
    isExternalUser: !!externalRole,
    isAdmin: !!adminRole,
    ventureClientId: externalRole?.ventureClientId,
  };
}

export default useRoles;
