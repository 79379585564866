import { Box, Typography } from "@mui/material";
import { ReactElement, useContext } from "react";
import { UserContext } from "../../Context/UserContext";
import theme from "../../theme";
import CustomFilter from "../UI/CustomFilter";
import CustomSearch from "../UI/CustomSearch";
import useClient from "../../Hooks/useClient";

interface ProjectKanbanHeaderProps {
  searchValue: string;
  setSearchValue: (value: string) => void;
  setFilterDrawerOpen: (value: boolean) => void;
  topHeaderRef: React.RefObject<HTMLDivElement>;
  bottomHeaderRef: React.RefObject<HTMLDivElement>;
  isStickied: boolean;
}

function ProjectKanbanHeader(props: ProjectKanbanHeaderProps): ReactElement {
  const topHeader = props.topHeaderRef;
  const user = useContext(UserContext);
  const { background: clientBackground, hero } = useClient();

  const date = new Date();

  const optionsWeekday: Intl.DateTimeFormatOptions = { weekday: "long" };
  const weekday = new Intl.DateTimeFormat("en-US", optionsWeekday).format(date);

  const optionsDay: Intl.DateTimeFormatOptions = { day: "numeric" };
  const day = new Intl.DateTimeFormat("en-US", optionsDay).format(date);

  const optionsMonth: Intl.DateTimeFormatOptions = { month: "long" };
  const month = new Intl.DateTimeFormat("en-US", optionsMonth).format(date);

  const optionsYear: Intl.DateTimeFormatOptions = { year: "numeric" };
  const year = new Intl.DateTimeFormat("en-US", optionsYear).format(date);

  const formattedDate = `${weekday}, ${day} ${month} ${year}`;

  const isDevelopment = process.env.NODE_ENV === "development";
  const baseS3Url = isDevelopment
    ? "https://vclos-frontend.s3.eu-central-1.amazonaws.com"
    : "https://vclos-frontend-prod.s3.eu-central-1.amazonaws.com";

  const backgroundUrl =
    hero || clientBackground || baseS3Url + "/hero_banner.png";

  const userFirstName = user?.name.split(" ")[0];
  return (
    <>
      <Box
        padding={theme.spacing(3, 5, 0, 5)}
        sx={{
          transition: "all 0.5s",
          background: `linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5)), url(${backgroundUrl}) no-repeat top center fixed`,
          backgroundSize: "100% auto",
        }}
        position="sticky"
        top={theme.spacing(-6)}
        zIndex={4}
        justifyContent="space-between"
        display="flex"
        ref={topHeader}
      >
        <Box
          position="relative"
          display="flex"
          justifyContent="space-between"
          width="100%"
          pt={13}
        >
          <Box data-testid="date-prompt">
            <Typography
              variant="body1"
              color={theme.palette.text.primaryInvert.mediumEmphasis}
            >
              {formattedDate}
            </Typography>
            <Typography
              variant="h3"
              color={theme.palette.text.primaryInvert.main}
            >{`Hello, ${userFirstName}`}</Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <CustomSearch
              placeholder="Type project or startup name"
              onSearchChange={props.setSearchValue}
              searchValue={props.searchValue}
            />
            <CustomFilter onClick={() => props.setFilterDrawerOpen(true)} />
          </Box>
        </Box>
      </Box>
      {topHeader?.current && (
        <Box
          padding={theme.spacing(0, 5, 3, 5)}
          sx={{
            background: `linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5)), url(${backgroundUrl}) no-repeat top center fixed`,
            backgroundSize: "100% auto",
          }}
          flexDirection="column"
          top={
            topHeader.current?.getBoundingClientRect().height -
            parseInt(theme.spacing(11))
          }
          position="sticky"
          zIndex={1}
          ref={props.bottomHeaderRef}
        >
          <Box
            position="relative"
            display="flex"
            justifyContent="space-between"
            width="100%"
            pb={6}
          >
            <Typography
              variant="h3"
              color={theme.palette.text.primaryInvert.main}
            >
              Here are your Venture Client projects.
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
}

export default ProjectKanbanHeader;
