import { Dispatch, ReactElement } from "react";
import { Project } from "../../../../Types/Project";
import Box from "@mui/material/Box";
import { PILOT_BACKGROUND_TOOLTIP } from "../../../../Constants/TooltipText";
import RichTextEditor from "../../../UI/InputFields/RichTextEditor";
import { InputLabel, Stack, Typography } from "@mui/material";
import CustomExpendableText from "../../../UI/CustomExpendableText";
import parse from "html-react-parser";

interface Props {
  project: Project;
  editMode: boolean;
  onChange: Dispatch<Partial<Project>>;
}

export default function PilotBackgroundSection(props: Props): ReactElement {
  return (
    <Stack gap={1}>
      <Typography variant="subtitle2">Pilot Background</Typography>
      <Box display="grid" gridTemplateColumns="3fr 1fr" gap={1} mt={1}>
        {props.editMode ? (
          <RichTextEditor
            fieldId="pilotBackground"
            labelText="Project Motivation"
            editMode={true}
            fieldValue={props.project.pilotBackground}
            toolTipText={PILOT_BACKGROUND_TOOLTIP}
            onChange={(value: string) =>
              props.onChange({ pilotBackground: value })
            }
            required
          />
        ) : (
          <Stack gap={0.5} sx={{ "& p": { m: 0 } }}>
            <InputLabel>
              <Typography variant="caption">Project Motivation</Typography>
            </InputLabel>
            <CustomExpendableText
              text={parse(props.project.pilotBackground || "--")}
            />
          </Stack>
        )}
      </Box>
    </Stack>
  );
}
