import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  debounce,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import React, {
  ReactElement,
  useState,
  Fragment,
  useEffect,
  useReducer,
  Reducer,
} from "react";
import { StartupContactDTO } from "../../../Types/StartupContact";
import StartupContactHttpService from "../../../Http/StartupContact/StartupContact.http.service";
import { StartupHttpService } from "../../../Http/Startup/Startup.http.service";
import { StartupContactsAssociation } from "../../../Types/Startup";
import { Gender } from "../../../Types/Common";
import CloseIcon from "@mui/icons-material/Close";

interface CreateContactModalProps {
  setModalOpen: (state: boolean) => void;
  modalOpen: boolean;
  handleSave: () => void;
  contact: StartupContactDTO;
}

const genders = ["male", "female", "non-binary"];

export default function EditStartupContactModal(
  props: CreateContactModalProps
): ReactElement {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [isLoading, setIsLoading] = useState(false);
  const [startups, setStartups] = useState<StartupContactsAssociation[]>([]);
  const [contact, setContact] = useReducer<
    Reducer<StartupContactDTO, Partial<StartupContactDTO>>
  >((state, newState) => ({ ...state, ...newState }), {} as StartupContactDTO);

  useEffect(() => {
    setContact({ ...props.contact });
    setStartups([props.contact.startup]);
  }, [props.contact]);

  const handleUpdateContact = async () => {
    if (contact) {
      await StartupContactHttpService.updateContact(contact, props.contact.id);
      props.handleSave();
      props.setModalOpen(false);
    }
  };

  const searchForStartups = async (searchValue: string): Promise<void> => {
    setIsLoading(true);
    if (searchValue.trim().length > 0)
      setStartups(await StartupHttpService.getStartupsByName(searchValue));
    setIsLoading(false);
  };

  const debouncedSearchForStartups = debounce(searchForStartups, 500);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      fullScreen={fullScreen}
      open={props.modalOpen}
      id="startup-edit-contact-view"
      data-testid="startup-edit-contact-view"
    >
      <Stack gap={4}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            ...theme.typography.h3,
          }}
        >
          Edit Startup Contact
          <IconButton
            sx={{ color: "icon.primary" }}
            onClick={() => props.setModalOpen(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {contact && (
            <Stack gap={4}>
              <Stack gap={2}>
                <Typography variant="subtitle1">
                  Personal Information
                </Typography>
                <Box display="flex" gap={3}>
                  <TextField
                    label="Name"
                    id="name"
                    variant="outlined"
                    required
                    fullWidth
                    value={contact.name}
                    onChange={(event) =>
                      setContact({ name: event.target.value })
                    }
                  />
                  <TextField
                    label="Gender"
                    id="gender"
                    variant="outlined"
                    fullWidth
                    select
                    value={contact.gender}
                    onChange={(event) =>
                      setContact({ gender: event.target.value as Gender })
                    }
                  >
                    {genders.map((gender) => (
                      <MenuItem key={gender} value={gender}>
                        {gender}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Stack>

              <Stack gap={2}>
                <Typography variant="subtitle1">Company Information</Typography>
                <Stack gap={3}>
                  <TextField
                    label="Title"
                    id="title"
                    variant="outlined"
                    fullWidth
                    required
                    value={contact.title}
                    onChange={(event) =>
                      setContact({ title: event.target.value })
                    }
                  />
                  <FormGroup sx={{ ml: 1 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          data-cy="founder"
                          id="isFounder"
                          checked={contact.founder ? true : false}
                          onChange={(e) =>
                            setContact({ founder: e.target.checked })
                          }
                        />
                      }
                      label="(Co-)Founder"
                    />
                  </FormGroup>
                  <Autocomplete
                    isOptionEqualToValue={(option, value) =>
                      option.name === value.name
                    }
                    onInputChange={(_, newValue: string) =>
                      debouncedSearchForStartups(newValue)
                    }
                    onChange={(_, selectedStartup) => {
                      setContact({ startupId: selectedStartup?.id });
                    }}
                    getOptionLabel={(option) => option.name}
                    options={startups}
                    noOptionsText="No startup found"
                    loading={isLoading}
                    defaultValue={contact.startup}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Startup"
                        placeholder="Type the startup's name"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <Fragment>
                              {isLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Stack>
              </Stack>

              <Stack gap={2}>
                <Typography variant="subtitle1">Contact</Typography>
                <Box display="flex" gap={2}>
                  <TextField
                    label="Phone Number"
                    id="phone"
                    variant="outlined"
                    fullWidth
                    value={contact.phone}
                    onChange={(event) =>
                      setContact({ phone: event.target.value })
                    }
                  />
                  <TextField
                    label="Email"
                    id="email"
                    variant="outlined"
                    required
                    fullWidth
                    value={contact.email}
                    onChange={(event) =>
                      setContact({ email: event.target.value })
                    }
                  />
                </Box>
              </Stack>
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => props.setModalOpen(false)}
            id="close-startup-edit-contact-view"
          >
            Cancel
          </Button>
          <Button
            onClick={handleUpdateContact}
            variant="contained"
            id="save-startup-edit-contact-view"
          >
            Save
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
}
