import { Box } from "@mui/material";
import { memo, useMemo, ReactElement } from "react";
import { Project } from "../../Types/Project";
import { EmptyStageKanbanCard } from "./EmptyStageKanbanCard";
import ProjectKanbanCardUserBased from "./ProjectKanbanCard";

function getProjectCardsByStage(
  projects: Project[],
  stage: string,
  refreshProjectData: () => void
): ReactElement[] {
  if (projects) {
    return projects
      ?.sort((a, b) => {
        if (a.funnelStageAge === b.funnelStageAge) return 0;
        else if (a.funnelStageAge === null) return 1;
        else if (b.funnelStageAge === null) return -1;
        return a.funnelStageAge - b.funnelStageAge;
      })
      .map((project) => (
        <Box key={project.id} data-testid="projectCard" width="100%">
          <ProjectKanbanCardUserBased
            project={project}
            refreshProjectData={refreshProjectData}
          />
        </Box>
      ));
  }
  return [
    <Box key={`${stage}-empty`} data-testid="emptyProjectCard">
      <EmptyStageKanbanCard />
    </Box>,
  ];
}

const ProjectCardsByStage = ({
  projects,
  stage,
  refreshProjectData,
}: {
  projects: Project[];
  stage: string;
  refreshProjectData: () => void;
}) => {
  const projectCards = useMemo(
    () => getProjectCardsByStage(projects, stage, refreshProjectData),
    [projects, stage]
  );

  return <>{projectCards}</>;
};

const MemoizedProjectCardsByStage = memo(ProjectCardsByStage);

export default MemoizedProjectCardsByStage;
