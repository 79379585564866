import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { ReactElement, useReducer, Reducer, useState, useEffect } from "react";
import ClientContactHttpService from "../../../Http/ClientContact/ClientContact.http.service";
import {
  ClientContactDTO,
  CreateClientContactDTO,
} from "../../../Types/ClientContact";
import { SelectInput } from "../../UI/InputFields/SelectInput";
import { Gender, SelectOption } from "../../../Types/Common";
import { BusinessUnitHttpService } from "../../../Http/BusinessUnit/BusinessUnit.http.service";
import CloseIcon from "@mui/icons-material/Close";

interface CreateContactGlobalModalProps {
  setModalOpen: (state: boolean) => void;
  modalOpen: boolean;
  getNewContact?: (contact: ClientContactDTO) => void;
  ventureClientId: number;
}

const genders = ["male", "female", "non-binary"];

//This Modal is only for situation where user intents to create contact for a specific relation
export default function CreateClientContactGlobalModal(
  props: CreateContactGlobalModalProps
): ReactElement {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [newContact, setNewContact] = useReducer<
    Reducer<CreateClientContactDTO, Partial<CreateClientContactDTO>>
  >(
    (state, newState) => ({ ...state, ...newState }),
    {} as CreateClientContactDTO
  );

  const [businessUnitSelectOptions, setBusinessUnitSelectOptions] = useState<
    SelectOption[]
  >([]);

  const handleCreateContact = async () => {
    if (newContact.name != null && newContact.businessUnitId != null) {
      const contactCreated = await ClientContactHttpService.createContact(
        newContact
      );
      if (props.getNewContact && contactCreated) {
        props.getNewContact(contactCreated);
      }
      props.setModalOpen(false);
    }
  };

  useEffect(() => {
    BusinessUnitHttpService.getBusinessUnits({
      ventureClientId: props.ventureClientId,
    }).then((results) => {
      const selectOptions = results.map((businessUnit) => ({
        id: businessUnit.id,
        name: businessUnit.name,
      }));
      setBusinessUnitSelectOptions(selectOptions);
    });
  }, [props.ventureClientId]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      fullScreen={fullScreen}
      open={props.modalOpen}
      id="client-create-view-global"
    >
      <Stack gap={4}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            ...theme.typography.h3,
          }}
        >
          Create Contact
          <IconButton
            sx={{ color: "icon.primary" }}
            onClick={() => props.setModalOpen(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack gap={3}>
            <Stack gap={2}>
              <Typography variant="subtitle1">Personal Information</Typography>
              <Box display="flex" flexDirection="row" gap={3}>
                <TextField
                  label="Name"
                  id="contact-name"
                  variant="outlined"
                  required
                  fullWidth
                  defaultValue=""
                  onChange={(event) =>
                    setNewContact({ name: event.target.value })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  label="Gender"
                  id="contact-gender"
                  variant="outlined"
                  fullWidth
                  select
                  defaultValue={null}
                  onChange={(event) =>
                    setNewContact({ gender: event.target.value as Gender })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  {genders.map((gender) => (
                    <MenuItem key={gender} value={gender}>
                      {gender}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Stack>

            <Stack gap={2}>
              <Typography variant="subtitle1">Company Information</Typography>
              <Box display="flex" gap={2}>
                <TextField
                  label="Title"
                  id="contact-title"
                  required
                  variant="outlined"
                  fullWidth
                  defaultValue=""
                  onChange={(event) =>
                    setNewContact({ title: event.target.value })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  label="Department Code"
                  id="department-code"
                  variant="outlined"
                  fullWidth
                  defaultValue=""
                  onChange={(event) =>
                    setNewContact({ departmentCode: event.target.value })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>

              <SelectInput
                label="Organizational Unit"
                selectValues={businessUnitSelectOptions}
                value={newContact.businessUnitId}
                onChange={(event) =>
                  setNewContact({
                    businessUnitId: parseInt(event.target.value),
                  })
                }
                required
                fullWidth
                editMode
                ignoreValidation
              />
            </Stack>

            <Stack gap={2}>
              <Typography variant="subtitle1">Contact</Typography>
              <Box display="flex" flexDirection="row" gap={3}>
                <TextField
                  label="Phone Number"
                  id="contact-phone"
                  variant="outlined"
                  fullWidth
                  defaultValue=""
                  onChange={(event) =>
                    setNewContact({ phone: event.target.value })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  label="Email"
                  id="contact-email"
                  required
                  variant="outlined"
                  fullWidth
                  defaultValue=""
                  onChange={(event) =>
                    setNewContact({ email: event.target.value })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => props.setModalOpen(false)}
            id="close-client-create-view"
          >
            Cancel
          </Button>
          <Button
            onClick={handleCreateContact}
            variant="contained"
            id="save-client-create-view"
          >
            Create
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
}
