import { Box, Grid, Typography, Theme } from "@mui/material";
import { useSnackbar } from "notistack";
import { ReactElement, useContext, useEffect, useState } from "react";
import { GlobalLoaderContext } from "../../Context/LoaderContext";
import { AnalyticsHttpService } from "../../Http/Analytics/Analytics.http.service";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { TBusinessValue } from "../../Types/Analytics";
import { DashboardFilterOptions } from "../../Types/Dashboard";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      ...theme.typography.h5,
      color: theme.palette.grey[600],
      fontWeight: "500",
      position: "absolute",
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
    },
    bodyWrapper: {
      paddingInline: "2rem",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      gap: theme.spacing(2),
      flexGrow: "1",
    },
    businessValueItem: {
      display: "flex",
      alignItems: "start",
      gap: theme.spacing(2),
    },
    businessValueLeft: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "10%",
    },
    businessValueRight: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    value: {
      background: theme.palette.chart.dark.primary,
      "-webkit-background-clip": "text",
      "-webkit-text-fill-color": "transparent",
      lineHeight: 1,
      fontSize: "40px",
      fontWeight: "bold",
    },
    description: {
      lineHeight: 1,
      marginLeft: "6px",
      color: theme.palette.grey[600],
      fontWeight: "bold",
    },
    arrowIcon: {
      fontSize: "40px",
      marginBottom: "-10px",
      marginTop: "-10px",
      color: theme.palette.chart.dark.primary,
    },
    euroIcon: {
      fontSize: "30px",
      lineHeight: 0.7,
      marginRight: "3px",
      color: theme.palette.chart.dark.primary,
      fontWeight: "bold",
    },
  })
);

interface Props {
  dashboardFilters: DashboardFilterOptions;
  refetch: boolean;
}

const BusinessValue = ({ dashboardFilters, refetch }: Props): ReactElement => {
  const classes = useStyles();
  const [businessValue, setBusinessValue] = useState<TBusinessValue>({
    Revenue: 0,
    Cost: 0,
    Cost_Revenue: 0,
  });
  const { setGlobalLoader } = useContext(GlobalLoaderContext);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchData = async () => {
      setGlobalLoader(true);
      try {
        const data = await AnalyticsHttpService.getBusinessValue({
          businessUnitIds: dashboardFilters.businessUnitsIds,
          focusAreasIds: dashboardFilters.focusAreasIds,
        });
        setBusinessValue(data);
        // eslint-disable-next-line
      } catch (error: any) {
        enqueueSnackbar(
          `Something went wrong with fetching the business value: ${error.message}`,
          {
            variant: "error",
          }
        );
      } finally {
        setGlobalLoader(false);
      }
    };
    fetchData();
  }, [refetch]);

  return (
    <Box data-testid="business-value" height="300px">
      <Grid container display="flex" flexDirection="column" p={0} height="100%">
        <Typography className={classes.title}>Business Value</Typography>

        <Grid className={classes.bodyWrapper} item>
          <Box className={classes.businessValueItem}>
            <Box className={classes.businessValueLeft}>
              <ArrowDropUpIcon className={classes.arrowIcon} />

              <Typography className={classes.euroIcon} component="span">
                &euro;
              </Typography>
            </Box>

            <Box className={classes.businessValueRight}>
              <Typography className={classes.value} component="span">
                € {new Intl.NumberFormat("de-DE").format(businessValue.Revenue)}
              </Typography>
              <Typography className={classes.description} variant="caption">
                Revenue increase potential
              </Typography>
            </Box>
          </Box>

          <Box className={classes.businessValueItem}>
            <Box className={classes.businessValueLeft}>
              <ArrowDropDownIcon className={classes.arrowIcon} />

              <Typography className={classes.euroIcon} component="span">
                &euro;
              </Typography>
            </Box>

            <Box className={classes.businessValueRight}>
              <Typography className={classes.value}>
                € {new Intl.NumberFormat("de-DE").format(businessValue.Cost)}
              </Typography>
              <Typography className={classes.description} variant="caption">
                Cost reduction potential
              </Typography>
            </Box>
          </Box>

          <Box className={classes.businessValueItem}>
            <Box className={classes.businessValueLeft}>
              <Box display="flex">
                <ArrowDropDownIcon
                  className={classes.arrowIcon}
                  style={{
                    marginLeft: "0px",
                  }}
                />
                <ArrowDropUpIcon
                  className={classes.arrowIcon}
                  style={{ marginLeft: "-25px" }}
                />
              </Box>

              <Typography className={classes.euroIcon} component="span">
                &euro;
              </Typography>
            </Box>

            <Box className={classes.businessValueRight}>
              <Typography className={classes.value}>
                €{" "}
                {new Intl.NumberFormat("de-DE").format(
                  businessValue.Cost_Revenue
                )}
              </Typography>
              <Typography className={classes.description} variant="caption">
                Combined potential of &quot;cost &amp; revenue&quot; projects
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BusinessValue;
