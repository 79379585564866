import { Box, Paper, Stack, styled, Typography } from "@mui/material";
import { ReactElement, useState } from "react";
import theme from "../../../../../theme";
import { LeadProjectProjectAssociation } from "../../../../../Types/LeadProject";
import { formatDate } from "../../../../../utils";
import LeadProjectDetailsModal from "../../../../UI/Modals/ProjectDetailsModal/LeadProjectDetailsModal";

interface LeadSectionProps {
  leadProject: LeadProjectProjectAssociation;
}

export default function LeadSection(props: LeadSectionProps): ReactElement {
  const [leadModalOpen, setLeadModalOpen] = useState(false);

  return (
    <Paper sx={{ p: 3 }}>
      <Typography variant="h6" mb={3}>
        Launched from Lead
      </Typography>
      <Stack
        gap={2}
        p={2}
        bgcolor={theme.palette.background.default}
        borderRadius={theme.shape.radius.minimal}
      >
        <Stack>
          <LeadName onClick={() => setLeadModalOpen(true)}>
            {props.leadProject.name}
          </LeadName>
          <Box display="flex" gap={1}>
            <Typography variant="body2" color="text.mediumEmphasis">
              Created
            </Typography>
            <Typography variant="body2">
              {formatDate(props.leadProject.dateCreated)}
            </Typography>
          </Box>
        </Stack>
        <Stack gap={0.5}>
          <Typography variant="overline">Lead Source</Typography>
          <Box display="grid" gap={1} gridTemplateColumns="1fr 2fr">
            <Typography color="text.mediumEmphasis" variant="body2" py={0.25}>
              Type
            </Typography>
            <Typography>{props.leadProject.sourceType ?? "--"}</Typography>
            <Typography color="text.mediumEmphasis" variant="body2" py={0.25}>
              Source Name
            </Typography>
            <Typography>
              {props.leadProject.sourceName?.value ?? "--"}
            </Typography>
          </Box>
        </Stack>
      </Stack>
      {leadModalOpen && (
        <LeadProjectDetailsModal
          leadProjectId={props.leadProject.id}
          modalOpen={true}
          setModalOpen={setLeadModalOpen}
        />
      )}
    </Paper>
  );
}

const LeadName = styled(Typography)(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  color: theme.palette.text.action.main,
  cursor: "pointer",
  ...theme.typography.subtitle2,
}));
