import { ReactElement, Reducer, useEffect, useReducer, useState } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Button from "@mui/material/Button";
import { VentureClient } from "../../../../Types/VentureClient";
import { VentureClientHttpService } from "../../../../Http/VentureClient/VentureClient.http.service";
import ClientContactSelect from "../../../UI/InputFields/ClientContactSelect";
import { FileHttpService } from "../../../../Http/File/File.http.service";
import { Grid, InputLabel } from "@mui/material";
import LogoUploadDropbox from "../../../UI/LogoUploadDropbox";
import { useSnackbar } from "notistack";

interface Props {
  ventureClientData: VentureClient;
  handleSave: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        margin: theme.spacing(2),
        minWidth: "35ch",
      },
      "& .MuiInput-underline:before, .MuiInput-underline:hover:before": {
        borderBottom: "none",
      },
      width: "inherit",
    },
    Typography: {
      marginTop: theme.spacing(6),
      fontWeight: theme.typography.fontWeightMedium,
    },
  })
);

export default function Form(props: Props): ReactElement {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [editMode, setEditMode] = useState(false);
  const [ventureClient, setVentureClient] = useReducer<
    Reducer<VentureClient, Partial<VentureClient>>
  >((state, newState) => ({ ...state, ...newState }), props.ventureClientData);
  useEffect(() => {
    setVentureClient(props.ventureClientData);
  }, [props.ventureClientData]);

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const cancelEdit = () => {
    setVentureClient(props.ventureClientData);
    setEditMode(false);
  };

  const saveProject = async () => {
    if (ventureClient) {
      await VentureClientHttpService.updateVentureClient(ventureClient);
      props.handleSave();
      setEditMode(false);
    }
  };

  const uploadFile = async (file: File, type: string) => {
    await Promise.all(
      props.ventureClientData.files
        .filter((file) => file.type === type)
        .map(async (file) => await FileHttpService.delete(file.id))
    );

    try {
      await FileHttpService.upload(
        file,
        props.ventureClientData.id,
        "ventureClient",
        type
      );
    } catch (error) {
      enqueueSnackbar("File can not be uploaded", {
        variant: "error",
      });
    }
  };

  const ventureClientUnitLogo = props.ventureClientData.files.find(
    (file) => file.type === "Logo"
  );
  const companyLogo = props.ventureClientData.files.find(
    (file) => file.type === "companyLogo"
  );

  return (
    <div className={classes.root}>
      {ventureClient && (
        <form noValidate autoComplete="off">
          <Box
            sx={{
              justifyContent: "space-between",
              flexDirection: "row",
              display: "flex",
            }}
          >
            <Typography variant="h6">Venture Client</Typography>
            {!editMode && (
              <Button onClick={toggleEditMode} id="edit-form-button">
                <EditOutlinedIcon />
              </Button>
            )}
          </Box>

          <Typography variant="h6" className={classes.Typography}>
            Information
          </Typography>
          <TextField
            label="Name"
            value={ventureClient.name}
            InputLabelProps={{ shrink: true }}
            variant={editMode ? "outlined" : "standard"}
            focused={false}
            inputProps={{
              readOnly: !editMode,
            }}
            onChange={(e) => setVentureClient({ name: e.target.value })}
            required={editMode}
          />
          <TextField
            label="Venture Client Unit Name"
            value={ventureClient.unitName}
            InputLabelProps={{ shrink: true }}
            variant={editMode ? "outlined" : "standard"}
            focused={false}
            inputProps={{
              readOnly: !editMode,
            }}
            onChange={(e) => setVentureClient({ unitName: e.target.value })}
            required={editMode}
          />
          <TextField
            label="Industry"
            InputLabelProps={{ shrink: true }}
            value={ventureClient.industry}
            variant={editMode ? "outlined" : "standard"}
            focused={false}
            inputProps={{
              readOnly: !editMode,
            }}
            onChange={(e) => setVentureClient({ industry: e.target.value })}
          />
          <Typography variant="h6" className={classes.Typography}>
            Contact
          </Typography>
          <div style={{ width: "35ch" }}>
            <ClientContactSelect
              labelText="Main Contact"
              ventureClientId={ventureClient.id}
              onChange={setVentureClient}
              fieldId={"mainContactId"}
              contactData={ventureClient.mainContact}
              multiSelect={false}
              editMode={editMode}
            />
          </div>
          <Typography variant="h6" className={classes.Typography}>
            Branding and Promotion
          </Typography>
          <Grid container gap={5}>
            <Box sx={{ my: 2 }}>
              <InputLabel shrink={true} sx={{ mb: 1 }}>
                Venture Client Unit Logo
              </InputLabel>
              {editMode && (
                <div style={{ maxWidth: "35ch" }}>
                  <LogoUploadDropbox
                    addLogo={(file) => uploadFile(file, "Logo")}
                  />
                </div>
              )}
              {ventureClientUnitLogo && !editMode && (
                <Box
                  sx={{ maxWidth: "25ch" }}
                  data-testid="venture-client-unit-logo"
                  component="img"
                  src={ventureClientUnitLogo.url}
                  alt={
                    props.ventureClientData.unitName
                      ? props.ventureClientData.unitName + " logo"
                      : "venture client unit logo"
                  }
                />
              )}
            </Box>
            <Box sx={{ my: 2 }}>
              <InputLabel shrink={true} sx={{ mb: 1 }}>
                Venture Client Logo
              </InputLabel>
              {editMode && (
                <div style={{ maxWidth: "35ch" }}>
                  <LogoUploadDropbox
                    addLogo={(file) => uploadFile(file, "companyLogo")}
                  />
                </div>
              )}
              {companyLogo && !editMode && (
                <Box
                  sx={{ maxWidth: "25ch" }}
                  data-testid="venture-client-logo"
                  component="img"
                  src={companyLogo.url}
                  alt={props.ventureClientData.name ?? "venture client logo"}
                />
              )}
            </Box>
          </Grid>
        </form>
      )}
      {editMode && (
        <Box display="flex" justifyContent="end" gap={2}>
          <Button
            onClick={cancelEdit}
            color="primary"
            id="cancel-venture-client-button"
          >
            Cancel
          </Button>
          <Button
            onClick={saveProject}
            variant="contained"
            color="secondary"
            id="save-venture-client-button"
          >
            Save Venture Client
          </Button>
        </Box>
      )}
    </div>
  );
}
