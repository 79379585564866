import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  Box,
  Chip,
  Grid,
} from "@mui/material";
import { ReactElement, useState } from "react";
import { Project } from "../../../../Types/Project";
import OverviewSection from "../Discover/CuratedList/OverviewSection/OverviewSection";
import SolutionFitMatrixTable from "../../SharedComponents/SolutionFitMatrixComponents/SolutionFitMatrixTable/SolutionFitMatrixTable";
import SolutionFitMatrixHeader from "../../SharedComponents/SolutionFitMatrixComponents/SolutionFitMatrixHeader/SolutionFitMatrixHeader";
import ProjectDetailsAccordionSummary from "../../SharedComponents/ProjectDetailsAccordionSummary";

interface QualifiedListProps extends Omit<AccordionProps, "children"> {
  project: Project;
  handleSave: () => void;
  showFullList: boolean;
  onFullWidthButtonClick: () => void;
}

export default function QualifiedList(props: QualifiedListProps): ReactElement {
  const [selectedDetailsView, setSelectedDetailsView] = useState<boolean>(true);
  const [isAddingStartup, setIsAddingStartup] = useState(false);

  return (
    <Accordion expanded={props.expanded} onChange={props.onChange}>
      <ProjectDetailsAccordionSummary
        $fullWidth
        $fullyExpanded={props.showFullList}
        $handleFullWidthButton={props.onFullWidthButtonClick}
      >
        <Box display="flex" gap={1} alignItems="center">
          Qualified List
          {!props.expanded && (
            <Chip
              variant="counter"
              color="info"
              size="small"
              label={
                props.project.opportunities.filter(
                  (opportunity) => opportunity.isQualified
                ).length
              }
            />
          )}
        </Box>
      </ProjectDetailsAccordionSummary>
      <AccordionDetails>
        <OverviewSection
          project={props.project}
          handleSave={props.handleSave}
          showFullList={props.showFullList}
        />
        {props.showFullList && (
          <Grid
            container
            sx={{
              mt: 3,
              isolation: "isolate",
              "& .flipper": {
                width: "100%",
              },
            }}
          >
            <SolutionFitMatrixHeader
              setSelectedDetailsView={(view: boolean) =>
                setSelectedDetailsView(view)
              }
              selectedDetailsView={selectedDetailsView}
              projectFunnelStage="assess"
              opportunities={props.project.opportunities}
              project={props.project}
            />

            <SolutionFitMatrixTable
              ventureClientId={props.project.businessUnit.ventureClientId}
              projectId={props.project.id}
              clusters={props.project.solutionClusters}
              opportunities={props.project.opportunities.filter(
                (opportunity) => opportunity.isQualified
              )}
              handleSave={props.handleSave}
              detailsView={selectedDetailsView}
              requirements={
                props.project.requirements ? props.project.requirements : []
              }
              projectFunnelStage="assess"
              currentProjectStage={props.project.funnelStage}
              isAddingStartup={isAddingStartup}
              setIsAddingStartup={setIsAddingStartup}
            />
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
