import { styled } from "@mui/styles";
import { MaterialDesignContent } from "notistack";
import theme from "../../theme";

const CustomSnackbar = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent": {
    backgroundColor: theme.palette.surface.tertiary.light,
    color: theme.palette.text.primary,
    width: "345px",
    ...theme.typography.body1,
    "& #notistack-snackbar:has(+div a)": {
      maxWidth: "254px",
    },
    "& div:has(a)": {
      marginRight: 0,
      display: "block",
      paddingLeft: 0,
    },
  },
  "&.notistack-MuiContent-error": {
    backgroundColor: theme.palette.surface.primary.main,
    borderLeft: `${theme.spacing(0.5)} solid ${theme.palette.surface.error}`,
    "& svg": {
      color: theme.palette.icon.error,
    },
  },
  "&.notistack-MuiContent-warning": {
    backgroundColor: theme.palette.surface.primary.main,
    borderLeft: `${theme.spacing(0.5)} solid ${theme.palette.surface.alert}`,
    "& svg": {
      color: theme.palette.icon.alert,
    },
  },
  "&.notistack-MuiContent-success": {
    backgroundColor: theme.palette.surface.primary.main,
    borderLeft: `${theme.spacing(0.5)} solid ${theme.palette.surface.success}`,
    "& svg": {
      color: theme.palette.icon.success,
    },
  },
}));

export default CustomSnackbar;
