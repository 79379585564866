import { Box, Button, Chip, Stack, styled, Typography } from "@mui/material";
import { ReactElement, useEffect, useRef, useState } from "react";
import theme from "../../../../theme";
import { Startup } from "../../../../Types/Startup";
import StartupCard from "../../StartupCard";
import CompetitorsModal from "./CompetitorsModal";
import { CompetitorsInfoTooltip } from "./CompetitorsInfoTooltip";

const CompetitorCardsWrapper = styled(Box)(() => ({
  display: "flex",
  flexWrap: "nowrap",
  height: "fit-content",
  gap: theme.spacing(3),
  overflowX: "scroll",
  padding: theme.spacing(0.75),
}));

export default function CompetitorsSection({
  competitors,
  onCompetitorAddedToProject,
  startup,
}: {
  startup: Startup;
  competitors: Startup[];
  onCompetitorAddedToProject: () => void;
}): ReactElement {
  const [modalOpen, setModalOpen] = useState(false);
  const [showViewAll, setShowViewAll] = useState(false);

  const competitorsContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateButtonVisibility = () => {
      const competitorsContainer = competitorsContainerRef.current;

      if (
        competitorsContainer &&
        competitorsContainer?.scrollWidth > competitorsContainer?.clientWidth
      ) {
        setShowViewAll(true);
      } else {
        setShowViewAll(false);
      }
    };

    updateButtonVisibility();

    window.addEventListener("resize", updateButtonVisibility);

    return () => {
      window.removeEventListener("resize", updateButtonVisibility);
    };
  }, [competitors]);

  const isListInaccurate =
    startup.opportunities?.length + startup.leadOpportunities?.length < 3;

  return (
    <>
      <Stack gap={2.25} data-testid="competitors-section">
        <Box display="flex" gap={1} height={48} p={0.75} alignItems="center">
          <Typography variant="h6">Competitors</Typography>
          <CompetitorsInfoTooltip isListInaccurate={isListInaccurate} />
          <Chip
            label={competitors.length}
            variant="counter"
            color="info"
            data-testid="competitors-count"
            sx={{ ml: theme.spacing(1) }}
          />
          {showViewAll && (
            <Button
              sx={{ ml: "auto" }}
              onClick={() => {
                setModalOpen(true);
              }}
            >
              View All
            </Button>
          )}
        </Box>
        {competitors.length > 0 ? (
          <CompetitorCardsWrapper ref={competitorsContainerRef}>
            {competitors.map((competitor, index) => (
              <Box key={index} width="197px">
                <StartupCard
                  startup={competitor}
                  onStartupAddedToProject={onCompetitorAddedToProject}
                  hasShadow
                  showWebsite
                />
              </Box>
            ))}
          </CompetitorCardsWrapper>
        ) : (
          <Typography color="text.disabled">
            {
              "Competitor information is unavailable as this startup hasn't yet been assigned to any projects."
            }
          </Typography>
        )}
      </Stack>
      {modalOpen && (
        <CompetitorsModal
          modalOpen={true}
          setModalOpen={setModalOpen}
          competitors={competitors}
          onCompetitorAddedToProject={onCompetitorAddedToProject}
          isListInaccurate={isListInaccurate}
        />
      )}
    </>
  );
}
