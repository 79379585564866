import React, { ReactElement } from "react";
import {
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  stepConnectorClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import theme from "../../../../../theme";
import { Opportunity } from "../../../../../Types/Opportunity";

interface StepData {
  label: string;
  value: string | number;
  icon: ReactElement;
  disabled: boolean;
}

const CustomStepConnector = styled(StepConnector)(() => ({
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.surface.brand.main,
    marginTop: "-16px",
    minHeight: "48px",
  },
  [`&.Mui-disabled .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.background.default,
  },
}));

interface Props {
  opportunities: Opportunity[];
}

const AssessmentStepper = (props: Props): ReactElement => {
  const totalCuratedStartups = props.opportunities.length;
  const assessedStartups = props.opportunities.filter(
    (opportunity) => opportunity.isQualified
  ).length;
  const nrOfDemos = props.opportunities.reduce((acc, opp) => {
    const demos = opp.productDemos.length;
    return acc + demos;
  }, 0);
  const selectedStartup = props.opportunities.find(
    (opp) => opp.isSelectedForPilot
  )?.startup.name;

  const steps: StepData[] = [
    {
      label: "Total Curated Startups",
      value: totalCuratedStartups,
      icon: <ArrowDropDownIcon />,
      disabled: false,
    },
    {
      label: "Assessed Startups",
      value: assessedStartups,
      icon: <ArrowDropDownIcon />,
      disabled: false,
    },
    {
      label: "Startup Demos",
      value: nrOfDemos > 0 ? nrOfDemos : selectedStartup ? 0 : "--",
      icon: <ArrowDropDownIcon />,
      disabled: selectedStartup || nrOfDemos > 0 ? false : true,
    },
    {
      label: "Selected Startup",
      value: selectedStartup || "--",
      icon: <RocketLaunchOutlinedIcon />,
      disabled: !selectedStartup,
    },
  ];

  const getIconStyles = (index: number, disabled: boolean) => {
    if (index === 3) {
      return {
        backgroundColor: disabled
          ? theme.palette.surface.medium
          : theme.palette.surface.brand.main,
        color: disabled ? theme.palette.icon.disabled : "white",
        "& path": {
          transform: "scale(0.6) translate(8px, 8px)",
        },
      };
    }

    return {
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: disabled
        ? theme.palette.surface.medium
        : theme.palette.surface.brand.main,
      color: disabled
        ? theme.palette.background.default
        : theme.palette.surface.brand.main,
    };
  };

  return (
    <Stepper
      orientation="vertical"
      nonLinear
      connector={<CustomStepConnector />}
      sx={{
        alignSelf: "flex-start",
      }}
    >
      {steps.map((step, index) => (
        <Step key={index} active={!step.disabled} disabled={step.disabled}>
          <StepLabel
            sx={{ alignItems: "flex-start", padding: 0 }}
            icon={React.cloneElement(step.icon, {
              sx: {
                ...getIconStyles(index, step.disabled),
                zIndex: 2,
                borderRadius: "50%",
              },
            })}
          >
            <Typography
              variant="subtitle1"
              color={step.disabled ? "text.disabled" : "text.primary"}
            >
              {step.value}
            </Typography>
            <Typography variant="overline" color="text.mediumEmphasis">
              {step.label}
            </Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default AssessmentStepper;
