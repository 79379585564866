import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Drawer,
  FormControlLabel,
  IconButton,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { ReactElement, useState } from "react";
import { StartupFilterCriteria } from "../../Types/Startup";
import { investmentStagesOptions } from "../../Constants/Startup";
import theme from "../../theme";
import useRoles from "../../Hooks/useRoles";

interface FilterDrawerProps {
  open: boolean;
  setFilterDrawerOpen: (state: boolean) => void;
  handleFilters: (state: StartupFilterCriteria) => void;
  activeFilters: StartupFilterCriteria;
}

const employees = ["<10", "<25", "<50", "<100", "<500", ">500", "Undisclosed"];

//add default value of filters
const defaultFilters = {
  currentInvestmentStage: "",
  employees: "",
  isMissingValidation: false,
};

export function FilterDrawer(props: FilterDrawerProps): ReactElement {
  const { isExternalUser } = useRoles();
  const [filterOptions, setFilterOptions] = useState(defaultFilters);

  const resetFilters = () => {
    if (Object.keys(props.activeFilters).length != 0) {
      props.handleFilters({});
    }
    setFilterOptions(defaultFilters);
    props.setFilterDrawerOpen(false);
  };

  const handleAcceptFilterChanges = () => {
    const validFilters: StartupFilterCriteria = Object.entries(
      filterOptions
    ).reduce((acc, [key, value]) => {
      // remove empty strings and undefined values (businessUnitId / ventureClientId e.g.)
      if (value !== "" && value !== undefined)
        acc = Object.assign({ [key]: value }, acc);
      return acc;
    }, {} as StartupFilterCriteria);

    if (JSON.stringify(validFilters) != JSON.stringify(props.activeFilters)) {
      props.handleFilters(validFilters);
    }
    props.setFilterDrawerOpen(false);
  };

  const handleSelectFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setFilterOptions({ ...filterOptions, [name]: value });
  };

  const handleToggleSwitchFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setFilterOptions({ ...filterOptions, isMissingValidation: checked });
  };

  return (
    <Drawer variant="temporary" anchor="right" open={props.open}>
      <Toolbar sx={{ padding: 3 }}>
        <IconButton
          id="filter-drawer-close-button"
          onClick={() => props.setFilterDrawerOpen(false)}
          sx={{
            color: theme.palette.icon.primary,
            ml: "auto",
          }}
        >
          <Close />
        </IconButton>
      </Toolbar>
      <Stack paddingInline={3} width="480px" gap={4}>
        <Box display="flex" alignItems="center">
          <Typography variant="h3">Filters</Typography>
        </Box>
        {!isExternalUser && (
          <Box>
            <FormControlLabel
              data-testid="incomplete-startup-profile-switch"
              control={
                <Switch
                  onChange={handleToggleSwitchFilter}
                  checked={filterOptions.isMissingValidation}
                />
              }
              label={
                <Typography
                  color={
                    filterOptions.isMissingValidation
                      ? theme.palette.text.primary
                      : theme.palette.text.disabled
                  }
                >
                  Incomplete Startup Profiles
                </Typography>
              }
            />
          </Box>
        )}
        <Stack gap={3}>
          <TextField
            label="Funding Stage"
            id="currentInvestmentStage"
            name="currentInvestmentStage"
            variant="outlined"
            select
            value={filterOptions.currentInvestmentStage}
            onChange={handleSelectFilter}
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem key="none" value="">
              None
            </MenuItem>
            {investmentStagesOptions.map((entity) => (
              <MenuItem key={entity.id} value={entity.id}>
                {entity.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Employees"
            id="employees"
            name="employees"
            variant="outlined"
            select
            value={filterOptions.employees}
            onChange={handleSelectFilter}
            InputLabelProps={{
              shrink: true,
            }}
          >
            <MenuItem key="none" value="">
              None
            </MenuItem>
            {employees.map((entity) => (
              <MenuItem key={entity} value={entity}>
                {entity}
              </MenuItem>
            ))}
          </TextField>
          <Box display="flex" p={1} alignSelf="flex-end" height="fit-content">
            <Button
              onClick={() => resetFilters()}
              variant="text"
              id="reset-startup-filters"
              startIcon={<Close />}
            >
              Clear All
            </Button>
          </Box>
        </Stack>
      </Stack>
      <Box mt="auto" p={3}>
        <Button
          fullWidth
          variant="contained"
          onClick={handleAcceptFilterChanges}
          id="apply-startup-filters"
        >
          Apply
        </Button>
      </Box>
    </Drawer>
  );
}
