import axios from "axios";
import { SourceName } from "../../Types/SourceName";

export class SourceNamesHttpService {
  public static async getSourceNames(
    ventureClientId: number,
    sourceType?: string
  ): Promise<SourceName[]> {
    const response = await axios.get<SourceName[]>(`/api/source-names`, {
      params: { ventureClientId, sourceType },
    });
    return response.data;
  }

  public static async createSourceName(
    sourceName: Omit<SourceName, "id">
  ): Promise<SourceName> {
    const response = await axios.post<SourceName>(
      "/api/source-names",
      sourceName
    );
    return response.data;
  }
}
