import { AppBar, Tabs, Tab, Box, styled } from "@mui/material";
import React, {
  Fragment,
  ReactElement,
  ReactFragment,
  useEffect,
  useState,
} from "react";
import DetailsView from "./DetailsView/DetailsView";
import BusinessUnitsList from "./BusinessUnitsView/BusinessUnitsList";
import { TabPanel, a11yProps } from "../../UI/TabPanel";
import { NavLink, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import { VentureClientHttpService } from "../../../Http/VentureClient/VentureClient.http.service";
import { VentureClient } from "../../../Types/VentureClient";
import ContentWrapper from "../../ProjectDetails/ContentWrapper";
import useRoles from "../../../Hooks/useRoles";
import theme from "../../../theme";

const StyledAppBar = styled(AppBar)(() => ({
  boxShadow: "none",
  backgroundColor: theme.palette.background.default,
  marginTop: theme.spacing(2),
}));

const StyledNavLink = styled(NavLink)(() => ({
  display: "inline-flex",
  alignItems: "center",
  gap: theme.spacing(2.5),
}));

const StyledTabsPanel = styled(Tabs)(() => ({
  "& .MuiTabs-flexContainer": {
    borderBottom: `none`,
  },
}));

export default function VentureClientDetailsPage(): ReactElement {
  const { id } = useParams<{ id: string }>();
  const { isExternalUser } = useRoles();
  const [ventureClientData, setVentureClientData] =
    React.useState<VentureClient>();

  const [refresh, setRefresh] = useState<boolean>(false);
  const [value, setValue] = useState(0);
  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number
  ) => {
    setValue(newValue);
  };
  useEffect(() => {
    VentureClientHttpService.getVentureClientById(parseInt(id)).then((data) => {
      setVentureClientData(data);
    });
  }, [id, refresh]);

  useEffect(() => {
    if (ventureClientData?.name) {
      document.title = `${ventureClientData?.name} - Venture Client Platform`;
    } else {
      document.title = `Client`;
    }
  }, [ventureClientData?.name]);

  const handleSave = () => {
    setRefresh(!refresh);
  };

  const renderTitle = (): ReactFragment => {
    const ventureClientName = (
      <Typography id="venture-client-details-page-name" variant="h5">
        {ventureClientData ? ventureClientData.name : "Loading...."}
      </Typography>
    );

    if (isExternalUser) return ventureClientName;
    return (
      <StyledNavLink to={"/venture-clients"}>
        <ArrowBackIcon color={"primary"} />
        {ventureClientName}
      </StyledNavLink>
    );
  };

  return (
    <Fragment>
      <ContentWrapper>
        <Box display="flex" flexGrow={1} justifyContent="space-between">
          {renderTitle()}
        </Box>
        <StyledAppBar position="static">
          <StyledTabsPanel value={value} onChange={handleChange}>
            <Tab label="Details" {...a11yProps(0)} />
            <Tab label="Organizational Units" {...a11yProps(1)} />
          </StyledTabsPanel>
        </StyledAppBar>
        <TabPanel value={value} index={0}>
          {ventureClientData && (
            <DetailsView
              handleSave={handleSave}
              ventureClientData={ventureClientData}
            />
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <BusinessUnitsList />
        </TabPanel>
      </ContentWrapper>
    </Fragment>
  );
}
