import { ReactElement, useEffect, useState } from "react";
import { StartupContactDTO } from "../../../Types/StartupContact";
import { ClientContactDTO } from "../../../Types/ClientContact";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Typography,
  styled,
  Modal,
} from "@mui/material";
import theme from "../../../theme";
import { Startup } from "../../../Types/Startup";
import { StartupHttpService } from "../../../Http/Startup/Startup.http.service";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../utils";
import ContactName from "./ContactName";
import ContactCardContacts from "./ContactCardContacts";
import ContactCounter from "./ContactCounter";
import { MoreVert } from "@mui/icons-material";
import StartupContactSelect from "../../UI/InputFields/StartupContactSelect";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import useRoles from "../../../Hooks/useRoles";

const MainContactCardWrapper = styled(Paper, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(
  ({
    $color = "white",
  }: {
    $color?: "gray" | "white";
    $isClient?: boolean;
  }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    ariaLabel: "main-contact-name",
    position: "relative",
    padding: theme.spacing(3),

    backgroundColor:
      $color === "white"
        ? theme.palette.surface.primary.main
        : theme.palette.background.default,
  })
);

type MainContactCardProps = {
  avatarUrl?: string;
  color?: "white" | "gray";
  showProjects?: boolean;
  editable: boolean;
  showVCL?: boolean;
  showButton?: boolean;
  showTitle?: boolean;
  handleSave: (withScroll?: boolean | undefined) => void;
} & (
  | {
      contact?: StartupContactDTO;
      variant: "startup";
      startupId: number;
    }
  | {
      contact: ClientContactDTO;
      variant: "client";
    }
);

export default function MainContactCard({
  color = "white",
  editable = true,
  showProjects = true,
  showVCL = true,
  showTitle = true,
  showButton = true,
  ...props
}: MainContactCardProps): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const { isExternalUser } = useRoles();

  const [startup, setStartup] = useState<Partial<Startup>>();
  const [openMainContactModal, setOpenMainContactModal] = useState(false);

  const isStartup = props.variant === "startup";
  const isClient = props.variant === "client";

  const handleCloseMainContactModal = () => {
    setOpenMainContactModal(false);
  };

  const handleEditMainContact = () => {
    setOpenMainContactModal(true);
  };

  useEffect(() => {
    if (isStartup) {
      StartupHttpService.getStartupById(props.startupId)
        .then((startup) => {
          setStartup({
            id: startup.id,
            mainContactId: startup.mainContactId,
            mainContact: startup.mainContact,
          });
        })

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          const message = getErrorMessage(error);
          enqueueSnackbar(message, { variant: "error" });
        });
      if (isClient) {
        // TODO: GET contact projects
      }
    }
  }, [isStartup]);

  const saveStartupDetails = async () => {
    try {
      if (!startup) {
        return;
      }
      await StartupHttpService.updateStartup(startup);
      props.handleSave(false);
      enqueueSnackbar("Startup saved successfully.", {
        variant: "success",
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const errorMessage = getErrorMessage(error);
      return enqueueSnackbar(
        `Could not update startup details: ${errorMessage}`,
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <MainContactCardWrapper
      $color={color}
      data-testid="main-contact-name"
      $isClient
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Box>
          <Typography variant="h6">Main Contact</Typography>
        </Box>
        {!isExternalUser && (
          <Box>
            <IconButton
              onClick={handleEditMainContact}
              data-testid="edit-main-contact-button"
            >
              <ModeEditOutlineOutlinedIcon />
            </IconButton>
          </Box>
        )}
      </Box>

      {isClient && showTitle && (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1">Main Contact</Typography>(
          <IconButton sx={{ position: "absolute", right: theme.spacing(1) }}>
            <MoreVert
              sx={{
                color: theme.palette.icon.disabled,
              }}
            />
          </IconButton>
          )
        </Box>
      )}

      {isClient && showVCL && props.contact?.businessUnit && (
        <Stack flex="1 1 0" gap={0.5}>
          <Typography variant="caption">
            {props.contact?.businessUnit.ventureClient.name} -{" "}
            {props.contact?.businessUnit.name}
          </Typography>
          <Typography variant="caption">
            {props.contact?.departmentCode}
          </Typography>
        </Stack>
      )}

      {editable && (
        <Modal data-testid="modal-view" open={openMainContactModal}>
          <Stack
            padding={theme.spacing(4)}
            gap={theme.spacing(4)}
            borderRadius={theme.shape.radius.minimal}
            position="absolute"
            top="50%"
            left="50%"
            boxShadow={theme.boxShadows[24]}
            minWidth={745}
            sx={{
              transform: "translate(-50%, -50%)",
              background: `${theme.palette.surface.primary.main}`,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Main Contact
            </Typography>
            {isStartup && (
              <>
                <StartupContactSelect
                  editMode={true}
                  labelText="Main Contact"
                  startupId={props.startupId}
                  onChange={(event: Startup) =>
                    setStartup({
                      id: props.startupId,
                      mainContact: event.mainContact,
                      mainContactId: event.mainContactId,
                    })
                  }
                  contactData={startup?.mainContact}
                  fieldId="mainContactId"
                  field="mainContact"
                />
              </>
            )}
            <Box display="flex" justifyContent="flex-end" gap={2} marginTop={2}>
              <Button onClick={handleCloseMainContactModal}>Cancel</Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  saveStartupDetails();
                  handleCloseMainContactModal();
                }}
              >
                Save
              </Button>
            </Box>
          </Stack>
        </Modal>
      )}
      {props.contact == null ? (
        <Typography
          variant="body1"
          color={theme.palette.text.disabled}
          data-testid="no-contact-added-text"
        >
          No Contacts added
        </Typography>
      ) : (
        <ContactName
          name={props.contact?.name}
          title={props.contact?.title}
          orientation="horizontal"
        />
      )}
      {(props.contact?.phone || props.contact?.email) && (
        <ContactCardContacts contact={props.contact} orientation="vertical" />
      )}
      {isClient && showButton && (
        <Button variant="contained" fullWidth>
          View Contacts
        </Button>
      )}
      {showProjects && props.contact?.name && (
        <ContactCounter
          label="Projects"
          backgroundColor={color}
          fullWidth={isStartup}
          value="5"
          counterColor="secondary"
        />
      )}
    </MainContactCardWrapper>
  );
}
