import { Box, styled, TableCell, Typography } from "@mui/material";
import { ReactElement, useState } from "react";
import {
  Opportunity,
  RejectionReason,
} from "../../../../../../Types/Opportunity";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import theme from "../../../../../../theme";
import AddSelectionReasonsModal from "./AddSelectionReasonsModal/AddSelectionReasonsModal";
interface SelectionRationaleProps {
  opportunity: Opportunity;
  ventureClientId: number;
  handleRejectionReasonChange: (
    updatedRejectionReasons: RejectionReason[],
    updatedDescription: string
  ) => void;
}

const SelectionContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  columnGap: theme.spacing(1),
  "& svg": {
    height: theme.spacing(2.5),
    width: theme.spacing(2.5),
  },
}));

const TypographySFMatrix = styled(Typography)(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 4,
  WebkitBoxOrient: "vertical",
  coursor: "pointer",
}));

export function SelectionRationale(
  props: SelectionRationaleProps
): ReactElement {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <TableCell
        sx={{ Left: theme.spacing(2) }}
        className="selection-rationale-Title extra-high-cell"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        {props.opportunity.isQualified ? (
          <SelectionContainer>
            <CheckCircleIcon
              sx={{ color: theme.palette.surface.tertiary.dark }}
            />
            Great fit for project
          </SelectionContainer>
        ) : !props.opportunity.rejectionReasons?.length ? (
          <SelectionContainer
            sx={{ cursor: "pointer" }}
            onClick={() => setModalOpen(true)}
          >
            <ErrorOutlineIcon sx={{ color: theme.palette.error.main }} />
            <Typography variant="caption" color="GrayText">
              Please provide comment
            </Typography>
          </SelectionContainer>
        ) : (
          <Box
            display="grid"
            data-testid="rejection-reasons-list"
            onClick={() => setModalOpen(true)}
          >
            <TypographySFMatrix variant="caption" fontWeight={"bold"}>
              {props.opportunity.rejectionReasons.map((reason) => {
                return (
                  <div
                    key={`list-of-reasons-${props.opportunity.id}-${reason.id}`}
                  >
                    {` • ${reason.name}`}
                  </div>
                );
              })}
            </TypographySFMatrix>
          </Box>
        )}
      </TableCell>

      {modalOpen && (
        <AddSelectionReasonsModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          opportunity={props.opportunity}
          ventureClientId={props.ventureClientId}
          handleRejectionReasonChange={props.handleRejectionReasonChange}
        />
      )}
    </>
  );
}
