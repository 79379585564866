import {
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  Box,
  TextField,
  styled,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useState, useReducer, Reducer, ReactElement } from "react";
import { useSnackbar } from "notistack";
import theme from "../../../../theme";
import { VariableHttpService } from "../../../../Http/Variable/Variable.http.service";
import { getErrorMessage } from "../../../../utils";
import { VariableType, VariableUnit } from "../../../../Types/ImpactValue";
import {
  NumberFormatCustom,
  NumberFormatGeneral,
} from "../../../UI/InputFields/NumberFormat";

const FormContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(4),
  marginBlock: theme.spacing(4),
  flexWrap: "wrap",
  "& > div:first-of-type": {
    flexBasis: "100%",
  },
  "& > div:not(:first-of-type)": {
    flex: 1,
  },
}));

const StyledRadioGroup = styled(RadioGroup)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  flexShrink: 0,
  "& .MuiRadio-root": {
    padding: 0,
  },
  "& .MuiTypography-root": {
    marginLeft: theme.spacing(1),
  },
}));

interface Props {
  calculationId: number;
  setModalOpen: (state: boolean) => void;
  modalOpen: boolean;
  handleImpactValue: () => void;
  variable?: VariableType;
  hasSingleVariable?: boolean;
}

const ManageVariableModal = (props: Props): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [newVariable, setNewVariable] = useReducer<
    Reducer<VariableType, Partial<VariableType>>
  >(
    (state, newState) => ({ ...state, ...newState }),
    props.variable ||
      ({
        calculationId: props.calculationId,
        operator: props.hasSingleVariable ? null : "x",
        unit: "Euro",
      } as VariableType)
  );

  const isCreateMode = !props.variable;

  const newVariableFields = {
    Name: newVariable.name,
    Value: newVariable.value,
  };

  const variableDecimalScale = 5;

  const checkRequiredFields = () => {
    const emptyRequiredFieldsArray: string[] = [];
    for (const [key, value] of Object.entries(newVariableFields)) {
      if (("" + value).trim().length === 0 || !value) {
        emptyRequiredFieldsArray.push(key);
      }
    }
    return emptyRequiredFieldsArray;
  };

  const showError = (value: string) => {
    enqueueSnackbar(`Please enter ${value}`, {
      variant: "error",
    });
  };

  const createVariable = async () => {
    const emptyRequiredFields = checkRequiredFields();
    if (emptyRequiredFields.length > 0) {
      return showError(emptyRequiredFields[0]);
    }

    setIsLoading(true);
    await VariableHttpService.createVariable(newVariable)
      .then(() => {
        props.handleImpactValue();
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(`Could not create Variable: ${errorMessage}`, {
          variant: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
        props.setModalOpen(false);
      });
  };

  const editVariable = async () => {
    const emptyRequiredFields = checkRequiredFields();
    if (emptyRequiredFields.length > 0) {
      return showError(emptyRequiredFields[0]);
    }

    setIsLoading(true);
    await VariableHttpService.updateVariable(newVariable)
      .then(() => {
        props.handleImpactValue();
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(`Could not update Variable: ${errorMessage}`, {
          variant: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
        props.setModalOpen(false);
      });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewVariable({ unit: (event.target.value as VariableUnit) || null });
  };

  return (
    <>
      <Dialog
        open={props.modalOpen}
        maxWidth="md"
        data-testid="manage-variable-modal"
      >
        <DialogTitle data-testid="manage-variable-header">
          {isCreateMode ? "Add Variable" : "Edit Variable"}
        </DialogTitle>
        <DialogContent>
          <FormContainer data-testid="manage-variable-form">
            <TextField
              label="Name"
              required
              placeholder="e.g. Material Cost"
              error={!newVariable.name}
              value={newVariable.name || ""}
              variant="outlined"
              onChange={(event) => setNewVariable({ name: event.target.value })}
              helperText="Provide a descriptive name for this variable"
              inputProps={{ "data-testid": "variable-name-input" }}
            />
            <Box display="flex" gap={5}>
              <TextField
                type="currency"
                label="Value"
                required
                fullWidth
                error={!newVariable.value}
                value={newVariable.value || ""}
                onChange={(event) => {
                  const value = event.target.value;
                  setNewVariable({ value: value });
                }}
                helperText="Provide a quantitative value for this variable"
                inputProps={{
                  "data-testid": "variable-value-input",
                  decimalScale: variableDecimalScale,
                }}
                InputProps={{
                  ...(newVariable.unit === "Euro" && {
                    // eslint-disable-next-line
                    inputComponent: NumberFormatCustom as any,
                  }),
                  ...(newVariable.unit === null && {
                    // eslint-disable-next-line
                    inputComponent: NumberFormatGeneral as any,
                  }),
                }}
              />
              <StyledRadioGroup
                value={newVariable.unit ?? ""}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="Euro"
                  control={<Radio />}
                  label={
                    <Typography variant="subtitle2">Monetary Value</Typography>
                  }
                />
                <FormControlLabel
                  value=""
                  control={<Radio />}
                  label={
                    <Typography variant="subtitle2">Measurement</Typography>
                  }
                />
              </StyledRadioGroup>
            </Box>
          </FormContainer>
        </DialogContent>
        <>
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            <Box display="flex" justifyContent="flex-end" gap={2}>
              <Button onClick={() => props.setModalOpen(false)}>Cancel</Button>
              <Button
                onClick={isCreateMode ? createVariable : editVariable}
                variant="contained"
              >
                Save
              </Button>
            </Box>
          )}
        </>
      </Dialog>
    </>
  );
};

export default ManageVariableModal;
