import { ReactElement } from "react";
import { ResponsivePie } from "@nivo/pie";
import {
  Box,
  Typography,
  styled,
  Stack,
  Tooltip,
  TooltipProps,
} from "@mui/material";
import theme from "../../../theme";
import { thousandSeparator } from "../../../utils";
import { BusinessImpactValues } from "../../../Types/Analytics";

const Wrapper = styled(Stack)(() => ({
  backgroundColor: theme.palette.surface.primary.main,
  padding: theme.spacing(3),
  gap: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  order: -1,
  height: "100%",
}));

const ContentRow = styled(Box)(() => ({
  display: "flex",
  gap: theme.spacing(2),
  justifyContent: "space-between",
}));

const ChartWrapper = styled(Box)(() => ({
  width: "120px",
  height: "120px",
}));

const NumberCardWrapper = styled(Stack)(() => ({
  backgroundColor: theme.palette.surface.secondary.main,
  padding: theme.spacing(2),
  height: "72px",
  minWidth: "0",
  borderRadius: theme.shape.radius.minimal,
}));

const CustomTooltip = (props: TooltipProps) => {
  return (
    <Tooltip
      {...props}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -14],
              },
            },
          ],
        },
      }}
    />
  );
};

function roundNumberToMagnitude(number: number): string {
  if (number < 1000) return thousandSeparator(number);
  else if (number < 1000000)
    return thousandSeparator(Math.round((number / 1000) * 100) / 100);
  else return thousandSeparator(Math.round((number / 1000000) * 100) / 100);
}

function numberMagnitude(number: number): string {
  if (number < 1000) return "";
  else if (number < 1000000) return "K";
  else return "M";
}

interface BusinessImpactProps {
  impactValuesByImpactType: BusinessImpactValues;
}

function BusinessImpact(props: BusinessImpactProps): ReactElement {
  const sumAllValue =
    props.impactValuesByImpactType.Cost +
    props.impactValuesByImpactType.Revenue +
    props.impactValuesByImpactType["Cost & Revenue"];

  return (
    <Wrapper>
      <Typography variant="h6">Business Impact</Typography>

      <ContentRow data-testid="business-impact">
        {/* Chart and 3 values */}
        <Box display="flex" gap={3}>
          <ChartWrapper>
            <ResponsivePie
              colors={[
                theme.palette.chart.dark.primary,
                theme.palette.chart.light.primary,
                theme.palette.chart.medium.primary,
              ]}
              data={[
                {
                  id: "cost",
                  value: props.impactValuesByImpactType.Cost / sumAllValue,
                },
                {
                  id: "cost_revenue",
                  value:
                    props.impactValuesByImpactType["Cost & Revenue"] /
                    sumAllValue,
                },
                {
                  id: "revenue",
                  value: props.impactValuesByImpactType.Revenue / sumAllValue,
                },
              ]}
              animate
              isInteractive={false}
              startAngle={90}
              endAngle={450}
              enableArcLabels={false}
              enableArcLinkLabels={false}
              innerRadius={0.5}
            />
          </ChartWrapper>

          <Stack justifyContent="space-between" flexGrow={1}>
            <Box>
              <CustomTooltip
                title={`€ ${thousandSeparator(
                  props.impactValuesByImpactType.Cost
                )}`}
                placement="top-start"
              >
                <Typography
                  variant="subtitle1"
                  color={theme.palette.chart.dark.primary}
                  display="flex"
                  alignItems="center"
                  gap={0.5}
                >
                  <Typography variant="subtitle2" component="span">
                    €
                  </Typography>
                  {roundNumberToMagnitude(props.impactValuesByImpactType.Cost)}
                  <Typography variant="subtitle2" component="span">
                    {numberMagnitude(props.impactValuesByImpactType.Cost)}
                  </Typography>
                </Typography>
              </CustomTooltip>
              <Typography
                variant="overline"
                color={theme.palette.text.mediumEmphasis}
                display={"block"}
              >
                Cost
              </Typography>
            </Box>

            <Box>
              <CustomTooltip
                title={`€ ${thousandSeparator(
                  props.impactValuesByImpactType.Revenue
                )}`}
                placement="top-start"
              >
                <Typography
                  variant="subtitle1"
                  color={theme.palette.chart.medium.primary}
                  display="flex"
                  alignItems="center"
                  gap={0.5}
                >
                  <Typography variant="subtitle2" component="span">
                    €
                  </Typography>
                  {roundNumberToMagnitude(
                    props.impactValuesByImpactType.Revenue
                  )}
                  <Typography variant="subtitle2" component="span">
                    {numberMagnitude(props.impactValuesByImpactType.Revenue)}
                  </Typography>
                </Typography>
              </CustomTooltip>
              <Typography
                variant="overline"
                color={theme.palette.text.mediumEmphasis}
              >
                Revenue
              </Typography>
            </Box>

            <Box>
              <CustomTooltip
                title={`€ ${thousandSeparator(
                  props.impactValuesByImpactType["Cost & Revenue"]
                )}`}
                placement="top-start"
              >
                <Typography
                  variant="subtitle1"
                  color={theme.palette.chart.light.primary}
                  display="flex"
                  alignItems="center"
                  gap={0.5}
                >
                  <Typography variant="subtitle2" component="span">
                    €
                  </Typography>
                  {roundNumberToMagnitude(
                    props.impactValuesByImpactType["Cost & Revenue"]
                  )}
                  <Typography variant="subtitle2" component="span">
                    {numberMagnitude(
                      props.impactValuesByImpactType["Cost & Revenue"]
                    )}
                  </Typography>
                </Typography>
              </CustomTooltip>
              <Typography
                variant="overline"
                color={theme.palette.text.mediumEmphasis}
                display={"block"}
              >
                Cost & Revenue
              </Typography>
            </Box>
          </Stack>
        </Box>

        {/* Number Card */}
        <NumberCardWrapper>
          <Typography variant="caption" color="text.mediumEmphasis">
            Total
          </Typography>
          <CustomTooltip
            title={`€ ${thousandSeparator(sumAllValue)}`}
            placement="top"
          >
            <Typography
              color={theme.palette.chart.dark.secondary}
              display="flex"
              alignItems="center"
              gap={0.5}
            >
              <Typography variant="subtitle2" component="span">
                €
              </Typography>
              <Typography
                variant="subtitle1"
                component="span"
                sx={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {roundNumberToMagnitude(sumAllValue)}
              </Typography>
              <Typography variant="subtitle2" component="span">
                {numberMagnitude(sumAllValue)}
              </Typography>
            </Typography>
          </CustomTooltip>
        </NumberCardWrapper>
      </ContentRow>
    </Wrapper>
  );
}

export default BusinessImpact;
