import { useEffect, useState } from "react";
import { SelectOption } from "../../Types/Common";
import { BusinessUnitCodependentAssociation } from "../../Types/BusinessUnit";
import useRoles from "../../Hooks/useRoles";
/**
 * A hook which initializes 2 co-dependent values for select inputs.
 * Possible select options for the second input are dependant on the value of the first select. When this latter value is updated, the
 * getNewCodependentSelectOptions function is called and fetches new select options.
 *
 * @export
 * @param {SelectOption} defaultSelectValue
 * @param {((id: number | string) => Promise<SelectOption[]>)} getNewCodependentSelectOptions
 * @return {*}  {[
 *   SelectOption,
 *   React.Dispatch<React.SetStateAction<SelectOption>>,
 *   SelectOption[]
 * ]}
 */
export function useCodependentSelectValues(
  defaultSelectValue: SelectOption,
  getNewCodependentSelectOptions: (filterCriteria?: {
    ventureClientId: number;
  }) => Promise<BusinessUnitCodependentAssociation[]>
): [
  SelectOption,
  React.Dispatch<React.SetStateAction<SelectOption>>,
  SelectOption[]
] {
  const [newSelectValue, setNewSelectValue] = useState(defaultSelectValue);
  const { isExternalUser } = useRoles();
  const [codependentSelectOptions, setCodependentSelectOptions] = useState<
    SelectOption[]
  >([]);

  useEffect(() => {
    if (isExternalUser) {
      getNewCodependentSelectOptions().then((newOptions) => {
        return setCodependentSelectOptions(newOptions);
      });
    }

    if (typeof newSelectValue.id === "number") {
      getNewCodependentSelectOptions({
        ventureClientId: newSelectValue.id,
      }).then((newOptions) => {
        return setCodependentSelectOptions(newOptions);
      });
    }
  }, [newSelectValue.id]);

  return [newSelectValue, setNewSelectValue, codependentSelectOptions];
}
