import { ReactElement, useContext } from "react";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import Box from "@mui/material/Box";
import { isVentureManager, UserContext } from "../../../../Context/UserContext";
import StageValidator from "../../../../StageValidation/StageValidator";
import { Project } from "../../../../Types/Project";
import theme from "../../../../theme";
import useRoles from "../../../../Hooks/useRoles";
import ScrollableDialogContent from "../../../UI/Modals/ScrollableDialogContent";

interface Props {
  modalOpen: boolean;
  setValidationDialogOpen: (arg: boolean) => void;
  projectData: Project;
  overrideValidationRules: () => void;
}

function StageValidationModal(props: Props): ReactElement {
  const user = useContext(UserContext);
  const userIsVentureManager = isVentureManager(
    user,
    props.projectData.businessUnit?.ventureClient.id
  );
  const { isExternalUser } = useRoles();

  return (
    <Dialog
      open={props.modalOpen}
      fullWidth
      maxWidth="xs"
      onClose={() => props.setValidationDialogOpen(false)}
      id="required-properties-dialog"
      PaperProps={{
        sx: {
          gap: theme.spacing(4),
        },
      }}
    >
      <DialogTitle>Missing Information</DialogTitle>
      <ScrollableDialogContent>
        The following data is required:
        <Box component="ul" sx={{ paddingInlineStart: "24px" }}>
          {StageValidator.getInvalidProperties(props.projectData).map(
            (property) => (
              <li key={property}>{property}</li>
            )
          )}
        </Box>
      </ScrollableDialogContent>
      <DialogActions>
        {(userIsVentureManager || isExternalUser) && (
          <Button
            onClick={() => props.overrideValidationRules()}
            color="warning"
          >
            Move Anyway
          </Button>
        )}
        <Button
          onClick={() => props.setValidationDialogOpen(false)}
          variant="contained"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default StageValidationModal;
