import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  Box,
  Button,
  Stack,
} from "@mui/material";
import { Project } from "../../../../Types/Project";
import { SelectInput } from "../../../UI/InputFields/SelectInput";
import { ReactElement, Reducer, useContext, useReducer, useState } from "react";
import {
  ADOPTION_DATE_TOOLTIP,
  ADOPTION_RESOURCES_TOOLTIP,
  ADOPTION_STATUS_TOOLTIP,
} from "../../../../Constants/TooltipText";
import CustomTextField from "../../../UI/InputFields/CustomTextField";
import ProjectDetailsAccordionSummary from "../../SharedComponents/ProjectDetailsAccordionSummary";
import { useSnackbar } from "notistack";
import { ProjectHttpService } from "../../../../Http/Project/Project.http.service";
import { getErrorMessage } from "../../../../utils";
import { GlobalProjectEditContext } from "../../../../Context/ProjectDetailsContext";
import useSaveProject from "../../../../Hooks/useSaveProject";
import CustomDatePicker from "../../../UI/InputFields/CustomDatePicker";

interface AdoptionInsightsProps extends Omit<AccordionProps, "children"> {
  project: Project;
  handleSave: () => void;
}

const adoptionStatusSelectValues = [
  { id: "Adoption Preparation", name: "Adoption Preparation" },
  { id: "Implement Solution", name: "Implement Solution" },
  { id: "Rollout Solution", name: "Rollout Solution" },
];

export default function AdoptionInsights(
  props: AdoptionInsightsProps
): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const { setGlobalEditMode } = useContext(GlobalProjectEditContext);
  const [editMode, setEditMode] = useState(false);
  const [project, setProject] = useReducer<Reducer<Project, Partial<Project>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      id: props.project.id,
      adoptionStatus: props.project.adoptionStatus,
      adoptionDate: props.project.adoptionDate,
      potentialLaunch: props.project.potentialLaunch,
      adoptionResources: props.project.adoptionResources,
    } as Project
  );

  const handleCancelEdit = () => {
    if (!props.expanded) return;

    setProject({
      adoptionStatus: props.project.adoptionStatus,
      adoptionDate: props.project.adoptionDate,
      potentialLaunch: props.project.potentialLaunch,
      adoptionResources: props.project.adoptionResources,
    });
    setEditMode(false);
    setGlobalEditMode(false);
  };

  const handleSaveSection = async () => {
    await ProjectHttpService.updateProject(project as Project)
      .then(() => {
        props.handleSave();
        setEditMode(false);
        setGlobalEditMode(false);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        enqueueSnackbar(`Could not save the project: ${errorMessage}`, {
          variant: "error",
        });
      });
  };

  const handleEditButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setEditMode(true);
    setGlobalEditMode(true);
  };

  const EditButton = () => {
    return (
      <Button
        data-testid="edit-adoption-insights"
        variant="contained"
        onClick={handleEditButtonClick}
      >
        Edit
      </Button>
    );
  };

  useSaveProject(handleSaveSection);

  return (
    <Accordion expanded={props.expanded} onChange={props.onChange}>
      <ProjectDetailsAccordionSummary
        actionButton={props.expanded && !editMode ? <EditButton /> : null}
      >
        Adoption Insights
      </ProjectDetailsAccordionSummary>
      <AccordionDetails>
        <Box display="flex" gap={8}>
          <Stack gap={3} flexBasis="30%">
            <SelectInput
              id="adoptionStatus"
              label="Adoption Status"
              selectValues={adoptionStatusSelectValues}
              value={project.adoptionStatus}
              onChange={(e) => setProject({ adoptionStatus: e.target.value })}
              editMode={editMode}
              toolTipText={ADOPTION_STATUS_TOOLTIP}
            />
            <CustomDatePicker
              id="adoptionDate"
              label="Date of Adoption"
              editMode={editMode}
              value={
                project.adoptionDate ? new Date(project.adoptionDate) : null
              }
              toolTipText={ADOPTION_DATE_TOOLTIP}
              onChange={(value) => {
                const newValue = value ? new Date(value) : null;
                setProject({
                  adoptionDate: newValue?.toLocaleDateString("fr-CA"),
                });
              }}
            />
            <CustomDatePicker
              id="potentialLaunch"
              label="Potential Launch of Solution"
              value={
                project.potentialLaunch
                  ? new Date(project.potentialLaunch)
                  : null
              }
              editMode={editMode}
              onChange={(value) => {
                const newValue = value ? new Date(value) : null;
                setProject({
                  potentialLaunch: newValue?.toLocaleDateString("fr-CA"),
                });
              }}
            />
          </Stack>
          <Box flexBasis="70%">
            <CustomTextField
              id="adoptionResources"
              label="Resources for Adoption"
              editMode={editMode}
              value={project.adoptionResources || ""}
              toolTipText={ADOPTION_RESOURCES_TOOLTIP}
              onChange={(e) =>
                setProject({ adoptionResources: e.target.value })
              }
              fullWidth
              maxCharacter={255}
              multiline
              minRows={3}
            />
          </Box>
        </Box>
      </AccordionDetails>

      {editMode && (
        <Box display="flex" justifyContent="flex-end" p={3} gap={3}>
          <Button onClick={handleCancelEdit}>Cancel</Button>
          <Button variant="contained" onClick={handleSaveSection}>
            Save
          </Button>
        </Box>
      )}
    </Accordion>
  );
}
