import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  Box,
  Button,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import React, {
  ReactElement,
  Reducer,
  useContext,
  useReducer,
  useState,
} from "react";
import {
  ACHIEVEMENTS_TOOLTIP,
  LEARNINGS_TOOLTIP,
} from "../../../../Constants/TooltipText";
import {
  PilotOutcome,
  PilotOutcomeReason,
  Project,
  ProjectPilotConclusion,
} from "../../../../Types/Project";
import RichTextEditor from "../../../UI/InputFields/RichTextEditor";
import theme from "../../../../theme";
import { Cancel, CheckCircle } from "@mui/icons-material";
import { SelectInput } from "../../../UI/InputFields/SelectInput";
import { useSnackbar } from "notistack";
import { ProjectHttpService } from "../../../../Http/Project/Project.http.service";
import { getErrorMessage } from "../../../../utils";
import ProjectDetailsAccordionSummary from "../../SharedComponents/ProjectDetailsAccordionSummary";
import useSaveProject from "../../../../Hooks/useSaveProject";
import { GlobalProjectEditContext } from "../../../../Context/ProjectDetailsContext";
import parse from "html-react-parser";
import CustomExpendableText from "../../../UI/CustomExpendableText";

const reasons = [
  "Failure to meet project objectives",
  "Insufficient data or inconclusive results",
  "Budget overruns",
  "Technical or logistical issues",
  "Changes of external factors",
  "Other",
];

const reasonValues = reasons.map((value) => ({
  id: value,
  name: value,
}));

const PilotProjectEvaluation = ({
  children,
}: {
  children: PilotOutcome | null;
}) => {
  const isPositive = children === "Positive";

  return (
    <Box display="flex" alignItems="center" gap={theme.spacing(0.5)}>
      {children && (
        <>
          {isPositive ? (
            <CheckCircle color="success" sx={{ fontSize: "1rem" }} />
          ) : (
            <Cancel color="error" sx={{ fontSize: "1rem" }} />
          )}
          <Typography
            color={
              isPositive ? theme.palette.success.main : theme.palette.error.main
            }
          >
            {children}
          </Typography>
        </>
      )}
    </Box>
  );
};

const RadioGroupLabel = ({
  value,
  label,
  required = false,
  editMode,
}: {
  value: string | null;
  label: string;
  required: boolean;
  editMode: boolean;
}) => {
  const showWarning = value !== null || !required || !editMode;
  return (
    <Typography
      variant="caption"
      color={
        showWarning ? theme.palette.text.secondary : theme.palette.warning.main
      }
    >
      {showWarning ? label : label + " *"}
    </Typography>
  );
};

interface Props extends Omit<AccordionProps, "children"> {
  project: ProjectPilotConclusion;
  handleSave: (withScroll: boolean) => void;
}

export default function PilotConclusion(props: Props): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const { setGlobalEditMode, globalEditMode } = useContext(
    GlobalProjectEditContext
  );
  const [editMode, setEditMode] = useState(false);
  const [outcome, setOutcome] = useState<string | null>(
    props.project?.pilotOutcome ?? null
  );
  const [project, setProject] = useReducer<
    Reducer<ProjectPilotConclusion, Partial<ProjectPilotConclusion>>
  >((state, newState) => ({ ...state, ...newState }), {
    id: props.project.id,
    learnings: props.project.learnings,
    achievements: props.project.achievements,
    pilotOutcome: props.project.pilotOutcome,
    pilotOutcomeReason: props.project.pilotOutcomeReason,
  });

  const handleCancelEdit = () => {
    if (!props.expanded) return;

    setProject({
      learnings: props.project.learnings,
      achievements: props.project.achievements,
      pilotOutcome: props.project.pilotOutcome,
      pilotOutcomeReason: props.project.pilotOutcomeReason,
    });
    setEditMode(false);
    setGlobalEditMode(false);
  };

  const handleSaveSection = async () => {
    if (!props.expanded) return;

    await ProjectHttpService.updateProject(project as Project)
      .then(() => {
        props.handleSave(false);
        setEditMode(false);
        setGlobalEditMode(false);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        enqueueSnackbar(`Could not save the project: ${errorMessage}`, {
          variant: "error",
        });
      });
  };

  const handleEditButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setEditMode(true);
    setGlobalEditMode(true);
  };

  const EditButton = () => {
    return (
      <Button
        data-testid="edit-pilot-conclusion"
        variant="contained"
        onClick={handleEditButtonClick}
        disabled={globalEditMode}
      >
        Edit
      </Button>
    );
  };

  const handleClick = (e: React.BaseSyntheticEvent) => {
    const newOutCome = outcome === e.target.value ? null : e.target.value;

    if (newOutCome === "Positive" && outcome === "Negative") {
      setProject({ pilotOutcomeReason: null });
    }

    setOutcome(newOutCome);
    setProject({ pilotOutcome: newOutCome });
  };

  useSaveProject(handleSaveSection);

  return (
    <Accordion
      expanded={props.expanded}
      onChange={props.onChange}
      disabled={editMode}
    >
      <ProjectDetailsAccordionSummary
        actionButton={props.expanded && !editMode ? <EditButton /> : null}
      >
        Pilot Conclusion
      </ProjectDetailsAccordionSummary>

      <AccordionDetails
        sx={{ display: "flex", flexDirection: "column", gap: 5 }}
      >
        <Box
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          gridTemplateRows="auto"
          gap={3}
        >
          <Box gridColumn="1 / -1">
            <RadioGroupLabel
              label="Project Evaluation"
              value={outcome}
              required
              editMode={editMode}
            />
            {editMode ? (
              <RadioGroup
                row
                value={outcome}
                sx={{
                  display: "flex",
                  gap: theme.spacing(7.5),
                }}
              >
                <FormControlLabel
                  value="Positive"
                  control={<Radio onClick={handleClick} />}
                  label="Positive"
                />
                <FormControlLabel
                  value="Negative"
                  control={<Radio onClick={handleClick} />}
                  label="Negative"
                />
              </RadioGroup>
            ) : (
              <PilotProjectEvaluation>
                {project.pilotOutcome}
              </PilotProjectEvaluation>
            )}
          </Box>

          {outcome === "Negative" && (
            <Box gridColumn="1">
              <SelectInput
                id="reason-input"
                label="Reason"
                selectValues={reasonValues}
                value={project.pilotOutcomeReason}
                onChange={(e) =>
                  setProject({
                    pilotOutcomeReason: e.target.value as PilotOutcomeReason,
                  })
                }
                editMode={editMode}
                disableSorting
                required
                fullWidth
              />
            </Box>
          )}
          <Box gridColumn="1">
            {editMode ? (
              <RichTextEditor
                editMode={editMode}
                fieldValue={project.learnings}
                onChange={(value) => setProject({ learnings: value })}
                labelText="Learnings"
                fieldId="learnings"
                required
                toolTipText={LEARNINGS_TOOLTIP}
              />
            ) : (
              <Stack gap={0.5} sx={{ "& p": { m: 0 } }}>
                <InputLabel>
                  <Typography variant="caption">Learnings</Typography>
                </InputLabel>
                <CustomExpendableText text={parse(project.learnings || "--")} />
              </Stack>
            )}
          </Box>
          <Box gridColumn="2">
            {editMode ? (
              <RichTextEditor
                editMode={editMode}
                fieldValue={project.achievements}
                onChange={(value) => setProject({ achievements: value })}
                labelText="Achievements"
                fieldId="achievements"
                required
                toolTipText={ACHIEVEMENTS_TOOLTIP}
              />
            ) : (
              <Stack gap={0.5} sx={{ "& p": { m: 0 } }}>
                <InputLabel>
                  <Typography variant="caption">Achievements</Typography>
                </InputLabel>
                <CustomExpendableText
                  text={parse(project.achievements || "--")}
                />
              </Stack>
            )}
          </Box>
        </Box>
      </AccordionDetails>
      {editMode && (
        <Box display="flex" justifyContent="flex-end" p={3} gap={3}>
          <Button onClick={handleCancelEdit}>Cancel</Button>
          <Button variant="contained" onClick={handleSaveSection}>
            Save
          </Button>
        </Box>
      )}
    </Accordion>
  );
}
