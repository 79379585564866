import { Badge, Box, Button, Stack, Typography } from "@mui/material";
import { ReactElement, useState } from "react";
import { LeadProject } from "../../../Types/LeadProject";
import ManageLeadProjectLeaderModal from "./ManageLeadProjectLeaderModal";
import LeadProjectLeaderSummary from "./LeadProjectLeaderSummary";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import theme from "../../../theme";

interface ProjectLeadLeaderProps {
  leadProject: LeadProject;
  onRefresh: () => void;
}
export default function LeadProjectLeaders(
  props: ProjectLeadLeaderProps
): ReactElement {
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const hasIncompleteLeaders =
    props.leadProject.leaders.length === 0 ||
    props.leadProject.leaders.some(
      (leader) =>
        !leader.projectLeader || !leader.businessUnit || !leader.useCase
    );

  return (
    <>
      <Stack gap={3}>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex">
            <Typography variant="h6">Project Leaders</Typography>
            {hasIncompleteLeaders && (
              <Badge
                color="warning"
                variant="dot"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={{
                  "& .MuiBadge-badge": {
                    right: theme.spacing(-1),
                    maxHeight: "6px",
                    minWidth: "6px",
                  },
                }}
              />
            )}
          </Box>
          <Button
            variant="contained"
            onClick={handleModalOpen}
            data-testid="add-lead-project-leader-button"
          >
            Add Project Leader
          </Button>
        </Box>

        {props.leadProject.leaders.length > 0 ? (
          props.leadProject.leaders.map((projectLeadLeader) => (
            <LeadProjectLeaderSummary
              leadProjectLeader={projectLeadLeader}
              onRefresh={props.onRefresh}
              leadProject={props.leadProject}
              key={projectLeadLeader.id}
            />
          ))
        ) : (
          <Box display="flex" flexDirection="row" alignItems="center">
            <ErrorOutlineIcon color="warning" />
            <Typography variant="body1" sx={{ ml: 1 }}>
              Please complete Project Leaders details and link it to a Use Case
            </Typography>
          </Box>
        )}

        {modalOpen && (
          <ManageLeadProjectLeaderModal
            setModalOpen={setModalOpen}
            modalOpen={modalOpen}
            leadProject={props.leadProject}
            onRefresh={props.onRefresh}
          />
        )}
      </Stack>
    </>
  );
}
