import { Stack, Tab, Tabs, Typography } from "@mui/material";
import { ReactElement } from "react";
import { startupTabs } from "../../../../Constants/Startup";
import useRoles from "../../../../Hooks/useRoles";
import { StartupPrimaryTabItems } from "../../../../Types/Startup";

interface Props {
  activeTab: number;
  handleChangeTab: (index: number, label: StartupPrimaryTabItems) => void;
}

function PrimaryTab(props: Props): ReactElement {
  const { isExternalUser } = useRoles();
  return (
    <Tabs
      value={props.activeTab}
      sx={{
        "& .MuiTabs-flexContainer": {
          borderBottom: "none",
          gap: 3,
        },
      }}
    >
      {startupTabs.primaryTab.map((type, index) => {
        const isDatabaseTab = index === 1;
        if (isDatabaseTab && isExternalUser) return;
        return (
          <Tab
            sx={{ pl: 0 }}
            key={index}
            onClick={() => props.handleChangeTab(index, type.title)}
            label={
              <Stack textAlign="start">
                <Typography variant="subtitle1">{type.title}</Typography>
                <Typography variant="overline" color="text.mediumEmphasis">
                  {type.description}
                </Typography>
              </Stack>
            }
          />
        );
      })}
    </Tabs>
  );
}

export default PrimaryTab;
