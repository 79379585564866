import { Box, CircularProgress, Typography, styled } from "@mui/material";
import { useSnackbar } from "notistack";
import { ReactElement, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";

const LogoUploadRoot = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  height: "100px",
  borderRadius: theme.shape.borderRadius,
}));

const allowedFileTypes = ["image/png", "image/svg+xml"];

interface FileDropZoneProps {
  addFile: (file: File) => Promise<void>;
  isImage?: boolean;
  isBmwProject?: boolean;
}
export function FileDropzone(props: FileDropZoneProps): ReactElement {
  const [isUploading, setIsUploading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onDrop = async (
    acceptedFiles: File[],
    fileRejections: FileRejection[]
  ) => {
    if (props.isBmwProject) {
      const maxSize = 12 * 1024 * 1024;
      const largeFiles = acceptedFiles.filter((file) => file.size > maxSize);
      if (largeFiles.length > 0) {
        return enqueueSnackbar("Upload failed - File exceeds 12 MB limit.", {
          variant: "error",
        });
      }
    }

    if (fileRejections.length > 0) {
      return enqueueSnackbar(
        "Please upload a valid file. Accepted formats: pdf, doc, docx, xls, xlsx, ppt, pptx, txt, png, jpg, jpeg, webp, svg, mp4, mov, mp3, wav",
        {
          variant: "error",
        }
      );
    }

    const file = acceptedFiles.pop();
    if (!file) return;

    if (!allowedFileTypes.includes(file.type) && props.isImage) {
      return enqueueSnackbar("Only .png and .svg accepted", {
        variant: "error",
      });
    }

    setIsUploading(true);

    if (file.type === "image/svg+xml") {
      enqueueSnackbar(
        "File is being converted to the correct format. It will be available shortly.",
        {
          variant: "success",
        }
      );
    }

    await props.addFile(file);
    setIsUploading(false);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept:
      "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, image/png, image/jpeg, image/webp, text/plain, image/svg+xml, video/mp4, video/quicktime, audio/mpeg, audio/wav",
  });

  return (
    <LogoUploadRoot id="dropzone" data-testid="dropzone" {...getRootProps()}>
      <input
        id="dropzone-input"
        data-testid="dropzone-input"
        {...getInputProps()}
        accept={props.isImage ? "image/png" : ""}
      />
      {isDragActive && (
        <Typography align="center">Drop it like it&apos;s hot</Typography>
      )}
      {!isDragActive && !isUploading && (
        <Typography align="center">
          Click or drag and drop files here to upload them
        </Typography>
      )}
      {!isDragActive && isUploading && <CircularProgress color="primary" />}
    </LogoUploadRoot>
  );
}
