import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { ReactElement, useReducer, Reducer, useState, useEffect } from "react";
import ClientContactHttpService from "../../../Http/ClientContact/ClientContact.http.service";
import { CreateClientContactDTO } from "../../../Types/ClientContact";
import { SelectInput } from "../../UI/InputFields/SelectInput";
import { Gender, SelectOption } from "../../../Types/Common";
import { VentureClientHttpService } from "../../../Http/VentureClient/VentureClient.http.service";
import { useCodependentSelectValues } from "../../ProjectKanban/useCodependentSelectValues";
import { BusinessUnitHttpService } from "../../../Http/BusinessUnit/BusinessUnit.http.service";
import CloseIcon from "@mui/icons-material/Close";
import useRoles from "../../../Hooks/useRoles";

interface CreateContactModalProps {
  setModalOpen: (state: boolean) => void;
  modalOpen: boolean;
  handleSave: () => void;
}

const genders = ["male", "female", "non-binary"];

export default function CreateClientContactModal(
  props: CreateContactModalProps
): ReactElement {
  const theme = useTheme();
  const { isExternalUser } = useRoles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [newContact, setNewContact] = useReducer<
    Reducer<CreateClientContactDTO, Partial<CreateClientContactDTO>>
  >(
    (state, newState) => ({ ...state, ...newState }),
    {} as CreateClientContactDTO
  );

  const [ventureClientSelectOptions, setVentureClientSelectOptions] = useState<
    SelectOption[]
  >([]);

  const handleCreateContact = async () => {
    if (newContact.name != null && newContact.businessUnitId != null) {
      await ClientContactHttpService.createContact(newContact);
      props.handleSave();
      props.setModalOpen(false);
      setVentureClient({ id: "", name: "" });
    }
  };

  useEffect(() => {
    if (isExternalUser) return;
    VentureClientHttpService.getVentureClients().then((results) => {
      const ventureClientSelectOptions = results.map((ventureClient) => ({
        id: ventureClient.id,
        name: ventureClient.name,
      }));
      setVentureClientSelectOptions(ventureClientSelectOptions);
    });
  }, []);

  const [ventureClient, setVentureClient, businessUnitSelectOptions] =
    useCodependentSelectValues(
      { id: "", name: "" },
      BusinessUnitHttpService.getBusinessUnits
    );

  const handleSelectBusinessUnit = (id: string) => {
    const buId = parseInt(id);
    for (let index = 0; index < businessUnitSelectOptions.length; index++) {
      if (buId === businessUnitSelectOptions[index].id) {
        setNewContact({ businessUnitId: buId });
        return;
      }
    }
  };

  const handleSelectVentureClient = (id: string) => {
    for (let index = 0; index < ventureClientSelectOptions.length; index++) {
      if (parseInt(id) === ventureClientSelectOptions[index].id) {
        setVentureClient(ventureClientSelectOptions[index]);
        return;
      }
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      fullScreen={fullScreen}
      open={props.modalOpen}
      id="client-create-view"
      PaperProps={{
        sx: {
          gap: theme.spacing(4),
        },
      }}
    >
      <DialogTitle>
        Add Contact
        <IconButton
          sx={{ color: "icon.primary" }}
          onClick={() => props.setModalOpen(false)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack gap={3}>
          <Stack gap={2}>
            <Typography variant="subtitle1">Personal Information</Typography>
            <Box display="flex" gap={2}>
              <TextField
                label="Name"
                id="contact-name"
                variant="outlined"
                required
                fullWidth
                defaultValue=""
                onChange={(event) =>
                  setNewContact({ name: event.target.value })
                }
              />
              <TextField
                label="Gender"
                id="contact-gender"
                variant="outlined"
                fullWidth
                select
                defaultValue={null}
                onChange={(event) =>
                  setNewContact({ gender: event.target.value as Gender })
                }
              >
                {genders.map((gender) => (
                  <MenuItem key={gender} value={gender}>
                    {gender}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Stack>
          <Stack gap={2}>
            <Typography variant="subtitle1">Company Information</Typography>
            <Box display="flex" gap={2}>
              <TextField
                label="Title"
                id="contact-title"
                variant="outlined"
                required
                fullWidth
                defaultValue=""
                onChange={(event) =>
                  setNewContact({ title: event.target.value })
                }
              />
              <TextField
                label="Department Code"
                id="department-code"
                variant="outlined"
                fullWidth
                defaultValue=""
                onChange={(event) =>
                  setNewContact({ departmentCode: event.target.value })
                }
              />
            </Box>
            <Grid container spacing={2}>
              {!isExternalUser && (
                <Grid item xs={6}>
                  <SelectInput
                    label="Venture Client"
                    selectValues={ventureClientSelectOptions}
                    value={ventureClient.id}
                    onChange={(e) => handleSelectVentureClient(e.target.value)}
                    editMode={true}
                    required
                    fullWidth
                    ignoreValidation
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <SelectInput
                  label="Organizational Unit"
                  selectValues={businessUnitSelectOptions}
                  value={newContact.businessUnitId}
                  onChange={(e) => handleSelectBusinessUnit(e.target.value)}
                  disabled={ventureClient.id === "" && !isExternalUser}
                  editMode={true}
                  required
                  fullWidth
                  ignoreValidation
                />
              </Grid>
            </Grid>
          </Stack>
          <Stack gap={2}>
            <Typography variant="subtitle1">Contact</Typography>
            <Box display="flex" gap={2}>
              <TextField
                label="Phone Number"
                id="contact-phone"
                variant="outlined"
                fullWidth
                defaultValue=""
                onChange={(event) =>
                  setNewContact({ phone: event.target.value })
                }
              />
              <TextField
                label="Email"
                id="contact-email"
                required
                variant="outlined"
                fullWidth
                defaultValue=""
                onChange={(event) =>
                  setNewContact({ email: event.target.value })
                }
              />
            </Box>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => props.setModalOpen(false)}
          color="primary"
          id="close-client-create-view"
        >
          Cancel
        </Button>
        <Button
          onClick={handleCreateContact}
          variant="contained"
          id="save-client-create-view"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
