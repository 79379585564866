import { ReactElement, useContext, useEffect, useState } from "react";
import { AnalyticsHttpService } from "../../Http/Analytics/Analytics.http.service";
import { ProjectCount } from "../../Types/Analytics";
import { useSnackbar } from "notistack";
import { GlobalLoaderContext } from "../../Context/LoaderContext";
import { Box, Grid, Theme, Typography } from "@mui/material";
import { FunnelDatum, ResponsiveFunnel } from "@nivo/funnel";
import { useAnimatedPath, useMotionConfig, useTheme } from "@nivo/core";
import { animated, useSpring } from "@react-spring/web";
import {
  DashboardFilterOptions,
  PartLabelProps,
  PartLabelsProps,
  PartProps,
  PartsProps,
} from "../../Types/Dashboard";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import theme from "../../theme";

let MIN_HEIGHT = 0;
let rateConvertedIntoPilot = 0;
let rateAdopted = 0;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      ...theme.typography.h5,
      color: theme.palette.grey[600],
      fontWeight: "500",
      position: "absolute",
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
    },
  })
);

interface Props {
  dashboardFilters: DashboardFilterOptions;
  refetch: boolean;
}

const ConversionRate = ({ dashboardFilters, refetch }: Props): ReactElement => {
  const classes = useStyles();

  const [projectCount, setProjectCount] = useState<ProjectCount[]>();
  const { setGlobalLoader } = useContext(GlobalLoaderContext);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchData = async () => {
      setGlobalLoader(true);
      try {
        const data = await AnalyticsHttpService.getProjectCount({
          businessUnitIds: dashboardFilters.businessUnitsIds,
          focusAreasIds: dashboardFilters.focusAreasIds,
        });
        setProjectCount(data);
        // eslint-disable-next-line
      } catch (error: any) {
        enqueueSnackbar(
          `Something went wrong with fetching the conversion rate: ${error.message}`,
          {
            variant: "error",
          }
        );
      } finally {
        setGlobalLoader(false);
      }
    };
    fetchData();
  }, [refetch]);

  const totalProjectsCount = projectCount
    ? projectCount.reduce((sum, funnelStage) => {
        return sum + funnelStage.value;
      }, 0)
    : 0;

  const pilotOrAdopt = projectCount
    ? projectCount.reduce((sum, funnelStage) => {
        if (funnelStage.id === "pilot" || funnelStage.id === "adopt") {
          return sum + funnelStage.value;
        } else {
          return sum;
        }
      }, 0)
    : 0;

  const adopt = projectCount
    ? projectCount.reduce((sum, funnelStage) => {
        if (funnelStage.id === "adopt") {
          return sum + funnelStage.value;
        } else {
          return sum;
        }
      }, 0)
    : 0;

  rateConvertedIntoPilot =
    Math.ceil((pilotOrAdopt / totalProjectsCount) * 100) || 0;
  rateAdopted = Math.ceil((adopt / pilotOrAdopt) * 100) || 0;

  const funnelData = [
    {
      id: "Started Projects",
      label: "Started Projects",
      value: totalProjectsCount,
    },
    {
      id: "Pilot Projects",
      label: "Pilot Projects",
      value: pilotOrAdopt,
    },
    {
      id: "Adoptions",
      label: "Adoptions",
      value: adopt,
    },
  ];

  MIN_HEIGHT = Math.ceil(
    funnelData.reduce((sum, projectCount) => sum + projectCount.value, 0) / 2
  );

  // This is needed to have min height for each funnel
  for (let i = 0; i < funnelData.length; i++) {
    funnelData[i].value += MIN_HEIGHT;
  }

  const defaultTheme = {
    grid: {
      line: {
        strokeWidth: 0,
      },
    },
  };

  return (
    <Box data-testid="conversion-rate" height="300px">
      <Grid container display="flex" flexDirection="column" p={0}>
        <Typography className={classes.title}>Conversion Rate</Typography>

        <Grid item height="300px">
          {projectCount && (
            <ResponsiveFunnel
              theme={defaultTheme}
              data={funnelData}
              margin={{ top: 70, right: 0, bottom: 30, left: 0 }}
              direction="horizontal"
              interpolation="smooth"
              shapeBlending={0.3}
              borderWidth={0}
              motionConfig="wobbly"
              isInteractive={false}
              spacing={1}
              colors={[
                theme.palette.chart.light.primary,
                theme.palette.chart.medium.secondary,
                theme.palette.chart.medium.primary,
                theme.palette.chart.dark.secondary,
                theme.palette.chart.dark.primary,
              ]}
              // colorBy="indexValue"
              layers={["separators", Parts, PartLabels, "annotations"]}
              enableBeforeSeparators={false}
              enableAfterSeparators={false}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

const Part = <D extends FunnelDatum>({
  part,
  areaGenerator,
  borderGenerator,
}: PartProps<D>) => {
  const { animate, config: motionConfig } = useMotionConfig();

  const animatedAreaPath = useAnimatedPath(
    areaGenerator(part.areaPoints) as string
  );
  const animatedBorderPath = useAnimatedPath(
    borderGenerator(part.borderPoints) as string
  );
  const animatedProps = useSpring({
    areaColor: part.color,
    borderWidth: part.borderWidth,
    borderColor: part.borderColor,
    config: motionConfig,
    immediate: !animate,
  });

  // these gradients are generated in ActiveProjects.tsx
  let background;
  if (part.data.id === "Started Projects") {
    background = theme.palette.chart.dark.primary;
  }
  if (part.data.id === "Pilot Projects") {
    background = theme.palette.chart.dark.secondary;
  }
  if (part.data.id === "Adoptions") {
    background = theme.palette.chart.medium.primary;
  }

  return (
    <>
      {part.borderWidth > 0 && (
        <animated.path
          d={animatedBorderPath}
          stroke={animatedProps.borderColor}
          strokeWidth={animatedProps.borderWidth}
          strokeOpacity={part.borderOpacity}
          fill={`${background}`}
        />
      )}
      <animated.path
        d={animatedAreaPath}
        fill={`${background}`}
        fillOpacity={part.fillOpacity}
        onMouseEnter={part.onMouseEnter}
        onMouseLeave={part.onMouseLeave}
        onMouseMove={part.onMouseMove}
        onClick={part.onClick}
      />
      {part.data.id === "Pilot Projects" && (
        <animated.text
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: 20,
            fontWeight: "bold",
            fill: `${background}`,
            pointerEvents: "none",
          }}
          transform={`translate(${part.width + 10},${
            (part.y1 + part.y0 - part.height) / 2.8
          })`}
        >
          {rateConvertedIntoPilot}%
        </animated.text>
      )}

      {part.data.id === "Adoptions" && (
        <animated.text
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: 20,
            fontWeight: "bold",
            fill: `${background}`,
            pointerEvents: "none",
          }}
          transform={`translate(${part.width * 2 + 10},${
            (part.y1 + part.y0 - part.height) / 2.8
          })`}
        >
          {rateAdopted}%
        </animated.text>
      )}
    </>
  );
};

const Parts = <D extends FunnelDatum>({
  parts,
  areaGenerator,
  borderGenerator,
}: PartsProps<D>): ReactElement => {
  return (
    <>
      {parts.map((part) => {
        return (
          <Part<D>
            key={part.data.id}
            part={part}
            areaGenerator={areaGenerator}
            borderGenerator={borderGenerator}
          />
        );
      })}
    </>
  );
};

const PartLabel = <D extends FunnelDatum>({
  part,
}: PartLabelProps<D>): ReactElement => {
  const theme = useTheme();
  const { animate, config: motionConfig } = useMotionConfig();

  const animatedProps = useSpring({
    transform: `translate(${part.x}, ${part.y})`,
    color: "#fff",
    config: motionConfig,
    immediate: !animate,
  });

  return (
    <animated.g transform={animatedProps.transform}>
      <animated.text
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          ...theme.labels.text,
          fontSize: 50,
          fill: animatedProps.color,
          pointerEvents: "none",
        }}
        transform={`translate(0,-15)`}
      >
        {part.data.value - MIN_HEIGHT}
      </animated.text>
      <animated.text
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          ...theme.labels.text,
          fontSize: 12,
          fontWeight: "bold",
          fill: animatedProps.color,
          pointerEvents: "none",
        }}
        transform={`translate(0,25)`}
      >
        {part.data.label}
      </animated.text>
    </animated.g>
  );
};

const PartLabels = <D extends FunnelDatum>({ parts }: PartLabelsProps<D>) => (
  <>
    {parts.map((part) => (
      <PartLabel key={part.data.id} part={part} />
    ))}
  </>
);

export default ConversionRate;
