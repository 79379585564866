import { Fragment, ReactElement, useState } from "react";
import { VariableType } from "../../../../Types/ImpactValue";
import styled from "@emotion/styled";
import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import theme from "../../../../theme";
import EditDeleteMenu from "../../../UI/EditDeleteMenu";
import { getErrorMessage, thousandSeparator } from "../../../../utils";
import ManageVariableModal from "./ManageVariableModal";
import { VariableHttpService } from "../../../../Http/Variable/Variable.http.service";
import { useSnackbar } from "notistack";
import DeleteOrRemoveModal from "../../../UI/Modals/DeleteOrRemoveModal/DeleteOrRemoveModal";

const VariableWrapper = styled(Box)({
  width: 202,
  minWidth: 208,
  padding: theme.spacing(2, 2, 2, 3),
  display: "flex",
  flexDirection: "column",
  alignSelf: "stretch",
  gap: theme.spacing(1),
  border: `1px solid ${theme.palette.borderOutline.main}`,
  borderRadius: theme.shape.radius.minimal,
});

const VariableHeader = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  "& > button": { marginRight: "-8px", padding: "4px" },
  "& svg": {
    fontSize: "1rem",
  },
}));

const VariableName = styled(Typography)({
  color: theme.palette.text.mediumEmphasis,
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
});

const VariableValue = styled(Typography)({
  color: theme.palette.text.brand.accessibility,
  position: "relative",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
});

const Operator = styled(IconButton)(() => ({
  width: "28px",
  height: "28px",
  fontsize: "2rem",
  backgroundColor: "transparent",
}));

const StyledMenuItem = styled(MenuItem)(() => ({
  display: "flex",
  justifyContent: "center",
  fontSize: "1rem",
}));

interface Props {
  variable: VariableType;
  handleImpactValue: () => void;
  handleDeleteVariable: (id: number) => void;
}

const Variable = (props: Props): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const [editVariableModalOpen, setEditVariableModalOpen] = useState(false);
  const [deleteVariableModalOpen, setDeleteVariableModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { variable } = props;

  const updateVarible = async (newOperator: string) => {
    await VariableHttpService.updateVariable({
      ...variable,
      operator: newOperator,
    })
      .then(() => {
        props.handleImpactValue();
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(`Could not update operator: ${errorMessage}`, {
          variant: "error",
        });
      });
  };

  const deleteVariable = async () => {
    await VariableHttpService.deleteVariable(variable.id)
      .then(() => {
        props.handleDeleteVariable(variable.id);
        props.handleImpactValue();
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(`Could not delete Variable: ${errorMessage}`, {
          variant: "error",
        });
      });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOperator = async (event: React.MouseEvent<HTMLElement>) => {
    const newOperator = event.currentTarget.innerText;
    await updateVarible(newOperator);
    setAnchorEl(null);
  };

  return (
    <Fragment>
      {variable.operator && (
        <>
          <Operator color="secondary" onClick={handleClick}>
            {variable.operator}
          </Operator>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            PaperProps={{
              sx: {
                ml: -1,
                mt: 0.5,
              },
            }}
          >
            <StyledMenuItem onClick={handleOperator}>+</StyledMenuItem>
            <StyledMenuItem onClick={handleOperator}>-</StyledMenuItem>
            <StyledMenuItem onClick={handleOperator}>x</StyledMenuItem>
            <StyledMenuItem onClick={handleOperator}>:</StyledMenuItem>
          </Menu>
        </>
      )}
      <VariableWrapper data-testid="variable">
        <VariableHeader>
          <VariableName variant="caption">{variable.name}</VariableName>
          <EditDeleteMenu
            entity="variable"
            setEditModalOpen={setEditVariableModalOpen}
            setDeleteModalOpen={setDeleteVariableModalOpen}
            sx={{
              color: theme.palette.icon.disabled,
              height: theme.spacing(2),
            }}
          />
        </VariableHeader>
        <Box display="flex" gap={1}>
          {variable.unit === "Euro" && (
            <Typography variant="subtitle2" color="text.brand.accessibility">
              €
            </Typography>
          )}
          <VariableValue variant="subtitle2">
            {thousandSeparator(variable.value, 5)}
          </VariableValue>
        </Box>
      </VariableWrapper>
      {editVariableModalOpen && (
        <ManageVariableModal
          variable={variable}
          modalOpen={editVariableModalOpen}
          calculationId={variable.calculationId}
          setModalOpen={setEditVariableModalOpen}
          handleImpactValue={props.handleImpactValue}
        />
      )}
      {deleteVariableModalOpen && (
        <DeleteOrRemoveModal
          id={variable.id}
          entity="Variable"
          modalOpen={deleteVariableModalOpen}
          setModalOpen={setDeleteVariableModalOpen}
          handleDelete={deleteVariable}
          actionType="delete"
        />
      )}
    </Fragment>
  );
};

export default Variable;
