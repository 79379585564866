import {
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  IconButton,
  Box,
  DialogActions,
} from "@mui/material";
import { ReactElement, useContext, useState } from "react";
import theme from "../../../../../../../theme";
import { useSnackbar } from "notistack";
import {
  getErrorMessage,
  isCharLimitExceeded,
} from "../../../../../../../utils";
import { GlobalProjectEditContext } from "../../../../../../../Context/ProjectDetailsContext";
import RichTextEditor from "../../../../../../UI/InputFields/RichTextEditor";
import { ProjectHttpService } from "../../../../../../../Http/Project/Project.http.service";
import { useParams } from "react-router-dom";
import { Project } from "../../../../../../../Types/Project";
import { Close } from "@mui/icons-material";

const KEY_FINDING_CHAR_LIMIT = 350;

interface EditKeyFindingsProps {
  modalOpen: boolean;
  setModalOpen: (state: boolean) => void;
  keyFindings: string;
  handleSave: () => void;
}

const EditKeyFindingsModal = (props: EditKeyFindingsProps): ReactElement => {
  const { id } = useParams<{ id: string }>();
  const { activeStep } = useContext(GlobalProjectEditContext);
  const { enqueueSnackbar } = useSnackbar();
  const [keyFindingsLocal, setKeyFindingsLocal] = useState(props.keyFindings);
  const [editMode, setEditMode] = useState(false);
  const isAssessStageActive = activeStep === 1;
  const keyFindingsProperty = isAssessStageActive
    ? "keyFindings"
    : "curatedKeyFindings";

  const saveKeyFinding = async () => {
    if (isCharLimitExceeded(keyFindingsLocal, KEY_FINDING_CHAR_LIMIT)) {
      enqueueSnackbar("Key Findings cannot be more than 350 characters", {
        variant: "error",
      });
      return;
    }

    await ProjectHttpService.updateProject({
      id: parseInt(id),
      [keyFindingsProperty]: keyFindingsLocal,
    } as unknown as Project)
      .then(() => {
        props.handleSave();
        props.setModalOpen(false);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(`Could not update project: ${errorMessage}`, {
          variant: "error",
        });
      });
  };

  return (
    <Dialog
      fullWidth
      open={props.modalOpen}
      data-testid="edit-key-findings-modal"
      PaperProps={{
        sx: {
          gap: theme.spacing(4),
        },
      }}
    >
      <DialogTitle
        sx={{ justifyContent: "space-between", ...theme.typography.h3 }}
      >
        {editMode ? "Edit Key Findings" : "Key Findings"}
        {!editMode && (
          <IconButton
            onClick={() => props.setModalOpen(false)}
            sx={{ mr: theme.spacing(-1) }}
          >
            <Close htmlColor={theme.palette.icon.primary} />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>
        <RichTextEditor
          fieldId="edit-key-findings"
          editMode={editMode}
          fieldValue={keyFindingsLocal}
          maxCharacter={KEY_FINDING_CHAR_LIMIT}
          onChange={(value) => setKeyFindingsLocal(value)}
          labelText=""
          isToolbarDisabled
        />
      </DialogContent>
      <DialogActions>
        {!editMode ? (
          <Button
            sx={{ alignSelf: "flex-end" }}
            variant="contained"
            onClick={() => setEditMode(true)}
            data-testid="edit-key-findings-button"
          >
            Edit
          </Button>
        ) : (
          <Box display="flex" gap={2} alignSelf="flex-end">
            <Button onClick={() => setEditMode(false)}>Cancel</Button>
            <Button variant="contained" onClick={saveKeyFinding}>
              Save
            </Button>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default EditKeyFindingsModal;
