import {
  Typography,
  Autocomplete,
  TextField,
  IconButton,
  Stack,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { Dispatch, ReactElement, useState } from "react";
import { sourceValues } from "../../../Constants/SourceValues";
import { SourceNamesHttpService } from "../../../Http/SourceNames/SourceNames.http.service";
import { SourceName } from "../../../Types/SourceName";
import { getErrorMessage } from "../../../utils";
import { SelectInput } from "../../UI/InputFields/SelectInput";
import AddIcon from "@mui/icons-material/Add";
import { CreateLeadProjectDTO, LeadProject } from "../../../Types/LeadProject";
import CustomCreateEntityByName from "../../UI/Modals/CustomCreateEntityByName/CustomCreateEntityByName";

interface Props {
  leadProject: CreateLeadProjectDTO;
  setNewLeadProject: Dispatch<Partial<LeadProject>>;
  sourceNames: SourceName[];
  setSourceNames: Dispatch<SourceName[]>;
}

const LeadSource = ({
  leadProject,
  setNewLeadProject,
  sourceNames,
  setSourceNames,
}: Props): ReactElement => {
  const [createSourceNameModalOpen, setCreateSourceNameModalOpen] =
    useState(false);
  const [inputValue, setInputValue] = useState("");

  const createSourceName = async (sourceName: string) => {
    if (!leadProject.ventureClientId || !leadProject.sourceType) return;

    if (!sourceName) {
      enqueueSnackbar("Source Name cannot be empty", {
        variant: "error",
      });
      return;
    }

    await SourceNamesHttpService.createSourceName({
      value: sourceName,
      ventureClientId: leadProject.ventureClientId,
      sourceType: leadProject.sourceType,
    })
      .then((newSourceName) => {
        setSourceNames([...sourceNames, newSourceName]);
        setNewLeadProject({ sourceNameId: newSourceName.id });
        setCreateSourceNameModalOpen(false);
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        enqueueSnackbar(`Could not create source name: ${message}`, {
          variant: "error",
        });
      });
  };

  const isSourceNameDisabled =
    !leadProject.ventureClientId || !leadProject.sourceType;
  const selectedSourceName = sourceNames.find(
    (name) => name.id === leadProject.sourceNameId
  );

  return (
    <Stack gap={2}>
      <Typography variant="subtitle1">Lead Source</Typography>
      <SelectInput
        label="Type"
        selectValues={sourceValues}
        value={leadProject.sourceType}
        disableSorting
        onChange={(e) =>
          setNewLeadProject({
            sourceType: e.target.value,
            sourceNameId: null,
          })
        }
        editMode
      />
      <Autocomplete
        id="source-name"
        forcePopupIcon={false}
        options={sourceNames}
        filterSelectedOptions
        filterOptions={(options) => options}
        getOptionLabel={(option) => option.value}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
        onChange={(_, selectedSourceName) => {
          setNewLeadProject({
            sourceNameId: selectedSourceName?.id || null,
            sourceType: leadProject.sourceType,
          });
        }}
        noOptionsText="No source name found"
        value={selectedSourceName || null}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            {option.value}
          </li>
        )}
        disabled={isSourceNameDisabled}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Name"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                  <IconButton
                    data-testid="add-new-source-name-button"
                    onClick={() => setCreateSourceNameModalOpen(true)}
                    disabled={isSourceNameDisabled}
                  >
                    <AddIcon fontSize="small" />
                  </IconButton>
                </>
              ),
            }}
            inputProps={{
              ...params.inputProps,
              "data-testid": "source-name-input",
            }}
          />
        )}
      />
      {createSourceNameModalOpen && (
        <CustomCreateEntityByName
          name="sourceName"
          label="Source Name"
          maxCharacter={50}
          modalOpen={createSourceNameModalOpen}
          setModalOpen={setCreateSourceNameModalOpen}
          handleCreate={createSourceName}
          initialValue={inputValue}
        />
      )}
    </Stack>
  );
};

export default LeadSource;
