import { MeetingDto } from "../../Types/Meeting";
import {
  Project,
  ProjectDTO,
  ProjectHistoryLatestChangeDTO,
} from "../../Types/Project";
import { Requirement, RequirementDTO } from "../../Types/Requirement";
import MeetingMapper from "../Meeting/Meeting.mapper";
import ProductDemoMapper from "../ProductDemos/ProductDemo.mapper";
import MapFile from "../File/File.mapper";
import LeadProjectMapper from "../LeadProjects/LeadProjects.mapper";

export default class ProjectMapper {
  public static map(Project: ProjectDTO): Project {
    return {
      ...Project,
      dateCreated: new Date(Project.dateCreated),
      deadline: Project.deadline
        ? new Date(Project.deadline).toLocaleDateString("fr-ca")
        : null,
      businessUnitId: Project.businessUnitId || 0,
      ...(Project.requirements && {
        requirements: Project.requirements.map(
          (requirementDTO: RequirementDTO) =>
            RequirementMapper.map(requirementDTO)
        ),
      }),
      ...(Project.meetings && {
        meetings: Project.meetings.map((meetingDTO: MeetingDto) =>
          MeetingMapper.map(meetingDTO)
        ),
      }),
      adoptionDate: Project.adoptionDate
        ? new Date(Project.adoptionDate).toLocaleDateString("fr-ca")
        : null,
      files: Project.files ? MapFile.map(Project.files) : [],
      lastModifiedDate: Project.lastModifiedDate
        ? new Date(Project.lastModifiedDate)
        : undefined,
      potentialLaunch: Project.potentialLaunch
        ? new Date(Project.potentialLaunch).toLocaleDateString("fr-ca")
        : null,
      pilotStartDate: Project.pilotStartDate
        ? new Date(Project.pilotStartDate).toLocaleDateString("fr-ca")
        : null,
      onHoldDeadline: Project.onHoldDeadline
        ? new Date(Project.onHoldDeadline).toLocaleDateString("fr-ca")
        : null,
      opportunities: Project.opportunities?.map((opportunity) => {
        opportunity.productDemos = opportunity.productDemos?.map((demo) =>
          ProductDemoMapper.map(demo)
        );
        opportunity.files = MapFile.map(opportunity.files);
        opportunity.startup.dateFounded = opportunity.startup.dateFounded
          ? new Date(opportunity.startup.dateFounded)
          : null;
        opportunity.startup.lastQualityCheckDate = opportunity.startup
          .lastQualityCheckDate
          ? new Date(opportunity.startup.lastQualityCheckDate)
          : null;
        return opportunity;
      }),
      ...(Project.projectHistoryLatestChange && {
        projectHistoryLatestChange: Project.projectHistoryLatestChange.map(
          (history: ProjectHistoryLatestChangeDTO) =>
            ProjectHistoryMapper.map(history)
        ),
      }),
      leadProject: Project.leadProject
        ? LeadProjectMapper.map(Project.leadProject)
        : null,
    };
  }
}

class RequirementMapper {
  static map(requirementDTO: RequirementDTO): Requirement {
    return {
      ...requirementDTO,
      dateCreated: new Date(requirementDTO.dateCreated),
    };
  }
}

class ProjectHistoryMapper {
  static map<T extends { dateTriggered: string }>(
    history: T
  ): T & { dateTriggered: Date } {
    return {
      ...history,
      dateTriggered: new Date(history.dateTriggered),
    };
  }
}
