import { ReactElement, useState } from "react";
import theme from "../../../theme";
import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { LeadProject } from "../../../Types/LeadProject";
import { getErrorMessage } from "../../../utils";
import { useSnackbar } from "notistack";
import { AxiosError } from "axios";
import LeadProjectStartupCard from "./LeadProjectStartupCard";
import AddLeadProjectOpportunityModal from "./AddLeadProjectOpportunityModal";
import { LeadProjectsHttpService } from "../../../Http/LeadProjects/LeadProjects.http.service";

interface leadProjectOpportunitiesProps {
  leadProject: LeadProject;
  onRefresh: () => void;
}
export default function LeadProjectOpportunities(
  props: leadProjectOpportunitiesProps
): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const deleteProjectLeadOpportunity = (leadOpportunityId: number) => {
    LeadProjectsHttpService.deleteLeadOpportunity(
      props.leadProject.id,
      leadOpportunityId
    )
      .then(() => {
        props.onRefresh();
      })
      .catch((error: AxiosError) => {
        const errorMessage = getErrorMessage(error);
        enqueueSnackbar(errorMessage, { variant: "error" });
      });
  };

  return (
    <>
      <Stack width="100%" gap={theme.spacing(3)}>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="h6">Startups</Typography>
            <Chip
              variant="counter"
              color="info"
              label={props.leadProject.opportunities.length}
              data-testid="lead-project-opportunities-counter"
            />
          </Box>
          <Button
            variant="contained"
            onClick={handleModalOpen}
            data-testid="add-lead-project-opportunity-button"
          >
            Add Startup
          </Button>
        </Box>

        <Box display="flex" gap={3} overflow="auto">
          {props.leadProject.opportunities.map((leadOpportunity) => {
            return (
              <LeadProjectStartupCard
                key={leadOpportunity.id}
                leadOpportunity={leadOpportunity}
                deleteProjectLeadOpportunity={deleteProjectLeadOpportunity}
              />
            );
          })}
        </Box>
      </Stack>
      {modalOpen && (
        <AddLeadProjectOpportunityModal
          leadProject={props.leadProject}
          modalOpen={modalOpen}
          handleModalClose={handleModalClose}
          onRefresh={props.onRefresh}
        />
      )}
    </>
  );
}
