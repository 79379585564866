import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Box,
  Button,
  styled,
  debounce,
  Autocomplete,
  CircularProgress,
  Typography,
} from "@mui/material";
import { ReactElement, useState } from "react";
import { useSnackbar } from "notistack";
import { StartupPb } from "../../../../Types/Startup";
import { getErrorMessage } from "../../../../utils";
import { PitchbookHttpService } from "../../../../Http/Pitchbook/Pitchbook.http.service";
import { StartupHttpService } from "../../../../Http/Startup/Startup.http.service";
import theme from "../../../../theme";
import DeleteOrRemoveModal from "../DeleteOrRemoveModal/DeleteOrRemoveModal";

interface PitchbookSearchModal {
  startupName: string;
  handleModalClose: () => void;
  pitchbookModalOpen: boolean;
  handleImport?: (id: number) => void;
  handleCreateStartupModalOpen?: (startupName: string) => void;
  handleRemoveLink?: () => void;
  customHandleContinue?: (startup: StartupPb) => void;
  customModalTitle?: string;
  showPbId?: boolean;
  allowManualEntry?: boolean;
}

const ImportIndicator = styled(Typography)(() => ({
  fontSize: "8px",
  color: theme.palette.text.secondary,
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  justifyContent: "right",
  marginLeft: "auto",
}));

const PitchbookSearchModal = ({
  allowManualEntry = true,
  ...props
}: PitchbookSearchModal): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const [startups, setStartups] = useState<StartupPb[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [selectedStartup, setSelectedStartup] = useState<StartupPb | null>(
    null
  );
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const searchForStartups = async (searchValue: string) => {
    if (shouldAllowSearch(searchValue)) return;

    setIsLoading(true);
    await PitchbookHttpService.getStartupsFromPitchbook(searchValue)
      .then((startups) => {
        setStartups([
          ...(!allowManualEntry
            ? []
            : [{ pbId: "-1", name: searchValue } as StartupPb]),
          ...startups,
        ]);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        enqueueSnackbar(errorMessage, { variant: "error" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const shouldAllowSearch = (searchValue: string) => {
    return (
      startups.some((startup) => startup.name === searchValue) ||
      !(searchValue.length > 0)
    );
  };

  const debouncedSearchForStartups = debounce(searchForStartups, 400);

  const handleSave = async () => {
    if (!selectedStartup && props.handleRemoveLink)
      return setDeleteModalOpen(true);

    if (!selectedStartup) return;

    if (props.customHandleContinue)
      return props.customHandleContinue(selectedStartup);

    if (selectedStartup.pbId === "-1") {
      props.handleCreateStartupModalOpen?.(selectedStartup.name);
    } else {
      try {
        setIsImporting(true);
        const importedStartupId =
          await StartupHttpService.importStartupFromPitchbook(
            selectedStartup.pbId
          );
        props.handleModalClose();
        props.handleImport?.(importedStartupId);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        const errorMessage = getErrorMessage(error);
        enqueueSnackbar(errorMessage, { variant: "error" });
      } finally {
        setIsImporting(false);
      }
    }
  };

  return (
    <>
      <Dialog
        data-testid="pitchbook-search-modal"
        open={props.pitchbookModalOpen}
        onClose={props.handleModalClose}
        maxWidth="md"
        PaperProps={{
          sx: {
            gap: theme.spacing(4),
          },
        }}
      >
        <DialogTitle>
          {props.customModalTitle || "Create New Startup"}
        </DialogTitle>
        <DialogContent>
          <Autocomplete
            forcePopupIcon={false}
            openOnFocus={!!props.startupName}
            sx={{ minWidth: 450 }}
            isOptionEqualToValue={(option: StartupPb, value: StartupPb) =>
              option.name === value.name
            }
            onInputChange={(_, newValue: string) => {
              debouncedSearchForStartups(newValue);
            }}
            onChange={(event, selectedStartup) => {
              setSelectedStartup(selectedStartup);
            }}
            filterOptions={(options) => options}
            getOptionLabel={(option) => option.name}
            options={startups}
            noOptionsText="No startup found"
            loading={isLoading}
            defaultValue={{ name: props.startupName } as StartupPb}
            renderOption={(_props, startup) => {
              if (startup.pbId === "-1") {
                return (
                  <li {..._props} key={startup.pbId}>
                    {startup.name}
                    <Typography fontSize="8px" color="text.secondary" ml="auto">
                      manual entry
                    </Typography>
                  </li>
                );
              }
              return (
                <li {..._props} key={startup.pbId}>
                  {startup.name}
                  {props.showPbId ? (
                    <Typography
                      fontSize="12px"
                      color="text.secondary"
                      ml="auto"
                    >
                      {startup.pbId}
                    </Typography>
                  ) : (
                    <ImportIndicator>
                      Import from
                      <img width="51px" src="/images/pitchbook.png" />
                    </ImportIndicator>
                  )}
                </li>
              );
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  autoFocus
                  label="Name or Website"
                  placeholder={
                    props.handleRemoveLink
                      ? "No PitchBook Link"
                      : "Enter startup name or website"
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : (
                          <>
                            {params.InputProps.endAdornment}
                            <Box display="flex" alignItems="center" px="12px">
                              {!props.showPbId && (
                                <Typography
                                  fontSize="8px"
                                  color="text.secondary"
                                >
                                  {selectedStartup &&
                                    (selectedStartup.pbId === "-1"
                                      ? "manual entry"
                                      : "Import from")}
                                </Typography>
                              )}
                              {selectedStartup &&
                                selectedStartup.pbId !== "-1" &&
                                (props.showPbId ? (
                                  <Typography
                                    fontSize="12px"
                                    color="text.secondary"
                                    ml="auto"
                                  >
                                    {selectedStartup.pbId}
                                  </Typography>
                                ) : (
                                  <img
                                    width="51px"
                                    src="/images/pitchbook.png"
                                  />
                                ))}
                            </Box>
                          </>
                        )}
                      </>
                    ),
                  }}
                  inputProps={{
                    ...params.inputProps,
                    "data-testid": "pitchbook-search-input",
                  }}
                />
              );
            }}
          />
        </DialogContent>
        <DialogActions>
          <Box display="flex" alignItems="flex-end" gap={1}>
            <Button onClick={props.handleModalClose} color="primary">
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSave}>
              Save
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      {isImporting && (
        <Dialog data-testid="importing-modal" open={isImporting}>
          <DialogContent sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <CircularProgress color="inherit" size={20} />
            Importing data...
          </DialogContent>
        </Dialog>
      )}
      {deleteModalOpen && props.handleRemoveLink && (
        <DeleteOrRemoveModal
          id={-1}
          modalOpen={deleteModalOpen}
          setModalOpen={setDeleteModalOpen}
          handleDelete={props.handleRemoveLink}
          entity="link to PitchBook"
          actionType="delete"
        />
      )}
    </>
  );
};

export default PitchbookSearchModal;
