import { ReactElement, useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { ClientContactDTO } from "../../../Types/ClientContact";

interface Props {
  options: ClientContactDTO[];
  onChange: (args: ClientContactDTO | null) => void;
  disabled?: boolean;
  defaultProgramManager?: ClientContactDTO;
}

export default function ProgramManagerSelect(props: Props): ReactElement {
  const [selectedProgramManager, setSelectedProgramManager] =
    useState<ClientContactDTO | null>();

  useEffect(() => {
    setSelectedProgramManager(props.defaultProgramManager);
  }, [props.defaultProgramManager]);

  return (
    <Autocomplete
      id="program-manager"
      isOptionEqualToValue={(
        option: ClientContactDTO,
        value: ClientContactDTO
      ) => option.id === value.id}
      onChange={(_, selectedProgramManager) => {
        setSelectedProgramManager(selectedProgramManager);
        props.onChange(selectedProgramManager);
      }}
      getOptionLabel={(option) => option.name}
      options={props.options}
      noOptionsText="No program manager found"
      value={selectedProgramManager || null}
      disabled={props.disabled}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Program Manager"
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  );
}
