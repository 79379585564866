import { NewReleasesOutlined, Verified } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import { ReactElement } from "react";
import theme from "../../../../theme";

interface QualityVerificationProgressProps {
  progress: number;
  target: number;
}

export default function QualityVerificationProgress({
  progress,
  target,
}: QualityVerificationProgressProps): ReactElement {
  const isVerified = progress === target;

  return (
    <Stack alignItems="center" gap={0.5}>
      <Typography variant="caption" color="text.mediumEmphasis">
        {isVerified
          ? "Quality Verification Criteria Successfully Fulfilled"
          : progress > 0
          ? "Quality Verification Criteria Not Fully Fulfilled"
          : "Quality Verification Criteria Not Fulfilled"}
      </Typography>
      <Box display="flex" width="100%" gap={0.25}>
        {[...Array(target)].map((_, index) => (
          <Stack key={index} width="100%" gap={0.5}>
            <Box
              height="2px"
              bgcolor={
                index < progress
                  ? theme.palette.success.main
                  : theme.palette.surface.cool
              }
              borderRadius={theme.shape.radius.full}
            />
            <Box
              ml="auto"
              position="relative"
              right={theme.spacing(-1)}
              fontSize="16px"
              display="flex"
              height="16px"
            >
              {index === progress - 1 && progress > 0 ? (
                <>
                  {isVerified ? (
                    <Verified color="success" fontSize="inherit" />
                  ) : (
                    <NewReleasesOutlined color="warning" fontSize="inherit" />
                  )}
                </>
              ) : (
                <></>
              )}
            </Box>
          </Stack>
        ))}
      </Box>
    </Stack>
  );
}
