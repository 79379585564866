import { ReactElement, useEffect, useState } from "react";
import { Startup, StartupPb } from "../../../../../Types/Startup";
import { Button, Icon, Stack, Typography } from "@mui/material";
import { Refresh } from "@mui/icons-material";
import PitchbookSearchModal from "../../../../UI/Modals/PitchbookSearchModal/PitchbookSearchModal";
import PitchBookAddLink from "./PitchBookAddLink";
import { PitchbookHttpService } from "../../../../../Http/Pitchbook/Pitchbook.http.service";
import LoadingModal from "../../../../UI/Modals/LoadingModal/LoadingModal";
import theme from "../../../../../theme";
import useRoles from "../../../../../Hooks/useRoles";
import usePitchbookSync from "../../../../../Hooks/usePitchbookSync";
import { formatDate } from "../../../../../utils";

interface Props {
  startup: Startup;
  handleSave: () => void;
}

export default function PitchBookSyncDetails(props: Props): ReactElement {
  const [pitchbookModalOpen, setPitchbookModalOpen] = useState(false);
  const { isExternalUser } = useRoles();
  const [isSyncing, setIsSyncing] = useState(false);
  const [startupPb, setStartupPb] = useState<StartupPb>();
  const [isLoading, setIsLoading] = useState(false);

  const { syncStartup, removeLink } = usePitchbookSync();

  useEffect(() => {
    fetch();
  }, [props.startup.pitchbookId]);

  const fetch = async () => {
    if (!props.startup.pitchbookId) {
      setStartupPb(undefined);
      return;
    }
    setIsLoading(true);
    const startup = await PitchbookHttpService.getSingleStartupFromPitchbook(
      props.startup.pitchbookId
    );
    setStartupPb(startup);
    setIsLoading(false);
  };

  const handleStartupSync = async (startup: StartupPb) => {
    setPitchbookModalOpen(false);
    setIsSyncing(true);
    await syncStartup(props.startup.id, startup.pbId);
    props.handleSave();
    setIsSyncing(false);
    setPitchbookModalOpen(false);
  };

  const handleRemoveLink = async () => {
    setPitchbookModalOpen(false);
    await removeLink(props.startup.id);
    props.handleSave();
  };

  const handleOpenPitchbookModal = () => {
    setPitchbookModalOpen(true);
  };

  const handleUpdateDataClick = () => {
    if (!startupPb) {
      setPitchbookModalOpen(true);
    } else {
      handleStartupSync(startupPb);
    }
  };

  const dynamicDate = () => {
    return props.startup.lastPitchbookSync
      ? formatDate(props.startup.lastPitchbookSync)
      : "Never";
  };

  return (
    <>
      {!isExternalUser && (
        <PitchBookAddLink
          startupPb={startupPb}
          isLoading={isLoading}
          onClick={handleOpenPitchbookModal}
        />
      )}

      {((isExternalUser && props.startup.pitchbookId) || !isExternalUser) && (
        <Stack gap={0.5}>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<Icon component={Refresh} />}
            onClick={handleUpdateDataClick}
            style={{
              opacity: startupPb ? 1 : 0.3,
            }}
            data-testid="update-data-button"
          >
            Fetch Data
          </Button>
          <Typography variant="overline" color="text.mediumEmphasis">
            <span style={{ color: theme.palette.text.disabled }}>
              Last Data Fetch
            </span>
            {` ${dynamicDate()}`}
          </Typography>
        </Stack>
      )}

      {pitchbookModalOpen && (
        <PitchbookSearchModal
          startupName={
            props.startup.website
              ? props.startup.website
              : startupPb?.name || props.startup.name || ""
          }
          showPbId
          allowManualEntry={false}
          pitchbookModalOpen={pitchbookModalOpen}
          handleModalClose={() => setPitchbookModalOpen(false)}
          customModalTitle="Link to PitchBook"
          customHandleContinue={handleStartupSync}
          handleRemoveLink={handleRemoveLink}
        />
      )}
      {isSyncing && (
        <LoadingModal isLoading={isSyncing} content="Updating Data..." />
      )}
    </>
  );
}
