import { ReactElement } from "react";
import { Box, Typography } from "@mui/material";
import theme from "../../theme";
import { styled } from "@mui/system";

const FooterBox = styled(Box)({
  width: "fill-available",
  marginLeft: theme.spacing(7),
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(9),
  },
  padding: "16px 24px",
  display: "flex",
  position: "absolute",
  bottom: 0,
  height: theme.spacing(7.5),
  justifyContent: "space-between",
  alignItems: "center",
  pointerEvents: "none",
});

export default function Footer(): ReactElement {
  const currentYear = new Date().getFullYear();

  const isDevelopment = process.env.NODE_ENV === "development";
  const baseS3Url = isDevelopment
    ? "https://vclos-frontend.s3.eu-central-1.amazonaws.com"
    : "https://vclos-frontend-prod.s3.eu-central-1.amazonaws.com";

  return (
    <FooterBox>
      <Typography variant="body2">
        © {currentYear} 27pilots Deloitte GmbH - All Rights Reserved
      </Typography>
      <Box
        component="img"
        alt="27pilots Logo"
        src={`${baseS3Url}/27pilots_Logo_black.png`}
        width={60}
      />
    </FooterBox>
  );
}
