import { ReactElement } from "react";
import { BarItemProps, ResponsiveBar } from "@nivo/bar";
import { Box, Typography, styled, Stack } from "@mui/material";
import theme from "../../../theme";
import { animated, useSpring } from "@react-spring/web";
import {
  ProjectCount,
  StartupCountByAssessment,
} from "../../../Types/Analytics";

const Wrapper = styled(Stack)(() => ({
  flex: 3,
  height: "100%",
  backgroundColor: theme.palette.surface.primary.main,
  gap: theme.spacing(3),
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  "& g[transform='translate(40,30)'] > *": {
    height: "100%",
  },
}));

const ChartWrapper = styled(Box)(() => ({
  width: "100%",
  height: "127px",
  display: "flex",
  scale: "1.41",
}));

const CustomBarComponent = (props: BarItemProps<ProjectCount>) => {
  const {
    bar,
    borderRadius,
    borderWidth,
    isFocusable,
    ariaLabel,
    ariaLabelledBy,
    ariaDescribedBy,
  } = props;

  const springs = useSpring({
    from: { width: 0, height: bar.height },
    to: {
      width: bar.width,
      height: bar.height,
    },
  });

  const borderColors = [
    theme.palette.chart.light.primary,
    theme.palette.chart.medium.secondary,
    theme.palette.chart.medium.primary,
    theme.palette.chart.dark.secondary,
    theme.palette.chart.dark.primary,
  ];

  return (
    <animated.g id={`bar-${bar.data.data.id}`}>
      <animated.rect
        style={{ ...springs }}
        clipPath="inset(0 0 8px 0)"
        transform="translate(0,8)"
        rx={borderRadius}
        ry={borderRadius}
        width={bar.width}
        height={bar.height}
        x={bar.x}
        y={bar.y}
        fill={bar.data.fill ?? bar.color}
        strokeWidth={borderWidth}
        stroke={borderColors[bar.data.index]}
        strokeOpacity={0.2}
        focusable={isFocusable}
        tabIndex={isFocusable ? 0 : undefined}
        aria-label={ariaLabel ? ariaLabel(bar.data) : undefined}
        aria-labelledby={ariaLabelledBy ? ariaLabelledBy(bar.data) : undefined}
        aria-describedby={
          ariaDescribedBy ? ariaDescribedBy(bar.data) : undefined
        }
      />

      <animated.text
        x={bar.x + bar.width / 2}
        y={bar.y - 4}
        textAnchor="middle"
        style={{
          ...theme.typography.overline,
          fontWeight: 600,
          fontSize: "0.514rem",
        }}
      >
        {bar.data.value}
      </animated.text>

      <animated.text
        x={bar.x + bar.width / 2}
        y={bar.y + bar.height + 16}
        dominantBaseline="central"
        textAnchor="middle"
        style={{ ...theme.typography.overline, fontSize: "0.45rem" }}
      >
        {bar.data.data.id}
      </animated.text>
    </animated.g>
  );
};

interface StartupsProps {
  startupCount: StartupCountByAssessment;
}

function Startups(props: StartupsProps): ReactElement {
  return (
    <Wrapper>
      <Typography variant="h6">Startups</Typography>
      <ChartWrapper data-testid="startups-chart">
        <ResponsiveBar
          animate
          padding={0.42}
          enableGridY={false}
          enableGridX={false}
          borderRadius={theme.shape.borderRadius}
          borderWidth={8}
          enableLabel={false}
          colorBy={"indexValue"}
          colors={[
            theme.palette.chart.light.primary,
            theme.palette.chart.medium.secondary,
          ]}
          barComponent={(props) => <CustomBarComponent {...props} />}
          axisLeft={null}
          axisBottom={null}
          keys={["value"]}
          data={[
            {
              label: "Curated",
              id: "Curated",
              value: props.startupCount.curated,
            },
            {
              label: "Qualified",
              id: "Qualified",
              value: props.startupCount.qualified,
            },
          ]}
          margin={{
            left: 0,
            bottom: 34.6,
            top: 30,
          }}
        />
      </ChartWrapper>
    </Wrapper>
  );
}

export default Startups;
