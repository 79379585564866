import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  styled,
  Link,
  IconButton,
  Chip,
  Stack,
} from "@mui/material";
import { Close, RocketLaunchOutlined } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LaunchIcon from "@mui/icons-material/Launch";
import { Link as RouterLink } from "react-router-dom";
import theme from "../../../../../../../theme";
import { Opportunity } from "../../../../../../../Types/Opportunity";
import { findLogo, normalizeUrl } from "../../../../../../../utils";
import { ReactElement } from "react";
import ScrollableDialogContent from "../../../../../../UI/Modals/ScrollableDialogContent";

const StartupWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  boxShadow: theme.boxShadows[0],
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
}));

interface RecommendedStartupsOverviewModalProps {
  modalOpen: boolean;
  setModalOpen: (state: boolean) => void;
  opportunities: Opportunity[];
  recommendationType: "rating" | "demo";
}

const RecommendedStartupsOverviewModal = (
  props: RecommendedStartupsOverviewModalProps
): ReactElement => {
  const modalTitle =
    props.recommendationType === "rating"
      ? "Recommended Startups"
      : "Recommended for Demo";
  const modalDescription =
    props.recommendationType === "rating"
      ? "It is recommended to assess A-rated startups based on the specific criteria and requirements of the use case in order to find the best fit."
      : "It is recommended to conduct a product demo with these promising startups.";

  return (
    <Dialog
      fullWidth
      open={props.modalOpen}
      onClose={() => props.setModalOpen(false)}
      data-testid="recommended-startups-overview-modal"
      PaperProps={{
        sx: {
          gap: theme.spacing(4),
        },
      }}
    >
      <DialogTitle
        sx={{ justifyContent: "space-between", ...theme.typography.h3 }}
      >
        <Box display="flex" gap={2} alignItems="center">
          {modalTitle}
          <Chip
            variant="counter"
            color="info"
            label={props.opportunities.length}
          />
        </Box>
        <IconButton
          onClick={() => props.setModalOpen(false)}
          sx={{ mr: theme.spacing(-1) }}
        >
          <Close htmlColor={theme.palette.icon.primary} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" gap={1}>
          <InfoOutlinedIcon fontSize="small" />
          <Typography>{modalDescription}</Typography>
        </Box>
      </DialogContent>
      <ScrollableDialogContent>
        <Stack gap={2}>
          {props.opportunities.map((opportunity) => {
            const logo = findLogo(opportunity.startup.files);
            const startup = opportunity.startup;
            const foundedYear = startup.dateFounded?.getFullYear();
            return (
              <StartupWrapper
                key={startup.id}
                data-testid={`startup-${startup.id}`}
              >
                <Box display="flex" justifyContent="space-between">
                  <RouterLink target="_blank" to={"/startups/" + startup.id}>
                    {logo ? (
                      <img
                        src={logo}
                        alt="startup logo"
                        style={{
                          maxWidth: "80px",
                          maxHeight: "40px",
                        }}
                      />
                    ) : (
                      <RocketLaunchOutlined color="disabled" />
                    )}
                  </RouterLink>
                  {startup.website && (
                    <Link target="_blank" href={normalizeUrl(startup.website)}>
                      <LaunchIcon
                        data-testid="startup-link-icon"
                        fontSize="small"
                      />
                    </Link>
                  )}
                </Box>
                <RouterLink target="_blank" to={"/startups/" + startup.id}>
                  <Typography color="other.secondaryAction">
                    {startup.name}
                  </Typography>
                </RouterLink>
                <Box display="flex">
                  <Typography variant="caption">
                    {startup.billingCountry || "N/A"}
                  </Typography>
                  <Typography variant="caption" ml={2}>
                    {foundedYear || "N/A"}
                  </Typography>
                  <Typography
                    ml="auto"
                    variant="caption"
                    color="text.brand.accessibility"
                  >
                    {startup.currentInvestmentStage || "N/A"}
                  </Typography>
                  <Typography
                    variant="caption"
                    ml={2}
                    color="text.brand.accessibility"
                  >
                    {startup.totalFunding ? `$${startup.totalFunding}M` : "N/A"}
                  </Typography>
                </Box>
              </StartupWrapper>
            );
          })}
        </Stack>
      </ScrollableDialogContent>
    </Dialog>
  );
};

export default RecommendedStartupsOverviewModal;
