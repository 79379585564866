import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Fragment, ReactElement, useEffect, useState } from "react";
import CreateVentureClientModal from "./CreateVentureClientModal";
import { VentureClientHttpService } from "../../Http/VentureClient/VentureClient.http.service";
import { VentureClient } from "../../Types/VentureClient";
import { useHistory } from "react-router-dom";
import ContentWrapper from "../ProjectDetails/ContentWrapper";
import useRoles from "../../Hooks/useRoles";

export default function VentureClientList(): ReactElement {
  const history = useHistory();
  const { isAdmin } = useRoles();

  const [ventureClients, setVentureClients] = useState<VentureClient[]>([]);
  const [toggleContentLoad, setToggleContentLoad] = useState(false);

  useEffect(() => {
    document.title = `Clients - Venture Client Platform`;
  }, []);

  useEffect(() => {
    VentureClientHttpService.getVentureClients().then((ventureClients) => {
      return setVentureClients(ventureClients);
    });
  }, [toggleContentLoad]);

  const [createVentureClientModalOpen, setCreateVentureClientModalOpen] =
    useState(false);

  const handleCreateVentureClientModalOpen = () => {
    setCreateVentureClientModalOpen(true);
  };

  const handleCreateVentureClientModalClose = () => {
    setCreateVentureClientModalOpen(false);
  };

  const handleCreateNewVentureClient = () => {
    setToggleContentLoad((prevState) => !prevState);
  };

  const redirect = (id: number) => {
    history.push(`/venture-clients/${id}`);
  };

  return (
    <Fragment>
      <ContentWrapper>
        {isAdmin && (
          <Box display="flex" justifyContent="flex-end" mb={2}>
            <Button
              onClick={handleCreateVentureClientModalOpen}
              variant="outlined"
            >
              + Add Client
            </Button>
          </Box>
        )}
        <CreateVentureClientModal
          modalOpen={createVentureClientModalOpen}
          handleModalClose={handleCreateVentureClientModalClose}
          handleCreateNewVentureClient={handleCreateNewVentureClient}
        />
        <TableContainer
          component={Paper}
          variant="outlined"
          id="venture-client-list"
        >
          <Table sx={{ minWidth: 200 }} aria-label="venture clients table">
            <TableHead>
              <TableRow>
                <TableCell>Company Name</TableCell>
                <TableCell>Industry</TableCell>
                <TableCell>Main Contact</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ cursor: "pointer" }}>
              {ventureClients
                .sort((option1, option2) =>
                  option1.name.localeCompare(option2.name)
                )
                .map((ventureClient) => (
                  <TableRow
                    key={ventureClient.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    onClick={() => redirect(ventureClient.id)}
                  >
                    <TableCell component="th" scope="ventureclient">
                      {ventureClient.name}
                    </TableCell>
                    <TableCell>{ventureClient.industry}</TableCell>
                    <TableCell>
                      {ventureClient.mainContact?.name}
                      {ventureClient.mainContact?.departmentCode &&
                        ` (${ventureClient.mainContact?.departmentCode})`}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ContentWrapper>
    </Fragment>
  );
}
