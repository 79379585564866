import { ReactElement, useContext, useEffect, useState } from "react";
import { ProjectCount } from "../../Types/Analytics";
import { AnalyticsHttpService } from "../../Http/Analytics/Analytics.http.service";
import { Box, Grid, Typography } from "@mui/material";
import { BarItemProps, ResponsiveBar } from "@nivo/bar";
import { animated, useSpring } from "@react-spring/web";
import theme from "../../theme";
import { GlobalLoaderContext } from "../../Context/LoaderContext";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { DashboardFilterOptions } from "../../Types/Dashboard";
const MIN_HEIGHT = 50;

interface Props {
  dashboardFilters: DashboardFilterOptions;
  refetch: boolean;
}

function ActiveProjectsSection({
  dashboardFilters,
  refetch,
}: Props): ReactElement {
  const history = useHistory();
  const [activeProjects, setActiveProjects] = useState<ProjectCount[]>();
  const { setGlobalLoader } = useContext(GlobalLoaderContext);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchData = async () => {
      setGlobalLoader(true);
      try {
        const data = await AnalyticsHttpService.getProjectCount(
          {
            businessUnitIds: dashboardFilters.businessUnitsIds,
            focusAreasIds: dashboardFilters.focusAreasIds,
          },
          "active"
        );
        setActiveProjects(data);
        // eslint-disable-next-line
      } catch (error: any) {
        enqueueSnackbar(
          `Something went wrong with fetching the funnel overview: ${error.message}`,
          {
            variant: "error",
          }
        );
      } finally {
        setGlobalLoader(false);
      }
    };
    fetchData();
  }, [refetch]);

  const totalProjectsCount = activeProjects
    ? activeProjects.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.value;
      }, 0)
    : 0;

  return (
    <Box
      data-testid="active-projects"
      sx={{ cursor: "pointer" }}
      onClick={() => history.push("/projects")}
    >
      <Grid container display="flex" flexDirection="column" p={0}>
        <Grid item p={1} style={{ position: "absolute" }} zIndex={1}>
          <Typography variant="h5" color="grey.600" fontWeight="500">
            Funnel Overview
          </Typography>
          <Typography
            variant="body2"
            data-cy="totalProjectCount"
            color="grey.600"
          >
            Active Projects: <b>{totalProjectsCount}</b>
          </Typography>
        </Grid>

        <Grid item maxWidth="100%" height={300}>
          {activeProjects && (
            <ResponsiveBar
              data={activeProjects}
              legends={undefined}
              keys={["value"]}
              indexBy="label"
              margin={{ top: 70, right: 0, bottom: MIN_HEIGHT, left: 0 }}
              padding={0}
              colors={[
                theme.palette.chart.light.primary,
                theme.palette.chart.medium.secondary,
                theme.palette.chart.medium.primary,
                theme.palette.chart.dark.secondary,
                theme.palette.chart.dark.primary,
              ]}
              colorBy="indexValue"
              borderColor={"#fff"}
              borderWidth={4}
              borderRadius={0}
              gridYValues={undefined}
              enableGridY={false}
              enableGridX={false}
              axisTop={null}
              axisRight={null}
              axisBottom={null}
              axisLeft={null}
              role="application"
              ariaLabel="Funnel Overview"
              barComponent={CustomBarComponent}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
export default ActiveProjectsSection;

const CustomBarComponent = (props: BarItemProps<ProjectCount>) => {
  const {
    bar,
    style,
    borderRadius,
    borderWidth,
    isFocusable,
    ariaLabel,
    ariaLabelledBy,
    ariaDescribedBy,
  } = props;

  const MIN_HEIGHT_VALUE0 = 4;
  const THRESHOLD = 60;
  const isValueInsideBar = bar.height >= THRESHOLD;

  const springs = useSpring({
    from: { width: 0, height: bar.height + MIN_HEIGHT },
    to: {
      width: bar.width,
      height: bar.height + MIN_HEIGHT,
    },
  });

  return (
    <animated.g
      id={`bar-${bar.data.data.id}`}
      transform={style.transform}
      // It is needed when each bar has 0 value
      style={
        bar.data.value === 0
          ? { translate: `${bar.x + 0.1}px, 180px` }
          : undefined
      }
    >
      <animated.rect
        style={{ ...springs }}
        rx={borderRadius}
        ry={borderRadius}
        y={bar.data.value === 0 ? MIN_HEIGHT - MIN_HEIGHT_VALUE0 : 0}
        fill={bar.data.fill ?? bar.color}
        strokeWidth={borderWidth}
        stroke={style.borderColor}
        focusable={isFocusable}
        tabIndex={isFocusable ? 0 : undefined}
        aria-label={ariaLabel ? ariaLabel(bar.data) : undefined}
        aria-labelledby={ariaLabelledBy ? ariaLabelledBy(bar.data) : undefined}
        aria-describedby={
          ariaDescribedBy ? ariaDescribedBy(bar.data) : undefined
        }
      />
      <animated.line
        x1="0"
        y1={bar.data.indexValue === "Started Projects" ? 0 : "-300"}
        x2="0"
        y2={bar.data.indexValue === "Started Projects" ? 0 : "300"}
        stroke={"#fff"}
        strokeWidth={1}
      />
      <animated.text
        x={style.labelX}
        y={style.labelY}
        dominantBaseline="central"
        textAnchor="middle"
        style={{
          fontSize: 50,
          pointerEvents: "none",
          fill: isValueInsideBar ? "#fff" : bar.data.fill,
        }}
        transform={`translate(0,${-(
          bar.height / 2 +
          (isValueInsideBar ? -40 : +30)
        )})`}
      >
        {bar.data.value}
      </animated.text>

      <animated.text
        x={style.labelX}
        y={style.labelY}
        dominantBaseline="central"
        textAnchor="middle"
        style={{
          fontSize: 20,
          fontWeight: "bold",
          pointerEvents: "none",
          fill: bar.data.value === 0 ? bar.data.fill : "#fff",
        }}
        transform={`translate(0,${bar.height / 2 + MIN_HEIGHT / 2})`}
      >
        {bar.data.data.label}
      </animated.text>
    </animated.g>
  );
};
