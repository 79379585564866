import { styled } from "@mui/material/styles";

const RatingButton = styled("span")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  cursor: "pointer",
  "& .A": {
    backgroundColor: theme.palette.surface.success,
  },
  "& .B": {
    backgroundColor: theme.palette.borderOutline.main,
  },
  "& .C": {
    backgroundColor: theme.palette.surface.secondary.main,
  },
}));

export default RatingButton;
