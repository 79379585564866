import {
  Dialog,
  DialogTitle,
  Button,
  Box,
  Typography,
  TextField,
  MenuItem,
  Autocomplete,
  IconButton,
  styled,
  debounce,
  CircularProgress,
  Checkbox,
  DialogActions,
  FormControlLabel,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useState, useContext, useReducer, Reducer, ReactElement } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useSnackbar } from "notistack";
import { GlobalProjectEditContext } from "../../../../../Context/ProjectDetailsContext";
import { UserContext } from "../../../../../Context/UserContext";
import { KpiHttpService } from "../../../../../Http/Kpi/Kpi.http.service";
import { UnitHttpService } from "../../../../../Http/Unit/Unit.http.service";
import { Kpi, Unit } from "../../../../../Types/Kpi";
import theme from "../../../../../theme";
import { getErrorMessage } from "../../../../../utils";
import CreateEntityByName from "../../../../UI/Modals/CreateEntityByName/CreateEntityByName";
import { KeyboardArrowDown } from "@mui/icons-material";
import ScrollableDialogContent from "../../../../UI/Modals/ScrollableDialogContent";

interface Props {
  objectiveId: number;
  setModalOpen: (state: boolean) => void;
  modalOpen: boolean;
  kpi?: Kpi;
  refreshObjective?: (kpiId: number, isKpiFulfilled: boolean | null) => void;
  updateKpi: (objectiveId: number, updatedKpi: Kpi) => void;
  createKpi: (newKpi: Kpi) => void;
}

const InfoSection = styled(Box)(() => ({
  paddingInline: theme.spacing(2),
  borderRadius: theme.shape.radius.minimal,
  backgroundColor: theme.palette.surface.secondary.main,
}));

const FormContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(4),
  flexWrap: "wrap",
  "& > div:first-of-type": {
    flexBasis: "100%",
  },
  "& > div:not(:first-of-type)": {
    flex: 1,
  },
}));

const BinaryInfoSection = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "start",
  gap: theme.spacing(2),
  flex: "2",
}));

const operators = [">", "<", "=", "<=", ">="];

const isFulfilled = (
  operator: string | null,
  target: number,
  measurement: number | null
) => {
  if (measurement === null) return null;

  switch (operator) {
    case ">":
      return measurement > target;
    case "<":
      return measurement < target;
    case "=":
      return measurement == target;
    case "<=":
      return measurement <= target;
    case ">=":
      return measurement >= target;
    default:
      throw new Error("Invalid operation");
  }
};

const shouldNotSearch = (
  event: React.SyntheticEvent<Element, Event>,
  newValue: string,
  units: Unit[]
) => {
  if (event === null || units.some((unit) => unit.name.includes(newValue))) {
    return true;
  }
  return false;
};

const ManageKpiModal = (props: Props): ReactElement => {
  const { activeStep } = useContext(GlobalProjectEditContext);
  const user = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [units, setUnits] = useState<Unit[]>([]);
  const [newKpi, setNewKpi] = useReducer<Reducer<Kpi, Partial<Kpi>>>(
    (state, newState) => ({ ...state, ...newState }),
    props.kpi || ({ objectiveId: props.objectiveId, target: null } as Kpi)
  );
  const [createUnitModalOpen, setCreateUnitModalOpen] = useState(false);

  const isBuyStageActive = activeStep === 2;
  const isPilotStageActive = activeStep === 3;
  const isBinaryKpi = newKpi.operator === "binary";
  const isCreateMode = !props.kpi;

  const searchForUnits = async (searchValue: string): Promise<void> => {
    if (searchValue) {
      setIsLoading(true);
      await UnitHttpService.getUnitsByName(searchValue)
        .then((units) => setUnits(units))
        .catch((error) => {
          const errorMessage = getErrorMessage(error);
          enqueueSnackbar(`Could not get units: ${errorMessage}`, {
            variant: "error",
          });
        })
        .finally(() => setIsLoading(false));
    }
  };

  const addUnit = (newUnit: Unit) => {
    setNewKpi({ unitId: newUnit.id, unit: newUnit });
    setUnits([...units, newUnit]);
  };

  const createKpi = async () => {
    setIsLoading(true);
    await KpiHttpService.createKpi(newKpi)
      .then((kpi: Kpi) => {
        props.createKpi(kpi);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(`Could not create KPI: ${errorMessage}`, {
          variant: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
        props.setModalOpen(false);
      });
  };

  const editKpi = async () => {
    setIsLoading(true);
    await KpiHttpService.updateKpi(newKpi)
      .then(() => {
        props.updateKpi?.(newKpi.objectiveId, newKpi);
        props.refreshObjective &&
          props.refreshObjective(props.kpi?.id as number, newKpi.fulfilled);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(`Could not update KPI: ${errorMessage}`, {
          variant: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
        props.setModalOpen(false);
      });
  };

  const debouncedSearchForUnits = debounce(searchForUnits, 500);

  return (
    <>
      <Dialog
        open={props.modalOpen}
        fullWidth
        data-testid="manage-kpi-modal"
        PaperProps={{
          sx: {
            gap: theme.spacing(4),
          },
        }}
      >
        <DialogTitle data-testid="manage-kpi-header">
          {isCreateMode ? "Add KPI" : "Edit KPI"}
        </DialogTitle>
        <ScrollableDialogContent
          sx={{
            ...(!isCreateMode && {
              overflow: "visible",
            }),
          }}
        >
          <Stack gap={4}>
            {isCreateMode && (
              <Accordion
                sx={{
                  backgroundColor: theme.palette.surface.secondary.main,
                }}
                defaultExpanded
              >
                <AccordionSummary expandIcon={<KeyboardArrowDown />}>
                  <Box display="flex" alignItems="center" gap={1}>
                    <InfoOutlinedIcon sx={{ fontSize: "1rem" }} />
                    <Typography>Hello {user?.name}</Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                  <InfoSection data-testid="info-section">
                    <Typography>
                      You are about to create a new KPI and assign it to one of
                      this project’s objectives.
                      <br />
                      <br />
                      <b>Key Performance Indicators (KPIs)</b> are target values
                      or outcomes that define the success criteria for each of
                      the project objectives.
                      <br />
                      <br />
                      Here are a few tips to make things easier:
                    </Typography>
                    <ul
                      style={{
                        marginTop: 0,
                        paddingInlineStart: theme.spacing(2),
                        ...theme.typography.body1,
                      }}
                    >
                      <li>
                        Ensure that your KPI directly aligns with the objective
                        to which it is assigned and enables the project team to
                        evaluate whether the objective has been achieved
                      </li>
                      <li>
                        Make the KPI specific and measurable by either defining
                        a quantitative threshold value or an outcome which can
                        be assessed on a binary dimension (fulfilled or
                        unfulfilled).
                      </li>
                    </ul>
                  </InfoSection>
                </AccordionDetails>
              </Accordion>
            )}
            {isBuyStageActive ? (
              <FormContainer data-testid="manage-kpi-form">
                <TextField
                  label="KPI"
                  multiline
                  value={newKpi.description || ""}
                  variant="outlined"
                  onChange={(event) =>
                    setNewKpi({ description: event.target.value })
                  }
                  placeholder="e.g. Material Weight"
                  helperText={
                    isCreateMode &&
                    "Describe the KPI in a few words. Clearly explain what will be measured or evaluated."
                  }
                  inputProps={{ "data-testid": "kpi-description-input" }}
                />
                <TextField
                  select
                  label="Type"
                  value={newKpi.operator || ""}
                  onChange={(event) => {
                    if (event.target.value === "binary") {
                      setNewKpi({
                        operator: event.target.value,
                        target: null,
                        unitId: null,
                      });
                    } else {
                      setNewKpi({
                        operator: event.target.value,
                      });
                    }
                  }}
                  helperText={
                    isCreateMode &&
                    "You can select a binary KPI or define a quantitative threshold value by choosing an operator."
                  }
                  inputProps={{ "data-testid": "kpi-operator-input" }}
                >
                  <MenuItem divider key={-1} value="binary">
                    binary
                  </MenuItem>
                  <Typography variant="caption" color="text.disabled" p={2}>
                    Quantitative
                  </Typography>
                  {operators.map((operator, index) => (
                    <MenuItem key={index} value={operator} sx={{ pl: 3.5 }}>
                      {operator}
                    </MenuItem>
                  ))}
                </TextField>

                {isBinaryKpi ? (
                  <BinaryInfoSection data-testid="binary-info-section">
                    <InfoOutlinedIcon sx={{ fontSize: "1rem" }} />
                    <Typography>
                      A binary KPI defines an outcome to be evaluated during the
                      pilot project which can either be fulfilled or
                      unfulfilled.
                    </Typography>
                  </BinaryInfoSection>
                ) : (
                  <>
                    <TextField
                      label="Value"
                      type="number"
                      value={newKpi.target !== null ? newKpi.target : ""}
                      onChange={(event) => {
                        const value = event.target.value
                          ? +event.target.value
                          : null;
                        setNewKpi({ target: value });
                      }}
                      helperText={
                        isCreateMode && "Provide a quantitative value."
                      }
                      disabled={isCreateMode && !newKpi.operator}
                      inputProps={{ "data-testid": "kpi-target-input" }}
                    />
                    <Autocomplete
                      forcePopupIcon={false}
                      isOptionEqualToValue={(option: Unit, value: Unit) =>
                        option.id === value.id
                      }
                      onInputChange={(event, newValue: string) => {
                        if (shouldNotSearch(event, newValue, units)) return;
                        debouncedSearchForUnits(newValue);
                      }}
                      onChange={(_, selectedUnit) => {
                        setNewKpi({
                          unitId: selectedUnit?.id || null,
                          unit: selectedUnit,
                        });
                      }}
                      value={newKpi.unit || null}
                      disabled={isCreateMode && !newKpi.operator}
                      filterOptions={(options) => options}
                      getOptionLabel={(option) => option.name ?? ""}
                      options={units}
                      noOptionsText="No Unit found"
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            label="Unit"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            helperText={
                              isCreateMode &&
                              "Define a measurement unit, if applicable."
                            }
                            placeholder="mm"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {params.InputProps.endAdornment}
                                  <IconButton
                                    data-testid="add-new-unit"
                                    onClick={() => setCreateUnitModalOpen(true)}
                                    disabled={isCreateMode && !newKpi.operator}
                                  >
                                    {isLoading ? (
                                      <CircularProgress size={20} />
                                    ) : (
                                      <AddIcon fontSize="small" />
                                    )}
                                  </IconButton>
                                </>
                              ),
                            }}
                            inputProps={{
                              ...params.inputProps,
                              "data-testid": "kpi-unit-input",
                            }}
                          />
                        );
                      }}
                    />
                  </>
                )}
              </FormContainer>
            ) : (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                data-testid="manage-kpi-content"
              >
                <Typography>{props.kpi?.description}</Typography>
                {!isBinaryKpi && (
                  <Box display="flex" alignItems="center" gap={1.5}>
                    <Typography
                      variant="subtitle2"
                      color="text.brand.accessibility"
                    >
                      {props.kpi?.operator}
                    </Typography>
                    <Typography variant="h4" color="text.brand.accessibility">
                      {props.kpi?.target}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      color="text.brand.accessibility"
                    >
                      {props.kpi?.unit?.name}
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
            {isPilotStageActive && (
              <Stack gap={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        "&.Mui-checked": {
                          color: theme.palette.action.secondary,
                        },
                      }}
                      checked={newKpi.isNotMeasured}
                      onChange={(_, checked) => {
                        setNewKpi({
                          isNotMeasured: checked,
                          measurement: null,
                          fulfilled: null,
                        });
                      }}
                    />
                  }
                  label={
                    <Typography variant="subtitle2">
                      Don&apos;t set a value for this KPI
                    </Typography>
                  }
                  sx={{ my: theme.spacing(-1) }}
                />
                {isBinaryKpi ? (
                  <TextField
                    sx={{ width: "200px" }}
                    select
                    label="Value"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={
                      newKpi.fulfilled === true
                        ? "fulfilled"
                        : newKpi.fulfilled === false
                        ? "Not fulfilled"
                        : ""
                    }
                    onChange={(event) => {
                      setNewKpi({
                        fulfilled: event.target.value === "fulfilled",
                      });
                    }}
                    disabled={newKpi.isNotMeasured}
                    inputProps={{
                      "data-testid": "kpi-measurement-input",
                    }}
                  >
                    <MenuItem value="fulfilled">Fulfilled</MenuItem>
                    <MenuItem value="Not fulfilled">Not Fulfilled</MenuItem>
                  </TextField>
                ) : (
                  <Box display="flex" alignItems="center" gap={2}>
                    <TextField
                      label="Value"
                      type="number"
                      value={newKpi.measurement || ""}
                      disabled={newKpi.isNotMeasured}
                      onChange={(event) => {
                        const measurement = event.target.value
                          ? +event.target.value
                          : null;
                        const targetValue = props.kpi?.target || 0;
                        const operator = props.kpi?.operator || null;
                        const fulfilled = isFulfilled(
                          operator,
                          targetValue,
                          measurement
                        );
                        setNewKpi({
                          measurement,
                          fulfilled,
                          isNotMeasured: false,
                        });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        "data-testid": "kpi-measurement-input",
                      }}
                    />
                    <Typography
                      color={newKpi.isNotMeasured ? "text.disabled" : "inherit"}
                    >
                      {props.kpi?.unit?.name}
                    </Typography>
                  </Box>
                )}
              </Stack>
            )}
          </Stack>
        </ScrollableDialogContent>

        <DialogActions>
          <Box display="flex" gap={1}>
            <Button
              onClick={() => props.setModalOpen(false)}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={isCreateMode ? createKpi : editKpi}
              disabled={isLoading}
            >
              Save
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      {createUnitModalOpen && (
        <CreateEntityByName
          name="unit"
          label="Unit"
          modalOpen={createUnitModalOpen}
          setModalOpen={() => setCreateUnitModalOpen(false)}
          handleAdd={addUnit}
        />
      )}
    </>
  );
};

export default ManageKpiModal;
