import { NewReleasesOutlined, Verified } from "@mui/icons-material";
import { Badge, Box, styled, Tooltip, Typography } from "@mui/material";
import { ReactElement } from "react";
import useRoles from "../../../../Hooks/useRoles";
import { User } from "../../../../Types/User";
import { formatDate, getDateMonthsAfter } from "../../../../utils";

const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-dot": {
    position: "relative",
    top: "5px",
    right: "9px",
    height: "6px",
    minWidth: "6px",
  },
}));

interface QualityVerificationIconProps {
  lastQualityCheckDate: Date | null;
  lastQualityCheckBy: User | null;
  verified: boolean;
  hasExpired: boolean;
}

export default function QualityVerificationIcon({
  lastQualityCheckDate,
  lastQualityCheckBy,
  verified,
  hasExpired,
}: QualityVerificationIconProps): ReactElement {
  const { isExternalUser } = useRoles();

  if (!lastQualityCheckDate) return <></>;

  const warningDate = getDateMonthsAfter(lastQualityCheckDate, 9);
  const isRunningOut =
    lastQualityCheckDate && verified && warningDate < new Date() && !hasExpired;

  const tooltipText = (
    <Box>
      {hasExpired ? (
        <Typography>Quality Verification needs renewal</Typography>
      ) : isRunningOut ? (
        <Typography>Quality Verification expires soon</Typography>
      ) : verified ? (
        <Typography>Quality Verified</Typography>
      ) : (
        <Typography>Failed Quality Verification</Typography>
      )}
      {lastQualityCheckBy && (
        <Typography variant="body2">
          {!isExternalUser ? lastQualityCheckBy?.name + " - " : ""}
          {formatDate(lastQualityCheckDate)}
        </Typography>
      )}
    </Box>
  );

  return (
    <Tooltip title={tooltipText}>
      {hasExpired ? (
        <Verified color="disabled" data-testid="expired-icon" />
      ) : isRunningOut ? (
        <StyledBadge variant="dot" color="warning" data-testid="renewal-badge">
          <Verified color="success" data-testid="success-icon" />
        </StyledBadge>
      ) : verified ? (
        <Verified color="success" data-testid="success-icon" />
      ) : (
        <NewReleasesOutlined color="warning" data-testid="failed-icon" />
      )}
    </Tooltip>
  );
}
