import { TextField, MenuItem } from "@mui/material";
import { ReactElement, useEffect, useState, ChangeEvent } from "react";
import { BusinessUnitHttpService } from "../../../Http/BusinessUnit/BusinessUnit.http.service";
import { BusinessUnit } from "../../../Types/BusinessUnit";
import { VentureClient } from "../../../Types/VentureClient";
import { Add } from "@mui/icons-material";
import CreateBusinessUnitModal from "../../VentureClient/DetailsPage/BusinessUnitsView/CreateBusinessUnitModal";
import theme from "../../../theme";

interface BusinessUnitSelectProps {
  selectedVentureClient?: VentureClient;
  defaultBusinessUnitId?: number;
  handleSelectBusinessUnit: (unitId: number) => void;
  className?: string;
  required?: boolean;
}

const BusinessUnitSelect = (props: BusinessUnitSelectProps): ReactElement => {
  const [businessUnitSelectOptions, setBusinessUnitSelectOptions] = useState<
    BusinessUnit[]
  >([]);
  const [businessUnitModalOpen, setBusinessUnitModal] = useState(false);

  const { required = true } = props;

  useEffect(() => {
    setBusinessUnitSelectOptions(
      props.selectedVentureClient?.businessUnits || []
    );
  }, [props.selectedVentureClient]);

  const handleBusinessUnitModalClose = () => {
    setBusinessUnitModal(false);
  };

  const handleBusinessUnitModalOpen = () => {
    setBusinessUnitModal(true);
  };

  const handleCreateNewBusinessUnit = async () => {
    BusinessUnitHttpService.getBusinessUnits({
      ventureClientId: props.selectedVentureClient?.id as number,
    }).then((businessUnits) => {
      setBusinessUnitSelectOptions(businessUnits);
    });
  };

  return (
    <>
      <TextField
        select
        className={props.className}
        label="Organizational Unit"
        id="organizational-unit"
        data-testid="organizational-unit"
        variant="outlined"
        fullWidth
        disabled={props.selectedVentureClient?.id === undefined}
        InputLabelProps={{
          shrink: true,
        }}
        value={
          businessUnitSelectOptions.length
            ? props.defaultBusinessUnitId
              ? props.defaultBusinessUnitId
              : ""
            : ""
        }
        onChange={(
          event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => props.handleSelectBusinessUnit(parseInt(event.target.value))}
        required={required}
      >
        {businessUnitSelectOptions
          ?.sort((option1, option2) => option1.name.localeCompare(option2.name))
          .map((unit: BusinessUnit) => (
            <MenuItem key={unit.id} value={unit.id}>
              {unit.name}
            </MenuItem>
          ))}
        <MenuItem
          onClick={handleBusinessUnitModalOpen}
          style={{ borderTop: `1px solid ${theme.palette.borderOutline.main}` }}
        >
          <Add />
          Add New Organizational Unit
        </MenuItem>
      </TextField>
      {businessUnitModalOpen && (
        <CreateBusinessUnitModal
          modalOpen={businessUnitModalOpen}
          handleModalClose={handleBusinessUnitModalClose}
          handleSave={handleCreateNewBusinessUnit}
          ventureClientId={props.selectedVentureClient?.id as number}
        />
      )}
    </>
  );
};

export default BusinessUnitSelect;
