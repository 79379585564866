import { Box, IconButton, Stack, Typography } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import useRoles from "../../../../Hooks/useRoles";
import { QualityCheck } from "../../../../Types/QualityCheck";
import { User } from "../../../../Types/User";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import QualityVerificationEditModal from "./QualityVerificationEditModal";
import { useSnackbar } from "notistack";
import {
  formatDate,
  getDateMonthsAfter,
  getErrorMessage,
} from "../../../../utils";
import { StartupHttpService } from "../../../../Http/Startup/Startup.http.service";
import { QualityChecksHttpService } from "../../../../Http/QualityChecks/QualityChecks.http.service";
import QualityVerificationIcon from "./QualityVerificationIcon";
import QualityVerificationItem from "./QualityVerificationItem";

interface QualityVerificationProps {
  startupId: number;
  qualityChecks: QualityCheck[];
  lastQualityCheckDate: Date | null;
  lastQualityCheckBy: User | null;
  handleSave: () => void;
}

export default function QualityVerification({
  startupId,
  qualityChecks,
  lastQualityCheckDate,
  lastQualityCheckBy,
  handleSave,
}: QualityVerificationProps): ReactElement {
  const { isExternalUser } = useRoles();
  const { enqueueSnackbar } = useSnackbar();

  const [updatedQualityChecks, setUpdatedQualityChecks] =
    useState(qualityChecks);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [qualityCheckOptions, setQualityCheckOptions] = useState<
    QualityCheck[]
  >([]);

  const isVerified = qualityCheckOptions.length === qualityChecks.length;
  const expiryDate = lastQualityCheckDate
    ? getDateMonthsAfter(lastQualityCheckDate, 12)
    : new Date();
  const hasExpired =
    (lastQualityCheckDate && isVerified && expiryDate < new Date()) ?? false;

  useEffect(() => {
    let cancelRequest = false;
    async function fetchQualityChecks() {
      await QualityChecksHttpService.getQualityChecks()
        .then((data) => {
          if (!cancelRequest) {
            setQualityCheckOptions(data);
          }
        })
        .catch((error) => {
          const message = getErrorMessage(error);
          enqueueSnackbar(`Could not get quality criteria: ${message}`, {
            variant: "error",
          });
        });
    }

    fetchQualityChecks();
    return () => {
      cancelRequest = true;
    };
  }, []);

  const handleCancel = () => {
    setUpdatedQualityChecks(qualityChecks);
    setEditModalOpen(false);
  };

  const handleEditButtonClick = () => {
    setEditModalOpen(true);
  };

  const handleSaveButtonClick = async () => {
    await StartupHttpService.updateStartup({
      id: startupId,
      qualityChecks: updatedQualityChecks,
    })
      .then(() => {
        enqueueSnackbar("Startup saved successfully.", {
          variant: "success",
        });
        setEditModalOpen(false);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        enqueueSnackbar(`Could not update startup details: ${errorMessage}`, {
          variant: "error",
        });
      });

    handleSave();
  };

  return (
    <>
      <Stack gap={3} data-testid="quality-verifiction">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" gap={1} alignItems="center">
            <Typography variant="h6">Quality Verification</Typography>
            <QualityVerificationIcon
              lastQualityCheckBy={lastQualityCheckBy}
              lastQualityCheckDate={lastQualityCheckDate}
              verified={isVerified}
              hasExpired={hasExpired}
            />
          </Box>
          {!isExternalUser && (
            <Box>
              <IconButton
                onClick={handleEditButtonClick}
                data-testid="edit-quality-verification-button"
              >
                <ModeEditOutlineOutlinedIcon />
              </IconButton>
            </Box>
          )}
        </Box>
        <Stack gap={1}>
          {qualityCheckOptions.map((qualityCheckOption) => (
            <QualityVerificationItem
              key={qualityCheckOption.id}
              qualityCheck={qualityCheckOption}
              checked={
                !!qualityChecks.find(
                  (qualityCheck) => qualityCheck.id === qualityCheckOption.id
                )
              }
              hasExpired={hasExpired}
              data-testid={`quality-verification-item-${qualityCheckOption.id}`}
            />
          ))}
        </Stack>
        {lastQualityCheckBy && (
          <Box display="flex">
            {!isExternalUser && (
              <Box display="flex" gap={0.5}>
                <Typography variant="body2" color="text.disabled">
                  By
                </Typography>
                <Typography variant="body2" color="text.mediumEmphasis">
                  {lastQualityCheckBy?.name}
                </Typography>
              </Box>
            )}
            {lastQualityCheckDate && (
              <Box display="flex" gap={0.5} ml="auto">
                {isExternalUser && (
                  <Typography variant="body2" color="text.disabled">
                    Last Update
                  </Typography>
                )}
                <Typography variant="body2" color="text.mediumEmphasis">
                  {formatDate(lastQualityCheckDate)}
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Stack>
      {editModalOpen && (
        <QualityVerificationEditModal
          open={editModalOpen}
          qualityChecks={updatedQualityChecks}
          handleSave={handleSaveButtonClick}
          handleCancel={handleCancel}
          updateQualityChecks={setUpdatedQualityChecks}
          qualityCheckOptions={qualityCheckOptions}
        />
      )}
    </>
  );
}
