import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { ReactElement, useContext, useEffect, useState } from "react";
import theme from "../../../../../theme";
import { Project } from "../../../../../Types/Project";
import { ProductDemo } from "../../../../../Types/ProductDemo";
import ProductDemosTable from "./ProductDemosTable";
import ProductDemosAgenda from "./ProductDemosAgenda";
import { ProductDemoHttpService } from "../../../../../Http/ProductDemos/ProductDemo.http.service";
import { useSnackbar } from "notistack";
import { ProjectHttpService } from "../../../../../Http/Project/Project.http.service";
import ProjectDetailsAccordionSummary from "../../../SharedComponents/ProjectDetailsAccordionSummary";
import useSaveProject from "../../../../../Hooks/useSaveProject";
import { GlobalProjectEditContext } from "../../../../../Context/ProjectDetailsContext";

interface ProductDemosSectionProps extends Omit<AccordionProps, "children"> {
  project: Project;
  handleSaveNoScroll: () => void;
}

type EditModeType = "demos" | "agenda";

export default function ProductDemosSection(
  props: ProductDemosSectionProps
): ReactElement {
  const [editModeType, setEditModeType] = useState<EditModeType>();
  const [productDemos, setProductDemos] = useState<ProductDemo[]>([]);
  const [demoUseCases, setDemoUseCases] = useState(props.project.demoUseCases);
  const [demoQuestions, setDemoQuestions] = useState(
    props.project.demoQuestions
  );
  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { globalEditMode, setGlobalEditMode } = useContext(
    GlobalProjectEditContext
  );

  useEffect(() => {
    setProductDemos(getInitialProductDemos());
  }, [props.project]);

  function getInitialProductDemos() {
    const demos: ProductDemo[] = [];
    props.project.opportunities?.forEach((opp) => {
      if (opp.productDemos) {
        demos.push(...opp.productDemos);
      }
    });
    const sortedDemos = demos.sort(sortProductDemos);
    return sortedDemos;
  }

  const handleSaveSection = async () => {
    if (isLoading) return;
    const currentDemos = getInitialProductDemos();

    if (arraysAreEqual(currentDemos, productDemos)) {
      setEditModeType(undefined);
      setGlobalEditMode(false);
      return await onAgendaSave();
    }

    const remainingDemoIds = productDemos.map((demo) => demo.id);

    const demosToDelete = currentDemos.filter(
      (demo) => !remainingDemoIds.includes(demo.id)
    );

    setIsLoading(true);

    await Promise.all([
      ...productDemos.map((demo) => {
        const { id, ...omittedDemo } = demo;
        if (id) {
          const currentDemo = currentDemos.find(
            (_demo) => demo.id === _demo.id
          );
          if (currentDemo && !objectsAreEqual(currentDemo, demo))
            return ProductDemoHttpService.updateProductDemo(demo);
        } else {
          return ProductDemoHttpService.createProductDemo(omittedDemo);
        }
      }),
      ...demosToDelete.map((demo) =>
        ProductDemoHttpService.deleteProductDemo(demo.id)
      ),
      ProjectHttpService.updateProject({
        id: props.project.id,
        demoUseCases,
        demoQuestions,
      }),
    ])
      .catch(() =>
        enqueueSnackbar("Something went wrong while updating the project", {
          variant: "error",
        })
      )
      .finally(() => props.handleSaveNoScroll());

    setIsLoading(false);
    setEditModeType(undefined);
    setGlobalEditMode(false);
  };

  const onDemosSave = async () => {
    if (isLoading) return;
    const currentDemos = getInitialProductDemos();

    if (arraysAreEqual(currentDemos, productDemos)) {
      setEditModeType(undefined);
      setGlobalEditMode(false);
      return;
    }

    const remainingDemoIds = productDemos.map((demo) => demo.id);

    const demosToDelete = currentDemos.filter(
      (demo) => !remainingDemoIds.includes(demo.id)
    );

    setIsLoading(true);

    await Promise.all([
      ...productDemos.map((demo) => {
        const { id, ...omittedDemo } = demo;
        if (id) {
          const currentDemo = currentDemos.find(
            (_demo) => demo.id === _demo.id
          );
          if (currentDemo && !objectsAreEqual(currentDemo, demo))
            return ProductDemoHttpService.updateProductDemo(demo);
        } else {
          return ProductDemoHttpService.createProductDemo(omittedDemo);
        }
      }),
      ...demosToDelete.map((demo) =>
        ProductDemoHttpService.deleteProductDemo(demo.id)
      ),
    ])
      .catch(() =>
        enqueueSnackbar("Something went wrong while updating product demos", {
          variant: "error",
        })
      )
      .finally(() => props.handleSaveNoScroll());
    setIsLoading(false);
    setEditModeType(undefined);
    setGlobalEditMode(false);
  };

  const onAgendaSave = async () => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      await ProjectHttpService.updateProject({
        id: props.project.id,
        demoUseCases,
        demoQuestions,
      });
      props.handleSaveNoScroll();
    } catch (err) {
      enqueueSnackbar("Something went wrong while updating demo agenda", {
        variant: "error",
      });
    }
    setIsLoading(false);
    setEditModeType(undefined);
    setGlobalEditMode(false);
  };

  const handleCancelEdit = () => {
    setEditModeType(undefined);
    setGlobalEditMode(false);
    setProductDemos(getInitialProductDemos());
  };

  useSaveProject(handleSaveSection);

  return (
    <Accordion
      expanded={props.expanded}
      onChange={props.onChange}
      disabled={!!editModeType}
    >
      <ProjectDetailsAccordionSummary>Demos</ProjectDetailsAccordionSummary>
      <AccordionDetails
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={theme.spacing(7)}
          width="100%"
        >
          <SectionBlock
            dataTestId="product-demo"
            title="Product Demos"
            editMode={editModeType === "demos"}
            onEditClick={() => {
              setEditModeType("demos");
              setGlobalEditMode(true);
            }}
            onCancelClick={handleCancelEdit}
            editDisabled={globalEditMode}
            onSaveClick={onDemosSave}
            sectionType="demos"
          >
            <ProductDemosTable
              project={props.project}
              productDemos={productDemos}
              setProductDemos={setProductDemos}
              editMode={editModeType === "demos"}
            />
          </SectionBlock>
          <SectionBlock
            dataTestId="demo-agenda"
            title="Demo Agenda"
            editMode={editModeType === "agenda"}
            onEditClick={() => {
              setEditModeType("agenda");
              setGlobalEditMode(true);
            }}
            onCancelClick={handleCancelEdit}
            editDisabled={globalEditMode}
            onSaveClick={onAgendaSave}
            sectionType="agenda"
          >
            <ProductDemosAgenda
              {...props}
              editMode={editModeType === "agenda"}
              setDemoUseCases={setDemoUseCases}
              setDemoQuestions={setDemoQuestions}
            />
          </SectionBlock>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

interface SectionBlockProps {
  title: string;
  editMode: boolean;
  onEditClick: () => void;
  onCancelClick: () => void;
  onSaveClick: () => void;
  editDisabled: boolean;
  children: ReactElement;
  sectionType: EditModeType;
  dataTestId: string;
}

function SectionBlock(props: SectionBlockProps): ReactElement {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={theme.spacing(1)}
      data-testid={props.dataTestId}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle2">{props.title}</Typography>
        {!props.editMode && (
          <Button
            variant="contained"
            disabled={props.editDisabled}
            onClick={props.onEditClick}
            data-testid={props.sectionType + "-edit-button"}
          >
            Edit
          </Button>
        )}
      </Box>

      {props.children}
      {props.editMode && (
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Box display="flex" gap={theme.spacing(2)}>
            <Button
              onClick={props.onCancelClick}
              data-testid={props.sectionType + "-cancel-button"}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={props.onSaveClick}
              data-testid={props.sectionType + "-save-button"}
            >
              Save
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export function sortProductDemos(a: ProductDemo, b: ProductDemo): number {
  if (a.date === null) return 1;
  if (b.date === null) return -1;
  return (
    (a.date ? new Date(a.date).getTime() : 1) -
    (b.date ? new Date(b.date).getTime() : 1)
  );
}

function arraysAreEqual(arr1: ProductDemo[], arr2: ProductDemo[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = arr1.slice().sort(sortProductDemos);
  const sortedArr2 = arr2.slice().sort(sortProductDemos);

  for (let i = 0; i < sortedArr1.length; i++) {
    if (!objectsAreEqual(sortedArr1[i], sortedArr2[i])) {
      return false;
    }
  }
  return true;
}

function objectsAreEqual(obj1: ProductDemo, obj2: ProductDemo) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}
