import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { ReactElement, useState, useEffect } from "react";
import ClientContactHttpService from "../../../Http/ClientContact/ClientContact.http.service";
import { ClientContactDTO } from "../../../Types/ClientContact";
import { SelectInput } from "../../UI/InputFields/SelectInput";
import { Gender, SelectOption } from "../../../Types/Common";
import { VentureClientHttpService } from "../../../Http/VentureClient/VentureClient.http.service";
import { useCodependentSelectValues } from "../../ProjectKanban/useCodependentSelectValues";
import { BusinessUnitHttpService } from "../../../Http/BusinessUnit/BusinessUnit.http.service";
import CloseIcon from "@mui/icons-material/Close";
import useRoles from "../../../Hooks/useRoles";

interface CreateContactModalProps {
  setModalOpen: (state: boolean) => void;
  modalOpen: boolean;
  handleSave: () => void;
  contact: ClientContactDTO;
}

const genders = ["male", "female", "non-binary"];

export default function EditClientContactModal(
  props: CreateContactModalProps
): ReactElement {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { isExternalUser } = useRoles();
  const [contact, setContact] = useState<ClientContactDTO>();
  const [ventureClientSelectOptions, setVentureClientSelectOptions] = useState<
    SelectOption[]
  >([]);

  useEffect(() => {
    setContact({ ...props.contact });
    setVentureClient({
      ...props.contact.businessUnit?.ventureClient,
    });
    if (isExternalUser) return;
    VentureClientHttpService.getVentureClients().then((results) => {
      const ventureClientSelectOptions = results.map((ventureClient) => ({
        id: ventureClient.id,
        name: ventureClient.name,
      }));
      setVentureClientSelectOptions(ventureClientSelectOptions);
    });
  }, [props.contact]);

  const handleUpdateContact = async () => {
    if (contact) {
      await ClientContactHttpService.updateContact(contact, props.contact.id);
      props.handleSave();
      props.setModalOpen(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    if (contact) {
      setContact({ ...contact, [id]: value });
    }
  };

  const handleGenderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (contact) {
      setContact({ ...contact, gender: e.target.value as Gender });
    }
  };

  const [ventureClient, setVentureClient, businessUnitSelectOptions] =
    useCodependentSelectValues(
      {
        id: "",
        name: "",
      },
      BusinessUnitHttpService.getBusinessUnits
    );

  const handleSelectBusinessUnit = (id: string) => {
    const buId = parseInt(id);
    if (contact && buId) {
      setContact({
        ...contact,
        businessUnitId: buId,
      });
      return;
    }
  };

  const handleSelectVentureClient = (id: string) => {
    const vcId = parseInt(id);
    for (let index = 0; index < ventureClientSelectOptions.length; index++) {
      if (vcId === ventureClientSelectOptions[index].id) {
        setVentureClient(ventureClientSelectOptions[index]);
        setVentureClient(ventureClientSelectOptions[index]);
        return;
      }
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      fullScreen={fullScreen}
      open={props.modalOpen}
      id="client-edit-view"
      data-testid="client-edit-view"
    >
      <Stack gap={4}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            ...theme.typography.h3,
          }}
        >
          Edit Contact
          <IconButton
            sx={{ color: "icon.primary" }}
            onClick={() => props.setModalOpen(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {contact && (
            <Stack gap={4}>
              <Stack gap={2}>
                <Typography variant="subtitle1">
                  Personal Information
                </Typography>
                <Box display="flex" gap={2}>
                  <TextField
                    label="Name"
                    id="name"
                    variant="outlined"
                    required
                    fullWidth
                    value={contact.name}
                    onChange={handleInputChange}
                  />
                  <TextField
                    label="Gender"
                    id="gender"
                    variant="outlined"
                    fullWidth
                    select
                    value={contact.gender}
                    onChange={handleGenderChange}
                  >
                    {genders.map((gender) => (
                      <MenuItem key={gender} value={gender}>
                        {gender}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Stack>

              <Stack gap={2}>
                <Typography variant="subtitle1">Company Information</Typography>
                <Box display="flex" gap={2}>
                  <TextField
                    label="Title"
                    id="title"
                    variant="outlined"
                    fullWidth
                    required
                    value={contact.title}
                    onChange={handleInputChange}
                  />
                  <TextField
                    label="Department Code"
                    id="departmentCode"
                    variant="outlined"
                    fullWidth
                    value={contact.departmentCode}
                    onChange={handleInputChange}
                  />
                </Box>
                <Grid container spacing={2}>
                  {!isExternalUser && (
                    <Grid item xs={6}>
                      <SelectInput
                        label="Venture Client"
                        selectValues={ventureClientSelectOptions}
                        value={
                          ventureClientSelectOptions.length
                            ? ventureClient.id
                            : ""
                        }
                        onChange={(e) =>
                          handleSelectVentureClient(e.target.value)
                        }
                        editMode={true}
                        required
                        fullWidth
                        ignoreValidation
                      />
                    </Grid>
                  )}

                  <Grid item xs={6}>
                    <SelectInput
                      label="Organizational Unit"
                      selectValues={businessUnitSelectOptions}
                      value={
                        businessUnitSelectOptions.length
                          ? contact.businessUnitId
                          : ""
                      }
                      onChange={(e) => handleSelectBusinessUnit(e.target.value)}
                      editMode={true}
                      disabled={
                        contact.businessUnit.ventureClient.id === undefined
                      }
                      required
                      fullWidth
                      ignoreValidation
                    />
                  </Grid>
                </Grid>
              </Stack>

              <Stack gap={2}>
                <Typography variant="subtitle1">Contact</Typography>
                <Box display="flex" gap={2}>
                  <TextField
                    label="Phone Number"
                    id="phone"
                    variant="outlined"
                    fullWidth
                    value={contact.phone}
                    onChange={handleInputChange}
                  />
                  <TextField
                    label="Email"
                    id="email"
                    variant="outlined"
                    required
                    fullWidth
                    value={contact.email}
                    onChange={handleInputChange}
                  />
                </Box>
              </Stack>
            </Stack>
          )}
        </DialogContent>
        <DialogActions sx={{ mt: 0 }}>
          <Button
            onClick={() => props.setModalOpen(false)}
            id="close-client-edit-view"
          >
            Cancel
          </Button>
          <Button
            onClick={handleUpdateContact}
            variant="contained"
            id="save-client-edit-view"
          >
            Save
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
}
