import { Box, Typography, styled } from "@mui/material";
import theme from "../../../theme";
import { ReactElement, useState } from "react";
import { ContentCopy, MailOutline, PhonelinkRing } from "@mui/icons-material";

const ContactCopyWrapper = styled(Box)(() => ({
  display: "flex",
  width: "fit-content",
  gap: theme.spacing(1),
  color: theme.palette.text.action.main,
  alignItems: "center",
  cursor: "pointer",
  fontSize: theme.spacing(2),
}));

interface ContactCopyProps {
  value: string;
  variant: "phone" | "email";
}

const ContactCopy = ({ value, variant }: ContactCopyProps): ReactElement => {
  const [hovering, setHovering] = useState(false);
  const icon =
    variant === "phone" ? (
      <PhonelinkRing fontSize="inherit" />
    ) : (
      <MailOutline fontSize="inherit" />
    );

  return (
    <ContactCopyWrapper
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      onClick={() => {
        navigator.clipboard.writeText(value);
      }}
    >
      {hovering ? (
        <ContentCopy fontSize="inherit" />
      ) : (
        <Box display="flex" color="icon.primary">
          {icon}
        </Box>
      )}
      <Typography variant="body2">{value}</Typography>
    </ContactCopyWrapper>
  );
};

export default ContactCopy;
