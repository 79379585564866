import { Box, Chip, Typography, styled, IconButton } from "@mui/material";
import { ReactElement, useState } from "react";
import EditPhaseModal from "./EditPhaseModal";
import ViewPhaseModal from "./ViewPhaseModal";
import Phase from "../../../../../Types/Phase";
import theme from "../../../../../theme";
import { getApproximateDuration } from "./ProjectTimeline";
import { EditOutlined } from "@mui/icons-material";

const PhaseColumnWrapper = styled(Box)(() => ({
  display: "flex",
  height: "56px",
}));

const PhaseColumn = styled(Box)(() => ({
  width: "45%",
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  position: "relative",
  padding: theme.spacing(2),
  cursor: "pointer",
}));

const ProgressBarWrapper = styled("span")(() => ({
  width: "10%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const ProgressBar = styled("span")(({ theme }) => ({
  width: "1px",
  height: "150%",
  backgroundColor: theme.palette.borderOutline.main,
}));

const DurationDisplay = styled(Chip)(() => ({
  border: `1px solid ${theme.palette.borderOutline.main}`,
  borderRadius: theme.shape.radius.minimal,
  backgroundColor: "transparent",
  height: "24px",
  color: theme.palette.grey[600],
  fontSize: theme.typography.caption.fontSize,
  pointerEvents: "none",
}));

const PhaseName = styled(Typography)(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  color: theme.palette.grey[600],
}));

const hasPhaseInfo = (obj: Phase) => {
  const keysToCheck = ["name", "description"] as const;
  return keysToCheck.some((key) => !!obj[key]);
};

interface Props {
  phase: Phase;
  index: number;
  handlePhaseEdit: (phase: Phase) => void;
}

const PhaseRow = (props: Props): ReactElement => {
  const [isEditPhaseModalOpen, setIsEditPhaseModalOpen] = useState(false);
  const [isViewPhaseModalOpen, setIsViewPhaseModalOpen] = useState(false);

  const handleManageModalOpen = () => {
    if (hasPhaseInfo(props.phase)) {
      setIsViewPhaseModalOpen(true);
    } else {
      setIsEditPhaseModalOpen(true);
    }
  };

  return (
    <>
      <PhaseColumnWrapper>
        <PhaseColumn sx={{ cursor: "default" }}></PhaseColumn>
        <ProgressBarWrapper>
          <ProgressBar />
        </ProgressBarWrapper>
        <PhaseColumn
          onClick={handleManageModalOpen}
          data-testid={`phase-row-${props.phase.id}`}
        >
          <DurationDisplay
            size="medium"
            label={
              <Typography variant="caption">
                {getApproximateDuration(props.phase.duration)}
              </Typography>
            }
          />

          <Box display="flex" alignItems="center">
            <PhaseName variant="subtitle2" color="text.mediumEmphasis">
              Phase {props.index}
            </PhaseName>
            &nbsp;
            <Typography variant="body1" color="text.mediumEmphasis">
              {props.phase.name ? "- " + props.phase.name : ""}
            </Typography>
          </Box>
          <IconButton color="inherit" sx={{ ml: "auto" }}>
            <EditOutlined />
          </IconButton>
        </PhaseColumn>
      </PhaseColumnWrapper>
      {isEditPhaseModalOpen && (
        <EditPhaseModal
          setModalOpen={setIsEditPhaseModalOpen}
          modalOpen={isEditPhaseModalOpen}
          phase={props.phase}
          handlePhaseEdit={props.handlePhaseEdit}
          index={props.index}
        />
      )}
      {isViewPhaseModalOpen && (
        <ViewPhaseModal
          index={props.index}
          setModalOpen={setIsViewPhaseModalOpen}
          modalOpen={isViewPhaseModalOpen}
          phase={props.phase}
          handlePhaseEdit={props.handlePhaseEdit}
        />
      )}
    </>
  );
};

export default PhaseRow;
