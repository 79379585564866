import { Stack } from "@mui/material";
import { ReactElement } from "react";
import ProjectTeamLeadersSection from "./ProjectTeamLeadersSection/ProjectTeamLeadersSection";
import theme from "../../../../theme";
import ProjectTasksSection from "./ProjectTasks/ProjectTasksSection";
import { ProjectFileSection } from "./FilesComponent/ProjectFilesSection";
import { FunnelStage, Project } from "../../../../Types/Project";
import {
  disabledFileTypes,
  opportunityFileTypes,
  projectFileTypes,
} from "./FilesComponent/FileTypes";
import ProjectStatus from "./ProjectStatus/ProjectStatus";
import LeadSection from "./LeadSection/LeadSection";

interface ProjectOverviewProps {
  project: Project;
  funnelStage: FunnelStage;
  handleSave: () => void;
  moveProjectToTheNewStage: () => Promise<void>;
}

export default function ProjectOverview(
  props: ProjectOverviewProps
): ReactElement {
  return (
    <Stack width={theme.spacing(39)} gap={3}>
      <ProjectTeamLeadersSection
        project={props.project}
        handleSave={props.handleSave}
      />
      {props.project.leadProject && (
        <LeadSection leadProject={props.project.leadProject} />
      )}
      <ProjectStatus project={props.project} handleSave={props.handleSave} />
      <ProjectTasksSection
        funnelStage={props.funnelStage}
        project={props.project}
        moveProjectToTheNewStage={props.moveProjectToTheNewStage}
      />
      <ProjectFileSection
        stage={props.funnelStage}
        handleSave={props.handleSave}
        project={props.project}
        opportunityFileTypes={opportunityFileTypes[props.funnelStage]}
        projectFileTypes={projectFileTypes[props.funnelStage]}
        disabledFileTypes={disabledFileTypes[props.funnelStage]}
      />
    </Stack>
  );
}
