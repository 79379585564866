import { MenuItem, TextField, Typography } from "@mui/material";
import { Fragment, ReactElement, useState } from "react";
import { LeadProject } from "../../../Types/LeadProject";
import { useSnackbar } from "notistack";
import { FileHttpService } from "../../../Http/File/File.http.service";
import theme from "../../../theme";
import { isProjectBmwCheck } from "../../../utils";
import { FileDropzone } from "../../ProjectDetails/SharedComponents/ProjectOverview/FilesComponent/Dropzone";

interface LeadProjectFileUploadSectionProps {
  leadProject: LeadProject;
  projectFileTypes: string[];
  opportunityFileTypes: string[];
  handleSave: () => void;
  setLastUploadFile: (id: number) => void;
}

export function LeadProjectFileUploadSection(
  props: LeadProjectFileUploadSectionProps
): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const [fileType, setFileType] = useState<string>("");
  const [selectedOpportunityId, setSelectedOpportunityId] = useState(0);

  const getFileType = (): string => {
    if (fileType === "Other") {
      return "Other";
    } else {
      return fileType;
    }
  };

  const isOpportunityFile = (): boolean => {
    return !!props.opportunityFileTypes?.includes(fileType);
  };

  const showOpportunityDropdown = (): boolean => {
    return !!props.opportunityFileTypes?.includes(fileType);
  };

  const getLinkableId = (): number => {
    if (isOpportunityFile()) {
      return selectedOpportunityId;
    } else {
      return props.leadProject.id;
    }
  };

  const uploadFile = async (file: File): Promise<void> => {
    try {
      await FileHttpService.upload(
        file,
        getLinkableId(),
        isOpportunityFile() ? "leadOpportunity" : "leadProject",
        getFileType()
      ).catch(() => {
        enqueueSnackbar("File can not be uploaded", {
          variant: "error",
        });
      });

      if (isOpportunityFile()) {
        props.setLastUploadFile(selectedOpportunityId);
      }

      setFileType("");
      setSelectedOpportunityId(0);
      props.handleSave();
    } catch (error) {
      enqueueSnackbar("File can not be uploaded", {
        variant: "error",
      });
    }
  };

  const showDropZone = (): boolean => {
    if (!fileType) {
      return false;
    } else if (!showOpportunityDropdown()) {
      return true;
    }
    return !!(showOpportunityDropdown() && selectedOpportunityId);
  };

  return (
    <Fragment>
      <Typography variant="subtitle1">Upload File</Typography>
      <TextField
        label="File Type to Upload"
        name="File Type to Upload"
        id="fileType"
        variant="outlined"
        select
        fullWidth
        value={fileType}
        onChange={(e) => setFileType(e.target.value as string)}
      >
        {props.opportunityFileTypes.map((option) => (
          <MenuItem value={option} key={option}>
            {option}
          </MenuItem>
        ))}
        {props.projectFileTypes.map((option) => (
          <MenuItem value={option} key={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      {showOpportunityDropdown() && (
        <TextField
          label="Select Startup"
          id="startupSelect"
          variant="outlined"
          select
          fullWidth
          value={selectedOpportunityId || ""}
          sx={{ marginTop: theme.spacing(2) }}
          onChange={(e) => setSelectedOpportunityId(parseInt(e.target.value))}
        >
          {props.leadProject.opportunities.map((opportunity) => (
            <MenuItem
              value={opportunity.id}
              key={opportunity.id}
              data-cy={
                "MenuItem-" + opportunity.startup.name.replaceAll(" ", "_")
              }
            >
              {opportunity.startup.name}
            </MenuItem>
          ))}
        </TextField>
      )}
      {showDropZone() && (
        <FileDropzone
          addFile={uploadFile}
          isBmwProject={isProjectBmwCheck(props.leadProject.ventureClient.id)}
        />
      )}
    </Fragment>
  );
}
