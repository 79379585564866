import { Box, Chip, Paper, Stack, styled, Typography } from "@mui/material";
import { ReactElement, useState } from "react";
import theme from "../../../theme";
import { Project, ProjectLeadAssociation } from "../../../Types/Project";
import { formatDate } from "../../../utils";
import ProjectDetailsModal from "../../UI/Modals/ProjectDetailsModal/ProjectDetailsModal";

interface ProjectsLaunchProps {
  projects: ProjectLeadAssociation[];
}

export default function ProjectsLaunched(
  props: ProjectsLaunchProps
): ReactElement {
  const [projectModalOpen, setProjectModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState<number | null>(null);

  return (
    <>
      <Paper sx={{ p: 3 }}>
        <Stack>
          <Box display="flex" gap={1} alignItems="center" mb={3}>
            <Typography variant="h6">Projects Launched</Typography>
            <Chip
              variant="counter"
              color="info"
              label={props.projects.length}
              data-testid="project-count"
            />
          </Box>
          {!props.projects.length && (
            <Typography color="text.disabled">
              No projects started from this lead yet.
            </Typography>
          )}
          <Stack gap={2}>
            {props.projects.map((project, index) => (
              <ProjectCard
                key={index}
                project={project}
                setModalOpen={setProjectModalOpen}
                setSelectedProject={setSelectedProject}
              />
            ))}
          </Stack>
        </Stack>
      </Paper>
      {selectedProject && projectModalOpen && (
        <ProjectDetailsModal
          projectId={selectedProject}
          modalOpen={true}
          setModalOpen={setProjectModalOpen}
        />
      )}
    </>
  );
}

interface ProjectCardProps {
  project: Project;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedProject: React.Dispatch<React.SetStateAction<number | null>>;
}

const ProjectCard = (props: ProjectCardProps) => {
  return (
    <>
      <Stack
        gap={2}
        p={2}
        bgcolor="background.default"
        borderRadius={theme.shape.radius.minimal}
      >
        <Stack gap={0.5}>
          <ProjectName
            onClick={() => {
              props.setSelectedProject(props.project.id);
              props.setModalOpen(true);
            }}
          >
            {props.project.name}
          </ProjectName>
          <Typography variant="body2" color="text.mediumEmphasis">
            {formatDate(props.project.dateCreated)}
          </Typography>
        </Stack>
        <Typography variant="body2">
          {props.project.businessUnit.name}
        </Typography>
      </Stack>
    </>
  );
};

const ProjectName = styled(Typography)(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  color: theme.palette.text.action.main,
  cursor: "pointer",
  ...theme.typography.subtitle2,
}));
