import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ReactElement } from "react";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { BusinessUnit } from "../../../../Types/BusinessUnit";

interface BusinessUnitDetailsModalProps {
  businessUnit: BusinessUnit;
  handleModalClose: () => void;
  handleEditModal: () => void;
  modalOpen: boolean;
}
function BusinessUnitDetailsModal(
  props: BusinessUnitDetailsModalProps
): ReactElement {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.modalOpen}
      onClose={props.handleModalClose}
      id="business-unit-details-view"
      PaperProps={{
        sx: {
          gap: theme.spacing(4),
        },
      }}
    >
      <DialogTitle>{props.businessUnit.name}</DialogTitle>
      <DialogContent sx={{ minWidth: 600 }}>
        <Typography>{props.businessUnit.shortDescription}</Typography>
        <Box display="flex" flexDirection="row" gap={3} mb={2} mt={2}>
          <LocalPhoneIcon />
          <Typography>
            {props.businessUnit.mainContact?.name}
            {props.businessUnit.mainContact?.departmentCode &&
              ` (${props.businessUnit.mainContact?.departmentCode})`}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.handleModalClose}
          color="primary"
          id="close-business-unit-details-view"
        >
          Close
        </Button>
        <Button
          variant="contained"
          onClick={props.handleEditModal}
          color="primary"
          id="edit-business-unit-details-view"
        >
          Edit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default BusinessUnitDetailsModal;
