import { OpenInNew, RocketLaunchOutlined } from "@mui/icons-material";
import {
  Paper,
  Stack,
  Box,
  Typography,
  Chip,
  Link,
  styled,
} from "@mui/material";
import theme from "../../../theme";
import {
  findLogo,
  getDateMonthsAfter,
  normalizeUrl,
  trimStageName,
} from "../../../utils";
import { ReactElement } from "react";
import { LeadOpportunity } from "../../../Types/LeadProject";
import FadingDeleteButton from "./FadingDeleteButton";
import QualityVerificationIcon from "../../Startup/DetailsPage/QualityVerification/QualityVerificationIcon";

const Container = styled(Paper)(() => ({
  position: "relative",
  "&:hover .deleteIcon": {
    opacity: 1,
  },
  "&:hover .qualityIcon": {
    transform: "translateX(-24px)",
  },
}));

const StyledCardContent = styled(Stack)(() => ({
  gap: theme.spacing(2),
  height: "327px",
  width: "197px",
  minWidth: "197px",
  padding: theme.spacing(2),
  borderRadius: theme.shape.radius.cardSmall,
  backgroundColor: theme.palette.surface.primary.main,
}));

const StartupName = styled(Typography)({
  display: "-webkit-box",
  overflow: "hidden",
  textOverflow: "ellipsis",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  ...theme.typography.subtitle2,
});

const Website = styled(Typography)({
  color: "text.action.main",
  overflow: "hidden",
  textOverflow: "ellipsis",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
  display: "-webkit-box",
});

const ProjectsCounter = styled(Box)({
  backgroundColor: theme.palette.surface.secondary.main,
  padding: theme.spacing(1, 2),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: theme.shape.radius.minimal,
});

const ShortDescription = styled(Typography)(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  color: theme.palette.text.mediumEmphasis,
  ...theme.typography.body2,
}));

const DeleteContainer = styled(Box)(() => ({
  position: "absolute",
  top: theme.spacing(3),
  right: theme.spacing(1.5),
  opacity: 0,
  transition: "opacity 0.3s, right 0.3s",
}));

const ValidationContainer = styled(Stack)(() => ({
  transition: "transform 0.3s",
}));

interface Props {
  leadOpportunity: LeadOpportunity;
  deleteProjectLeadOpportunity: (startupId: number) => void;
}

const LeadProjectStartupCard = (props: Props): ReactElement => {
  const { startup } = props.leadOpportunity;
  const logo = findLogo(startup?.files);

  const isVerified = startup.qualityChecks?.length == 4;
  const expiryDate = startup.lastQualityCheckDate
    ? getDateMonthsAfter(startup.lastQualityCheckDate, 12)
    : new Date();
  const hasExpired =
    (startup.lastQualityCheckDate && isVerified && expiryDate < new Date()) ??
    false;

  return (
    <Container>
      <a
        href={`/startups/${startup.id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <StyledCardContent
          data-testid={`lead-opportunity-${props.leadOpportunity.id}`}
        >
          <Box
            minHeight={40}
            height={40}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {logo ? (
              <img
                src={logo}
                alt="startup logo"
                style={{
                  objectFit: "contain",
                  maxHeight: "100%",
                  maxWidth: "110px",
                }}
              />
            ) : (
              <RocketLaunchOutlined color="disabled" fontSize="large" />
            )}
            <ValidationContainer className="qualityIcon">
              <QualityVerificationIcon
                lastQualityCheckBy={startup.lastQualityCheckBy}
                lastQualityCheckDate={startup.lastQualityCheckDate}
                verified={isVerified}
                hasExpired={hasExpired}
              />
            </ValidationContainer>
          </Box>

          <Stack height="100%">
            <Box display="flex" justifyContent="space-between">
              <StartupName>{startup.name}</StartupName>
              {startup.dateFounded !== null && (
                <Typography
                  variant="caption"
                  color="brand.accessibility"
                  pt={0.25}
                >
                  {startup.dateFounded.getFullYear()}
                </Typography>
              )}
            </Box>

            <ShortDescription>{startup.shortDescription}</ShortDescription>
            <Stack gap={0.5} pt={0.5}>
              <Box display="flex" alignItems="center">
                <Typography variant="caption">
                  {`${startup.billingCountry} ${
                    startup.billingCity ? `- ${startup.billingCity}` : ""
                  }`}
                </Typography>
              </Box>
              {startup.website && (
                <Box>
                  <Link
                    href={normalizeUrl(startup.website)}
                    target="_blank"
                    rel="noopener noreferrer"
                    display="flex"
                    alignItems="center"
                    gap={0.5}
                    width="fit-content"
                  >
                    <OpenInNew sx={{ fontSize: 16 }} />
                    <Website>Website</Website>
                  </Link>
                </Box>
              )}
            </Stack>
          </Stack>
          <Stack mt="auto" gap={2}>
            <Stack>
              <Box display="flex" justifyContent="space-between" gap={0.5}>
                <Typography variant="caption">Funding</Typography>
                <Typography variant="caption" color="text.brand.accessibility">
                  {startup.fundingIsUndisclosed
                    ? "Undisclosed"
                    : startup.totalFunding !== null
                    ? `$${startup.totalFunding}M`
                    : "$0.00M"}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" gap={0.5}>
                <Typography variant="caption">Stage</Typography>
                <Typography variant="caption" color="text.brand.accessibility">
                  {trimStageName(startup.currentInvestmentStage) || "--"}
                </Typography>
              </Box>
            </Stack>
            <ProjectsCounter>
              <Typography variant="body2">Projects</Typography>
              <Chip
                variant="counter"
                color="info"
                label={
                  startup.opportunities.length +
                  startup.leadOpportunities.length
                }
              />
            </ProjectsCounter>
          </Stack>
        </StyledCardContent>
      </a>
      <DeleteContainer className="deleteIcon">
        <FadingDeleteButton
          id={startup.id}
          onDelete={() =>
            props.deleteProjectLeadOpportunity(props.leadOpportunity.id)
          }
        />
      </DeleteContainer>
    </Container>
  );
};

export default LeadProjectStartupCard;
