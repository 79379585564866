import axios from "axios";
import {
  CreateProjectDTO,
  GenericProject,
  Project,
  ProjectDTO,
  ProjectFilterCriteria,
} from "../../Types/Project";
import ProjectMapper from "./Project.mapper";

export class ProjectHttpService {
  public static async getProjects(
    filterCriteria: ProjectFilterCriteria
  ): Promise<Project[]> {
    const response = await axios.get<ProjectDTO[]>(`/api/projects`, {
      params: {
        filterCriteria: JSON.stringify(filterCriteria),
      },
    });
    return response.data.map((project) => ProjectMapper.map(project));
  }

  public static async getProjectById(
    id: string | number,
    representation?: string
  ): Promise<Project> {
    const response = await axios.get<ProjectDTO>(`/api/projects/${id}`, {
      params: {
        representation,
      },
    });
    return ProjectMapper.map(response.data);
  }

  public static async createProject(
    project: CreateProjectDTO
  ): Promise<number> {
    const response = await axios.post<number>("/api/projects", project);
    return response.data;
  }

  public static async updateProject(project: Partial<Project>): Promise<void> {
    await axios.put<void>(`/api/projects/${project.id}`, project);
  }

  public static async getAllGenericProjects(
    searchValue?: string
  ): Promise<GenericProject[]> {
    const response = await axios.get<GenericProject[]>(`/api/projects`, {
      params: {
        fetchAll: true,
        ...(searchValue ? { searchValue } : {}),
      },
    });

    const sortedData = response.data.map((history) => ({
      ...history,
      projectHistoryLatestChange: history.projectHistoryLatestChange.sort(
        (a, b) =>
          new Date(b.dateTriggered).getTime() -
          new Date(a.dateTriggered).getTime()
      ),
      projectHistoryStageChange: history.projectHistoryStageChange.sort(
        (a, b) =>
          new Date(b.dateTriggered).getTime() -
          new Date(a.dateTriggered).getTime()
      ),
    }));

    return sortedData;
  }
}
