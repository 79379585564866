import {
  DataGridPremiumProps,
  GridColDef,
  GridValueGetterParams,
  GridInitialState,
} from "@mui/x-data-grid-premium";
import { ReactElement, useEffect } from "react";
import { Opportunity } from "../../Types/Opportunity";
import useRoles from "../../Hooks/useRoles";
import { formatDate, getImpactValueByFunnelStage } from "../../utils";
import { FocusArea } from "../../Types/VentureClient";
import { ClientContactDTO } from "../../Types/ClientContact";
import { ProjectHistoryStageChange } from "../../Types/Project";
import { Meeting } from "../../Types/Meeting";
import CustomDataGrid from "./CustomDataGrid";
import { ProjectHistory } from "../../Types/ProjectHistory";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";

interface ProjectsDataGrid extends Omit<DataGridPremiumProps, "columns"> {
  apiRef: React.MutableRefObject<GridApiPremium>;
  gridState: GridInitialState | null;
}

type GridColumn = GridColDef & {
  hidden?: boolean;
};

export default function ProjectsDataGrid(
  props: ProjectsDataGrid
): ReactElement {
  const { apiRef, paginationModel } = props;
  const { isExternalUser } = useRoles();

  useEffect(() => {
    if (props.gridState) {
      apiRef.current.restoreState(props.gridState);
    }
  }, [apiRef, props.gridState]);

  const baseColumns: GridColumn[] = [
    {
      flex: 0.5,
      field: "id",
      type: "number",
      headerName: "Project ID",
    },
    {
      flex: 1,
      field: "name",
      headerName: "Project Name",
      editable: false,
    },
    {
      flex: 1,
      field: "shortDescription",
      headerName: "Short Description",
    },
    {
      flex: 0.75,
      field: "ventureClientName",
      headerName: "Venture Client",
      hidden: isExternalUser,
      valueGetter: (params: GridValueGetterParams) => {
        if (
          !params.row.businessUnit ||
          !params.row.businessUnit.ventureClient
        ) {
          return "";
        }
        return `${params.row.businessUnit.ventureClient.name}`;
      },
    },
    {
      flex: 0.5,
      field: "businessUnit",
      headerName: "Organizational Unit",
      valueGetter: (params: GridValueGetterParams) => {
        if (!params.row.businessUnit) {
          return "";
        }
        return `${params.row.businessUnit.name}`;
      },
    },
    {
      flex: 1,
      field: "focusAreas",
      headerName: "Focus Areas",
      valueGetter: (params: GridValueGetterParams) => {
        if (!params.row.focusAreas) {
          return "";
        }
        return `${params.row.focusAreas
          .map((focusArea: FocusArea) => focusArea.name)
          .join(", ")}`;
      },
    },
    {
      flex: 0.5,
      field: "funnelStage",
      headerName: "Stage",
    },
    {
      flex: 0.5,
      field: "status",
      headerName: "Status",
    },
    {
      flex: 1,
      field: "projectOwnerName",
      headerName: "Project Owner",
      valueGetter: (params: GridValueGetterParams) => {
        if (!params.row.projectOwner) {
          return "";
        }
        return `${params.row.projectOwner.name}`;
      },
    },
    {
      flex: 1,
      field: "projectLeader",
      headerName: "Project Leader",
      valueGetter: (params: GridValueGetterParams) => {
        if (!params.row.projectLeader) {
          return "";
        }
        const departmentCode = params.row.projectLeader.departmentCode
          ? ` (${params.row.projectLeader.departmentCode})`
          : "";
        return `${params.row.projectLeader.name}${departmentCode}`;
      },
    },
    {
      flex: 1,
      field: "programManager",
      headerName: "Program Manager",
      valueGetter: (params: GridValueGetterParams) => {
        if (!params.row.programManager) {
          return "";
        }
        const departmentCode = params.row.programManager.departmentCode
          ? ` (${params.row.programManager.departmentCode})`
          : "";
        return `${params.row.programManager.name}${departmentCode}`;
      },
    },
    {
      flex: 1,
      field: "adoptionOwner",
      headerName: "Adoption Owner",
      valueGetter: (params: GridValueGetterParams) => {
        if (!params.row.adoptionOwner) {
          return "";
        }
        const departmentCode = params.row.adoptionOwner.departmentCode
          ? ` (${params.row.adoptionOwner.departmentCode})`
          : "";
        return `${params.row.adoptionOwner.name}${departmentCode}`;
      },
    },
    {
      flex: 1,
      field: "projectSponsor",
      headerName: "Project Sponsor",
      valueGetter: (params: GridValueGetterParams) => {
        if (!params.row.projectSponsor) {
          return "";
        }
        const departmentCode = params.row.projectSponsor.departmentCode
          ? ` (${params.row.projectSponsor.departmentCode})`
          : "";
        return `${params.row.projectSponsor.name}${departmentCode}`;
      },
    },
    {
      flex: 1,
      field: "otherStakeholders",
      headerName: "Other Stakeholders",
      valueGetter: (params: GridValueGetterParams) => {
        if (!params.row.otherStakeholders) {
          return "";
        }
        return `${params.row.otherStakeholders
          .map((otherStakeholder: ClientContactDTO) => {
            const departmentCode = otherStakeholder.departmentCode
              ? ` (${otherStakeholder.departmentCode})`
              : "";
            return otherStakeholder.name + departmentCode;
          })
          .join(", ")}`;
      },
    },
    {
      flex: 0.5,
      field: "discoveredStartups",
      type: "number",
      headerName: "Discovered Startups",
      valueGetter: (params: GridValueGetterParams) =>
        params.row.opportunities.length,
    },
    {
      flex: 1,
      field: "assessedStartups",
      headerName: "Assessed Startups",
      type: "number",
      valueGetter: (params: GridValueGetterParams) =>
        params.row.opportunities.filter(
          (opportunity: Opportunity) => opportunity.isQualified
        ).length,
    },
    {
      flex: 1,
      field: "selectedStartup",
      headerName: "Selected Startup",
      valueGetter: (params: GridValueGetterParams) =>
        params.row.opportunities.find(
          (opportunity: Opportunity) => opportunity.isSelectedForPilot === true
        )?.startup.name,
    },
    {
      flex: 1,
      field: "selectedStartupCountry",
      headerName: "Selected Startup Country",
      valueGetter: (params: GridValueGetterParams) => {
        if (!params.row.opportunities) {
          return "";
        }
        const selectedOpportunity: Opportunity = params.row.opportunities.find(
          (opp: Opportunity) => opp.isSelectedForPilot
        );

        if (!selectedOpportunity) {
          return "";
        }
        return `${selectedOpportunity.startup.billingCountry}`;
      },
    },
    {
      flex: 1,
      field: "impactType",
      headerName: "Impact Type",
    },
    {
      flex: 0.5,
      field: "impactValue",
      headerName: "Impact Value",
      type: "number",
      valueGetter: (params: GridValueGetterParams) => {
        const impactValue = getImpactValueByFunnelStage(
          params.row.funnelStage,
          params.row.impactValues
        );
        return impactValue?.value;
      },
    },
    {
      flex: 1,
      field: "strategicBenefits",
      headerName: "Intangible Benefits",
    },
    {
      flex: 0.5,
      field: "dateCreated",
      headerName: "Creation Date",
      type: "date",
      valueGetter: (params: GridValueGetterParams) => new Date(params.value),
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      flex: 1,
      field: "lastStageChange",
      type: "date",
      headerName: "Last Stage Change",
      valueGetter: (params: GridValueGetterParams) =>
        params.row.projectHistoryStageChange?.[0]?.dateTriggered
          ? new Date(params.row.projectHistoryStageChange[0].dateTriggered)
          : null,
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      flex: 1,
      field: "onHoldDate",
      headerName: "Last Put On Hold",
      type: "date",
      valueGetter: (params: GridValueGetterParams) => {
        if (!params.row.projectHistoryLatestChange) {
          return null;
        }
        const onHoldDate = params.row.projectHistoryLatestChange.find(
          (change: ProjectHistory) => change.newValue === "on hold"
        )?.dateTriggered;
        return onHoldDate ? new Date(onHoldDate) : null;
      },
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      flex: 1,
      field: "archivedDate",
      headerName: "Last Put on Archived",
      type: "date",
      valueGetter: (params: GridValueGetterParams) => {
        if (!params.row.projectHistoryLatestChange) {
          return null;
        }
        const archivedDate = params.row.projectHistoryLatestChange.find(
          (change: ProjectHistory) => change.newValue === "archived"
        )?.dateTriggered;
        return archivedDate ? new Date(archivedDate) : null;
      },
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      flex: 1,
      field: "discoverDate",
      headerName: "First Moved to Discover",
      type: "date",
      valueGetter: (params: GridValueGetterParams) => {
        if (!params.row.projectHistoryStageChange) {
          return null;
        }
        const discoverDate = params.row.projectHistoryStageChange.findLast(
          (change: ProjectHistoryStageChange) => change.newValue === "discover"
        )?.dateTriggered;
        return discoverDate ? new Date(discoverDate) : null;
      },
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      flex: 1,
      field: "assessDate",
      headerName: "First Moved to Assess",
      type: "date",
      valueGetter: (params: GridValueGetterParams) => {
        if (!params.row.projectHistoryStageChange) {
          return null;
        }
        const assessDate = params.row.projectHistoryStageChange.findLast(
          (change: ProjectHistoryStageChange) => change.newValue === "assess"
        )?.dateTriggered;
        return assessDate ? new Date(assessDate) : null;
      },
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      flex: 1,
      field: "buyDate",
      headerName: "First Moved to Buy",
      type: "date",
      valueGetter: (params: GridValueGetterParams) => {
        if (!params.row.projectHistoryStageChange) {
          return null;
        }
        const buyDate = params.row.projectHistoryStageChange.findLast(
          (change: ProjectHistoryStageChange) => change.newValue === "buy"
        )?.dateTriggered;
        return buyDate ? new Date(buyDate) : null;
      },
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      flex: 1,
      field: "pilotDate",
      headerName: "First Moved to Pilot",
      type: "date",
      valueGetter: (params: GridValueGetterParams) => {
        if (!params.row.projectHistoryStageChange) {
          return null;
        }
        const pilotDate = params.row.projectHistoryStageChange.findLast(
          (change: ProjectHistoryStageChange) => change.newValue === "pilot"
        )?.dateTriggered;
        return pilotDate ? new Date(pilotDate) : null;
      },
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      flex: 1,
      field: "adoptDate",
      headerName: "First Moved to Adopt",
      type: "date",
      valueGetter: (params: GridValueGetterParams) => {
        if (!params.row.projectHistoryStageChange) {
          return null;
        }
        const adoptDate = params.row.projectHistoryStageChange.findLast(
          (change: ProjectHistoryStageChange) => change.newValue === "adopt"
        )?.dateTriggered;
        return adoptDate ? new Date(adoptDate) : null;
      },
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      flex: 1,
      field: "pilotKickoffDate",
      headerName: "Pilot Kickoff Date",
      type: "date",
      valueGetter: (params: GridValueGetterParams) => {
        if (!params.row.meetings) {
          return null;
        }
        const kickoffMeeting = params.row.meetings.find(
          (meeting: Meeting) => meeting.type === "Kick-off"
        );
        return kickoffMeeting?.dateStart
          ? new Date(kickoffMeeting.dateStart)
          : null;
      },
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      flex: 1,
      field: "pilotConclusionDate",
      headerName: "Pilot Conclusion Date",
      type: "date",
      valueGetter: (params: GridValueGetterParams) => {
        if (!params.row.meetings) {
          return null;
        }
        const conclusionMeeting = params.row.meetings.find(
          (meeting: Meeting) => meeting.type === "Conclusion"
        );
        return conclusionMeeting?.dateStart
          ? new Date(conclusionMeeting.dateStart)
          : null;
      },
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      flex: 1,
      field: "pilotOutcome",
      headerName: "Pilot Outcome",
    },
  ];

  const columns = baseColumns.filter((column) => !column.hidden);

  return (
    <CustomDataGrid
      {...props}
      apiRef={apiRef}
      paginationModel={paginationModel}
      initialState={
        props.gridState || {
          columns: {
            columnVisibilityModel: {
              id: false,
              businessUnit: false,
              focusAreas: false,
              otherStakeholders: false,
              strategicBenefits: false,
              selectedStartupCountry: false,
              discoverDate: false,
              assessDate: false,
              buyDate: false,
              pilotDate: false,
              adoptDate: false,
              onHoldDate: false,
              archivedDate: false,
              pilotKickoffDate: false,
              pilotConclusionDate: false,
              selectedStartup: false,
              adoptionOwner: false,
              impactType: false,
              impactValue: false,
              lastStageChange: false,
              projectSponsor: false,
              programManager: false,
              dateCreated: false,
              pilotOutcome: false,
              shortDescription: false,
              assessedStartups: false,
            },
          },
        }
      }
      columns={columns}
      noRowsText="No Project Found"
    />
  );
}
