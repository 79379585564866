import {
  Box,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  debounce,
  Stack,
} from "@mui/material";
import {
  Fragment,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { ClientContactDTO } from "../../Types/ClientContact";
import { StartupContactDTO } from "../../Types/StartupContact";
import ClientsDataGrid from "./ClientComponents/ClientContactList";
import { thousandSeparator } from "../../utils";
import { GlobalLoaderContext } from "../../Context/LoaderContext";
import ClientContactHttpService from "../../Http/ClientContact/ClientContact.http.service";
import { unstable_batchedUpdates } from "react-dom";
import StartupContactHttpService from "../../Http/StartupContact/StartupContact.http.service";
import { useSnackbar } from "notistack";
import ContentWrapper from "../ProjectDetails/ContentWrapper";
import useRoles from "../../Hooks/useRoles";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import ContactsDataGridToolbar from "./ContactsDataGridToolbar";
import SearchBar from "../UI/SearchBar";
import StartupsDataGrid from "./StartupComponents/StartupContactList";

type Contacts = StartupContactDTO[] | ClientContactDTO[];
type ContactType = "client" | "startup";

export default function ContactList(): ReactElement {
  const apiRef = useGridApiRef();
  const { enqueueSnackbar } = useSnackbar();
  const { isExternalUser } = useRoles();
  const { globalLoader, setGlobalLoader } = useContext(GlobalLoaderContext);
  const [searchValue, setSearchValue] = useState("");
  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");
  const [currentTab, setCurrentTab] = useState<ContactType>("client");
  const [contacts, setContacts] = useState<Contacts>([]);
  const [totalNumberOfContacts, setTotalNumberOfContacts] = useState(0);
  const [createClientContactModalOpen, setCreateContactClientModalOpen] =
    useState(false);
  const [createStartupContactModalOpen, setCreateStartupContactModalOpen] =
    useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    document.title = `Contacts - Venture Client Platform`;
  }, []);

  const getClientContacts = async () => {
    setGlobalLoader(true);
    try {
      await ClientContactHttpService.getContacts(searchValue).then((res) => {
        setContacts(res);
        setTotalNumberOfContacts(res.length);
      });
    } catch (error) {
      enqueueSnackbar("Something went wrong with fetching contacts", {
        variant: "error",
      });
    } finally {
      setGlobalLoader(false);
      window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
    }
  };

  const getStartupContacts = async () => {
    setGlobalLoader(true);
    try {
      await StartupContactHttpService.getContacts(searchValue).then((res) => {
        setContacts(res);
        setTotalNumberOfContacts(res.length);
      });
    } catch (error) {
      enqueueSnackbar("Something went wrong with fetching contacts", {
        variant: "error",
      });
    } finally {
      setGlobalLoader(false);
      window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
    }
  };

  useEffect(() => {
    if (currentTab === "client") {
      getClientContacts();
    } else {
      getStartupContacts();
    }
  }, [debouncedSearchValue, currentTab, refresh]);

  const handleSearch = (value: string) => {
    setSearchValue(value);
    debouncedStateChange(value);
  };

  const debouncedStateChange = useCallback(
    debounce((searchVal: string) => {
      unstable_batchedUpdates(() => {
        setDebouncedSearchValue(searchVal);
      });
    }, 500),
    []
  );

  const changeContactView = (
    event: React.MouseEvent<HTMLElement>,
    type: "client" | "startup"
  ) => {
    if (type) {
      setCurrentTab(type);
      setContacts([]);
    }
  };

  const handleAddContact = () => {
    if (currentTab === "client") {
      setCreateContactClientModalOpen(true);
    } else {
      setCreateStartupContactModalOpen(true);
    }
  };

  const formattedSearchResult = thousandSeparator(totalNumberOfContacts);

  return (
    <Fragment>
      <ContentWrapper>
        <Stack gap={4}>
          <Box display="flex" gap={4}>
            {!isExternalUser && (
              <ToggleButtonGroup
                data-testid="toggle-button-group"
                color="primary"
                value={currentTab}
                exclusive
                onChange={changeContactView}
              >
                <ToggleButton value="client" id="clientContactView">
                  Client
                </ToggleButton>
                <ToggleButton value="startup" id="startupContactView">
                  Startup
                </ToggleButton>
              </ToggleButtonGroup>
            )}
            <Box display="flex" alignItems="end" flexGrow={1} mb={2} gap={3}>
              <Typography
                variant="body2"
                color="text.secondary"
                marginRight="auto"
              >
                {!globalLoader &&
                  contacts.length > 0 &&
                  `${formattedSearchResult} ${
                    formattedSearchResult === "1" ? "result" : "results"
                  }`}
              </Typography>
              <Button
                onClick={handleAddContact}
                variant="outlined"
                id="add-contact"
              >
                + Add Contact
              </Button>
            </Box>
          </Box>
          <Stack
            gap={2}
            bgcolor="transparent"
            id="contacts-list"
            height="calc(100vh - 272px)"
          >
            <Box display="flex" justifyContent="space-between">
              <SearchBar
                debouncedSearchValue={debouncedSearchValue}
                handleValueChange={handleSearch}
                entity="contact"
              />
              <ContactsDataGridToolbar apiRef={apiRef} />
            </Box>
            {currentTab === "client" ? (
              <ClientsDataGrid
                rows={contacts}
                apiRef={apiRef}
                loading={globalLoader}
                refreshClientContact={() => setRefresh(!refresh)}
                setCreateContactClientModalOpen={
                  setCreateContactClientModalOpen
                }
                createClientContactModalOpen={createClientContactModalOpen}
              />
            ) : (
              <StartupsDataGrid
                rows={contacts}
                apiRef={apiRef}
                loading={globalLoader}
                refreshStartupContact={() => setRefresh(!refresh)}
                setCreateStartupContactModalOpen={
                  setCreateStartupContactModalOpen
                }
                createStartupContactModalOpen={createStartupContactModalOpen}
              />
            )}
          </Stack>
        </Stack>
      </ContentWrapper>
    </Fragment>
  );
}
