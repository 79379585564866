import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
} from "@mui/material";
import { ReactElement, Reducer, useEffect, useReducer, useState } from "react";
import theme from "../../../../../theme";
import { SelectInput } from "../../../../UI/InputFields/SelectInput";
import {
  FunnelStage,
  Project,
  ProjectStatusChangeReason,
} from "../../../../../Types/Project";
import {
  ON_HOLD_DEADLINE_TOOLTIP,
  PROJECT_STATUS_COMMENT_TOOLTIP,
  PROJECT_STATUS_TOOLTIP,
} from "../../../../../Constants/TooltipText";
import { StatusChangeReasonsHttpService } from "../../../../../Http/StatusChangeReasons/StatusChangeReasons.http.service";
import CustomToolTip from "../../../../UI/CustomToolTip";
import { getErrorMessage } from "../../../../../utils";
import { ProjectHttpService } from "../../../../../Http/Project/Project.http.service";
import { useSnackbar } from "notistack";
import CustomDatePicker from "../../../../UI/InputFields/CustomDatePicker";

interface Props {
  projectId: number;
  statusComment: string;
  status: string;
  statusReason: ProjectStatusChangeReason | null;
  onHoldDeadline: string | null;
  modalOpen: boolean;
  funnelStage: FunnelStage;
  setModalOpen: (state: boolean) => void;
  handleSave: () => void;
  openAssociatedModal: boolean;
  setProjectStatusOverviewModal: (state: boolean) => void;
}

const EditProjectStatus = (props: Props): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const [project, setProject] = useReducer<
    Reducer<Partial<Project>, Partial<Project>>
  >((state, newState) => ({ ...state, ...newState }), {
    id: props.projectId,
    status: props.status,
    statusReasonId: props.statusReason?.id,
    onHoldDeadline: props.onHoldDeadline,
    statusComment: props.statusComment,
    funnelStage: props.funnelStage,
  } as Partial<Project>);
  const [reasons, setReasons] = useState<ProjectStatusChangeReason[]>([]);

  const statusOptions =
    project.funnelStage === "adopt"
      ? ["active", "on hold", "archived", "adopted"]
      : ["active", "on hold", "archived"];

  const isActive = project.status === "active";
  const isArchived = project.status === "archived";
  const isAdopted = project.status === "adopted";
  const isOnHold = project.status === "on hold";
  const hasComment = !!project.statusComment;
  const hasReason = !!project.statusReasonId;
  const hasOnHoldDeadline = !!project.onHoldDeadline;

  const showError = (value: string) => {
    enqueueSnackbar(`Please Enter ${value}`, {
      variant: "error",
    });
  };

  useEffect(() => {
    if (project.status === "on hold") {
      StatusChangeReasonsHttpService.getReasons("on hold").then((res) =>
        setReasons(res)
      );
    }
    if (project.status === "archived") {
      StatusChangeReasonsHttpService.getReasons("archived").then((res) =>
        setReasons(res)
      );
    }
  }, [project.status]);

  const saveProjectStatus = async () => {
    if (isOnHold && (!hasComment || !hasReason || !hasOnHoldDeadline))
      return showError("Comment, Reason and Date");

    if (!isActive && !isAdopted && (!hasComment || !hasReason)) {
      return showError("Comment and Reason");
    }

    await ProjectHttpService.updateProject(project as Project)
      .then(() => {
        props.handleSave();
        props.setModalOpen(false);
        if (props.openAssociatedModal) {
          props.setProjectStatusOverviewModal(true);
        }
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        enqueueSnackbar(`Could not save the project status: ${errorMessage}`, {
          variant: "error",
        });
      });
  };

  const handleCancel = () => {
    props.setModalOpen(false);
    if (props.openAssociatedModal) props.setProjectStatusOverviewModal(true);
  };

  return (
    <Dialog
      fullWidth
      open={props.modalOpen}
      data-testid="edit-project-status-modal"
      PaperProps={{
        sx: {
          gap: theme.spacing(4),
        },
      }}
    >
      <DialogTitle>Edit Status</DialogTitle>
      <SelectInput
        id="status"
        label="Status"
        value={project.status}
        onChange={(e) =>
          setProject({
            status: e.target.value,
            statusReasonId: null,
          })
        }
        toolTipText={PROJECT_STATUS_TOOLTIP}
        selectValues={
          statusOptions.map((type) => {
            return {
              id: type,
              name: type,
            };
          }) || []
        }
        editMode
        required
        fullWidth
      />

      {(isOnHold || isArchived) && (
        <Box display="flex" gap={2}>
          <SelectInput
            data-testid="statusReason"
            label="Reason"
            value={project.statusReasonId || null}
            onChange={(e) =>
              setProject({
                statusReasonId: +e.target.value,
              })
            }
            selectValues={reasons.map((reason) => ({
              id: reason.id,
              name: reason.description,
            }))}
            editMode
            fullWidth
            required
          />
          {isOnHold && (
            <CustomDatePicker
              editMode={true}
              label="On Hold until"
              id="onHoldDeadline"
              onChange={(value) => {
                if (value) setProject({ onHoldDeadline: value.toString() });
              }}
              value={
                project.onHoldDeadline ? new Date(project.onHoldDeadline) : null
              }
              required
              toolTipText={ON_HOLD_DEADLINE_TOOLTIP("project")}
              minDate={new Date()}
            />
          )}
        </Box>
      )}
      <TextField
        label={
          <>
            Comment
            <CustomToolTip
              id="statusCommentTooltip"
              key="statusCommentTooltip"
              toolTipText={PROJECT_STATUS_COMMENT_TOOLTIP}
              warning={!props.statusComment}
            />
          </>
        }
        value={project.statusComment || ""}
        onChange={(e) => setProject({ statusComment: e.target.value })}
        multiline
        minRows={1}
        required={!isActive}
        error={!project.statusComment && !isActive}
        InputLabelProps={{
          shrink: true,
          sx: {
            marginTop: "-5px",
          },
        }}
      />
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={saveProjectStatus} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditProjectStatus;
