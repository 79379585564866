import { ReactElement, useContext } from "react";
import {
  styled,
  Typography,
  Box,
  tooltipClasses,
  Tooltip,
  Checkbox,
  TooltipProps,
} from "@mui/material";
import { Opportunity } from "../../../../../../Types/Opportunity";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import { GlobalLoaderContext } from "../../../../../../Context/LoaderContext";

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  padding: `0 ${theme.spacing(1.5)} 0 0`,
  "&.MuiCheckbox-root:hover": {
    backgroundColor: "transparent",
  },
  "&.disabled > svg": {
    opacity: "24%",
  },
  "& svg": {
    color: theme.palette.common.white,
  },
}));

const ToolTipContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(0.5),
  columnGap: theme.spacing(1),
  color: theme.palette.common.black,
  alignItems: "center",
}));

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
  },
}));

interface HighlightStartupProps {
  opportunity: Opportunity;
  handleOpportunityStatusChange: (opportunity: Opportunity) => void;
  isSelectionDisabled: boolean;
}

export default function HighlightStartup(
  props: HighlightStartupProps
): ReactElement {
  const { globalLoader } = useContext(GlobalLoaderContext);

  return (
    <>
      <StyledTooltip
        onClick={(event) => event.stopPropagation()}
        title={
          <ToolTipContainer>
            <RocketLaunchOutlinedIcon />
            <Typography color="text.primary" variant="caption">
              Select for next stage
            </Typography>
          </ToolTipContainer>
        }
      >
        <StyledCheckbox
          disabled={props.isSelectionDisabled}
          data-testid={`qualified-checkbox-${props.opportunity.id}`}
          checked={props.opportunity.isQualified}
          className={props.isSelectionDisabled ? "disabled" : ""}
          onChange={() => {
            if (!props.isSelectionDisabled && !globalLoader) {
              props.handleOpportunityStatusChange(props.opportunity);
            }
          }}
        />
      </StyledTooltip>
    </>
  );
}
