import axios from "axios";
import {
  CreateStartupContactDTO,
  GetStartupContactParams,
  StartupContactDTO,
} from "../../Types/StartupContact";

export default class StartupContactHttpService {
  public static async createContact(
    contact: CreateStartupContactDTO
  ): Promise<StartupContactDTO> {
    const response = await axios.post<StartupContactDTO>(
      `/api/startup-contacts`,
      contact
    );
    return response.data;
  }

  public static async updateContact(
    contact: StartupContactDTO,
    id: number
  ): Promise<void> {
    await axios.put<void>(`/api/startup-contacts/${id}`, contact);
  }

  public static async getContactById(id: number): Promise<StartupContactDTO> {
    const response = await axios.get(`/api/startup-contacts/${id}`);
    return response.data;
  }

  public static async getContacts(name: string): Promise<StartupContactDTO[]> {
    const params: GetStartupContactParams = {};

    if (name) {
      params.name = name;
    }

    const response = await axios.get<StartupContactDTO[]>(
      "/api/startup-contacts",
      { params: params }
    );

    return response.data;
  }

  public static async getContactsByName(
    startupId: number,
    name: string
  ): Promise<StartupContactDTO[]> {
    return (
      await axios.get<StartupContactDTO[]>(`/api/startup-contacts`, {
        params: { startupId, name },
      })
    ).data;
  }
}
