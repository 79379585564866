import { Box, Chip, Typography, styled } from "@mui/material";
import { ReactElement, useRef, useState } from "react";
import EditMeetingModal from "./EditMeetingModal";
import ViewMeetingModal from "./ViewMeetingModal";
import { Meeting, Status } from "../../../../../Types/Meeting";
import theme from "../../../../../theme";
import EditDeleteMenu from "../../../../UI/EditDeleteMenu";
import MeetingDatePicker from "../../../../UI/InputFields/MeetingDatePicker";
import DeleteOrRemoveModal from "../../../../UI/Modals/DeleteOrRemoveModal/DeleteOrRemoveModal";
import { formatDate } from "../../../../../utils";

interface StyledProps {
  $borderColor: string;
  $bgColor: string;
}

const MeetingColumnWrapper = styled(Box)(() => ({
  display: "flex",
  height: "56px",
  position: "relative",
}));

const MeetingColumn = styled(Box)(() => ({
  width: "45%",
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  position: "relative",
  padding: theme.spacing(2),
  cursor: "pointer",
  borderRadius: theme.shape.radius.minimal,
  backgroundColor: theme.palette.surface.secondary.main,
}));

const BarFromMeeting = styled("span", {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})<StyledProps>(({ $borderColor, $bgColor }) => ({
  position: "absolute",
  top: "50%",
  left: "100%",
  width: "11%",
  height: "1px",
  backgroundColor: $borderColor,
  "&::after": {
    content: "''",
    position: "absolute",
    right: "0",
    transform: "translate(50%,-50%)",
    width: "16px",
    aspectRatio: "1/1",
    borderRadius: "50%",
    border: `1px solid ${$borderColor}`,
    backgroundColor: $bgColor,
    zIndex: 9,
  },
}));

const ProgressBarWrapper = styled("span")(() => ({
  width: "10%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const ProgressBar = styled("span", {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $isFirst, $isLast }: { $isFirst: boolean; $isLast: boolean }) => ({
  width: "1px",
  height: "150%",
  backgroundColor: theme.palette.borderOutline.main,
  transform: $isFirst ? "translateY(50%)" : $isLast ? "translateY(-50%)" : "",
}));

const DateDisplay = styled(Chip, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $dateStart }: { $dateStart: Date | null }) => ({
  borderRadius: theme.shape.radius.minimal,
  height: "24px",
  pointerEvents: "none",
  borderColor: $dateStart
    ? theme.palette.surface.brand.accessibility
    : theme.palette.borderOutline.main,
  color: $dateStart
    ? theme.palette.text.brand.accessibility
    : theme.palette.text.action.main,
}));

const HideElement = styled("div")(() => ({
  opacity: 0,
  pointerEvents: "none",
  position: "absolute",
  left: "10%",
  top: "0",
  width: "0px",
  height: "0px",
}));

const hasMeetingInfo = (obj: Meeting) => {
  const keysToCheck = [
    "dateStart",
    "deliverables",
    "location",
    "title",
  ] as const;
  return keysToCheck.some((key) => !!obj[key]);
};

interface Props {
  meeting: Meeting;
  handleMeetingEdit: (meeting: Meeting, isPushMeetingsEnabled: boolean) => void;
  handleDelete: (id: number) => void;
  isFirst: boolean;
  isLast: boolean;
  status: Status;
  startupId: number;
  ventureClientId: number;
  index: number | null;
  maxDate: Date | null;
  minDate: Date | null;
}
const MeetingRow = (props: Props): ReactElement => {
  const [deleteMeetingModalOpen, setDeleteMeetingModalOpen] = useState(false);
  const [isEditMeetingModalOpen, setIsEditMeetingModalOpen] = useState(false);
  const [isViewMeetingModalOpen, setIsViewMeetingModalOpen] = useState(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const boxRef = useRef<HTMLDivElement>(null);

  const dateStart = props.meeting.dateStart
    ? formatDate(props.meeting.dateStart)
    : null;

  const handleManageModalOpen = (e: React.MouseEvent<HTMLDivElement>) => {
    // to avoid opening meeting modal
    if (boxRef.current?.contains(e.target as Node)) {
      return;
    }

    if (hasMeetingInfo(props.meeting)) {
      setIsViewMeetingModalOpen(true);
    } else {
      setIsEditMeetingModalOpen(true);
    }
  };

  const borderColor =
    props.status === "done" || props.status === "completed"
      ? theme.palette.primary.main
      : props.status === "today"
      ? theme.palette.primary.main
      : theme.palette.borderOutline.main;

  const bgColor =
    props.status === "done" || props.status === "completed"
      ? theme.palette.primary.main
      : theme.palette.common.white;

  const meetingType = props.meeting.type;
  const meetingDisplayType =
    meetingType === "Kick-off" ? "Kickoff" : meetingType;
  return (
    <>
      <MeetingColumnWrapper>
        <MeetingColumn
          onClick={handleManageModalOpen}
          data-testid={`meeting-row-${props.meeting.id}`}
        >
          <Box
            ref={boxRef}
            onClick={() => {
              setIsDatePickerOpen(!isDatePickerOpen);
            }}
            data-testid={`meeting-date-${props.meeting.id}`}
          >
            <DateDisplay
              variant="outlined"
              size="medium"
              label={
                <Typography variant="caption">
                  {dateStart || "Set date"}
                </Typography>
              }
              $dateStart={props.meeting.dateStart}
            />
          </Box>
          <Box display="flex" alignItems="center">
            <Typography
              variant="subtitle2"
              color={
                hasMeetingInfo(props.meeting)
                  ? theme.palette.text.primary
                  : theme.palette.text.mediumEmphasis
              }
            >
              {meetingDisplayType} {meetingType === "Milestone" && props.index}
            </Typography>
            &nbsp;
            <Typography variant="body1">
              {props.meeting.title ? `- ${props.meeting.title}` : ""}
            </Typography>
          </Box>
          {props.meeting.type === "Milestone" && (
            <EditDeleteMenu
              entity="meeting"
              setEditModalOpen={setIsEditMeetingModalOpen}
              setDeleteModalOpen={setDeleteMeetingModalOpen}
              sx={{ ml: "auto" }}
            />
          )}
          <BarFromMeeting $borderColor={borderColor} $bgColor={bgColor} />
        </MeetingColumn>
        <ProgressBarWrapper>
          <ProgressBar $isFirst={props.isFirst} $isLast={props.isLast} />
        </ProgressBarWrapper>
        {isDatePickerOpen && (
          <HideElement>
            <MeetingDatePicker
              value={props.meeting.dateStart}
              onSave={(value, isPushMeetingsEnabled) => {
                setIsDatePickerOpen(false);
                const updatedMeeting = { ...props.meeting, dateStart: value };
                props.handleMeetingEdit(updatedMeeting, isPushMeetingsEnabled);
              }}
              isOpen={isDatePickerOpen}
              setIsOpen={() => setIsDatePickerOpen(false)}
              hasActionButton
              maxDate={props.maxDate}
              minDate={props.minDate}
            />
          </HideElement>
        )}
      </MeetingColumnWrapper>
      {deleteMeetingModalOpen && (
        <DeleteOrRemoveModal
          id={props.meeting.id}
          modalOpen={deleteMeetingModalOpen}
          setModalOpen={setDeleteMeetingModalOpen}
          entity="Meeting"
          handleDelete={props.handleDelete}
          actionType="delete"
        />
      )}
      {isEditMeetingModalOpen && (
        <EditMeetingModal
          modalOpen={isEditMeetingModalOpen}
          setModalOpen={setIsEditMeetingModalOpen}
          meeting={props.meeting}
          handleMeetingEdit={props.handleMeetingEdit}
          startupId={props.startupId}
          ventureClientId={props.ventureClientId}
          maxDate={props.maxDate}
          minDate={props.minDate}
        />
      )}
      {isViewMeetingModalOpen && (
        <ViewMeetingModal
          index={props.index}
          modalOpen={isViewMeetingModalOpen}
          setModalOpen={setIsViewMeetingModalOpen}
          meeting={props.meeting}
          handleMeetingEdit={props.handleMeetingEdit}
          startupId={props.startupId}
          ventureClientId={props.ventureClientId}
          maxDate={props.maxDate}
          minDate={props.minDate}
        />
      )}
    </>
  );
};

export default MeetingRow;
