import { ReactElement, useState } from "react";
import { TableCell } from "@mui/material";
import { Opportunity } from "../../../../../../Types/Opportunity";
import { Requirement } from "../../../../../../Types/Requirement";
import { RequirementsFullfilment } from "./RequirementsFulfillment";

interface RequirementsStartupCellsProps {
  requirements: Requirement[];
  opportunity: Opportunity;
  requirementChange: (
    updatedRequirementId: number,
    updatedStatus: string
  ) => void;
  editingOpportunity: string | null;
  setEditingOpportunity: (id: string | null) => void;
  isSelectionReasonVisible: boolean;
}

export function RequirementsStartupCells(
  props: RequirementsStartupCellsProps
): ReactElement {
  const [editingFullfilmentStatus, setEditingFullfilmentStatus] = useState<
    number | null
  >(null);
  const handleChangeStatusCell = (requirementId: number) => {
    props.setEditingOpportunity(`requirement-status-${props.opportunity.id}`);
    setEditingFullfilmentStatus(requirementId);
  };

  return (
    <>
      {props.requirements.length ? (
        props.requirements.map((requirement) => {
          const requirementStatus =
            props.opportunity.requirementStartupFits?.find(
              (requirementStartupFit) =>
                requirementStartupFit.requirementId === requirement.id
            );
          if (!requirementStatus) {
            return null;
          }
          return (
            <TableCell
              data-testid="requirement-status-cell"
              className="requirement-cell"
              key={requirementStatus.id}
            >
              <RequirementsFullfilment
                opportunity={props.opportunity}
                status={requirementStatus.status}
                requirementId={requirementStatus.requirementId}
                requirementChange={props.requirementChange}
                editingFullfilmentStatus={editingFullfilmentStatus}
                handleChangeStatusCell={() =>
                  handleChangeStatusCell(requirement.id)
                }
                editingOpportunity={props.editingOpportunity}
                setEditingFullfilmentStatus={setEditingFullfilmentStatus}
                setEditingOpportunity={props.setEditingOpportunity}
              />
            </TableCell>
          );
        })
      ) : (
        <TableCell className="no-requirement-cell"></TableCell>
      )}
      <>
        {props.isSelectionReasonVisible ? (
          <TableCell className="selection-reason-divider"></TableCell>
        ) : null}
      </>
    </>
  );
}
