import { RocketLaunchOutlined, FilterList } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  InputAdornment,
  Pagination,
  TextField,
  Typography,
  debounce,
  IconButton,
  Divider,
  styled,
  Stack,
  Skeleton,
} from "@mui/material";
import {
  Fragment,
  ReactElement,
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import { useHistory } from "react-router-dom";
import { GlobalLoaderContext } from "../../Context/LoaderContext";
import { StartupHttpService } from "../../Http/Startup/Startup.http.service";
import {
  Startup,
  StartupCounts,
  StartupFilterCriteria,
  StartupPrimaryTabItems,
  StartupSecondaryTabItems,
} from "../../Types/Startup";
import CreateStartupModal from "./CreateStartupModal";
import { FilterDrawer } from "./FilterDrawer";
import { unstable_batchedUpdates } from "react-dom";
import PitchbookSearchModal from "../UI/Modals/PitchbookSearchModal/PitchbookSearchModal";
import useRoles from "../../Hooks/useRoles";
import StartupCard from "./StartupCard";
import theme from "../../theme";
import PageHeader from "../UI/PageHeader";
import PrimaryTab from "./DetailsPage/StartupTabs/PrimaryTab";
import SecondaryTab from "./DetailsPage/StartupTabs/SecondaryTabs";
import { enqueueSnackbar } from "notistack";
import { getErrorMessage } from "../../utils";

const PRIMARY_HEADER_OFFSET = 18;
const SECONDARY_HEADER_OFFSET = 8;

const StartupCardSkeleton = styled(Skeleton)(({}) => ({
  height: theme.spacing(17),
  transform: "none",
  borderRadius: theme.shape.radius.minimal,
  background: `linear-gradient(303deg, ${theme.palette.surface.secondary.light} -3.61%, ${theme.palette.borderOutline.main} 159.13%)`,
}));

const ContentWrapper = styled(Box)(() => ({
  padding: theme.spacing(8, 5, 5),
  backgroundColor: theme.palette.background.default,
}));

const StyledHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  marginBottom: theme.spacing(4),
  gap: theme.spacing(4),
}));

const StyledSearchBar = styled(TextField)(({}) => ({
  width: "40%",
  marginRight: "auto",
  backgroundColor: theme.palette.surface.primary.main,
  boxShadow: theme.boxShadows[24],
  height: theme.spacing(6),
  padding: `10px 16px`,
  borderRadius: theme.shape.radius.full,
}));

const AddStartup = styled(Button)(({ theme }) => ({
  padding: `${theme.spacing(1.3)} ${theme.spacing(2.75)}`,
}));

export default function StartupList(): ReactElement {
  const history = useHistory();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [useScroll, setUseScroll] = useState(true);
  const { globalLoader, setGlobalLoader } = useContext(GlobalLoaderContext);
  const { isExternalUser } = useRoles();
  const [counts, setCounts] = useState<StartupCounts>();
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [selectedStartupName, setSelectedStartupName] = useState("");
  const [pitchbookModalOpen, setPitchbookModalOpen] = useState(false);
  const [createStartupModalOpen, setCreateStartupModalOpen] = useState(false);

  // States for managing startups and pagination
  const [startups, setStartups] = useState<Startup[]>([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState<number>();

  // States for managing tabs
  const [selectedPrimaryTab, setSelectedPrimaryTab] =
    useState<StartupPrimaryTabItems>("Startup Portfolio");
  const [selectedSecondaryTab, setSelectedSecondaryTab] =
    useState<StartupSecondaryTabItems>("Leads");
  const [activePrimaryTab, setActivePrimaryTab] = useState(0);
  const [activeSecondaryTab, setActiveSecondaryTab] = useState(0);

  // States for managing filters and search
  const [searchInName, setSearchInName] = useState("");
  const [filters, setFilters] = useState<StartupFilterCriteria>({});
  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");

  const headerTitleRef = useRef<HTMLElement>(null);

  const topHeaderPadding = parseInt(theme.spacing(10.5));
  const headerPadding = parseInt(theme.spacing(3));

  const [headerMaxHeight, setHeaderMaxHeight] = useState(
    headerTitleRef.current
      ? topHeaderPadding +
          headerPadding +
          headerTitleRef.current.getBoundingClientRect().height
      : 0
  );

  useEffect(() => {
    const updateHeight = () => {
      setHeaderMaxHeight(
        headerTitleRef.current
          ? topHeaderPadding +
              headerPadding +
              headerTitleRef.current.getBoundingClientRect().height
          : 0
      );
    };

    if (!headerTitleRef.current) return;

    updateHeight();

    const resizeObserver = new ResizeObserver(() => {
      updateHeight();
    });

    resizeObserver.observe(headerTitleRef.current);

    return () => resizeObserver.disconnect();
  }, [headerTitleRef.current]);

  const handleSearch = (value: string) => {
    setSearchInName(value);
    setGlobalLoader(true);
    debouncedStateChange(value);
  };

  const handleFilters = (filters: StartupFilterCriteria) => {
    setFilters(filters);
    setPage(1);
  };

  const refreshData = () => {
    setStartups([]);
    if (useScroll) window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
    setUseScroll(true);
    setGlobalLoader(true);
    StartupHttpService.getStartups(
      filters,
      page,
      searchInName,
      selectedPrimaryTab,
      selectedSecondaryTab
    )
      .then((res) => {
        setStartups(res.data);
        setTotalPage(res.totalPages);
        setCounts(res.counts);
        setGlobalLoader(false);
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, { variant: "error" });
        setGlobalLoader(false);
      });
  };

  useEffect(() => {
    refreshData();
  }, [
    filters,
    debouncedSearchValue,
    refresh,
    page,
    selectedSecondaryTab,
    selectedPrimaryTab,
  ]);

  useEffect(() => {
    document.title = `Startups - Venture Client Platform`;
  }, []);

  const debouncedStateChange = useCallback(
    debounce((searchVal: string) => {
      unstable_batchedUpdates(() => {
        setDebouncedSearchValue(searchVal);
        setPage(1);
      });
    }, 500),
    []
  );

  const handleStartupImportAndCreate = async (startupId: number) => {
    history.push(`/startups/${startupId}`);
  };

  const handleStartupAddedToProject = () => {
    setUseScroll(false);
    setRefresh((prevState) => !prevState);
  };

  const handleChangePrimaryTab = (
    step: number,
    label: StartupPrimaryTabItems
  ) => {
    setActivePrimaryTab(step);
    setActiveSecondaryTab(0);
    setSelectedPrimaryTab(label);
    setPage(1);
    setSelectedSecondaryTab(step === 1 ? "All" : "Leads");
  };

  const handleChangeSecondaryTab = (
    step: number,
    label: StartupSecondaryTabItems
  ) => {
    setPage(1);
    setActiveSecondaryTab(step);
    setSelectedSecondaryTab(label);
  };

  return (
    <Fragment>
      <PageHeader
        title="Find leading startups."
        subtitle="Learn about startups and their Venture Client activities."
        headerTitleRef={headerTitleRef}
      />
      <ContentWrapper>
        <StyledHeader>
          <StyledSearchBar
            inputProps={{ "data-testid": "startup-search" }}
            variant="standard"
            value={searchInName}
            onChange={(e) => handleSearch(e.target.value)}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton size="small" sx={{ pointerEvents: "none" }}>
                    <SearchIcon color={searchInName ? "primary" : "action"} />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="start">
                  {debouncedSearchValue && (
                    <IconButton
                      size="small"
                      onClick={() => handleSearch("")}
                      id="clear-search"
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
              sx: { borderRadius: theme.shape.radius.full },
            }}
            placeholder="Search a startup"
          />

          <Button
            id="startup-list-filter-button"
            onClick={() => setFilterDrawerOpen(true)}
          >
            <FilterList sx={{ mr: 1 }} /> Filter
          </Button>

          {!isExternalUser && (
            <AddStartup
              variant="contained"
              onClick={() => setPitchbookModalOpen(true)}
            >
              Add Startup
            </AddStartup>
          )}
        </StyledHeader>

        <Box
          position="sticky"
          bgcolor={theme.palette.background.default}
          top={headerMaxHeight - PRIMARY_HEADER_OFFSET + "px"}
        >
          <PrimaryTab
            activeTab={activePrimaryTab}
            handleChangeTab={handleChangePrimaryTab}
          />
        </Box>

        <Divider />

        <Box
          position="sticky"
          zIndex={3}
          top={headerMaxHeight - SECONDARY_HEADER_OFFSET + "px"}
          bgcolor={theme.palette.background.default}
        >
          <SecondaryTab
            handleChangeTab={handleChangeSecondaryTab}
            activeTab={activeSecondaryTab}
            headerHeight={headerMaxHeight}
            counts={counts}
            activePrimaryTab={activePrimaryTab}
          />
        </Box>

        <Box
          id="startup-list"
          display="grid"
          boxSizing="border-box"
          gap={3}
          mt={5}
          gridTemplateColumns="repeat(auto-fill,minmax(max(200px,(100%)/7), 1fr))"
        >
          {globalLoader && (
            <>
              <StartupCardSkeleton height={330} />
              {[...Array(18)].map((_, index) => (
                <StartupCardSkeleton key={index} height={330} />
              ))}
            </>
          )}
          {startups.map((startup) => {
            return (
              <StartupCard
                startup={startup}
                key={startup.id}
                onStartupAddedToProject={handleStartupAddedToProject}
              />
            );
          })}
        </Box>
        {startups.length > 0 && (
          <Pagination
            page={page}
            sx={{
              marginBlock: 5,
              display: "flex",
              justifyContent: "flex-end",
              "& .MuiPaginationItem-root": {
                "&.Mui-selected": {
                  color: theme.palette.background.paper,
                  background: theme.palette.brand.accessibility,
                },
              },
            }}
            size="medium"
            data-testid="pagination-startuplist"
            count={totalPage}
            onChange={(_, page) => setPage(page)}
            siblingCount={2}
            shape="rounded"
          />
        )}
        {startups.length < 1 && searchInName && (
          <Stack mt={1} alignItems="center" textAlign="center">
            <RocketLaunchOutlined sx={{ color: theme.palette.text.disabled }} />
            <Typography color={theme.palette.text.disabled}>
              No startups found.
              <br />
              It might be due to your search input..
            </Typography>
          </Stack>
        )}
        <FilterDrawer
          open={filterDrawerOpen}
          setFilterDrawerOpen={(state) => setFilterDrawerOpen(state)}
          handleFilters={handleFilters}
          activeFilters={filters}
        />

        {pitchbookModalOpen && (
          <PitchbookSearchModal
            startupName={searchInName}
            pitchbookModalOpen={pitchbookModalOpen}
            handleModalClose={() => setPitchbookModalOpen(false)}
            handleImport={handleStartupImportAndCreate}
            handleCreateStartupModalOpen={(startupName) => {
              setCreateStartupModalOpen(true);
              setSelectedStartupName(startupName);
            }}
          />
        )}
        {createStartupModalOpen && (
          <CreateStartupModal
            startupName={selectedStartupName || searchInName}
            modalOpen={createStartupModalOpen}
            handleModalClose={() => setCreateStartupModalOpen(false)}
            handleCreateResponse={handleStartupImportAndCreate}
          />
        )}
      </ContentWrapper>
    </Fragment>
  );
}
