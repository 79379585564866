import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControlLabel,
  Stack,
  Switch,
  Toolbar,
  Typography,
  styled,
  Tooltip,
} from "@mui/material";
import {
  ChangeEvent,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import UserSelectInput from "../UI/InputFields/UserSelect";
import { UserContext } from "../../Context/UserContext";
import { VentureClient } from "../../Types/VentureClient";
import { ProjectFilterCriteria } from "../../Types/Project";
import { User } from "../../Types/User";
import theme from "../../theme";
import { SelectInput } from "../UI/InputFields/SelectInput";
import BookmarkToggle from "../UI/InputFields/BookmarkToggle";
import ProgramManagerSelect from "../UI/InputFields/ProgramManagerSelect";
import useRoles from "../../Hooks/useRoles";
import { STATUS_FILTER_TOOLTIP } from "../../Constants/TooltipText";

interface ProjectKanbanFilterProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  refresh: () => void;
  ventureClientSelectOptions: VentureClient[];
}

const StyledCheckbox = styled(FormControlLabel)(() => ({
  margin: 0,
}));

const CloseButton = styled(Button)(({ theme }) => ({
  color: theme.palette.icon.primary,
}));

export function ProjectKanbanFilter({
  open,
  setOpen,
  refresh,
  ventureClientSelectOptions,
}: ProjectKanbanFilterProps): ReactElement {
  const user = useContext(UserContext);
  const { isExternalUser, ventureClientId } = useRoles();
  const defaultFilters = JSON.stringify({
    status: ["active"],
    projectOwnerId: !isExternalUser ? user?.id : undefined,
    startupIntelligenceId: !isExternalUser ? user?.id : undefined,
  });
  const filterOptions = JSON.parse(
    localStorage.getItem("projectFilters") || defaultFilters
  );
  const [activeFilters, setActiveFilters] = useState(filterOptions);
  const [selectedVentureClient, setSelectedVentureClient] =
    useState<VentureClient>();

  useEffect(() => {
    if (isExternalUser && ventureClientId) {
      const ventureClient = ventureClientSelectOptions.find(
        (client) => client.id === ventureClientId
      );
      setSelectedVentureClient(ventureClient);
    } else {
      const ventureClient = ventureClientSelectOptions.find(
        (client) => client.id === activeFilters.ventureClientId
      );
      setSelectedVentureClient(ventureClient);
    }
  }, [
    ventureClientSelectOptions,
    ventureClientId,
    isExternalUser,
    activeFilters.ventureClientId,
  ]);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const resetFilters = () => {
    localStorage.setItem("projectFilters", defaultFilters);
    setActiveFilters(JSON.parse(defaultFilters));
    refresh();
    setOpen(false);
  };

  const handleAcceptFilterChanges = () => {
    const newFilterCriteria: ProjectFilterCriteria = Object.entries(
      activeFilters
    ).reduce((acc, [key, value]) => {
      if (value !== "" && value !== undefined && value !== null)
        acc = Object.assign({ [key]: value }, acc);
      return acc;
    }, {} as ProjectFilterCriteria);

    localStorage.setItem("projectFilters", JSON.stringify(newFilterCriteria));
    refresh();
    setOpen(false);
  };

  const handleFilterByStatus = (
    statusChangeEvent: ChangeEvent<HTMLInputElement>
  ) => {
    const statusOption = statusChangeEvent.target.name;
    const updatedActiveFilters = activeFilters.status || [];
    const filterIndex = updatedActiveFilters.indexOf(statusOption);
    filterIndex === -1
      ? updatedActiveFilters.push(statusOption)
      : updatedActiveFilters.splice(filterIndex, 1);
    setActiveFilters({
      ...activeFilters,
      status: updatedActiveFilters,
    });
  };

  const handleFilterByProjectOwner = (isOwnerChecked: boolean) => {
    setActiveFilters({
      ...activeFilters,
      projectOwnerId: isOwnerChecked ? user?.id : undefined,
      startupIntelligenceId: isOwnerChecked ? user?.id : undefined,
    });
  };

  const handleFilterByProgramManager = (programManagerId?: number) => {
    setActiveFilters({
      ...activeFilters,
      programManagerId,
    });
  };

  const handleSelectFilter = (
    filterName: string,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const filters = { ...activeFilters };
    if (filterName === "ventureClientId") {
      const ventureClient = ventureClientSelectOptions.find(
        (client) => client.id === parseInt(event.target.value)
      );
      setSelectedVentureClient(ventureClient);
      filters.businessUnitId = "";
      filters.focusAreaId = "";
      filters.programManagerId = undefined;
    }

    setActiveFilters({
      ...filters,
      [filterName]: parseInt(event.target.value),
    });
  };

  const handleProjectOwnerSelect = (user: User | undefined) => {
    setActiveFilters({
      ...activeFilters,
      projectOwnerId: user ? user.id : undefined,
      startupIntelligenceId: user ? user?.id : undefined,
    });
  };

  const handleFilterByBookmark = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setActiveFilters({ ...activeFilters, bookmarked: true });
    } else {
      const updatedFilter = activeFilters;
      delete updatedFilter.bookmarked;
      setActiveFilters({ ...updatedFilter });
    }
  };

  const programManagers =
    selectedVentureClient?.businessUnits.flatMap(
      (bu) => bu.clientContacts || []
    ) || [];

  const disableStatusDeselection = activeFilters.status.length < 2;

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={open}
      data-testid="kanban-filter-drawer"
    >
      <Toolbar />
      <Box
        sx={{
          position: "absolute",
          right: 0,
          top: 0,
          padding: theme.spacing(3, 3, 1, 3),
        }}
      >
        <CloseButton
          id="filter-drawer-close-button"
          onClick={handleDrawerClose}
        >
          <Close />
        </CloseButton>
      </Box>
      <Stack role="presentation" p={theme.spacing(0, 3)} gap={4}>
        <Box display="flex" alignItems="center">
          <Typography variant="h3">Filters</Typography>
        </Box>
        <Box>
          <Box
            display="flex"
            flexWrap="wrap"
            bgcolor="background.default"
            p={theme.spacing(1, 2)}
            gap={2}
            borderRadius={theme.shape.radius.minimal}
          >
            <StatusCheckbox
              name="Active"
              checked={activeFilters.status?.includes("active")}
              disableDeselection={disableStatusDeselection}
              handleChange={handleFilterByStatus}
            />
            <StatusCheckbox
              name="On Hold"
              checked={activeFilters.status?.includes("on hold")}
              disableDeselection={disableStatusDeselection}
              handleChange={handleFilterByStatus}
            />
            <StatusCheckbox
              name="Archived"
              checked={activeFilters.status?.includes("archived")}
              disableDeselection={disableStatusDeselection}
              handleChange={handleFilterByStatus}
            />
            <StatusCheckbox
              name="Adopted"
              checked={activeFilters.status?.includes("adopted")}
              disableDeselection={disableStatusDeselection}
              handleChange={handleFilterByStatus}
            />
            {isExternalUser && (
              <BookmarkToggle
                data-testid="filter-checkbox-bookmarked"
                size="medium"
                checked={!!activeFilters.bookmarked}
                handleBookmark={handleFilterByBookmark}
                label="Bookmarked"
              />
            )}
          </Box>
        </Box>
        <Box>
          <FormControlLabel
            data-testid="my-projects-switch"
            control={
              <Switch
                onChange={(e) => handleFilterByProjectOwner(e.target.checked)}
                checked={activeFilters.projectOwnerId === user?.id}
              />
            }
            label={<Typography>My projects</Typography>}
          />
        </Box>
        <Stack gap={theme.spacing(3)}>
          <Box>
            <UserSelectInput
              defaultUserId={activeFilters.projectOwnerId}
              onChange={handleProjectOwnerSelect}
              label="Project Owner"
            />
          </Box>
          {!isExternalUser && (
            <Box>
              <SelectInput
                label="Venture Client"
                id="venture-client"
                selectValues={ventureClientSelectOptions}
                value={activeFilters.ventureClientId}
                editMode={true}
                onChange={(event) =>
                  handleSelectFilter("ventureClientId", event)
                }
                deselectPossible
                fullWidth
              />
            </Box>
          )}
          <Box>
            <SelectInput
              label="Organizational Unit"
              id="organizational-unit"
              selectValues={selectedVentureClient?.businessUnits || []}
              value={activeFilters.businessUnitId}
              editMode={true}
              onChange={(event) => handleSelectFilter("businessUnitId", event)}
              disabled={!selectedVentureClient?.id}
              deselectPossible
              fullWidth
            />
          </Box>
          <ProgramManagerSelect
            options={programManagers}
            defaultProgramManager={programManagers.find(
              (pm) => pm.id === activeFilters.programManagerId
            )}
            onChange={(programManager) =>
              handleFilterByProgramManager(programManager?.id)
            }
            disabled={!selectedVentureClient?.id}
          />
          <Box>
            <SelectInput
              label="Focus Area"
              id="focus-area"
              selectValues={selectedVentureClient?.focusAreas || []}
              value={activeFilters.focusAreaId}
              editMode={true}
              onChange={(event) => handleSelectFilter("focusAreaId", event)}
              disabled={!selectedVentureClient?.id}
              fullWidth
            />
          </Box>
          <Box display="flex" p={1} alignSelf="flex-end" height="fit-content">
            <Button onClick={resetFilters} variant="text">
              <Close sx={{ fontSize: "1.2rem", mr: 1 }} />
              <Typography variant="button">Reset</Typography>
            </Button>
          </Box>
        </Stack>
      </Stack>
      <Box
        display="flex"
        sx={{
          position: "absolute",
          padding: theme.spacing(3),
          bottom: "0",
          width: "100%",
        }}
      >
        <Button
          fullWidth
          variant="contained"
          onClick={handleAcceptFilterChanges}
        >
          Apply
        </Button>
      </Box>
    </Drawer>
  );
}

interface StatusCheckboxProps {
  name: string;
  checked: boolean;
  disableDeselection: boolean;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const StatusCheckbox = ({
  name,
  checked,
  disableDeselection,
  handleChange,
}: StatusCheckboxProps) => {
  const formattedName = name.replace(" ", "-").toLocaleLowerCase();
  const disabled = disableDeselection && checked;

  return (
    <Tooltip title={disabled ? STATUS_FILTER_TOOLTIP : null}>
      <StyledCheckbox
        disabled={disabled}
        control={
          <Checkbox
            name={name.toLocaleLowerCase()}
            data-testid={`filter-checkbox-${formattedName}`}
            edge="start"
            disableRipple
            checked={checked}
            onChange={handleChange}
          />
        }
        label={name}
      />
    </Tooltip>
  );
};
