import { ReactElement, useContext, useEffect, useState } from "react";
import { ProjectTimeline } from "../../Types/Analytics";
import { AnalyticsHttpService } from "../../Http/Analytics/Analytics.http.service";
import { Box, Divider, Grid, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import { GlobalLoaderContext } from "../../Context/LoaderContext";
import { DashboardFilterOptions } from "../../Types/Dashboard";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      ...theme.typography.h5,
      color: theme.palette.grey[600],
      fontWeight: "500",
      position: "absolute",
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
    },
    contentWrapper: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
      flexGrow: "1",
    },
    section: {
      flexGrow: "1",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    speedValueFirst: {
      fontWeight: "bold",
      background: theme.palette.chart.dark.secondary,
      "-webkit-background-clip": "text",
      "-webkit-text-fill-color": "transparent",
      fontSize: "40px",
      lineHeight: "1.2",
    },
    speedValueSecond: {
      fontWeight: "bold",
      background: theme.palette.chart.dark.primary,
      "-webkit-background-clip": "text",
      "-webkit-text-fill-color": "transparent",
      fontSize: "40px",
      lineHeight: "1.2",
    },
    noValue: {
      backgroundImage: theme.palette.chart.dark.primary,
    },
    description: {
      ...theme.typography.caption,
      color: theme.palette.text.secondary,
      fontSize: "14px",
      fontWeight: "500",
    },
  })
);

interface Props {
  dashboardFilters: DashboardFilterOptions;
  refetch: boolean;
}

const ProjectTimelineSection = ({
  dashboardFilters,
  refetch,
}: Props): ReactElement => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { setGlobalLoader } = useContext(GlobalLoaderContext);
  const [projectTimeline, setProjectTimeline] = useState<ProjectTimeline>({
    discoverToPilotAge: 0,
    pilotToAdoptAge: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await AnalyticsHttpService.getProjectTimeline({
          businessUnitIds: dashboardFilters.businessUnitsIds,
          focusAreasIds: dashboardFilters.focusAreasIds,
        });
        setProjectTimeline(data);
        // eslint-disable-next-line
      } catch (error: any) {
        enqueueSnackbar(
          `Something went wrong with fetching project timeline: ${error.message}`,
          {
            variant: "error",
          }
        );
      } finally {
        setGlobalLoader(false);
      }
    };

    fetchData();
  }, [refetch]);

  return (
    <Box data-testid="speed" height="300px">
      <Grid container height="100%">
        <Typography className={classes.title}>Speed</Typography>

        <Grid className={classes.contentWrapper}>
          <Grid className={classes.section}>
            <Typography
              className={clsx(classes.speedValueFirst, {
                [classes.noValue]: projectTimeline.discoverToPilotAge === 0,
              })}
            >
              {projectTimeline.discoverToPilotAge
                ? `${projectTimeline.discoverToPilotAge} days`
                : "-"}
            </Typography>
            <Typography className={classes.description}>
              from Discovery to Pilot
            </Typography>
          </Grid>
          <Divider
            orientation="vertical"
            variant="middle"
            sx={{ height: "75%" }}
          />
          <Grid className={classes.section}>
            <Typography
              className={clsx(classes.speedValueSecond, {
                [classes.noValue]: projectTimeline.pilotToAdoptAge === 0,
              })}
            >
              {projectTimeline.pilotToAdoptAge
                ? `${projectTimeline.pilotToAdoptAge} days`
                : "-"}
            </Typography>
            <Typography className={classes.description}>
              from Pilot to Adoption
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ProjectTimelineSection;
