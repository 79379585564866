import axios from "axios";
import {
  BusinessUnit,
  BusinessUnitDTO,
  CreateBusinessUnitDTO,
  EditBusinessUnitDTO,
} from "../../Types/BusinessUnit";
import BusinessUnitMapper from "./BusinessUnit.mapper";

export class BusinessUnitHttpService {
  public static async createBusinessUnit(
    businessUnit: CreateBusinessUnitDTO
  ): Promise<void> {
    await axios.post<CreateBusinessUnitDTO>(
      "/api/business-units",
      businessUnit
    );
  }

  public static async updateBusinessUnit(
    businessUnit: EditBusinessUnitDTO
  ): Promise<void> {
    await axios.put<void>(
      `/api/business-units/${businessUnit.id}`,
      businessUnit
    );
  }

  public static async getBusinessUnits(filterCriteria?: {
    ventureClientId: number;
  }): Promise<BusinessUnit[]> {
    const response = await axios.get<BusinessUnitDTO[]>(`/api/business-units`, {
      params: {
        filterCriteria: JSON.stringify(filterCriteria),
      },
    });
    return response.data.map((businessUnit) =>
      BusinessUnitMapper.map(businessUnit)
    );
  }
}
