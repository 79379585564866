import { Close, Search } from "@mui/icons-material";
import { IconButton, InputAdornment, InputBase, styled } from "@mui/material";
import { ReactElement } from "react";
import theme from "../../theme";

interface SearchBarProps {
  debouncedSearchValue: string;
  handleValueChange: (value: string) => void;
  entity: string;
}
const StyledSearchBar = styled(InputBase)(() => ({
  width: "40%",
  height: theme.spacing(6),
  padding: `8px 16px`,
  borderRadius: theme.shape.radius.full,
  backgroundColor: theme.palette.surface.primary.main,
  boxShadow: theme.boxShadows[24],
}));

export default function SearchBar(props: SearchBarProps): ReactElement {
  const { debouncedSearchValue, handleValueChange, entity } = props;

  return (
    <StyledSearchBar
      id={`search-${entity}s`}
      inputProps={{ "data-testid": `search-${entity}s` }}
      onChange={(e) => handleValueChange(e.target.value)}
      placeholder={`Search a ${entity}`}
      startAdornment={
        <InputAdornment position="start">
          <IconButton size="small" sx={{ pointerEvents: "none" }}>
            <Search color="action" />
          </IconButton>
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="start">
          {debouncedSearchValue && (
            <IconButton
              size="small"
              onClick={() => handleValueChange("")}
              id="clear-search"
            >
              <Close />
            </IconButton>
          )}
        </InputAdornment>
      }
    />
  );
}
