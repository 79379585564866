import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { ReactElement, useEffect, Reducer, useReducer } from "react";
import {
  BusinessUnit,
  EditBusinessUnitDTO,
} from "../../../../Types/BusinessUnit";
import { BusinessUnitHttpService } from "../../../../Http/BusinessUnit/BusinessUnit.http.service";
import ClientContactSelect from "../../../UI/InputFields/ClientContactSelect";
interface EditBusinessUnitModalProps {
  handleModalClose: () => void;
  modalOpen: boolean;
  handleSave: () => void;
  businessUnit: BusinessUnit;
}

export default function EditBusinessUnitModal(
  props: EditBusinessUnitModalProps
): ReactElement {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [businessUnit, setBusinessUnit] = useReducer<
    Reducer<EditBusinessUnitDTO, Partial<EditBusinessUnitDTO>>
  >(
    (state, newState) => ({ ...state, ...newState }),
    {} as EditBusinessUnitDTO
  );

  useEffect(() => {
    setBusinessUnit({ ...props.businessUnit } as EditBusinessUnitDTO);
  }, [props.businessUnit]);

  const handleUpdateBusinessUnit = async () => {
    if (businessUnit) {
      await BusinessUnitHttpService.updateBusinessUnit(businessUnit);
      props.handleSave();
      props.handleModalClose();
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      fullScreen={fullScreen}
      open={props.modalOpen}
      id="business-unit-edit-view"
      PaperProps={{
        sx: {
          gap: theme.spacing(4),
        },
      }}
    >
      <DialogTitle>Edit Organizational Unit</DialogTitle>
      <DialogContent>
        {businessUnit && (
          <Stack noValidate autoComplete="off" component="form" gap={4}>
            <Stack gap={2}>
              <Typography variant="h6">Information</Typography>
              <Box display="flex" flexDirection="row" gap={3}>
                <TextField
                  label="Name"
                  id="name"
                  variant="outlined"
                  required
                  fullWidth
                  value={businessUnit.name}
                  onChange={(e) => setBusinessUnit({ name: e.target.value })}
                />
                <TextField
                  label="Short Description"
                  id="shortDescription"
                  variant="outlined"
                  required
                  fullWidth
                  value={businessUnit.shortDescription}
                  onChange={(e) =>
                    setBusinessUnit({ shortDescription: e.target.value })
                  }
                />
              </Box>
            </Stack>
            <Stack gap={2}>
              <Typography variant="h6">Contact</Typography>
              <ClientContactSelect
                labelText="Venture Client Program Manager"
                ventureClientId={props.businessUnit.ventureClientId}
                onChange={setBusinessUnit}
                fieldId="mainContactId"
                multiSelect={false}
                contactData={props.businessUnit.mainContact}
                editMode={true}
              />
            </Stack>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={props.handleModalClose}
          color="primary"
          id="close-business-unit-edit-view"
        >
          Cancel
        </Button>
        <Button
          onClick={handleUpdateBusinessUnit}
          variant="contained"
          id="save-business-unit-edit-view"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
