import { Box, Typography, styled } from "@mui/material";
import { ReactElement, useState } from "react";
import { LeadProject, LeadProjectLeader } from "../../../Types/LeadProject";
import ManageLeadProjectLeaderModal from "./ManageLeadProjectLeaderModal";
import DeleteOrRemoveModal from "../../UI/Modals/DeleteOrRemoveModal/DeleteOrRemoveModal";
import { getErrorMessage } from "../../../utils";
import { enqueueSnackbar } from "notistack";
import theme from "../../../theme";
import ContactName from "../../Contacts/ContactCard/ContactName";
import EditDeleteMenu from "../../UI/EditDeleteMenu";
import { LeadProjectsHttpService } from "../../../Http/LeadProjects/LeadProjects.http.service";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const LeadProjectLeaderSummaryContainer = styled(Box)({
  backgroundColor: theme.palette.surface.secondary.main,
  display: "grid",
  alignItems: "center",
  padding: theme.spacing(2),
  borderRadius: theme.shape.radius.minimal,
  gridTemplateColumns: "1fr 1fr 1fr auto",
});

interface LeadProjectLeaderSummaryProps {
  leadProjectLeader: LeadProjectLeader;
  onRefresh: () => void;
  leadProject: LeadProject;
}

const LeadProjectLeaderSummary = (
  props: LeadProjectLeaderSummaryProps
): ReactElement => {
  const [projectLeadLeaderModalOpen, setLeadProjectLeaderModalOpen] =
    useState(false);
  const [
    isEditProjectLeadLeaderModalOpen,
    setisEditProjectLeadLeaderModalOpen,
  ] = useState(false);

  const { leadProjectLeader: LeadProjectLeader, leadProject } = props;

  const deleteLeadProjectLeader = () => {
    LeadProjectsHttpService.deleteLeadProjectLeader(
      leadProject.id,
      LeadProjectLeader.id
    )
      .then(() => {
        setLeadProjectLeaderModalOpen(false);
        props.onRefresh();
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(
          `Could not delete project lead leader: ${errorMessage}`,
          { variant: "error" }
        );
      });
  };

  return (
    <>
      <LeadProjectLeaderSummaryContainer
        key={LeadProjectLeader.id}
        data-testid={`lead-project-leader-${LeadProjectLeader.id}`}
      >
        {LeadProjectLeader.projectLeader ? (
          <ContactName
            name={LeadProjectLeader.projectLeader?.name || ""}
            title={LeadProjectLeader.projectLeader?.title || ""}
            orientation="horizontal"
          />
        ) : (
          <Box display="flex" alignItems="center">
            <ErrorOutlineIcon color="warning" />
            <Typography sx={{ ml: 1 }}>Please assign a contact</Typography>
          </Box>
        )}
        {LeadProjectLeader.businessUnit ? (
          <Typography>{LeadProjectLeader.businessUnit?.name || ""}</Typography>
        ) : (
          <Box display="flex" alignItems="center">
            <ErrorOutlineIcon color="warning" />
            <Typography sx={{ ml: 1 }}>
              Please assign an organizational unit
            </Typography>
          </Box>
        )}
        {LeadProjectLeader.useCase ? (
          <Typography style={{ textAlign: "right" }}>
            {LeadProjectLeader.useCase?.name || ""}
          </Typography>
        ) : (
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <ErrorOutlineIcon color="warning" />
            <Typography sx={{ ml: 1, textAlign: "right" }}>
              Please link to a use case
            </Typography>
          </Box>
        )}
        <EditDeleteMenu
          entity="lead-project-leader"
          setEditModalOpen={setisEditProjectLeadLeaderModalOpen}
          setDeleteModalOpen={setLeadProjectLeaderModalOpen}
        />
      </LeadProjectLeaderSummaryContainer>

      {projectLeadLeaderModalOpen && (
        <DeleteOrRemoveModal
          id={props.leadProjectLeader.id}
          modalOpen={projectLeadLeaderModalOpen}
          setModalOpen={setLeadProjectLeaderModalOpen}
          entity="Project Leader"
          handleDelete={deleteLeadProjectLeader}
          actionType="remove"
        />
      )}

      {isEditProjectLeadLeaderModalOpen && (
        <ManageLeadProjectLeaderModal
          LeadProjectLeader={LeadProjectLeader}
          setModalOpen={setisEditProjectLeadLeaderModalOpen}
          modalOpen={isEditProjectLeadLeaderModalOpen}
          leadProject={props.leadProject}
          onRefresh={props.onRefresh}
        />
      )}
    </>
  );
};

export default LeadProjectLeaderSummary;
