import { MenuItem, TextField, Typography } from "@mui/material";
import { Fragment, ReactElement, useState } from "react";
import { FileHttpService } from "../../../../../Http/File/File.http.service";
import { Project } from "../../../../../Types/Project";
import { FileDropzone } from "./Dropzone";
import { DisabledFiles } from "../../../../../Types/File";
import theme from "../../../../../theme";
import { Opportunity } from "../../../../../Types/Opportunity";
import { useSnackbar } from "notistack";
import { isProjectBmwCheck } from "../../../../../utils";

interface FileUploadSectionProps {
  project: Project;
  projectFileTypes: string[];
  opportunityFileTypes: string[];
  disabledFileTypes?: DisabledFiles;
  handleSave: () => void;
  setLastUploadFile: (id: number) => void;
  stageSpecificOpportunities: Opportunity[];
  projectStage: string;
}

export function FileUploadSection(props: FileUploadSectionProps): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const [fileType, setFileType] = useState<string>("");
  const [selectedOpportunityId, setSelectedOpportunityId] = useState(0);

  const mapFileType = (fileType: string): string => {
    if (fileType === "Other") {
      return props.projectStage;
    } else {
      return fileType;
    }
  };

  const isOpportunityFile = (): boolean => {
    return !!(
      props.opportunityFileTypes?.includes(fileType) ||
      props.projectStage === "buy" ||
      props.projectStage === "pilot" ||
      props.projectStage === "adopt"
    );
  };

  const showOpportunityDropdown = (): boolean => {
    return !!(
      props.opportunityFileTypes?.includes(fileType) &&
      props.projectStage !== "buy" &&
      props.projectStage !== "pilot" &&
      props.projectStage !== "adopt"
    );
  };

  const fileTypeIsDisabled = (typeCheck: string): boolean => {
    return props.disabledFileTypes &&
      Object.keys(props.disabledFileTypes).includes(typeCheck)
      ? !props.disabledFileTypes[typeCheck]?.includes(
          props.project.businessUnit.ventureClientId
        )
      : true;
  };

  const getLinkableId = (): number => {
    if (!isOpportunityFile()) {
      return props.project.id;
    }

    if (
      ["discover", "assess"].includes(props.projectStage) &&
      isOpportunityFile()
    ) {
      return selectedOpportunityId;
    }

    const selectedOpportunity = props.project.opportunities?.find(
      (opportunity) => opportunity.isSelectedForPilot === true
    );

    if (
      ["buy", "pilot", "adopt"].includes(props.projectStage) &&
      selectedOpportunity
    ) {
      return selectedOpportunity.id;
    }

    return props.project.id;
  };

  const uploadFile = async (file: File): Promise<void> => {
    try {
      await FileHttpService.upload(
        file,
        getLinkableId(),
        isOpportunityFile() ? "opportunity" : "project",
        mapFileType(fileType)
      ).catch(() => {
        enqueueSnackbar("File can not be uploaded", {
          variant: "error",
        });
      });

      if (isOpportunityFile()) {
        props.setLastUploadFile(selectedOpportunityId);
      }

      setFileType("");
      setSelectedOpportunityId(0);
      props.handleSave();
    } catch (error) {
      enqueueSnackbar("File can not be uploaded", {
        variant: "error",
      });
    }
  };

  const showDropZone = (): boolean => {
    if (!fileType) {
      return false;
    } else if (!showOpportunityDropdown()) {
      return true;
    }
    return !!(showOpportunityDropdown() && selectedOpportunityId);
  };

  return (
    <Fragment>
      <Typography variant="subtitle1">Upload File</Typography>
      <TextField
        label="File Type to Upload"
        name="File Type to Upload"
        id="fileType"
        variant="outlined"
        select
        fullWidth
        value={fileType}
        onChange={(e) => setFileType(e.target.value as string)}
      >
        {props.projectFileTypes
          .filter((projectFileType) => fileTypeIsDisabled(projectFileType))
          .map((option) => (
            <MenuItem value={option} key={option}>
              {option}
            </MenuItem>
          ))}
        {props.opportunityFileTypes
          .filter((opportunityFileType) =>
            fileTypeIsDisabled(opportunityFileType)
          )
          .map((option) => (
            <MenuItem value={option} key={option}>
              {option}
            </MenuItem>
          ))}
      </TextField>
      {showOpportunityDropdown() && (
        <TextField
          label="Select Startup"
          id="startupSelect"
          variant="outlined"
          select
          fullWidth
          value={selectedOpportunityId || ""}
          sx={{ marginTop: theme.spacing(2) }}
          onChange={(e) => setSelectedOpportunityId(parseInt(e.target.value))}
        >
          {props.stageSpecificOpportunities.map((opportunity) => (
            <MenuItem
              value={opportunity.id}
              key={opportunity.id}
              data-cy={
                "MenuItem-" + opportunity.startup.name.replaceAll(" ", "_")
              }
            >
              {opportunity.startup.name}
            </MenuItem>
          ))}
        </TextField>
      )}
      {showDropZone() && (
        <FileDropzone
          addFile={uploadFile}
          isBmwProject={isProjectBmwCheck(
            props.project.businessUnit.ventureClientId
          )}
        />
      )}
    </Fragment>
  );
}
