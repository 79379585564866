import {
  Box,
  styled,
  TooltipProps,
  tooltipClasses,
  Tooltip,
} from "@mui/material";
import { ReactElement } from "react";
import {
  ProjectTask,
  ProjectTaskStatus,
  ProjectTasksCluster,
} from "../../../../../Types/ProjectTask";

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "grey",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
    padding: "16px",
    fontSize: "16px",
    fontWeight: "400",
  },
}));

const ProjectTaskProgressBarContainer = styled(Box)(({ theme }) => ({
  paddingBlock: theme.spacing(2),
  display: "flex",
  gap: "2px",
}));

const ClusterMarker = styled("div")(({ theme }) => ({
  borderRadius: theme.spacing(1),
  overflow: "hidden",
  columnGap: "1px",
  display: "flex",
  "& :first-of-type.skipped ": {
    borderTopLeftRadius: theme.spacing(1),
    borderBottomLeftRadius: theme.spacing(1),
  },
  "& :last-of-type.skipped ": {
    borderTopRightRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
  },
}));

const TaskMarker = styled("div")(({ theme }) => ({
  height: theme.spacing(1),
  transition: "background-color 0.2s ease",
  "&.completed": {
    backgroundColor: theme.palette.success.main,
  },
  "&.not-completed": {
    backgroundColor: theme.palette.grey[400],
  },
  "&.skipped": {
    border: `solid 1px ${theme.palette.grey[400]}`,
  },
  "&.current": {
    backgroundColor: theme.palette.secondary.main,
  },
  "&.completed:hover": {
    backgroundColor: theme.palette.success.main + "CC",
  },
  "&.not-completed:hover": {
    backgroundColor: theme.palette.grey[400] + "CC",
  },
  "&.skipped:hover": {
    backgroundColor: theme.palette.grey[600],
    border: `solid 1px ${theme.palette.grey[600]}`,
  },
  "&.current:hover": {
    backgroundColor: theme.palette.secondary.main + "CC",
  },
}));

interface ProjectTaskProgressBarProps {
  projectTaskClusters: ProjectTasksCluster[];
  taskCount: number;
  currentTask: ProjectTask | null;
}

export default function ProjectTaskProgressBar(
  props: ProjectTaskProgressBarProps
): ReactElement {
  const taskWidth = 100 / props.taskCount;

  const getStatusClassName = (task: ProjectTask) => {
    if (task.status === "COMPLETED" || task.status === "SKIPPED") {
      return taskStatusToClassName(task.status);
    } else if (props.currentTask && task.id === props.currentTask.id) {
      return "current";
    } else {
      return taskStatusToClassName("NOT_COMPLETED");
    }
  };

  const taskStatusToClassName = (status: ProjectTaskStatus) => {
    switch (status) {
      case "COMPLETED":
        return "completed";
      case "NOT_COMPLETED":
        return "not-completed";
      case "SKIPPED":
        return "skipped";
      default:
        return "not-completed";
    }
  };

  return (
    <ProjectTaskProgressBarContainer data-testid="project-task-progress-bar">
      {props.projectTaskClusters.map((cluster) => {
        return (
          <ClusterMarker
            key={cluster.id}
            sx={{ width: `${taskWidth * cluster.tasks.length}%` }}
            data-testid={`progress-bar-cluster-task-${cluster.id}`}
          >
            {cluster.tasks.map((task) => {
              return (
                <StyledTooltip key={task.id} title={task.description}>
                  <TaskMarker
                    data-testid={`progress-bar-task-${task.id}`}
                    className={getStatusClassName(task)}
                    sx={{
                      width: `${100 / cluster.tasks.length}%`,
                    }}
                  />
                </StyledTooltip>
              );
            })}
          </ClusterMarker>
        );
      })}
    </ProjectTaskProgressBarContainer>
  );
}
