const sourceNames = [
  "Research by 27pilots",
  "Research by Venture Client Unit",
  "Research by Startup",
  "Referral by 27pilots",
  "Referral by Venture Client Unit",
  "Referral by Business Unit",
  "Referral by Accelerator",
  "Referral by Venture Capital Unit / Firm",
  "Event",
  "Media",
  "Other",
];

export const sourceValues = sourceNames.map((value) => ({
  id: value,
  name: value,
}));
