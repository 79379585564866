import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionProps,
  Button,
  Grid,
  Stack,
} from "@mui/material";
import {
  PROJECT_LEADER_TOOLTIP,
  PROJECT_SPONSOR_TOOLTIP,
  PROJECT_TEAM_TOOLTIP,
  OTHER_STAKEHOLDERS_TOOLTIP,
  DEADLINE_TOOLTIP,
  ADOPTION_STRATEGY_TOOLTIP,
  BUDGET_TOOLTIP,
} from "../../../../Constants/TooltipText";
import { ClientContactDTO } from "../../../../Types/ClientContact";
import { Project, ProjectStakeholders } from "../../../../Types/Project";
import ClientContactSelect from "../../../UI/InputFields/ClientContactSelect";
import { SelectInput } from "../../../UI/InputFields/SelectInput";
import { SelectOption } from "../../../../Types/Common";
import { ReactElement, Reducer, useContext, useReducer, useState } from "react";
import ProjectDetailsAccordionSummary from "../../SharedComponents/ProjectDetailsAccordionSummary";
import { ProjectHttpService } from "../../../../Http/Project/Project.http.service";
import NumberCard from "../../../UI/NumberCard";
import { getErrorMessage } from "../../../../utils";
import { useSnackbar } from "notistack";
import CustomDatePicker from "../../../UI/InputFields/CustomDatePicker";
import useSaveProject from "../../../../Hooks/useSaveProject";
import { GlobalProjectEditContext } from "../../../../Context/ProjectDetailsContext";

const adoptionStrategySelectValues: SelectOption[] = [
  { id: "Acquisition", name: "Acquisition" },
  { id: "Exploration", name: "Exploration" },
  { id: "Partnership", name: "Partnership" },
];

interface StakeholdersSectionProps extends Omit<AccordionProps, "children"> {
  project: Project;
  handleSave: (withScroll?: boolean | undefined) => void;
}

export default function StakeholdersSection(
  props: StakeholdersSectionProps
): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const { globalEditMode, setGlobalEditMode } = useContext(
    GlobalProjectEditContext
  );
  const [editMode, setEditMode] = useState(false);
  const [project, setProject] = useReducer<
    Reducer<ProjectStakeholders, Partial<ProjectStakeholders>>
  >((state, newState) => ({ ...state, ...newState }), {
    id: props.project.id,
    businessUnit: props.project.businessUnit,
    projectLeader: props.project.projectLeader,
    projectLeaderId: props.project.projectLeaderId,
    projectSponsor: props.project.projectSponsor,
    projectSponsorId: props.project.projectSponsorId,
    projectTeam: props.project.projectTeam,
    programManager: props.project.programManager,
    programManagerId: props.project.programManagerId,
    otherStakeholders: props.project.otherStakeholders,
    budget: props.project.budget,
    startupAdoptionAmount: props.project.startupAdoptionAmount,
    pilotStartDate: props.project.pilotStartDate,
    deadline: props.project.deadline,
    adoptionStrategy: props.project.adoptionStrategy,
  });

  const handleCancelEdit = () => {
    if (!props.expanded) return;

    setProject({
      projectLeaderId: props.project.projectLeaderId,
      projectLeader: props.project.projectLeader,
      projectSponsorId: props.project.projectSponsorId,
      projectSponsor: props.project.projectSponsor,
      projectTeam: props.project.projectTeam,
      programManagerId: props.project.programManagerId,
      programManager: props.project.programManager,
      otherStakeholders: props.project.otherStakeholders,
      budget: props.project.budget,
      startupAdoptionAmount: props.project.startupAdoptionAmount,
      pilotStartDate: props.project.pilotStartDate,
      deadline: props.project.deadline,
      adoptionStrategy: props.project.adoptionStrategy,
    });
    setEditMode(false);
    setGlobalEditMode(false);
  };

  const handleSaveSection = async () => {
    if (!props.expanded) return;

    await ProjectHttpService.updateProject(project as Project)
      .then(() => {
        props.handleSave(false);
        setEditMode(false);
        setGlobalEditMode(false);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        enqueueSnackbar(`could not save the project: ${errorMessage}`, {
          variant: "error",
        });
      });
  };

  const handleEditButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setEditMode(true);
    setGlobalEditMode(true);
  };

  const EditButton = () => {
    return (
      <Button
        variant="contained"
        onClick={handleEditButtonClick}
        data-testid="edit-stakeholders"
        disabled={globalEditMode}
      >
        Edit
      </Button>
    );
  };

  useSaveProject(handleSaveSection);

  return (
    <div>
      <Accordion
        expanded={props.expanded}
        onChange={props.onChange}
        disabled={editMode}
      >
        <ProjectDetailsAccordionSummary
          actionButton={props.expanded && !editMode ? <EditButton /> : null}
        >
          Stakeholders and Timeline
        </ProjectDetailsAccordionSummary>
        <AccordionDetails>
          <Stack gap={5}>
            <Box display="flex" justifyContent="space-between" gap={5}>
              <Grid
                container
                rowSpacing={5}
                columnSpacing={3}
                maxWidth={editMode ? "70%" : "60%"}
              >
                <Grid item xs={6}>
                  <ClientContactSelect
                    editMode={editMode}
                    labelText="Project Leader"
                    fieldId="projectLeaderId"
                    field="projectLeader"
                    ventureClientId={project.businessUnit?.ventureClient.id}
                    onChange={setProject}
                    contactData={project.projectLeader}
                    required
                    toolTipText={PROJECT_LEADER_TOOLTIP}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ClientContactSelect
                    editMode={editMode}
                    labelText="Project Sponsor"
                    fieldId="projectSponsorId"
                    field="projectSponsor"
                    ventureClientId={project.businessUnit?.ventureClient.id}
                    onChange={setProject}
                    contactData={project.projectSponsor}
                    toolTipText={PROJECT_SPONSOR_TOOLTIP}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ClientContactSelect
                    editMode={editMode}
                    labelText="Project Team"
                    fieldId="projectTeam"
                    field="projectTeam"
                    ventureClientId={project.businessUnit?.ventureClient.id}
                    onChange={(projectTeam: ClientContactDTO[]) => {
                      setProject({ projectTeam });
                    }}
                    contactData={project.projectTeam}
                    multiSelect={true}
                    toolTipText={PROJECT_TEAM_TOOLTIP}
                    orientation="horizontal"
                    layoutColumn={2}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ClientContactSelect
                    editMode={editMode}
                    labelText="Venture Client Program Manager"
                    required
                    fieldId="programManagerId"
                    field="programManager"
                    ventureClientId={project.businessUnit?.ventureClient.id}
                    onChange={setProject}
                    contactData={project.programManager}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ClientContactSelect
                    editMode={editMode}
                    labelText="Other Stakeholders"
                    fieldId="otherStakeholders"
                    field="otherStakeholders"
                    ventureClientId={project.businessUnit?.ventureClient.id}
                    onChange={(otherStakeholders: ClientContactDTO[]) => {
                      setProject({ otherStakeholders });
                    }}
                    contactData={project.otherStakeholders}
                    multiSelect={true}
                    toolTipText={OTHER_STAKEHOLDERS_TOOLTIP}
                  />
                </Grid>
                <Grid item container xs={12} spacing={3}>
                  <Grid item xs={6}>
                    <NumberCard
                      id="budget"
                      label="Amount for Pilot Project"
                      value={project.budget}
                      onChange={(e) =>
                        setProject({ budget: parseInt(e.target.value) })
                      }
                      editMode={editMode}
                      hideUnit
                      fullWidth
                      inputTooltip={BUDGET_TOOLTIP}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <NumberCard
                      id="startupAdoptionAmount"
                      label="Amount for Startup Solution Adoption"
                      value={project.startupAdoptionAmount}
                      onChange={(e) =>
                        setProject({
                          startupAdoptionAmount: e.target.value,
                        })
                      }
                      editMode={editMode}
                      hideUnit
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Box
                flex="1 1 0"
                maxWidth={editMode ? "initial" : "30%"}
                display="flex"
                flexDirection="column"
                gap={5}
              >
                <CustomDatePicker
                  id="pilotStartDate"
                  label="Starting Date of Pilot Project"
                  value={
                    project.pilotStartDate
                      ? new Date(project.pilotStartDate)
                      : null
                  }
                  onChange={(value) => {
                    setProject({
                      pilotStartDate: value?.toString(),
                    });
                  }}
                  editMode={editMode}
                  required
                />
                <CustomDatePicker
                  id="deadline"
                  label="Deadline"
                  value={project.deadline ? new Date(project.deadline) : null}
                  onChange={(value) => {
                    setProject({
                      deadline: value?.toString(),
                    });
                  }}
                  editMode={editMode}
                  required
                  toolTipText={DEADLINE_TOOLTIP}
                />
                <SelectInput
                  id="adoptionStrategy"
                  label="Adoption Strategy"
                  selectValues={adoptionStrategySelectValues}
                  value={project.adoptionStrategy}
                  onChange={(e) =>
                    setProject({ adoptionStrategy: e.target.value })
                  }
                  editMode={editMode}
                  toolTipText={ADOPTION_STRATEGY_TOOLTIP}
                />
              </Box>
            </Box>
            {editMode && (
              <Box width="fit-content" marginLeft="auto" display="flex" gap={2}>
                <Button onClick={handleCancelEdit}>Cancel</Button>
                <Button variant="contained" onClick={handleSaveSection}>
                  Save
                </Button>
              </Box>
            )}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
