import {
  Box,
  Button,
  Checkbox,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import { Check, FastForward, Undo } from "@mui/icons-material";
import { ReactElement, useEffect, useRef, useState } from "react";
import {
  ProjectTask as ProjectTaskType,
  ProjectTaskStatus,
} from "../../../../../Types/ProjectTask";
import theme from "../../../../../theme";
import { SnackbarKey } from "notistack";
import { FunnelStage } from "../../../../../Types/Project";
import { capitalizeFirstLetter } from "../../../../../utils";
import { funnelStages } from "../../../../../Constants/FunnelStages";

const SkipButton = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  color: theme.palette.text.action.main,
  cursor: "pointer",
  columnGap: theme.spacing(0.5),
}));

const FadingContainer = styled(Box)(() => ({
  animation: "fadeIn 0.3s ease-in-out",
  "@keyframes fadeIn": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
}));

const BackButton = styled(Box)(() => ({
  color: theme.palette.text.action.main,
  cursor: "pointer",
  display: "flex",
  width: "fit-content",
  columnGap: theme.spacing(0.5),
}));

const ProjectTaskContainer = styled(Paper)(() => ({
  padding: theme.spacing(2),
  borderRadius: theme.shape.radius.minimal,
  rowGap: theme.spacing(1.5),
  display: "grid",
}));

const TasksCompleted = styled("div")(({ theme }) => ({
  display: "flex",
  columnGap: theme.spacing(1),
}));

interface ProjectTaskProps {
  currentTask?: ProjectTaskType;
  previousTask?: ProjectTaskType;
  clusterName?: string;
  hideBackButton: boolean;
  funnelStage: FunnelStage;
  projectFunnelStage: FunnelStage;
  tasksToComplete: ProjectTaskType[];
  moveProjectToTheNewStage?: () => void;
  updateTask: (
    status: ProjectTaskStatus,
    taskToUpdate?: ProjectTaskType
  ) => Promise<SnackbarKey | undefined>;
  onRefresh: () => Promise<void>;
}

export default function ProjectTask(props: ProjectTaskProps): ReactElement {
  const [delayType, setDelayType] = useState<ProjectTaskStatus>();
  const taskTimeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    return () => {
      clearUpdateTimeout();
    };
  }, []);

  const handleDelayedUpdate = (
    action: ProjectTaskStatus,
    taskToUpdate?: ProjectTaskType
  ) => {
    setDelayType(action);
    taskTimeoutRef.current = setTimeout(() => {
      handleTaskUpdate(action, taskToUpdate);
    }, 300);
  };

  const clearUpdateTimeout = () => {
    if (taskTimeoutRef.current) {
      clearInterval(taskTimeoutRef.current);
    }
    setDelayType(undefined);
  };

  const handleTaskUpdate = async (
    action: ProjectTaskStatus,
    taskToUpdate?: ProjectTaskType
  ) => {
    // setModalOpen(false);
    if (delayType) return;
    await props.updateTask(action, taskToUpdate);
    await props.onRefresh();
    clearUpdateTimeout();
  };

  const getNextFunnelStage = () => {
    const funnelStageIndex = funnelStages.indexOf(props.funnelStage);
    return funnelStages[funnelStageIndex + 1];
  };

  return (
    <>
      <ProjectTaskContainer
        key={props.currentTask?.id}
        data-testid="current-task"
        variant="outlined"
      >
        {props.currentTask && (
          <FadingContainer>
            <Box
              display="flex"
              flexDirection="column"
              rowGap={theme.spacing(1.5)}
            >
              <Typography variant="subtitle2">Next task</Typography>
              {props.clusterName && (
                <Typography color="text.disabled" variant="caption">
                  {props.clusterName}
                </Typography>
              )}
              <Box display="flex" justifyContent="space-between">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  gap={theme.spacing(1)}
                >
                  {delayType === "SKIPPED" ? (
                    <FastForward />
                  ) : delayType === "COMPLETED" ? (
                    <Checkbox
                      key="checked-task-checkbox"
                      sx={{ padding: "0" }}
                      color="success"
                      checked
                    />
                  ) : (
                    <Checkbox
                      sx={{ padding: "0" }}
                      onChange={() =>
                        handleDelayedUpdate("COMPLETED", props.currentTask)
                      }
                      data-testid="task-checkbox"
                    />
                  )}
                  <Typography>{props.currentTask.description}</Typography>
                </Box>
                <SkipButton
                  onClick={() => {
                    handleDelayedUpdate("SKIPPED", props.currentTask);
                  }}
                >
                  <FastForward fontSize="small" />
                  <Typography fontSize="small">Skip</Typography>
                </SkipButton>
              </Box>
            </Box>
          </FadingContainer>
        )}
        {props.tasksToComplete.length === 0 &&
          (props.funnelStage === props.projectFunnelStage &&
          props.funnelStage !== "adopt" ? (
            <Box>
              <Typography variant="subtitle2">Next Phase</Typography>
              <Button
                variant="contained"
                sx={{ mt: 1, textTransform: "none" }}
                onClick={props.moveProjectToTheNewStage}
              >
                Move to {capitalizeFirstLetter(getNextFunnelStage())}
              </Button>
            </Box>
          ) : (
            <TasksCompleted>
              <Check color="success" fontSize="small" />
              <Typography>All tasks are completed</Typography>
            </TasksCompleted>
          ))}
        {!props.hideBackButton && (
          <FadingContainer>
            <BackButton
              data-testid="task-back-button"
              onClick={() =>
                handleTaskUpdate("NOT_COMPLETED", props.previousTask)
              }
            >
              <Undo fontSize="small" />
              <Typography fontSize="small">Back</Typography>
            </BackButton>
          </FadingContainer>
        )}
      </ProjectTaskContainer>
      {/* <Dialog open={modalOpen} maxWidth="sm" data-testid="verify-task-modal">
        <Box sx={{ padding: theme.spacing(2) }}>
          <DialogTitle>Are you sure you finished this task?</DialogTitle>
          <DialogContent sx={{ borderBottom: "none" }}>
            Make sure you already completed all activities related to{" "}
            {props.currentTask.description} before completing this task. Do
            you want to proceed?
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ color: theme.palette.grey[600] }}
              onClick={() => {
                setModalOpen(false);
              }}
            >
              No
            </Button>
            <Button
              onClick={() => {
                handleTaskUpdate("COMPLETED");
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </Box>
      </Dialog> */}
    </>
  );
}
