import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { ReactElement, useReducer, Reducer } from "react";
import { VentureClientHttpService } from "../../Http/VentureClient/VentureClient.http.service";
import { CreateVentureClientDTO } from "../../Types/VentureClient";
interface CreateVentureClientModalProps {
  handleModalClose: () => void;
  modalOpen: boolean;
  handleCreateNewVentureClient: () => void;
}
export default function CreateVentureClientModal(
  props: CreateVentureClientModalProps
): ReactElement {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [newVentureClient, setNewVentureClient] = useReducer<
    Reducer<CreateVentureClientDTO, Partial<CreateVentureClientDTO>>
  >(
    (state, newState) => ({ ...state, ...newState }),
    {} as CreateVentureClientDTO
  );

  const handleCreateVentureClient = async () => {
    await VentureClientHttpService.createVentureClient(newVentureClient);
    props.handleCreateNewVentureClient();
    props.handleModalClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      fullScreen={fullScreen}
      open={props.modalOpen}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        sx: {
          gap: theme.spacing(4),
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title">
        Create Venture Client
      </DialogTitle>
      <DialogContent>
        <Stack noValidate autoComplete="off" component="form" gap={4}>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            required
            onChange={(event) =>
              setNewVentureClient({ name: event.target.value })
            }
          />
          <TextField
            label="Venture Client Unit Name"
            variant="outlined"
            fullWidth
            required
            onChange={(event) =>
              setNewVentureClient({ unitName: event.target.value })
            }
          />
          <TextField
            label="Industry"
            variant="outlined"
            fullWidth
            required
            onChange={(event) =>
              setNewVentureClient({ industry: event.target.value })
            }
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={props.handleModalClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleCreateVentureClient} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
