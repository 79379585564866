import {
  Dialog,
  DialogTitle,
  Button,
  Box,
  Typography,
  TextField,
  CircularProgress,
  Stack,
} from "@mui/material";
import theme from "../../../../../../../theme";
import { ReactElement, Reducer, useReducer, useState } from "react";
import { SolutionCluster } from "../../../../../../../Types/SolutionCluster";
import { SolutionClusterHttpService } from "../../../../../../../Http/SolutionCluster/SolutionCluster.http.service";
import { getErrorMessage } from "../../../../../../../utils";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import ScrollableDialogContent from "../../../../../../UI/Modals/ScrollableDialogContent";

const isClusterNameEmpty = (cluster: SolutionCluster) => {
  return !cluster.name;
};

interface Props {
  setModalOpen: (state: boolean) => void;
  modalOpen: boolean;
  handleSave: () => void;
  solutionCluster?: SolutionCluster;
  numberOfOpportunities?: number;
  totalFunding?: string;
  handleNewCluster?: (clusterId: number) => void;
}

const ManageSolutionClusterModal = (props: Props): ReactElement => {
  const { id } = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [newCluster, setNewCluster] = useReducer<
    Reducer<SolutionCluster, Partial<SolutionCluster>>
  >(
    (state, newState) => ({ ...state, ...newState }),
    props.solutionCluster || ({ projectId: Number(id) } as SolutionCluster)
  );

  const isCreateMode = !props.solutionCluster;
  const totalStartups = isCreateMode ? 0 : props.numberOfOpportunities;
  const totalFunding = isCreateMode ? 0 : props.totalFunding;

  const handleSave = () => {
    if (isClusterNameEmpty(newCluster)) {
      return enqueueSnackbar("Please provide Cluster Name", {
        variant: "error",
      });
    }

    return isCreateMode ? createCluster() : editCluster();
  };

  const createCluster = async () => {
    setIsLoading(true);
    await SolutionClusterHttpService.createSolutionCluster(newCluster)
      .then((cluster) => {
        props.handleSave();
        props.handleNewCluster && props.handleNewCluster(cluster.id);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(`Could not create cluster: ${errorMessage}`, {
          variant: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
        props.setModalOpen(false);
      });
  };

  const editCluster = async () => {
    setIsLoading(true);
    await SolutionClusterHttpService.updateSolutionCluster(newCluster)
      .then(() => {
        props.handleSave();
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        return enqueueSnackbar(`Could not update Cluster: ${errorMessage}`, {
          variant: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
        props.setModalOpen(false);
      });
  };

  return (
    <Dialog
      open={props.modalOpen}
      fullWidth
      data-testid="manage-cluster-modal"
      PaperProps={{
        sx: {
          gap: theme.spacing(4),
        },
      }}
    >
      <DialogTitle data-testid="manage-cluster-header">
        {isCreateMode ? "Add" : "Edit"} Solution Cluster
      </DialogTitle>
      <ScrollableDialogContent>
        <Stack gap={4}>
          <Stack gap={4} data-testid="manage-cluster-form">
            <TextField
              id="clusterTitle"
              required
              label="Cluster Title"
              value={newCluster.name || ""}
              variant="outlined"
              onChange={(event) => setNewCluster({ name: event.target.value })}
              inputProps={{ "data-testid": "cluster-name-input" }}
              InputLabelProps={{ shrink: true }}
              sx={{ mt: 1 }}
            />
            <TextField
              id="clusterDescription"
              label="Description"
              value={newCluster.description || ""}
              variant="outlined"
              onChange={(event) =>
                setNewCluster({ description: event.target.value })
              }
              inputProps={{ "data-testid": "cluster-description-input" }}
              InputLabelProps={{ shrink: true }}
              multiline
              minRows={3}
            />
          </Stack>
          <Stack data-testid="manage-cluster-modal-stats">
            <Box py={0.5} display="flex" justifyContent="space-between">
              <Typography variant="caption">Total Startups</Typography>
              <Typography variant="caption" fontWeight="bold">
                {totalStartups}
              </Typography>
            </Box>
            <Box py={0.5} display="flex" justifyContent="space-between">
              <Typography variant="caption">Total Funding (in USD)</Typography>
              <Typography variant="caption" fontWeight="bold">
                ${totalFunding}M
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </ScrollableDialogContent>
      <>
        {isLoading ? (
          <CircularProgress size={24} />
        ) : (
          <Box alignSelf="flex-end" display="flex" gap={2}>
            <Button onClick={() => props.setModalOpen(false)}>Cancel</Button>
            <Button variant="contained" onClick={handleSave}>
              Save
            </Button>
          </Box>
        )}
      </>
    </Dialog>
  );
};

export default ManageSolutionClusterModal;
