import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  Stack,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import { Preset } from "../../../Types/Preset";
import { PresetsHttpService } from "../../../Http/Preset/Preset.http.service";
import { LeadsPresetsHttpService } from "../../../Http/LeadsPreset/LeadsPreset.http.service";
import { enqueueSnackbar } from "notistack";
import theme from "../../../theme";
import { getErrorMessage } from "../../../utils";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { GridInitialState } from "@mui/x-data-grid-premium";
import { SelectOption } from "../../../Types/Common";
import useRoles from "../../../Hooks/useRoles";
import { VentureClientHttpService } from "../../../Http/VentureClient/VentureClient.http.service";
import { SelectInput } from "../../UI/InputFields/SelectInput";

interface AddPresetModalProps {
  modalOpen: boolean;
  setModalOpen: (state: boolean) => void;
  setPresetList: (state: Preset[]) => void;
  presetList: Preset[];
  setAppliedPreset: (preset: Preset) => void;
  apiRef: React.MutableRefObject<GridApiPremium>;
  griStateToSave: (state: GridInitialState) => void;
  currentTab: "project" | "lead";
}

const AddPresetModal = ({
  modalOpen,
  setModalOpen,
  presetList,
  setPresetList,
  apiRef,
  setAppliedPreset,
  griStateToSave,
  currentTab,
}: AddPresetModalProps): ReactElement => {
  const { isExternalUser, ventureClientId } = useRoles();

  const [presetName, setPresetName] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [ventureClientSelectOptions, setVentureClientSelectOptions] = useState<
    SelectOption[]
  >([]);
  const [ventureClientSelected, setVentureClientSelected] = useState<
    number | null
  >(null);

  const sharePreset = ventureClientSelected !== null;

  useEffect(() => {
    if (!isExternalUser) {
      VentureClientHttpService.getVentureClients().then((results) => {
        const ventureClientSelectOptions = results.map((ventureClient) => ({
          id: ventureClient.id,
          name: ventureClient.name,
        }));
        setVentureClientSelectOptions(ventureClientSelectOptions);
      });
    }
  }, []);

  const handleCancel = () => {
    setModalOpen(false);
    setPresetName("");
    setWarningMessage("");
    setVentureClientSelected(null);
  };

  const handleSave = async () => {
    try {
      const currentGridState = apiRef.current.exportState();
      const response: Preset =
        currentTab === "project"
          ? await PresetsHttpService.createPreset({
              name: presetName,
              state: JSON.stringify(currentGridState),
              ventureClientId: ventureClientSelected,
            })
          : await LeadsPresetsHttpService.createLeadsPreset({
              name: presetName,
              state: JSON.stringify(currentGridState),
              ventureClientId: ventureClientSelected,
            });

      setPresetList([...presetList, response]);
      setAppliedPreset(response);
      griStateToSave(JSON.parse(response.state));
      enqueueSnackbar("Preset Successfully Created", {
        variant: "success",
      });
      handleCancel();
    } catch (error: any) {
      if (error.response && error.response.status === 409) {
        setWarningMessage(
          "Preset name already used. Please try a different name."
        );
      }
      const errorMessage = getErrorMessage(error);
      enqueueSnackbar(`Could not save preset: ${errorMessage}`, {
        variant: "error",
      });
    }
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPresetName(event.target.value);
  };

  const handleSharePresetToggle = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      setVentureClientSelected(
        isExternalUser
          ? ventureClientId ?? null
          : +ventureClientSelectOptions[0].id
      );
    } else {
      setVentureClientSelected(null);
    }
  };

  const handleSelectVentureClient = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    ventureClientSelected && setVentureClientSelected(+event.target.value);
  };

  return (
    <Dialog
      open={modalOpen}
      maxWidth="xs"
      data-testid="add-preset-modal"
      PaperProps={{
        sx: {
          gap: theme.spacing(4),
        },
      }}
    >
      <Stack gap={2}>
        <DialogTitle>Save Preset</DialogTitle>
        <DialogContent>
          <Stack gap={2}>
            <Typography color="text.mediumEmphasis">
              Save your current column, filter, and sorting settings as a preset
              for later use.
            </Typography>
            <TextField
              autoFocus
              margin="none"
              id="presetName"
              label="Name"
              fullWidth
              value={presetName}
              onChange={handleNameChange}
              error={warningMessage !== ""}
              helperText={warningMessage}
              data-testid="preset-name-input"
              InputLabelProps={{ shrink: true }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={sharePreset}
                  onChange={handleSharePresetToggle}
                />
              }
              label={`Share preset with ${
                isExternalUser ? "team" : "Venture Client"
              }`}
            />
            {sharePreset && !isExternalUser && (
              <SelectInput
                label="Venture Client"
                selectValues={ventureClientSelectOptions}
                value={
                  ventureClientSelectOptions.length ? ventureClientSelected : ""
                }
                onChange={handleSelectVentureClient}
                editMode={true}
                required
                fullWidth
              />
            )}
          </Stack>
        </DialogContent>
      </Stack>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          disabled={presetName === ""}
          data-testid="save-preset-button"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPresetModal;
