import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { ReactElement, Reducer, useReducer, useEffect } from "react";
import { StartupHttpService } from "../../Http/Startup/Startup.http.service";
import { CreateStartupDTO } from "../../Types/Startup";
import { SelectInput } from "../UI/InputFields/SelectInput";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import { useSnackbar } from "notistack";
import CustomDatePicker from "../UI/InputFields/CustomDatePicker";
import {
  investmentStagesOptions,
  legalEntities,
} from "../../Constants/Startup";

interface CreateStartupModalProps {
  startupName: string;
  handleModalClose: () => void;
  modalOpen: boolean;
  handleCreateResponse: (startupId: number) => void;
}

const initialModalState = {
  name: "",
  employees: "",
  legalEntity: "",
  currentInvestmentStage: "",
  website: "",
  shortDescription: "",
} as CreateStartupDTO;

const MAX_CHARACTER = 255;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        marginBlock: theme.spacing(2),
        minWidth: "35ch",
        "& .MuiFormHelperText-root": {
          position: "absolute",
          right: 0,
          bottom: 0,
        },
      },
    },
  })
);

export default function CreateStartupModal(
  props: CreateStartupModalProps
): ReactElement {
  const theme = useTheme();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [newStartup, setNewStartup] = useReducer<
    Reducer<CreateStartupDTO, Partial<CreateStartupDTO>>
  >((state, newState) => ({ ...state, ...newState }), initialModalState);

  // update state on creation with startupName prop
  useEffect(() => {
    setNewStartup({ name: props.startupName });
  }, [props.startupName]);

  const handleCreateStartup = async () => {
    try {
      const response = await StartupHttpService.createStartup({
        ...newStartup,
      });
      props.handleModalClose();
      props.handleCreateResponse(response);
      // eslint-disable-next-line
    } catch (error: any) {
      enqueueSnackbar(error.response.data.message || error.message, {
        variant: "error",
      });
    }
  };

  const employeesSelectValues = [
    { id: "<10", name: "<10" },
    { id: "<25", name: "<25" },
    { id: "<50", name: "<50" },
    { id: "<100", name: "<100" },
    { id: "<500", name: "<500" },
    { id: ">500", name: ">500" },
    { id: "Undisclosed", name: "Undisclosed" },
  ];

  const technologyReadinessSelectValues = [
    { id: "Idea Stage", name: "Idea Stage" },
    { id: "Prototype Stage", name: "Prototype Stage" },
    { id: "Early Market Stage", name: "Early Market Stage" },
    { id: "Growth Market Stage", name: "Growth Market Stage" },
  ];

  return (
    <Dialog
      data-testid="create-startup-modal"
      fullScreen={fullScreen}
      open={props.modalOpen}
      aria-labelledby="responsive-dialog-title"
      className={classes.root}
      maxWidth="md"
    >
      <DialogTitle
        id="responsive-dialog-title-create-startup"
        sx={{
          justifyContent: "space-between",
          ...theme.typography.h3,
        }}
        mb={4}
      >
        Create New Startup
      </DialogTitle>
      <DialogContent>
        <Grid container columnSpacing={2} columns={12}>
          <Grid item xs={6}>
            <TextField
              label="Company Name"
              id="startup-name"
              variant="outlined"
              value={newStartup.name}
              fullWidth
              required
              onChange={(event) => setNewStartup({ name: event.target.value })}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectInput
              label="Legal Entity"
              fullWidth
              selectValues={legalEntities.map((entity) => {
                return { id: entity, name: entity };
              })}
              required
              value={newStartup.legalEntity || ""}
              onChange={(event) =>
                setNewStartup({ legalEntity: event.target.value as string })
              }
              editMode
              ignoreValidation
            />
          </Grid>

          <Grid item xs={6}>
            <SelectInput
              label="Number Of Employees"
              fullWidth
              selectValues={employeesSelectValues}
              value={newStartup.employees || ""}
              onChange={(event) =>
                setNewStartup({ employees: event.target.value as string })
              }
              required
              editMode
              ignoreValidation
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Website"
              id="startup-website"
              variant="outlined"
              fullWidth
              required
              onChange={(event) =>
                setNewStartup({ website: event.target.value })
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Short Description"
              id="startup-short-description"
              variant="outlined"
              fullWidth
              required
              onChange={(event) =>
                setNewStartup({ shortDescription: event.target.value })
              }
              inputProps={{
                maxLength: MAX_CHARACTER,
              }}
              helperText={
                newStartup.shortDescription.length + "/" + MAX_CHARACTER
              }
            />
          </Grid>
          <Grid item xs={6}>
            <CustomDatePicker
              label="Year Founded"
              value={newStartup.dateFounded || null}
              onChange={(value) =>
                setNewStartup({
                  dateFounded: value,
                })
              }
              id="dateFounded"
              editMode={true}
              fullWidth={true}
              views={["year"]}
              inputFormat="YYYY"
            />
          </Grid>
          <Grid item xs={6}>
            <SelectInput
              label="Investment Stage"
              required
              fullWidth
              selectValues={investmentStagesOptions}
              value={newStartup.currentInvestmentStage || ""}
              onChange={(event) =>
                setNewStartup({
                  currentInvestmentStage: event.target.value as string,
                })
              }
              disableSorting
              editMode
              ignoreValidation
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Funding Amount (in $M)"
              id="startup-funding"
              variant="outlined"
              required
              disabled={newStartup.fundingIsUndisclosed}
              inputProps={{ type: "number" }}
              placeholder="Value in $M"
              onChange={(event) =>
                setNewStartup({
                  totalFunding: parseFloat(event.target.value),
                })
              }
            />
          </Grid>
          <Grid
            item
            xs={6}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <FormGroup sx={{ marginTop: "auto", marginBottom: "auto" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="fundingIsUndisclosed"
                    checked={newStartup.fundingIsUndisclosed}
                    onChange={(e) => {
                      setNewStartup({
                        fundingIsUndisclosed: e.target.checked,
                      });
                      if (e.target.checked) {
                        setNewStartup({ totalFunding: null });
                      }
                    }}
                  />
                }
                label="Funding is Undisclosed"
              />
            </FormGroup>
          </Grid>

          <Grid item xs={6}>
            <SelectInput
              fullWidth={true}
              label="Technology Readiness"
              selectValues={technologyReadinessSelectValues}
              value={newStartup.technologyReadiness || ""}
              onChange={(event) =>
                setNewStartup({
                  technologyReadiness: event.target.value as string,
                })
              }
              editMode={true}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box display="flex" alignItems="flex-end" gap={1}>
          <Button onClick={props.handleModalClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCreateStartup} variant="contained">
            Create Startup
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
