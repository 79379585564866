import {
  Accordion,
  AccordionSummary,
  IconButton,
  Typography,
  Box,
  AccordionDetails,
  styled,
  Button,
} from "@mui/material";
import { Fragment, ReactElement, useEffect, useRef, useState } from "react";
import theme from "../../../../theme";
import { getErrorMessage, thousandSeparator } from "../../../../utils";
import ManageVariableModal from "./ManageVariableModal";
import AddIcon from "@mui/icons-material/Add";
import Variable from "./Variable";
import { CalculationType, VariableType } from "../../../../Types/ImpactValue";
import { VariableHttpService } from "../../../../Http/Variable/Variable.http.service";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useSnackbar } from "notistack";
import { KeyboardArrowDown } from "@mui/icons-material";
import DeleteOrRemoveModal from "../../../UI/Modals/DeleteOrRemoveModal/DeleteOrRemoveModal";
import { CalculationHttpService } from "../../../../Http/Calculation/Calculation.http.service";

const StyledAccordion = styled(Accordion)(() => ({
  boxShadow: "none",
  background: "transparent",
  marginBlock: 1,
  "&:before": {
    backgroundColor: "transparent",
  },
  "&:not(:first-of-type)": {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
  },
  "& .MuiAccordion-region": {
    position: "relative",
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  padding: theme.spacing(1, 4),
  "& .MuiAccordionSummary-content": {
    display: "flex",
    alignItems: "center",
  },
  "&:hover button": {
    opacity: "100%",
  },
}));

const CalculationValue = styled(Box)(() => ({
  display: "flex",
  gap: theme.spacing(1),
  alignItems: "center",
  color: theme.palette.text.brand.accessibility,
  marginLeft: "auto",
}));

const StyledAccordionDetails = styled(AccordionDetails, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})<{ $showLeftShadow: boolean; $showRightShadow: boolean }>(
  ({ $showLeftShadow, $showRightShadow }) => ({
    padding: theme.spacing(1, 4),
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
    overflow: "scroll",
    "&::before, &::after": {
      content: "''",
      position: "absolute",
      top: 0,
      bottom: 0,
      width: theme.spacing(5),
      background: `linear-gradient(269.69deg, #FFFFFF 9.48%, rgba(255, 255, 255, 0) 99.73%)`,
      zIndex: 1,
      transition: "opacity 0.3s",
    },
    "&::before": {
      left: 0,
      transform: "scaleX(-1)",
      opacity: $showLeftShadow ? 1 : 0,
    },
    "&::after": {
      right: 0,
      opacity: $showRightShadow ? 1 : 0,
    },
  })
);

interface Props {
  calculation: CalculationType;
  index: number;
  variables: VariableType[];
  result: number;
  handleImpactValue: () => void;
  expanded: boolean;
  handleToggleExpand: () => void;
}

const Calculation = (props: Props): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const accordionDetailsRef = useRef<HTMLDivElement>(null);
  const [editVariableModalOpen, setEditVariableModalOpen] = useState(false);
  const [deleteCalculationModalOpen, setDeleteCalculationModalOpen] =
    useState(false);
  const [showLeftShadow, setShowLeftShadow] = useState(false);
  const [showRightShadow, setShowRightShadow] = useState(false);
  const { calculation, index, variables, result } = props;

  useEffect(() => {
    const container = accordionDetailsRef.current;
    if (!container) return;

    const handleScroll = () => {
      setShowLeftShadow(container.scrollLeft > 0);
      setShowRightShadow(
        container.scrollWidth - container.clientWidth > container.scrollLeft
      );
    };

    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, [accordionDetailsRef]);

  const deleteOperatorFromSecondVarible = async () => {
    const secondVariable = variables[1];
    if (secondVariable?.operator) {
      await VariableHttpService.updateVariable({
        ...secondVariable,
        operator: null,
      }).catch((error) => {
        const errorMessage = getErrorMessage(error);
        enqueueSnackbar(`Could not update calculation: ${errorMessage}`, {
          variant: "error",
        });
      });
    }
  };

  const handleDeleteVariable = async (id: number) => {
    const isFirstVariable = variables[0].id === id;
    if (isFirstVariable) {
      await deleteOperatorFromSecondVarible();
    }
  };

  const handleDeleteClick = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setDeleteCalculationModalOpen(true);
  };

  const deleteCalculation = async (id: number) => {
    await CalculationHttpService.deleteCalculation(id)
      .then(() => {
        props.handleImpactValue();
        setDeleteCalculationModalOpen(false);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        enqueueSnackbar(`Could not delete calculation: ${errorMessage}`, {
          variant: "error",
        });
      });
  };

  return (
    <Fragment key={calculation.id}>
      <StyledAccordion
        square
        expanded={props.expanded}
        onChange={props.handleToggleExpand}
      >
        <StyledAccordionSummary
          data-testid={`calculation-${index + 1}`}
          expandIcon={
            <IconButton>
              <KeyboardArrowDown />
            </IconButton>
          }
        >
          <Typography variant="caption" color="text.mediumEmphasis">
            {calculation.name} {index + 1}
          </Typography>
          <IconButton
            sx={{ opacity: 0 }}
            onClick={handleDeleteClick}
            data-testid={`delete-calculation-${index + 1}`}
          >
            <DeleteOutlineIcon sx={{ fontSize: "1rem" }} />
          </IconButton>
          <CalculationValue>
            <Typography variant="subtitle2">€</Typography>
            <Typography
              variant="subtitle1"
              data-testid={`calculation-${index + 1}-result`}
            >
              {thousandSeparator(result, 2)}
            </Typography>
          </CalculationValue>
        </StyledAccordionSummary>
        <StyledAccordionDetails
          data-testid={`calculation-${index + 1}-details`}
          ref={accordionDetailsRef}
          $showLeftShadow={showLeftShadow}
          $showRightShadow={showRightShadow}
        >
          {variables.map((variable) => (
            <Variable
              key={variable.id}
              variable={variable}
              handleImpactValue={props.handleImpactValue}
              handleDeleteVariable={handleDeleteVariable}
            />
          ))}

          <Button
            onClick={() => setEditVariableModalOpen(true)}
            data-testid="add-variable"
            startIcon={<AddIcon fontSize="small" />}
            sx={{ flexShrink: 0 }}
          >
            Add Variable
          </Button>
        </StyledAccordionDetails>
      </StyledAccordion>
      {editVariableModalOpen && (
        <ManageVariableModal
          calculationId={calculation.id}
          setModalOpen={setEditVariableModalOpen}
          modalOpen={editVariableModalOpen}
          handleImpactValue={props.handleImpactValue}
          hasSingleVariable={variables.length === 0}
        />
      )}
      {deleteCalculationModalOpen && (
        <DeleteOrRemoveModal
          id={calculation.id}
          entity="Calculation"
          modalOpen={deleteCalculationModalOpen}
          setModalOpen={setDeleteCalculationModalOpen}
          handleDelete={deleteCalculation}
          actionType="delete"
        />
      )}
    </Fragment>
  );
};

export default Calculation;
