import { Avatar, Box, BoxProps, Typography, styled } from "@mui/material";
import theme from "../../../theme";
import { ReactElement } from "react";
import { nameToInitials } from "../../../utils";

const ContactAvatar = styled(Avatar, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $isVentureAssociate }: { $isVentureAssociate: boolean }) => ({
  backgroundColor: $isVentureAssociate
    ? theme.palette.surface.brand.main
    : theme.palette.surface.primary.invert,
  width: theme.spacing(4),
  height: theme.spacing(4),
  ...theme.typography.body1,
}));

export interface ContactNameProps extends BoxProps {
  avatar?: string;
  name: string;
  title?: string;
  isVentureAssociate?: boolean;
  orientation?: "horizontal" | "vertical";
  invertColors?: boolean;
  gender?: string | null;
  founder?: boolean;
}
const ContactName = ({
  isVentureAssociate = false,
  name,
  title,
  avatar,
  orientation = "vertical",
  invertColors = false,
  gender,
  founder,
  ...props
}: ContactNameProps): ReactElement => {
  return (
    <Box
      display="flex"
      flexDirection={orientation === "horizontal" ? "row" : "column"}
      gap={2}
      {...props}
    >
      <ContactAvatar
        $isVentureAssociate={isVentureAssociate}
        alt={name}
        src={avatar}
      >
        {nameToInitials(name)}
      </ContactAvatar>
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Typography
          className="contact-name"
          variant="subtitle2"
          color={
            !!props.onClick
              ? "text.action.main"
              : invertColors
              ? "text.primaryInvert.main"
              : "text.primary"
          }
          sx={{ cursor: !!props.onClick ? "pointer" : "default" }}
        >
          {name}
          {gender && (
            <Typography
              ml={0.5}
              variant="body2"
              color="text.mediumEmphasis"
              component="span"
            >
              ({gender})
            </Typography>
          )}
        </Typography>
        <Typography
          variant="body2"
          color={
            invertColors
              ? "text.primaryInvert.mediumEmphasis"
              : "text.mediumEmphasis"
          }
        >
          {founder && "Founder "}
          {title && title}
        </Typography>
      </Box>
    </Box>
  );
};

export default ContactName;
