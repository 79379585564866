import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
  debounce,
} from "@mui/material";
import { Startup, StartupFunding } from "../../../../Types/Startup";
import {
  ReactElement,
  Reducer,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import PitchBookSyncDetails from "../Details/PitchBookSyncDetails/PitchBookSyncDetails";
import { StartupHttpService } from "../../../../Http/Startup/Startup.http.service";
import { formatDate, formatFunding, getErrorMessage } from "../../../../utils";
import { enqueueSnackbar } from "notistack";
import NumberCard from "../../../UI/NumberCard";
import { MultiSelectInput } from "../../../UI/InputFields/MultiSelectInput";
import Investor from "../../../../Types/Investor";
import { SelectOption } from "../../../../Types/Common";
import { InvestorsHttpService } from "../../../../Http/Investors/Investors.http.service";
import CustomExpendableText from "../../../UI/CustomExpendableText";
import CustomDatePicker from "../../../UI/InputFields/CustomDatePicker";
import { SelectInput } from "../../../UI/InputFields/SelectInput";
import { investmentStagesOptions } from "../../../../Constants/Startup";
import SingleSelectInput from "../../../UI/InputFields/SingleSelectInput";
import { CompaniesHttpService } from "../../../../Http/Companies/Companies.http.service";
import ReferenceClient from "../../../../Types/Company";
import CreateEntityByName from "../../../UI/Modals/CreateEntityByName/CreateEntityByName";
import { GlobalStartupEditContext } from "../../../../Context/StartupDetailsContext";
import useRoles from "../../../../Hooks/useRoles";

interface FundingSectionProps {
  startup: Startup;
  handleSave: (withScroll?: boolean | undefined) => void;
}

const FundingSection = (props: FundingSectionProps): ReactElement => {
  const { globalEditMode, setGlobalEditMode } = useContext(
    GlobalStartupEditContext
  );
  const { isExternalUser } = useRoles();
  const [editMode, setEditMode] = useState(false);
  const [startup, setStartup] = useReducer<
    Reducer<StartupFunding, Partial<StartupFunding>>
  >((state, newState) => ({ ...state, ...newState }), {
    id: props.startup.id,
    totalFunding: props.startup.totalFunding,
    currentInvestmentStage: props.startup.currentInvestmentStage,
    lastFundingDate: props.startup.lastFundingDate,
    fundingIsUndisclosed: props.startup.fundingIsUndisclosed,
    lastFunding: props.startup.lastFunding,
    investors: props.startup.investors,
    parentCompany: props.startup.parentCompany,
    parentCompanyId: props.startup.parentCompanyId,
    referenceClients: props.startup.referenceClients,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [investors, setInvestors] = useState<Investor[]>([]);
  const [referenceClients, setReferenceClients] = useState<ReferenceClient[]>(
    []
  );
  const [createInvestorsModalOptions, setCreateInvestorsModalOptions] =
    useState({
      open: false,
      value: "",
    });
  const [createParentCompany, setCreateParentCompany] = useState(false);
  const [totalFundingInput, setTotalFundingInput] = useState<string | null>(
    startup.totalFunding?.toString() || null
  );
  const [lastFundingInput, setLastFundingInput] = useState(
    startup.lastFunding?.toString() || null
  );

  useEffect(() => {
    !editMode && handleCancelEdit();
  }, [props.startup]);

  const handleCancelEdit = () => {
    setStartup({
      totalFunding: props.startup.totalFunding,
      currentInvestmentStage: props.startup.currentInvestmentStage,
      lastFundingDate: props.startup.lastFundingDate,
      fundingIsUndisclosed: props.startup.fundingIsUndisclosed,
      lastFunding: props.startup.lastFunding,
      investors: props.startup.investors,
      parentCompany: props.startup.parentCompany,
      parentCompanyId: props.startup.parentCompanyId,
    });
    setLastFundingInput(props.startup.lastFunding?.toString() || null);
    setTotalFundingInput(props.startup.totalFunding?.toString() || null);
    setEditMode(false);
    setGlobalEditMode(false);
  };

  const handleSaveSection = async () => {
    await StartupHttpService.updateStartup(startup)
      .then(() => {
        setEditMode(false);
        setGlobalEditMode(false);
        enqueueSnackbar("Startup saved successfully.", {
          variant: "success",
        });
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        enqueueSnackbar(`Could not update startup details: ${errorMessage}`, {
          variant: "error",
        });
      });

    props.handleSave();
  };

  const searchForInvestors = async (searchValue: string): Promise<void> => {
    setIsLoading(true);
    if (searchValue.trim().length) {
      setCreateInvestorsModalOptions((prevState) => ({
        ...prevState,
        value: searchValue,
      }));
      await InvestorsHttpService.getInvestorsByName(searchValue).then(
        (result) => {
          setInvestors([...result, ...startup.investors]);
        }
      );
    }
    setIsLoading(false);
  };

  const handleAddInvestor = (investor: Investor) => {
    setStartup({
      investors: [...startup.investors, investor],
    });
  };

  const searchForReferenceClients = async (
    searchValue: string
  ): Promise<void> => {
    if (searchValue.trim().length) {
      setIsLoading(true);
      await CompaniesHttpService.getCompaniesByName(searchValue)
        .then((result) =>
          setReferenceClients([...result, ...startup.referenceClients])
        )
        .finally(() => {
          setIsLoading(false);
        });
    }
    setIsLoading(false);
  };

  const handleAddParentCompany = (parentCompany: ReferenceClient) => {
    setStartup({
      parentCompanyId: parentCompany.id,
      parentCompany: parentCompany,
    });
  };

  const handleFundingUndisclosedCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setStartup({ fundingIsUndisclosed: e.target.checked });
    if (e.target.checked) {
      setLastFundingInput(null);
      setTotalFundingInput(null);
      setStartup({
        totalFunding: null,
        lastFunding: null,
        lastFundingDate: null,
      });
    } else {
      setLastFundingInput(props.startup.lastFunding?.toString() || null);
      setTotalFundingInput(props.startup.totalFunding?.toString() || null);
      setStartup({
        totalFunding: props.startup.totalFunding,
        lastFunding: props.startup.lastFunding,
        lastFundingDate: props.startup.lastFundingDate,
      });
    }
  };

  const getFundingDisplayValue = (
    inputName: "totalFunding" | "lastFunding"
  ) => {
    const isTotalFunding = inputName === "totalFunding";
    const isLastFunding = inputName === "lastFunding";
    const value = isTotalFunding ? startup.totalFunding : startup.lastFunding;
    const editedValue = isTotalFunding ? totalFundingInput : lastFundingInput;

    if (editMode) {
      return editedValue || "";
    }

    if (startup?.fundingIsUndisclosed) {
      return "Funding Undisclosed";
    }

    if (value !== null) {
      return formatFunding(value);
    }

    if (
      (isTotalFunding || isLastFunding) &&
      startup?.currentInvestmentStage === "No Institutional Investment"
    ) {
      return formatFunding(isTotalFunding ? "0.00" : 0);
    }

    return "No Funding Information";
  };

  const hideTotalFundingCurrency =
    (!startup.totalFunding || startup.fundingIsUndisclosed) &&
    startup?.currentInvestmentStage !== "No Institutional Investment";

  return (
    <>
      <Box display="flex" gap={2}>
        <Typography variant="h6" mr="auto">
          Funding
        </Typography>
        {!editMode && (
          <>
            <PitchBookSyncDetails
              startup={props.startup}
              handleSave={props.handleSave}
            />
            {!isExternalUser && (
              <Button
                id="edit-button-funding"
                variant="contained"
                disabled={globalEditMode}
                onClick={() => {
                  setEditMode(true);
                  setGlobalEditMode(true);
                }}
              >
                Edit
              </Button>
            )}
          </>
        )}
      </Box>
      <Stack gap={6}>
        <Box
          display="grid"
          gridTemplateColumns={editMode ? "repeat(6, 1fr)" : "repeat(3, 1fr)"}
          gap={3}
        >
          <NumberCard
            label="Total Funding"
            currency="$"
            hideCurrency={hideTotalFundingCurrency}
            disabled={!!startup.fundingIsUndisclosed}
            value={getFundingDisplayValue("totalFunding")}
            cardSize="big"
            onChange={(e) => {
              setTotalFundingInput(e.target.value);
              setStartup({
                totalFunding:
                  e.target.value !== "" ? parseFloat(e.target.value) : null,
              });
            }}
            editMode={editMode}
            fullWidth
            data-testid="total-funding"
            sx={{
              gridColumn:
                startup.currentInvestmentStage === "Acquired"
                  ? "span 2"
                  : "span 3",
            }}
          />
          {editMode ? (
            <SelectInput
              label="Funding Stage"
              id="currentInvestmentStage"
              editMode={true}
              selectValues={investmentStagesOptions}
              value={startup.currentInvestmentStage}
              onChange={(e) =>
                setStartup({
                  currentInvestmentStage: e.target.value,
                  parentCompanyId:
                    e.target.value === "Acquired"
                      ? startup.parentCompanyId
                      : null,
                  parentCompany:
                    e.target.value === "Acquired"
                      ? startup.parentCompany
                      : null,
                })
              }
              fullWidth
              disableSorting
              sx={{
                gridColumn:
                  startup.currentInvestmentStage === "Acquired"
                    ? "span 2"
                    : "span 3",
              }}
            />
          ) : (
            <NumberCard
              data-testid="funding-stage"
              label="Funding Stage"
              value={startup.currentInvestmentStage}
              cardSize="big"
              editMode={editMode}
              helperText={
                startup.currentInvestmentStage === "Acquired" &&
                startup.parentCompany
                  ? `by ${startup.parentCompany?.name}`
                  : undefined
              }
              hideCurrency
              fullWidth
            />
          )}
          {editMode && startup.currentInvestmentStage === "Acquired" && (
            <Box
              sx={{
                gridColumn: "span 2",
              }}
            >
              <SingleSelectInput
                labelText="Parent Company"
                fieldId="parentCompany"
                selectValues={referenceClients as SelectOption[]}
                value={startup.parentCompany}
                onChange={(parentCompany) => {
                  setStartup({
                    parentCompanyId: parentCompany
                      ? (parentCompany.id as number)
                      : null,
                    parentCompany: parentCompany as ReferenceClient,
                  });
                }}
                addIcon
                isLoading={isLoading}
                setModalOpen={() => setCreateParentCompany(true)}
                onInputChange={debounce(searchForReferenceClients, 500)}
              />
            </Box>
          )}
          {editMode && (
            <FormGroup sx={{ gridColumn: "span 6" }}>
              <FormControlLabel
                componentsProps={{ typography: { variant: "subtitle2" } }}
                control={
                  <Checkbox
                    data-testid="fundingIsUndisclosed"
                    checked={startup.fundingIsUndisclosed}
                    onChange={handleFundingUndisclosedCheckbox}
                  />
                }
                label="Funding is Undisclosed"
              />
            </FormGroup>
          )}
          <Box
            display="flex"
            gridColumn={editMode ? "span 6" : "unset"}
            gap={3}
          >
            {editMode && (
              <CustomDatePicker
                id="lastFundingDate"
                label="Last Funding Date"
                value={startup.lastFundingDate}
                onChange={(value) =>
                  setStartup({
                    lastFundingDate: value ? new Date(value) : null,
                  })
                }
                editMode={true}
                fullWidth
                data-testid="last-funding-date"
              />
            )}
            <NumberCard
              label={editMode ? "Last Funding Amount (in $M)" : "Last Funding"}
              currency="$"
              disabled={!!startup.fundingIsUndisclosed}
              value={getFundingDisplayValue("lastFunding")}
              hideCurrency={
                !startup.lastFunding || startup.fundingIsUndisclosed
              }
              helperText={
                startup.lastFunding !== null &&
                startup.lastFundingDate &&
                !editMode
                  ? formatDate(startup.lastFundingDate)
                  : undefined
              }
              cardSize="big"
              editMode={editMode}
              onChange={(e) => {
                setLastFundingInput(e.target.value);
                setStartup({
                  lastFunding:
                    e.target.value !== "" ? parseFloat(e.target.value) : null,
                });
              }}
              fullWidth
              data-testid="last-funding"
            />
          </Box>
        </Box>
        <Box>
          {editMode ? (
            <MultiSelectInput
              labelText="Investors"
              fieldId="investors"
              selectValues={investors as SelectOption[]}
              value={(startup.investors as SelectOption[]) || []}
              onChange={(investors) => {
                setStartup({ investors: investors as Investor[] });
              }}
              addIcon
              isLoading={isLoading}
              setModalOpen={() => {
                setCreateInvestorsModalOptions((prevState) => ({
                  ...prevState,
                  open: true,
                }));
              }}
              onInputChange={debounce(searchForInvestors, 500)}
              fullWidth
            />
          ) : (
            <Stack gap={1}>
              <Typography variant="caption" color="text.mediumEmphasis">
                Investors
              </Typography>
              <CustomExpendableText
                maxLines={1}
                text={
                  <Box>
                    {startup.investors.length
                      ? startup.investors.map((investor) => (
                          <Typography
                            component="span"
                            key={investor.id}
                            mr={2}
                            variant="subtitle2"
                          >
                            {investor.name}
                          </Typography>
                        ))
                      : "--"}
                  </Box>
                }
              ></CustomExpendableText>
            </Stack>
          )}
        </Box>
        {editMode && (
          <Box marginLeft="auto" display="flex" gap={2}>
            <Button id="cancel-button-funding" onClick={handleCancelEdit}>
              Cancel
            </Button>
            <Button
              id="save-button-funding"
              variant="contained"
              onClick={handleSaveSection}
            >
              Save
            </Button>
          </Box>
        )}
      </Stack>
      {createInvestorsModalOptions.open && (
        <CreateEntityByName
          name="investor"
          label="Investor"
          modalOpen={createInvestorsModalOptions.open}
          setModalOpen={() =>
            setCreateInvestorsModalOptions(() => ({
              open: false,
              value: "",
            }))
          }
          initialValue={createInvestorsModalOptions.value}
          handleAdd={handleAddInvestor}
        />
      )}
      {createParentCompany && (
        <CreateEntityByName
          name="parentCompany"
          label="Parent Company"
          modalOpen={createParentCompany}
          setModalOpen={() => setCreateParentCompany(false)}
          handleAdd={handleAddParentCompany}
        />
      )}
    </>
  );
};

export default FundingSection;
