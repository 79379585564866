import { Box, styled, Tab, Tabs, Typography } from "@mui/material";
import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { startupTabs } from "../../../../Constants/Startup";
import { GlobalLoaderContext } from "../../../../Context/LoaderContext";
import theme from "../../../../theme";
import {
  StartupCounts,
  StartupSecondaryTabItems,
} from "../../../../Types/Startup";
import { thousandSeparator } from "../../../../utils";

const StyledBox = styled(Box)({
  transition: "all 0.3s",
  width: "100%",
  "&.stickied": {
    width: `calc(100% + ${theme.spacing(10)})`,
    margin: theme.spacing(0, -5),
    boxShadow: theme.boxShadows[3],
  },
  backgroundColor: theme.palette.surface.primary.main,
  display: "flex",
  justifyContent: "center",
});

const StyledTabs = styled(Tabs)({
  "& .MuiTabs-flexContainer": {
    borderBottom: "none",
  },
  "& .MuiTab-root": {
    padding: theme.spacing(2, 3),
    ...theme.typography.subtitle2,
    "&.Mui-selected": {
      color: theme.palette.text.action.main,
    },
  },
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.surface.action.main,
  },
});

interface Props {
  handleChangeTab: (index: number, label: StartupSecondaryTabItems) => void;
  activeTab: number | null;
  headerHeight: number;
  counts?: StartupCounts;
  activePrimaryTab: number;
}

function SecondaryTab(props: Props): ReactElement {
  const [isStickied, setIsStickied] = useState(false);
  const { globalLoader } = useContext(GlobalLoaderContext);

  const countsMapping: {
    All: number;
    Leads: number;
    Curated: number;
    Qualified: number;
    "Selected for Pilot": number;
  } = {
    All: props.counts?.allStartupsCount || 0,
    Leads: props.counts?.leadStartupsCount || 0,
    Curated: props.counts?.curatedStartupsCount || 0,
    Qualified: props.counts?.qualifiedStartupsCount || 0,
    "Selected for Pilot": props.counts?.selectedStartupsCount || 0,
  };

  const navigationRef = useRef<Element>(null);

  const navigationHeight = navigationRef.current
    ? navigationRef.current.getBoundingClientRect().height
    : 0;

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([e]) => {
        if (e.intersectionRatio < 1) {
          setIsStickied(
            e.boundingClientRect.bottom <=
              props.headerHeight + navigationHeight + 1
          );
        }
      },
      {
        root: null,
        threshold: 0,
        rootMargin: `-${
          props.headerHeight + navigationHeight + 1
        }px 0px 0px 0px`,
      }
    );

    navigationRef.current && observer.observe(navigationRef.current);

    return () => {
      navigationRef.current && observer.unobserve(navigationRef.current);
    };
  }, [navigationRef, navigationHeight, props.headerHeight]);

  const isDatabaseTabActive = props.activePrimaryTab === 0;

  const visibleTabs = startupTabs.secondaryTab.filter((tab) => {
    if (isDatabaseTabActive) {
      return tab.title !== "All";
    } else {
      return true;
    }
  });

  return (
    <StyledBox className={isStickied ? "stickied" : ""} ref={navigationRef}>
      <StyledTabs value={props.activeTab}>
        {visibleTabs.map((tab, index) => {
          const count = countsMapping[tab.title as keyof typeof countsMapping];
          const isActive = props.activeTab === index;
          return (
            <Tab
              data-testid={"secondary-tab-type-" + index}
              label={
                <Box display="flex" gap={1}>
                  <Typography variant="subtitle2">{tab.title}</Typography>
                  <Typography
                    color={isActive ? "inherit" : "text.mediumEmphasis"}
                  >
                    {globalLoader ? <>...</> : thousandSeparator(count)}
                  </Typography>
                </Box>
              }
              key={index}
              onClick={() => {
                props.handleChangeTab(index, tab.title);
              }}
            />
          );
        })}
      </StyledTabs>
    </StyledBox>
  );
}

export default SecondaryTab;
