import {
  FormControlLabel,
  Checkbox,
  FormControlLabelProps,
} from "@mui/material";
import { ChangeEvent, ReactElement } from "react";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkOutlinedIcon from "@mui/icons-material/BookmarkOutlined";
import theme from "../../../theme";

interface BookmarkProps {
  checked: boolean;
  handleBookmark: (event: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  label?: string;
  disableRipple?: boolean;
  size?: "small" | "medium";
  sx?: FormControlLabelProps["sx"];
}

const BookmarkToggle = (props: BookmarkProps): ReactElement => {
  const {
    checked = false,
    handleBookmark,
    className,
    label = "",
    disableRipple,
    size = "small",
  } = props;

  return (
    <FormControlLabel
      className={className}
      onClick={(event) => event.stopPropagation()}
      control={
        <Checkbox
          className="bookmark-btn"
          name="bookmarked"
          size={size}
          data-testid="bookmark-btn"
          checked={checked}
          onChange={handleBookmark}
          checkedIcon={
            <BookmarkOutlinedIcon
              style={{ fill: theme.palette.icon.action.main }}
            />
          }
          icon={
            <BookmarkBorderOutlinedIcon
              htmlColor={theme.palette.icon.action.main}
            />
          }
          disableRipple={disableRipple}
        />
      }
      label={label}
      sx={props.sx}
    />
  );
};

export default BookmarkToggle;
