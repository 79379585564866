import { useEffect, useState, useContext } from "react";
import { enqueueSnackbar } from "notistack";

import { ProjectHttpService } from "../Http/Project/Project.http.service";
import { LeadProjectsHttpService } from "../Http/LeadProjects/LeadProjects.http.service";
import { VentureClientHttpService } from "../Http/VentureClient/VentureClient.http.service";
import UserHttpService from "../Http/User/User.Http.service";

import { GlobalLoaderContext } from "../Context/LoaderContext";
import { Project } from "../Types/Project";
import { LeadProject } from "../Types/LeadProject";
import { VentureClient } from "../Types/VentureClient";
import { User } from "../Types/User";

import { getErrorMessage } from "../utils";

interface UseProjectKanbanDataResult {
  projects: Project[];
  leadProjects: LeadProject[];
  ventureClientSelectOptions: VentureClient[];
  users: User[];
  isLoading: boolean;
  refreshProjectData: (preventLoading?: boolean) => Promise<void>;
}

const useProjectKanbanData = (
  user: User | null,
  isExternalUser: boolean,
  ventureClientId?: number
): UseProjectKanbanDataResult => {
  const { setGlobalLoader } = useContext(GlobalLoaderContext);

  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState<Project[]>([]);
  const [leadProjects, setLeadProjects] = useState<LeadProject[]>([]);
  const [ventureClientSelectOptions, setVentureClientSelectOptions] = useState<
    VentureClient[]
  >([]);
  const [users, setUsers] = useState<User[]>([]);

  const defaultFilters = JSON.stringify({
    status: ["active"],
    projectOwnerId: !isExternalUser ? user?.id : undefined,
    startupIntelligenceId: !isExternalUser ? user?.id : undefined,
  });

  const refreshProjectData = async (preventLoading = false) => {
    const filterQuery = JSON.parse(
      localStorage.getItem("projectFilters") || defaultFilters
    );
    if (!preventLoading) {
      setGlobalLoader(true);
      setIsLoading(true);
    }

    const getProjects = ProjectHttpService.getProjects(filterQuery);
    delete filterQuery.programManagerId;
    const getLeads = LeadProjectsHttpService.getLeadProjects(filterQuery);

    const [projectResponse, leadResponse] = await Promise.all([
      getProjects,
      getLeads,
    ]);

    setProjects(projectResponse);
    setLeadProjects(leadResponse);

    if (!preventLoading) {
      setGlobalLoader(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    refreshProjectData();
    document.title = "Kanban - Venture Client Platform";

    if (isExternalUser && ventureClientId) {
      VentureClientHttpService.getVentureClientById(ventureClientId).then(
        (ventureClient) => {
          setVentureClientSelectOptions([ventureClient]);
        }
      );
    } else {
      VentureClientHttpService.getVentureClients().then(
        setVentureClientSelectOptions
      );
    }

    UserHttpService.getUsers()
      .then(setUsers)
      .catch((error) => {
        enqueueSnackbar(`Could not fetch users: ${getErrorMessage(error)}`, {
          variant: "error",
        });
      });
  }, []);

  return {
    projects,
    leadProjects,
    ventureClientSelectOptions,
    users,
    isLoading,
    refreshProjectData,
  };
};

export default useProjectKanbanData;
