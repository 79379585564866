import { Fragment, ReactElement, useEffect, useState } from "react";
import {
  Autocomplete,
  CircularProgress,
  debounce,
  IconButton,
  TextField,
  Theme,
  Tooltip,
  Box,
  Typography,
} from "@mui/material";
import { ClientContactDTO } from "../../../Types/ClientContact";
import ClientContactHttpService from "../../../Http/ClientContact/ClientContact.http.service";
import AddIcon from "@mui/icons-material/Add";
import CreateClientContactGlobalModal from "../../Contacts/ClientComponents/CreateClientContactGlobalModal";
import CustomToolTip from "../CustomToolTip";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import DetailsClientContactModal from "../../Contacts/ClientComponents/DetailsClientcontactModal/DetailsClientContactModal";
import theme from "../../../theme";
import ContactName from "../../Contacts/ContactCard/ContactName";

interface Props {
  editMode: boolean;
  labelText: string;
  ventureClientId: number;
  // eslint-disable-next-line
  onChange: any;
  contactData: ClientContactDTO | ClientContactDTO[] | undefined;
  fieldId: string;
  field?: string;
  multiSelect?: boolean;
  required?: boolean;
  toolTipText?: string;
  orientation?: "horizontal" | "vertical";
  layoutColumn?: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .Mui-error.MuiFormLabel-root .MuiSvgIcon-root": {
        color: theme.palette.surface.alert,
      },
      "& .MuiOutlinedInput-root.MuiInputBase-root.MuiAutocomplete-inputRoot": {
        paddingRight: "75px",
      },
      "& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment": {
        right: 0,
      },
    },
    contactRoot: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1),
    },
    contactValue: {
      fontSize: "1rem",
      color: theme.palette.primary.main,
      "&:hover": {
        cursor: "pointer",
      },
    },
    endAdornmentWrapper: {
      position: "absolute",
      right: "9px",
      display: "flex",
      alignItems: "center",

      "& .MuiAutocomplete-endAdornment": {
        position: "relative",
        translate: "0 50%",
      },
    },
  })
);

export default function ClientContactSelect(props: Props): ReactElement {
  const classes = useStyles();
  const [contacts, setContacts] = useState<ClientContactDTO[]>([]);
  const [selectedContacts, setSelectedContacts] = useState<ClientContactDTO[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [createClientContactModalOpen, setCreateContactClientModalOpen] =
    useState<boolean>(false);

  const [selectedClientContact, setSelectedClientContact] =
    useState<ClientContactDTO>();
  const [openClientContact, setOpenClientContact] = useState<boolean>(false);

  const searchForContacts = async (searchValue: string): Promise<void> => {
    setIsLoading(true);
    if (searchValue.trim().length > 0)
      setContacts(
        await ClientContactHttpService.getContactsByName(
          props.ventureClientId,
          searchValue
        )
      );
    setIsLoading(false);
  };

  const handleNewContact = (newContact: ClientContactDTO) => {
    if (newContact) {
      const newSelectedContact = props.multiSelect
        ? [...selectedContacts, newContact]
        : [newContact];
      setSelectedContacts(newSelectedContact);
      props.onChange(newSelectedContact);
    }
  };

  const handleEditSave = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (props.multiSelect) {
      setSelectedContacts(props.contactData as ClientContactDTO[]);
    } else if (!props.multiSelect) {
      setSelectedContacts([{ ...(props.contactData as ClientContactDTO) }]);
    }
  }, [props.contactData]);

  const handlMultiSelect = (selectedContacts: ClientContactDTO[]) => {
    setSelectedContacts(selectedContacts);
    props.onChange(selectedContacts);
  };

  const handleSingleSelect = (contact: ClientContactDTO | null) => {
    setSelectedContacts(contact ? [contact] : []);
    props.onChange({
      [props.fieldId]: contact ? contact.id : null,
      ...(props.field && { [props.field]: contact ? contact : null }),
    });
  };

  const debouncedSearchForContacts = debounce(searchForContacts, 500);

  return (
    <Fragment>
      {props.editMode ? (
        props.multiSelect ? (
          <Autocomplete
            id={props.fieldId}
            fullWidth
            multiple
            forcePopupIcon={false}
            options={contacts as ClientContactDTO[]}
            isOptionEqualToValue={(
              option: ClientContactDTO,
              value: ClientContactDTO
            ) => option.id === value.id}
            getOptionLabel={(option: ClientContactDTO) =>
              option.departmentCode
                ? `${option.name} (${option.departmentCode})`
                : option.name
            }
            filterOptions={(options) => options}
            value={selectedContacts as ClientContactDTO[]}
            filterSelectedOptions
            onInputChange={(event, newValue: string) => {
              if (event === null) return;
              debouncedSearchForContacts(newValue);
            }}
            onChange={(_, selectedContactsList) =>
              handlMultiSelect(selectedContactsList)
            }
            className={classes.root}
            data-testid="client-contact-select-input"
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  !!!props.toolTipText ? (
                    props.labelText
                  ) : (
                    <>
                      <span>{props.labelText}</span>
                      {props.editMode && (
                        <CustomToolTip
                          id={`${props.fieldId}Tooltip`}
                          data-testid={`${props.fieldId}Tooltip`}
                          key={`${props.fieldId}Tooltip`}
                          toolTipText={props.toolTipText}
                        />
                      )}
                    </>
                  )
                }
                id={props.fieldId}
                placeholder="search by name"
                required={!!props.required}
                focused={false}
                InputLabelProps={{
                  shrink: true,
                  sx: {
                    marginTop: props.toolTipText ? "-5px" : "0px",
                  },
                }}
                error={
                  props.required &&
                  !!!Object.keys(selectedContacts[0] || {}).length
                }
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <div className={classes.endAdornmentWrapper}>
                      {isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        <>
                          {params.InputProps.endAdornment}
                          <Tooltip
                            title="Create New Contact"
                            placement="right-end"
                          >
                            <IconButton
                              id={"addNewContact-" + props.fieldId}
                              data-testid={"addNewContact-" + props.fieldId}
                              onClick={() =>
                                setCreateContactClientModalOpen(true)
                              }
                            >
                              <AddIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                    </div>
                  ),
                }}
              />
            )}
          />
        ) : (
          <Autocomplete
            id={props.fieldId}
            fullWidth
            options={contacts}
            forcePopupIcon={false}
            getOptionLabel={(option) =>
              option.departmentCode
                ? `${option.name} (${option.departmentCode})`
                : option.name || ""
            }
            filterOptions={(options) => options}
            value={
              !!Object.keys(selectedContacts[0] || {}).length
                ? (selectedContacts[0] as ClientContactDTO)
                : null
            }
            filterSelectedOptions
            isOptionEqualToValue={(
              option: ClientContactDTO,
              value: ClientContactDTO
            ) => option.id === value.id}
            onInputChange={(event, newValue: string) => {
              if (event === null) return;
              debouncedSearchForContacts(newValue);
            }}
            onChange={(_, selectedContact) =>
              handleSingleSelect(selectedContact)
            }
            className={classes.root}
            data-testid="client-contact-select-input"
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  !!!props.toolTipText ? (
                    props.labelText
                  ) : (
                    <>
                      <span>{props.labelText}</span>
                      {props.editMode && (
                        <CustomToolTip
                          id={`${props.fieldId}Tooltip`}
                          key={`${props.fieldId}Tooltip`}
                          toolTipText={props.toolTipText}
                        />
                      )}
                    </>
                  )
                }
                id={props.fieldId}
                placeholder="search by name"
                focused={false}
                required={!!props.required}
                InputLabelProps={{
                  shrink: true,
                  sx: {
                    marginTop: props.toolTipText ? "-5px" : "0px",
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <div className={classes.endAdornmentWrapper}>
                      {isLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        <>
                          {params.InputProps.endAdornment}
                          <Tooltip
                            title="Create New Contact"
                            placement="right-end"
                          >
                            <IconButton
                              id={"addNewContact-" + props.fieldId}
                              data-testid={"addNewContact-" + props.fieldId}
                              onClick={() =>
                                setCreateContactClientModalOpen(true)
                              }
                            >
                              <AddIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                    </div>
                  ),
                }}
                error={
                  props.required &&
                  !!!Object.keys(selectedContacts[0] || {}).length
                }
              />
            )}
          />
        )
      ) : (
        <Box
          className={classes.contactRoot}
          gap={theme.spacing(1)}
          data-testid="client-contact"
        >
          {props.labelText && (
            <Typography variant="caption" color="text.mediumEmphasis">
              {props.labelText}
            </Typography>
          )}
          <Box
            display="grid"
            gridTemplateColumns={`repeat(${
              props.orientation === "horizontal" ? props.layoutColumn : 1
            }, 1fr)`}
            rowGap={1}
            columnGap={props.orientation === "horizontal" ? 3 : 1}
            alignItems="start"
          >
            {selectedContacts.length && selectedContacts[0].name
              ? selectedContacts.flatMap((contact, index) => (
                  <ContactName
                    key={index}
                    name={contact.name}
                    title={contact.title}
                    orientation="horizontal"
                    onClick={() => {
                      setOpenClientContact(true);
                      setSelectedClientContact(contact);
                    }}
                  />
                ))
              : "--"}
          </Box>
        </Box>
      )}
      {createClientContactModalOpen && (
        <CreateClientContactGlobalModal
          setModalOpen={setCreateContactClientModalOpen}
          modalOpen={createClientContactModalOpen}
          getNewContact={(contact) => {
            handleNewContact(contact);
            if (!props.multiSelect) handleSingleSelect(contact);
          }}
          ventureClientId={props.ventureClientId}
        />
      )}
      {openClientContact && selectedClientContact && (
        <DetailsClientContactModal
          setModalOpen={setOpenClientContact}
          modalOpen={openClientContact}
          contact={selectedClientContact}
          handleSave={handleEditSave}
          mustFetch
        />
      )}
    </Fragment>
  );
}
