import { Stack } from "@mui/material";
import ContactCopy from "./ContactCopy";
import { ClientContactDTO } from "../../../Types/ClientContact";
import { StartupContactDTO } from "../../../Types/StartupContact";
import { ReactElement } from "react";

interface ContactCardContactsProps {
  orientation?: "horizontal" | "vertical";
  contact: ClientContactDTO | StartupContactDTO;
}

const ContactCardContacts = ({
  contact,
  orientation,
}: ContactCardContactsProps): ReactElement => {
  return (
    <Stack
      gap={1}
      columnGap={4}
      flex="1 1 0"
      flexDirection={orientation === "horizontal" ? "column" : "row"}
      flexWrap="wrap"
    >
      {contact.phone && <ContactCopy value={contact.phone} variant="phone" />}
      {contact.email && <ContactCopy value={contact.email} variant="email" />}
    </Stack>
  );
};

export default ContactCardContacts;
