import {
  DataGridPremiumProps,
  GridColDef,
  GridInitialState,
  GridValueGetterParams,
} from "@mui/x-data-grid-premium";
import { ReactElement, useEffect } from "react";
import CustomDataGrid from "./CustomDataGrid";
import UseCase from "../../Types/UseCase";
import { LeadOpportunity, LeadProjectLeader } from "../../Types/LeadProject";
import useRoles from "../../Hooks/useRoles";
import { Project } from "../../Types/Project";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";

type GridColumn = GridColDef & {
  hidden?: boolean;
};

interface LeadProjectsDataGridProps
  extends Omit<DataGridPremiumProps, "columns"> {
  apiRef: React.MutableRefObject<GridApiPremium>;
  gridState: GridInitialState | null;
}

export default function LeadProjectsDataGrid(
  props: LeadProjectsDataGridProps
): ReactElement {
  const { isExternalUser } = useRoles();
  const { apiRef, gridState, ...rest } = props;

  useEffect(() => {
    if (gridState) {
      apiRef.current.restoreState(gridState);
    }
  }, [apiRef, gridState]);

  const baseColumns: GridColumn[] = [
    {
      flex: 0.5,
      field: "id",
      headerName: "Lead ID",
      editable: false,
    },
    {
      flex: 1,
      field: "name",
      headerName: "Lead Name",
      editable: false,
    },
    ...(!isExternalUser
      ? [
          {
            flex: 1,
            field: "ventureClient",
            headerName: "Venture Client",
            editable: false,
            valueGetter: (params: GridValueGetterParams) =>
              params.row.ventureClient.name,
          },
        ]
      : []),
    {
      flex: 0.5,
      field: "status",
      headerName: "Status",
    },
    {
      flex: 1,
      field: "sourceType",
      headerName: "Source Type",
    },
    {
      flex: 1,
      field: "sourceName",
      headerName: "Source Name",
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.sourceName?.value;
      },
    },
    {
      flex: 0.5,
      field: "numberOfLaunchedProjects",
      headerName: "No. of Launched Projects",
      type: "number",
      valueGetter: (params: GridValueGetterParams) =>
        params.row.projects?.length,
    },
    {
      flex: 1,
      field: "launchedProjects",
      headerName: "Launched Projects",
      valueGetter: (params: GridValueGetterParams) =>
        params.row.projects.map((project: Project) => project.name).join(", "),
    },
    {
      flex: 0.5,
      field: "numberOfUseCases",
      headerName: "No. of Use Cases",
      type: "number",
      valueGetter: (params: GridValueGetterParams) =>
        params.row.useCases?.length,
    },
    {
      flex: 1,
      field: "useCases",
      headerName: "Use Cases",
      valueGetter: (params: GridValueGetterParams) =>
        params.row.useCases.map((uc: UseCase) => uc.name).join(", "),
    },
    {
      flex: 0.5,
      field: "numberOfProjectLeaders",
      headerName: "No. of Project Leaders",
      type: "number",
      valueGetter: (params: GridValueGetterParams) =>
        params.row.leaders?.length,
    },
    {
      flex: 1,
      field: "leaders",
      headerName: "Project Leaders",
      valueGetter: (params: GridValueGetterParams) =>
        params.row.leaders
          .filter((pl: LeadProjectLeader) => pl.projectLeader?.name)
          .map((pl: LeadProjectLeader) => {
            const departmentCode = pl.projectLeader?.departmentCode
              ? ` (${pl.projectLeader?.departmentCode})`
              : "";
            return `${pl.projectLeader?.name}${departmentCode}`;
          })
          .join(", "),
    },
    {
      flex: 0.5,
      field: "numberOfStartups",
      headerName: "No. of Startups",
      type: "number",
      valueGetter: (params: GridValueGetterParams) =>
        params.row.opportunities?.length,
    },
    {
      flex: 1,
      field: "startups",
      headerName: "Startups",
      valueGetter: (params: GridValueGetterParams) =>
        params.row.opportunities
          .map((opportunity: LeadOpportunity) => opportunity.startup?.name)
          .join(", "),
    },
  ];

  const columns = baseColumns.filter((column) => !column.hidden);

  return (
    <CustomDataGrid
      {...rest}
      apiRef={apiRef}
      initialState={
        gridState || {
          columns: {
            columnVisibilityModel: {
              id: false,
              launchedProjects: false,
              useCases: false,
              leaders: false,
              startups: false,
              sourceName: false,
            },
          },
        }
      }
      columns={columns}
      noRowsText="No Lead Found"
    />
  );
}
