import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { ReactElement, useReducer, Reducer, useEffect } from "react";
import { CreateBusinessUnitDTO } from "../../../../Types/BusinessUnit";
import { BusinessUnitHttpService } from "../../../../Http/BusinessUnit/BusinessUnit.http.service";
import { useSnackbar } from "notistack";

interface CreateBusinessUnitModalProps {
  handleModalClose: () => void;
  modalOpen: boolean;
  handleSave: () => void;
  ventureClientId: number;
}

export default function CreateBusinessUnitModal(
  props: CreateBusinessUnitModalProps
): ReactElement {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [newBusinessUnit, setNewBusinessUnit] = useReducer<
    Reducer<CreateBusinessUnitDTO, Partial<CreateBusinessUnitDTO>>
  >(
    (state, newState) => ({ ...state, ...newState }),
    {} as CreateBusinessUnitDTO
  );

  useEffect(() => {
    setNewBusinessUnit({ ventureClientId: props.ventureClientId });
  }, [props.ventureClientId]);

  const isBusinessUnitNameValid = () => {
    return newBusinessUnit.name && newBusinessUnit.name.trim().length !== 0;
  };

  const handleCreateBusinessUnit = async () => {
    if (isBusinessUnitNameValid() && newBusinessUnit.ventureClientId != null) {
      await BusinessUnitHttpService.createBusinessUnit(newBusinessUnit);
      props.handleSave();
      props.handleModalClose();
    } else {
      enqueueSnackbar("Please enter Name", {
        variant: "error",
      });
      return false;
    }
  };

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      open={props.modalOpen}
      data-testid="organizational-unit-create-modal"
      PaperProps={{
        sx: {
          gap: theme.spacing(4),
        },
      }}
    >
      <DialogTitle>Add Organizational Unit</DialogTitle>
      <DialogContent>
        <Stack gap={4}>
          <TextField
            label="Name"
            id="name"
            variant="outlined"
            fullWidth
            defaultValue=""
            onChange={(event) =>
              setNewBusinessUnit({ name: event.target.value })
            }
          />
          <TextField
            label="Short Description"
            id="shortDescription"
            variant="outlined"
            fullWidth
            defaultValue=""
            onChange={(event) =>
              setNewBusinessUnit({ shortDescription: event.target.value })
            }
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.handleModalClose}
          color="primary"
          id="close-business-unit-create-view"
        >
          Cancel
        </Button>
        <Button
          onClick={handleCreateBusinessUnit}
          variant="contained"
          id="save-business-unit-create-view"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
