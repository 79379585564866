import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  IconButton,
  Stack,
  TextField,
  Checkbox,
} from "@mui/material";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { Preset } from "../../../Types/Preset";
import { formatDate, getErrorMessage } from "../../../utils";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import theme from "../../../theme";
import { useSnackbar } from "notistack";
import { GridInitialState } from "@mui/x-data-grid-premium";
import { PresetsHttpService } from "../../../Http/Preset/Preset.http.service";
import { LeadsPresetsHttpService } from "../../../Http/LeadsPreset/LeadsPreset.http.service";
import DeleteOrRemoveModal from "../../UI/Modals/DeleteOrRemoveModal/DeleteOrRemoveModal";
import { AxiosError } from "axios";
import { EditOutlined, GroupOutlined } from "@mui/icons-material";
import useRoles from "../../../Hooks/useRoles";
import { SelectOption } from "../../../Types/Common";
import { VentureClientHttpService } from "../../../Http/VentureClient/VentureClient.http.service";
import { SelectInput } from "../../UI/InputFields/SelectInput";
import { UserContext } from "../../../Context/UserContext";
import ScrollableDialogContent from "../../UI/Modals/ScrollableDialogContent";

interface LoadPresetModalProps {
  modalOpen: boolean;
  setModalOpen: (state: boolean) => void;
  presetList: Preset[];
  setPresetList: (state: Preset[]) => void;
  setAppliedPreset: (state: Preset | null) => void;
  griStateToLoad: (state: GridInitialState) => void;
  currentTab: "project" | "lead";
  resetSelectedPreset: () => void;
}

const LoadPresetModal = ({
  modalOpen,
  setModalOpen,
  presetList,
  setPresetList,
  setAppliedPreset,
  griStateToLoad,
  currentTab,
  resetSelectedPreset,
}: LoadPresetModalProps): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const { isExternalUser, ventureClientId } = useRoles();
  const user = useContext(UserContext);

  const [selectedPreset, setSelectedPreset] = useState<Preset | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [presetToDelete, setPresetToDelete] = useState<number | null>(null);
  const [editedPreset, setEditedPreset] = useState<Preset | null>(null);
  const [refresh, setRefresh] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");

  const [ventureClientSelectOptions, setVentureClientSelectOptions] = useState<
    SelectOption[]
  >([]);

  const sharePreset = editedPreset?.ventureClientId !== null;

  useEffect(() => {
    if (!isExternalUser) {
      VentureClientHttpService.getVentureClients().then((results) => {
        const ventureClientSelectOptions = results.map((ventureClient) => ({
          id: ventureClient.id,
          name: ventureClient.name,
        }));
        setVentureClientSelectOptions(ventureClientSelectOptions);
      });
    }
  }, []);

  useEffect(() => {
    const fetchPresets = async () => {
      try {
        if (currentTab === "project") {
          const presets = await PresetsHttpService.getPresets();
          setPresetList(presets);
        } else {
          const presets = await LeadsPresetsHttpService.getLeadsPresets();
          setPresetList(presets);
        }
      } catch (error: any) {
        const errorMessage = getErrorMessage(error);
        enqueueSnackbar(`Couldn't load presets: ${errorMessage}`, {
          variant: "error",
        });
      }
    };

    if (modalOpen) {
      fetchPresets();
    }
  }, [modalOpen, currentTab, setPresetList, refresh]);

  useEffect(() => {
    if (!modalOpen) {
      setSelectedPreset(null);
      resetSelectedPreset();
    }
  }, [modalOpen, resetSelectedPreset]);

  const handleCancel = () => {
    setModalOpen(false);
    setWarningMessage("");
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedPresetId = parseInt(event.target.value);
    const selectedPreset = presetList.find(
      (preset) => preset.id === selectedPresetId
    );
    setSelectedPreset(selectedPreset || null);
  };

  const handleDeleteClick = (id: number) => {
    setPresetToDelete(id);
    setDeleteModalOpen(true);
  };

  const handleEditClick = (preset: Preset) => {
    setEditedPreset(preset);
  };

  const handleCancelEdit = () => {
    setEditedPreset(null);
    setWarningMessage("");
  };

  const handleSaveEdit = async () => {
    try {
      if (editedPreset) {
        if (currentTab === "project") {
          await PresetsHttpService.updatePreset(editedPreset?.id, editedPreset);
        } else {
          await LeadsPresetsHttpService.updateLeadsPreset(
            editedPreset?.id,
            editedPreset
          );
        }
        setRefresh((prev) => !prev);
        enqueueSnackbar("Preset Successfully Updated", {
          variant: "success",
        });
      }
    } catch (error: any) {
      if (error.response && error.response.status === 409) {
        setWarningMessage(
          "Preset name already used. Please try a different name."
        );
      }
      const errorMessage = getErrorMessage(error);
      enqueueSnackbar(`Could not save preset: ${errorMessage}`, {
        variant: "error",
      });
    } finally {
      setEditedPreset(null);
      setWarningMessage("");
    }
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    editedPreset &&
      setEditedPreset({ ...editedPreset, name: event.target.value });
  };

  const handleSelectVentureClient = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    editedPreset &&
      setEditedPreset({
        ...editedPreset,
        ventureClientId: +event.target.value,
      });
  };

  const handleSharePresetToggle = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!editedPreset) return;

    if (event.target.checked) {
      setEditedPreset({
        ...editedPreset,
        ventureClientId: isExternalUser
          ? ventureClientId ?? null
          : +ventureClientSelectOptions[0].id,
      });
    } else {
      setEditedPreset({
        ...editedPreset,
        ventureClientId: null,
      });
    }
  };

  const handleDeleteConfirm = async (id: number) => {
    try {
      if (currentTab === "project") {
        await PresetsHttpService.deletePreset(id);
      } else {
        await LeadsPresetsHttpService.deleteLeadsPreset(id);
      }

      enqueueSnackbar("Preset Successfully Deleted", {
        variant: "success",
      });

      const updatedPresetList = presetList.filter((preset) => preset.id !== id);
      setPresetList(updatedPresetList);
      setDeleteModalOpen(false);
      setAppliedPreset(null);
    } catch (error) {
      const errorMessage = getErrorMessage(error as AxiosError);
      enqueueSnackbar(`Could not delete preset: ${errorMessage}`, {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Dialog
        open={modalOpen}
        onClose={handleCancel}
        fullWidth
        maxWidth="xs"
        data-testid="load-preset-modal"
        PaperProps={{
          sx: {
            gap: theme.spacing(4),
          },
        }}
      >
        <DialogTitle>Presets</DialogTitle>
        <Typography color="text.mediumEmphasis" mb={-2}>
          Load, edit and apply one of your custom reporting presets to the
          Project List.
        </Typography>
        <ScrollableDialogContent>
          {presetList.length !== 0 ? (
            <>
              <Box mt={theme.spacing(1)}>
                <FormControl component="fieldset" fullWidth>
                  <RadioGroup
                    aria-label="preset"
                    name="preset"
                    value={selectedPreset ? selectedPreset.id.toString() : ""}
                    onChange={handleChange}
                    sx={{ display: "flex", gap: 2 }}
                  >
                    {presetList.map((preset, index) => (
                      <Stack key={preset.id} gap={2}>
                        <Box
                          data-testid={`preset-option-${index + 1}`}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          width="100%"
                        >
                          <FormControlLabel
                            value={preset.id.toString()}
                            control={<Radio />}
                            label={
                              <Box>
                                <Box display="flex" gap={2}>
                                  <Typography>{preset.name}</Typography>
                                  <>
                                    {preset.ventureClientId && (
                                      <Box
                                        display="flex"
                                        alignItems="center"
                                        gap={0.5}
                                      >
                                        <GroupOutlined
                                          sx={{
                                            color: "icon.mediumEmphasis",
                                            fontSize: 12,
                                          }}
                                        />
                                        {isExternalUser ? (
                                          <Typography
                                            variant="body2"
                                            color="text.mediumEmphasis"
                                          >
                                            Shared
                                          </Typography>
                                        ) : (
                                          <>
                                            {preset.ventureClient?.name && (
                                              <Typography
                                                variant="body2"
                                                color="text.mediumEmphasis"
                                              >
                                                {preset.ventureClient.name}
                                              </Typography>
                                            )}
                                          </>
                                        )}
                                      </Box>
                                    )}
                                  </>
                                </Box>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  Saved on:{" "}
                                  {formatDate(new Date(preset.dateCreated))}
                                </Typography>
                              </Box>
                            }
                          />
                          {+preset.userId === user?.id && (
                            <>
                              <IconButton
                                className="edit-icon"
                                onClick={() => handleEditClick(preset)}
                                data-testid="edit-preset"
                                sx={{ color: "icon.action.main", ml: "auto" }}
                              >
                                <EditOutlined />
                              </IconButton>
                              <IconButton
                                className="delete-icon"
                                onClick={() => handleDeleteClick(preset.id)}
                                data-testid="delete-preset"
                              >
                                <DeleteOutlineOutlinedIcon />
                              </IconButton>
                            </>
                          )}
                        </Box>
                        {editedPreset?.id === preset.id && (
                          <Stack
                            p={2}
                            gap={2}
                            borderRadius={theme.shape.radius.minimal}
                            boxShadow={theme.boxShadows[12]}
                          >
                            <TextField
                              autoFocus
                              margin="none"
                              id="presetName"
                              label="Name"
                              fullWidth
                              value={editedPreset.name}
                              onChange={handleNameChange}
                              error={warningMessage !== ""}
                              helperText={warningMessage}
                              data-testid="preset-name-input"
                              InputLabelProps={{ shrink: true }}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={sharePreset}
                                  onChange={handleSharePresetToggle}
                                />
                              }
                              label={`Share preset with ${
                                isExternalUser ? "team" : "Venture Client"
                              }`}
                            />
                            {sharePreset && !isExternalUser && (
                              <SelectInput
                                label="Venture Client"
                                selectValues={ventureClientSelectOptions}
                                value={
                                  ventureClientSelectOptions.length
                                    ? editedPreset.ventureClientId
                                    : ""
                                }
                                onChange={handleSelectVentureClient}
                                editMode={true}
                                required
                                fullWidth
                              />
                            )}
                            <Box
                              display="flex"
                              gap={1}
                              justifyContent="flex-end"
                            >
                              <Button onClick={handleCancelEdit}>Cancel</Button>
                              <Button
                                onClick={handleSaveEdit}
                                variant="contained"
                              >
                                Save
                              </Button>
                            </Box>
                          </Stack>
                        )}
                      </Stack>
                    ))}
                  </RadioGroup>
                </FormControl>
              </Box>
            </>
          ) : (
            <Stack>
              <Box marginBottom={2}>
                <Typography color="text.disabled">
                  No presets created yet.
                </Typography>
              </Box>
              <Box>
                <Typography color="text.disabled">
                  Apply your desired column settings, filters, and sorting, then
                  click &apos;Add Preset&apos; to create one.
                </Typography>
              </Box>
            </Stack>
          )}
        </ScrollableDialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={!selectedPreset || presetList.length === 0}
            onClick={() => {
              setAppliedPreset(selectedPreset as Preset);
              handleCancel();
              griStateToLoad(JSON.parse(selectedPreset?.state as string));
            }}
          >
            Load
          </Button>
        </DialogActions>
      </Dialog>
      {presetToDelete && (
        <DeleteOrRemoveModal
          entity="preset"
          handleDelete={handleDeleteConfirm}
          id={presetToDelete}
          setModalOpen={setDeleteModalOpen}
          modalOpen={deleteModalOpen}
          actionType="delete"
        />
      )}
    </>
  );
};

export default LoadPresetModal;
