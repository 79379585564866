import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { ReactElement } from "react";
import theme from "../../../../theme";
import { QualityCheck } from "../../../../Types/QualityCheck";
import QualityVerificationProgress from "./QualityVerificationProgress";

interface QualityVerificationEditModalProps {
  open: boolean;
  handleCancel: () => void;
  qualityChecks: QualityCheck[];
  qualityCheckOptions: QualityCheck[];
  updateQualityChecks: React.Dispatch<React.SetStateAction<QualityCheck[]>>;
  handleSave: () => void;
}

export default function QualityVerificationEditModal({
  open,
  handleCancel,
  qualityChecks,
  updateQualityChecks,
  qualityCheckOptions,
  handleSave,
}: QualityVerificationEditModalProps): ReactElement {
  const handleCheckOption = (option: QualityCheck) => {
    const isSelected = !!qualityChecks.find(
      (qualityCheck) => qualityCheck.id === option.id
    );

    if (isSelected) {
      updateQualityChecks((prev) =>
        prev.filter((prevOption) => prevOption.id != option.id)
      );
    } else {
      updateQualityChecks((prev) => [...prev, option]);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      PaperProps={{
        sx: {
          gap: theme.spacing(4),
        },
      }}
      data-testid="quality-verification-edit-modal"
    >
      <DialogTitle>Quality Verification</DialogTitle>
      <DialogContent>
        <Stack gap={3}>
          <Typography color="text.mediumEmphasis">
            Select the quality criteria met by this startup to evaluate its
            suitability for Venture Client projects:
          </Typography>
          <Stack>
            {qualityCheckOptions.map((qualityCheckOption) => {
              const isChecked = !!qualityChecks.find(
                (qualityCheck) => qualityCheck.id === qualityCheckOption.id
              );

              return (
                <Box
                  key={qualityCheckOption.id}
                  display="flex"
                  alignItems="center"
                  gap={1}
                  ml={-1.5}
                >
                  <Checkbox
                    checked={isChecked}
                    onChange={() => handleCheckOption(qualityCheckOption)}
                  />
                  <Typography> {qualityCheckOption.description}</Typography>
                </Box>
              );
            })}
          </Stack>
          <QualityVerificationProgress
            progress={qualityChecks.length}
            target={qualityCheckOptions.length}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
