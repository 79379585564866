import { ReactElement } from "react";
import {
  Project,
  ProjectStartupOpportunityAssociation,
} from "../../../../Types/Project";
import { Box, Stack, Typography } from "@mui/material";
import { formatDate } from "../../../../utils";

interface ProjectMainInformationProps {
  project: Project | ProjectStartupOpportunityAssociation;
}

export default function ProjectMainInformation(
  props: ProjectMainInformationProps
): ReactElement {
  return (
    <Box display="flex" gap={4}>
      <InfoItem
        label="Venture Client"
        value={props.project.businessUnit.ventureClient.name}
      />
      <InfoItem
        label="Organizational Unit"
        value={props.project.businessUnit.name}
      />
      <InfoItem label="Created" value={formatDate(props.project.dateCreated)} />
    </Box>
  );
}

interface InfoItemProps {
  value: string;
  label: string;
}

export function InfoItem(props: InfoItemProps): ReactElement {
  return (
    <Stack>
      <Typography variant="body2">{props.value}</Typography>
      <Typography variant="overline" color="text.mediumEmphasis">
        {props.label}
      </Typography>
    </Stack>
  );
}
