import axios from "axios";
import { ProjectFilterCriteria } from "../../Types/Project";
import {
  CreateLeadProjectDTO,
  CreateLeadProjectLeaderDTO,
  LaunchProjectDTO,
  LeadProject,
  LeadProjectDTO,
  LeadProjectLeader,
  LeadProjectUseCase,
} from "../../Types/LeadProject";
import LeadProjectMapper from "./LeadProjects.mapper";

export class LeadProjectsHttpService {
  public static async getLeadProjects(
    filterCriteria?: ProjectFilterCriteria
  ): Promise<LeadProject[]> {
    const response = await axios.get<LeadProjectDTO[]>(`/api/lead-project`, {
      params: {
        filterCriteria: JSON.stringify(filterCriteria),
      },
    });

    return response.data.map((leadProject) =>
      LeadProjectMapper.map(leadProject)
    );
  }

  public static async getAllGenericLeadProjects(
    searchValue?: string
  ): Promise<LeadProject[]> {
    const response = await axios.get<LeadProjectDTO[]>(`/api/lead-project`, {
      params: {
        fetchAll: true,
        searchValue,
      },
    });

    return response.data.map((leadProject) =>
      LeadProjectMapper.map(leadProject)
    );
  }

  public static async getLeadProjectById(
    id: string | number,
    representation?: string
  ): Promise<LeadProject> {
    const response = await axios.get<LeadProjectDTO>(
      `/api/lead-project/${id}`,
      {
        params: {
          representation,
        },
      }
    );
    return LeadProjectMapper.map(response.data);
  }

  public static async createLeadProject(
    leadProject: CreateLeadProjectDTO
  ): Promise<number> {
    const response = await axios.post<number>("/api/lead-project", leadProject);
    return response.data;
  }

  public static async updateLeadProject(
    newLead: Partial<LeadProject>
  ): Promise<void> {
    await axios.put<void>(`/api/lead-project/${newLead.id}`, newLead);
  }

  public static async addLeadProjectLeader(
    leadProjectLeader: CreateLeadProjectLeaderDTO
  ): Promise<void> {
    const leadId = leadProjectLeader.leadProjectId;
    await axios.post(`/api/lead-project/${leadId}/leader`, leadProjectLeader);
  }

  public static async updateLeadProjectLeader(
    leadProjectLeader: LeadProjectLeader
  ): Promise<void> {
    const leadId = leadProjectLeader.leadProjectId;
    await axios.put<void>(
      `/api/lead-project/${leadId}/leader/${leadProjectLeader.id}`,
      leadProjectLeader
    );
  }

  public static async deleteLeadProjectLeader(
    leadId: number,
    id: number
  ): Promise<void> {
    await axios.delete<void>(`/api/lead-project/${leadId}/leader/${id}`);
  }

  public static async addUseCase(useCase: {
    name: string;
    leadProjectId: number;
    description: string;
  }): Promise<LeadProjectUseCase> {
    const leadId = useCase.leadProjectId;
    const response = await axios.post<LeadProjectUseCase>(
      `/api/lead-project/${leadId}/use-case`,
      useCase
    );
    return response.data;
  }

  public static async deleteUseCase(leadId: number, id: number): Promise<void> {
    await axios.delete<void>(`/api/lead-project/${leadId}/use-case/${id}`);
  }

  public static async updateUseCase(
    useCase: LeadProjectUseCase
  ): Promise<LeadProjectUseCase> {
    const leadId = useCase.leadProjectId;
    const response = await axios.put<LeadProjectUseCase>(
      `/api/lead-project/${leadId}/use-case/${useCase.id}`,
      useCase
    );
    return response.data;
  }

  public static async addLeadOpportunity(opportunity: {
    leadProjectId: number;
    startupId: number;
  }): Promise<void> {
    const leadId = opportunity.leadProjectId;
    await axios.post(
      `/api/lead-project/${leadId}/lead-opportunity`,
      opportunity
    );
  }

  public static async deleteLeadOpportunity(
    leadId: number,
    id: number
  ): Promise<void> {
    await axios.delete<void>(
      `/api/lead-project/${leadId}/lead-opportunity/${id}`
    );
  }

  public static async launchProject(
    launchProject: LaunchProjectDTO
  ): Promise<number> {
    const leadId = launchProject.leadProjectId;
    const response = await axios.post<number>(
      `/api/lead-project/${leadId}/launch`,
      launchProject
    );
    return response.data;
  }
}
