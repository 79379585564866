import {
  styled,
  TableCell,
  TableRow,
  Typography,
  debounce,
} from "@mui/material";
import { ReactElement, useCallback, useState, useContext } from "react";
import { Requirement } from "../../../../../../../Types/Requirement";
import CustomTextField from "../../../../../../UI/InputFields/CustomTextField";
import { Opportunity } from "../../../../../../../Types/Opportunity";
import { GlobalLoaderContext } from "../../../../../../../Context/LoaderContext";
import { FunnelStage } from "../../../../../../../Types/Project";
import { CommentOutlined } from "@mui/icons-material";
import theme from "../../../../../../../theme";

const CHAR_LIMIT_INPUTS = 320;

const CommentRow = styled(TableRow)(() => ({
  cursor: "pointer",
  "& td": { backgroundColor: theme.palette.surface.secondary.light },
}));

const CommentHeader = styled(TableCell)(() => ({
  display: "flex",
  justifyContent: "end",
}));

const StyledInput = styled(CustomTextField)(({ theme }) => ({
  marginBlock: 0,
  "& .MuiInputBase-root": {
    paddingTop: 0,
  },
  "& .MuiInputBase-input": {
    fontSize: theme.typography.caption.fontSize,
    paddingTop: theme.spacing(0.5),
  },
}));
interface CommentCellProps {
  requirement: Requirement;
  editingComment: number | "insights" | null;
  setEditingComment: (id: number | null) => void;
  commentChange: (
    selectedOpportunity: Opportunity,
    updatedRequirementId: number,
    updatedComment: string
  ) => void;
  opportunity: Opportunity;
}

export function CommentCell(props: CommentCellProps): ReactElement {
  const [requirementValue, setRequirementValue] = useState(
    props.opportunity.requirementStartupFits.find(
      (requirementFit) => requirementFit.requirementId === props.requirement.id
    )?.fit
  );
  const { globalLoader } = useContext(GlobalLoaderContext);

  const debouncedCommentInput = useCallback(
    debounce(
      (input: string) =>
        props.commentChange(props.opportunity, props.requirement.id, input),
      300
    ),
    [props.opportunity]
  );

  return (
    <TableCell
      className="requirement-cell"
      key={`comment-cell-${props.requirement.id}`}
      onClick={(event) => {
        if (!globalLoader) {
          event.stopPropagation();
          props.setEditingComment(props.requirement.id);
        }
      }}
      data-testid="requirement-comment"
    >
      {props.editingComment === props.requirement.id ? (
        <form
          data-testid="requirement-comment-input"
          noValidate
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
          }}
          onBlur={() => {
            props.setEditingComment(null);
          }}
        >
          <StyledInput
            id="requirement-cell-comment"
            editMode={true}
            autoFocus
            fullWidth
            onChange={(e) => {
              setRequirementValue(e.target.value);
              debouncedCommentInput(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                props.setEditingComment(null);
              }
            }}
            value={requirementValue}
            maxCharacter={CHAR_LIMIT_INPUTS}
            multiline
            maxRows={3}
            minRows={2}
          />
        </form>
      ) : (
        <Typography sx={{ width: "100%" }} variant="caption">
          {requirementValue}
        </Typography>
      )}
    </TableCell>
  );
}

interface RequirementCommentsProps {
  requirements: Requirement[];
  expandedComments: number | null;
  opportunity: Opportunity;
  toggleComment: () => void;
  commentChange: (
    selectedOpportunity: Opportunity,
    updatedRequirementId: number,
    updatedComment: string
  ) => void;
  handleInsightsUpdate: (updatedInsights: string) => void;
  isSelectionReasonVisible: boolean;
  funnelStage: FunnelStage;
}

export default function RequirementComments(
  props: RequirementCommentsProps
): ReactElement {
  const [editingComment, setEditingComment] = useState<
    number | "insights" | null
  >();
  const [insights, setInsights] = useState(props.opportunity.insights);
  const { globalLoader } = useContext(GlobalLoaderContext);

  const debouncedCommentInput = useCallback(
    debounce((input: string) => props.handleInsightsUpdate(input), 300),
    [props.opportunity]
  );

  return (
    <>
      {props.expandedComments === props.opportunity.id ? (
        <CommentRow
          onClick={() => {
            if (!globalLoader) {
              props.toggleComment();
              setEditingComment(null);
            }
          }}
          className="comment-column"
          data-testid="comment-column"
        >
          <CommentHeader>
            <CommentOutlined />
          </CommentHeader>
          <TableCell className="startup-link-logo">
            <Typography variant="subtitle2">Comments</Typography>
          </TableCell>
          <TableCell
            onClick={(event) => {
              if (!globalLoader) {
                event.stopPropagation();
                setEditingComment("insights");
              }
            }}
            className={`${
              props.funnelStage === "discover"
                ? "startup-description-short"
                : "startup-description-long"
            } highlighted-border`}
          >
            {editingComment === "insights" ? (
              <form
                data-testid="requirement-insights-input"
                noValidate
                autoComplete="off"
                onSubmit={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                onBlur={() => {
                  setEditingComment(null);
                }}
              >
                <StyledInput
                  id="insights-cell-insights"
                  editMode={true}
                  fullWidth
                  onChange={(e) => {
                    setInsights(e.target.value);
                    debouncedCommentInput(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setEditingComment(null);
                    }
                  }}
                  value={insights}
                  maxCharacter={CHAR_LIMIT_INPUTS}
                  multiline
                  autoFocus
                  maxRows={props.funnelStage === "discover" ? 3 : 5}
                  minRows={props.funnelStage === "discover" ? 3 : 5}
                />
              </form>
            ) : (
              <Typography variant="caption">{insights}</Typography>
            )}
          </TableCell>

          {props.requirements.length ? (
            props.requirements.map((requirement) => {
              return (
                <CommentCell
                  key={`requirement-comment-${requirement.id}`}
                  opportunity={props.opportunity}
                  commentChange={props.commentChange}
                  requirement={requirement}
                  editingComment={editingComment ?? null}
                  setEditingComment={(id) => setEditingComment(id)}
                />
              );
            })
          ) : (
            <TableCell className="no-requirement-cell"></TableCell>
          )}
          <>
            {props.isSelectionReasonVisible ? (
              <TableCell
                data-testid="selection-reason-comment"
                className="selection-reason-comment"
              ></TableCell>
            ) : null}
          </>
        </CommentRow>
      ) : null}
    </>
  );
}
