import { IconButton, IconButtonProps, Menu, MenuItem } from "@mui/material";
import { ReactElement, useState } from "react";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { DeleteOutlined, EditOutlined } from "@mui/icons-material";

type Props = IconButtonProps & {
  entity:
    | "objective"
    | "kpi"
    | "cluster"
    | "meeting"
    | "variable"
    | "lead-project-leader"
    | "project-status-comment"
    | "lead-status-comment";

  setEditModalOpen: (state: boolean) => void;
  setDeleteModalOpen: (state: boolean) => void;
};

const EditDeleteMenu = (props: Props): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { entity, setEditModalOpen, setDeleteModalOpen, ...defaultProps } =
    props;
  const editOpen = Boolean(anchorEl);
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        {...defaultProps}
        onClick={handleMenuOpen}
        data-testid={`${entity}-menu-button`}
      >
        <MoreVertOutlinedIcon fontSize="inherit" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={editOpen}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{
          "& ul": {
            minWidth: "100px",
          },
        }}
      >
        <MenuItem
          onClick={(event) => {
            event.stopPropagation();
            setEditModalOpen(true);
            setAnchorEl(null);
          }}
          sx={{
            paddingInline: 0.7,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Edit
          <EditOutlined sx={{ fontSize: "16px" }} />
        </MenuItem>

        <MenuItem
          onClick={(event) => {
            event.stopPropagation();
            setDeleteModalOpen(true);
            setAnchorEl(null);
          }}
          sx={{
            paddingInline: 0.7,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Delete
          <DeleteOutlined sx={{ fontSize: "16px" }} />
        </MenuItem>
      </Menu>
    </>
  );
};

export default EditDeleteMenu;
