import axios from "axios";
import { FocusArea } from "../../Types/VentureClient";

export class FocusAreasHttpService {
  public static async getAll(): Promise<FocusArea[]> {
    return (await axios.get<FocusArea[]>(`/api/focus-areas`)).data;
  }

  public static async createFocusArea(
    focusArea: Omit<FocusArea, "id">
  ): Promise<FocusArea> {
    const response = await axios.post<FocusArea>(`/api/focus-areas`, focusArea);
    return response.data;
  }
}
