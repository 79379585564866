import React, { ReactElement } from "react";
import Grid from "@mui/material/Grid";
import Form from "./Form";
import { Paper } from "@mui/material";
import { VentureClient } from "../../../../Types/VentureClient";

interface Props {
  handleSave: () => void;
  ventureClientData: VentureClient;
}

export default function DetailsView(props: Props): ReactElement {
  return (
    <Grid
      container
      spacing={3}
      component={Paper}
      variant="outlined"
      sx={{ p: 4 }}
      display="flex"
    >
      <Form
        ventureClientData={props.ventureClientData}
        handleSave={props.handleSave}
      />
    </Grid>
  );
}
