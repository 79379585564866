import { Box, Stack, styled, Typography } from "@mui/material";
import { AxiosError } from "axios";
import { enqueueSnackbar } from "notistack";
import { ReactElement, useState } from "react";
import ProjectHistoryHttpService from "../../../../../Http/ProjectHistory/ProjectHistory.http.service";
import theme from "../../../../../theme";
import { ProjectHistory } from "../../../../../Types/ProjectHistory";
import {
  capitalizeFirstLetter,
  checkExternalUser,
  formatDate,
  getErrorMessage,
} from "../../../../../utils";
import ContactName from "../../../../Contacts/ContactCard/ContactName";
import CustomExpendableText from "../../../../UI/CustomExpendableText";
import EditDeleteMenu from "../../../../UI/EditDeleteMenu";
import ManageStatusComment from "./ManageStatusComment";
import {
  Check,
  East,
  Pause,
  PlayArrow,
  Stop,
  Stream,
} from "@mui/icons-material";
import DeleteOrRemoveModal from "../../../../UI/Modals/DeleteOrRemoveModal/DeleteOrRemoveModal";

const Container = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
  gap: theme.spacing(2),
}));

const IconWrapper = styled(Box)(() => ({
  padding: theme.spacing(1),
  height: theme.spacing(4),
  borderRadius: "50%",
  backgroundColor: theme.palette.surface.mediumEmphasis,
}));

interface Props {
  onHoldDeadline: string | null;
  handleSave: () => void;
  history: ProjectHistory;
  refreshHistory: () => void;
}

const ProjectHistoryItem = (props: Props): ReactElement => {
  const { history, refreshHistory, handleSave } = props;
  const isExternalUser = checkExternalUser(history.user);
  const [manageCommentModal, setManageCommentModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleDeleteComment = async () => {
    await ProjectHistoryHttpService.deleteProjectHistory(history.id)
      .then(() => {
        enqueueSnackbar("Status Comment successfully deleted", {
          variant: "success",
        });
        refreshHistory();
        handleSave();
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error as AxiosError);
        enqueueSnackbar(`Could not delete the comment: ${errorMessage}`, {
          variant: "error",
        });
      });
  };

  switch (history.type) {
    case "Project Status Comment":
      return (
        <>
          <Stack px={2} gap={2} data-testid="status-comment-history">
            <Box display="flex" justifyContent="space-between">
              <ContactName
                isVentureAssociate={!isExternalUser}
                orientation="horizontal"
                name={history.user.name}
                title={formatDate(history.dateTriggered)}
              />
              <EditDeleteMenu
                entity="project-status-comment"
                setEditModalOpen={setManageCommentModal}
                setDeleteModalOpen={setIsDeleteModalOpen}
                sx={{ fontSize: 18 }}
              />
            </Box>
            <Typography whiteSpace="pre-wrap">
              <CustomExpendableText text={history.description} />
            </Typography>
          </Stack>
          {isDeleteModalOpen && (
            <DeleteOrRemoveModal
              id={history.projectId}
              modalOpen={isDeleteModalOpen}
              setModalOpen={setIsDeleteModalOpen}
              entity="Comment"
              handleDelete={handleDeleteComment}
              actionType="delete"
            />
          )}

          {manageCommentModal && (
            <ManageStatusComment
              modalOpen={manageCommentModal}
              setModalOpen={setManageCommentModal}
              projectId={history.projectId}
              handleSave={props.handleSave}
              openAssociatedModal={false}
              setProjectStatusOverviewModal={() => false}
              refreshHistory={props.refreshHistory}
              history={history}
            />
          )}
        </>
      );
    case "Project Stage Change":
      return (
        <Container data-testid="stage-change-history">
          <IconWrapper>
            <East sx={{ color: "white", fontSize: "1rem" }} />
          </IconWrapper>
          <Typography>
            The project has been moved to{" "}
            <b>{capitalizeFirstLetter(history.newValue || "")}</b> stage.
          </Typography>
          <Box ml="auto" display="flex" alignItems="center" gap={1}>
            <Typography variant="body2">{history.user.name}</Typography>
            <Typography
              variant="body2"
              color={theme.palette.text.mediumEmphasis}
            >
              {formatDate(history.dateTriggered)}
            </Typography>
          </Box>
        </Container>
      );
    case "Project Status Change":
      let icon;
      switch (history.newValue) {
        case "on hold":
          icon = (
            <IconWrapper>
              <Pause sx={{ color: "white", fontSize: "1rem" }} />
            </IconWrapper>
          );
          break;
        case "archived":
          icon = (
            <IconWrapper>
              <Stop sx={{ color: "white", fontSize: "1rem" }} />
            </IconWrapper>
          );
          break;
        case "adopted":
          icon = (
            <IconWrapper>
              <Check sx={{ color: "white", fontSize: "1rem" }} />
            </IconWrapper>
          );
          break;
        default:
          icon = (
            <IconWrapper>
              <PlayArrow sx={{ color: "white", fontSize: "1rem" }} />
            </IconWrapper>
          );
      }
      const isOnHold = history.newValue === "on hold";
      const isArchived = history.newValue === "archived";
      const isOnHoldorArchived = isOnHold || isArchived;
      const onHoldDeadline = props.onHoldDeadline
        ? formatDate(new Date(props.onHoldDeadline))
        : null;
      const reason = history.projectStatusChangeReason?.description;

      return (
        <Stack
          bgcolor="background.default"
          p={2}
          gap={2}
          data-testid="status-change-history"
        >
          <Box display="flex" alignItems="center" gap={2}>
            {icon}
            <Typography>
              The project has been set to <b>{history.newValue}</b>.
            </Typography>
            <Box ml="auto" display="flex" alignItems="center" gap={1}>
              <Typography variant="body2">{history.user.name}</Typography>
              <Typography
                variant="body2"
                color={theme.palette.text.mediumEmphasis}
              >
                {formatDate(history.dateTriggered)}
              </Typography>
            </Box>
          </Box>

          {(isOnHoldorArchived || history.description) && (
            <Stack gap={1}>
              {isOnHoldorArchived && (
                <Box display="flex" gap={3}>
                  <Box display="flex" alignItems="center">
                    <Typography
                      variant="body2"
                      color={theme.palette.text.mediumEmphasis}
                    >
                      Reason:
                    </Typography>
                    &nbsp;
                    <Typography variant="body2">{reason}</Typography>
                  </Box>
                  {isOnHold && (
                    <Box display="flex" alignItems="center">
                      <Typography
                        variant="body2"
                        color={theme.palette.text.mediumEmphasis}
                      >
                        On hold until:
                      </Typography>
                      &nbsp;
                      <Typography variant="body2">{onHoldDeadline}</Typography>
                    </Box>
                  )}
                </Box>
              )}
              {history.description && (
                <Typography whiteSpace="pre-wrap">
                  {history.description}
                </Typography>
              )}
            </Stack>
          )}
        </Stack>
      );
    case "Project Created":
      return (
        <Container data-testid="project-created-history">
          <IconWrapper>
            <Stream sx={{ color: "white", fontSize: "1rem" }} />
          </IconWrapper>
          <Typography>
            The project has been <b>launched</b>.
          </Typography>
          <Box ml="auto" display="flex" alignItems="center" gap={1}>
            <Typography variant="body2">{history.user.name}</Typography>
            <Typography
              variant="body2"
              color={theme.palette.text.mediumEmphasis}
            >
              {formatDate(history.dateTriggered)}
            </Typography>
          </Box>
        </Container>
      );
    default:
      return <></>;
  }
};

export default ProjectHistoryItem;
